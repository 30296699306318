import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { SearchList } from 'forms/fields'
import _ from 'lodash'
import City from 'models/base/City'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	FormFeedback,
	Input,
} from 'reactstrap'
import styles from './styles.module.scss'

const emptyList = [City.create({})]

/**
 * Render city field
 *
 * @param {Function} setState
 * @param {string} value
 * @param {Object} field
 * @param {Function} getCity
 * @param {Function} getSearchList
 * @param {Object} searchListState
 * @param {Object} invalid
 *
 * @return {*}
 * @constructor
 */
export const CityField = ({
	setState,
	value,
	field,
	getCity,
	getSearchList,
	searchListState,
	invalid,
}) => {
	const { id, title, disabled } = field

	const [inputValue, setInputValue]           = useState('')
	const [currentCity, setCurrentCity]         = useState(emptyList)
	const [isResultShown, setIsResultShown]     = useState(false)
	const [isInputDisabled, setIsInputDisabled] = useState(false)

	const inputRef = useRef()

	const getSearchQuery = useCallback(searchString => DataProvider
		.buildQuery()
		.addBody({ query: { filter: { title: { like: `%${searchString}%` } } } }, true)
		.setSuccessCallback(res => setCurrentCity(City.createList(res))), [])

	const sendRequest = useCallback(_.debounce((data, isInitialFetch = false) => {
		if (!data) {
			setCurrentCity(emptyList)
		} else if (isInitialFetch) {
			const searchQuery = DataProvider
				.buildQuery()
				.addBody({ id: data })
				.setSuccessCallback(res => {
					setInputValue(City.create(res).getTitle() || '')
					setIsInputDisabled(false)
				})

			getCity(searchQuery)
		} else {
			getSearchList(getSearchQuery(data))
		}
	}, 400), [])

	const getList = useCallback(searchString => {
		setInputValue(searchString)
		sendRequest(searchString)
	}, [sendRequest])

	useEffect(() => {
		if (value && !inputValue.length) {
			setIsInputDisabled(true)
			setInputValue('Загрузка города...')
			sendRequest(value, true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, sendRequest])

	const setValues = useCallback(cityId => {
		setState(id, cityId)
	}, [id, setState])

	const checkAttributes = useCallback(city => {
		setInputValue(city.getTitle())
		setIsResultShown(false)
		setValues(city.getId())
	}, [setValues])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<Input
				type="text"
				value={inputValue}
				className={(isResultShown && !!inputValue.length && styles.active) || ''}
				onChange={event => getList(event.target.value)}
				onFocus={() => setIsResultShown(true)}
				onBlur={() => setTimeout(() => setIsResultShown(false), 200)}
				innerRef={inputRef}
				invalid={invalid.result}
				disabled={isInputDisabled || disabled}
				placeholder={`${title}...`}
			/>
			<FormFeedback>{invalid.message}</FormFeedback>
			<SearchList
				inputValue={inputValue}
				isFetching={StateService.create(searchListState).isFetching()}
				isActive={isResultShown && !!inputValue.length}
				items={currentCity}
				action={checkAttributes}
			/>
		</div>
	)
}

CityField.propTypes = {
	field:           PropTypes.object,
	getCity:         PropTypes.func,
	getSearchList:   PropTypes.func,
	invalid:         PropTypes.object,
	searchListState: PropTypes.object,
	setState:        PropTypes.func,
	value:           PropTypes.number,
}

CityField.defaultProps = {
	field:           {},
	getCity:         () => null,
	getSearchList:   () => null,
	invalid:         {},
	searchListState: {},
	setState:        () => null,
	value:           undefined,
}
