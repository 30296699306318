import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { CheckBox } from 'components/global/Inputs/Checkbox'
import { InputLabel } from 'components/global/Inputs/InputLabel'
import { authMethods } from 'forms/user/config/fields/AuthMethods/data'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render auth methods field
 *
 * @param {Array} enabledMethods
 * @param {function} handler
 *
 * @return {*}
 */
export const AuthMethodsField = ({ enabledMethods, handler }) => (
	<>
		<FieldLabel title="Методы аутентификации" id="authentication" />
		<div className={styles.authMethods}>
			{authMethods.map(method => {
				const isChecked = enabledMethods.includes(method.value) ?? false

				return (
					<div key={method.label} className={styles.checkbox}>
						<InputLabel label={method.label} checked={isChecked}>
							<CheckBox
								value={method.value}
								onChange={event => handler(event.target.value, isChecked)}
								checked={isChecked}
							/>
						</InputLabel>
					</div>
				)
			})}
		</div>
	</>
)

AuthMethodsField.propTypes = {
	enabledMethods: PropTypes.array,
	handler:        PropTypes.func,
}

AuthMethodsField.defaultProps = {
	enabledMethods: [],
	handler:        () => null,
}
