import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * Service model class
 */
class Service extends BaseModel {
	/**
	 * Create Service instance
	 *
	 * @param {Object} object
	 *
	 * @returns {Service}
	 */
	static create(object) {
		return super.create(object?.result?.model || object.result || object)
	}

	/**
	 * Create Service list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<Service>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map customers
	 *
	 * @param {Array.<object>} objects
	 * @param {function(Service, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get title
	 *
	 * @returns {string}
	 */
	getTitle = () => this.model.title

	/**
	 * Get alias
	 *
	 * @returns {string}
	 */
	getAlias = () => this.model.alias

	/**
	 * Get description
	 *
	 * @returns {string}
	 */
	getDescription = () => this.model.description

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get docker image
	 *
	 * @return {string}
	 */
	getDockerImage = () => this.model.dockerImage

	/**
	 * Get project services
	 *
	 * @returns {Object}
	 */
	getProjectServices = () => this.model.project_services

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default Service
