import React from 'react'
import styles from './styles.module.scss'

export const lightSearchString = (searchString, title) => {
	const idx = title.toLowerCase()
		.indexOf(searchString.toLowerCase())

	const str  = title.split('')
		.slice(idx, idx + searchString.length)
		.join('')
	const str1 = title.split('')
		.slice(0, idx)
		.join('')
	const str2 = title.split('')
		.slice(idx + searchString.length)
		.join('')

	if (!searchString.length) {
		return <span>{title}</span>
	}
	return (
		<>
			<span>{str1}</span>
			<span className={styles.lightSearchString}>{str}</span>
			<span>{str2}</span>
		</>
	)
}
