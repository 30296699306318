import { JsonInputField } from 'forms/fields'
import React from 'react'

export const firebaseConfigFields = [
	{ id: 'databaseURL', title: 'URL Базы данных' },
	{ id: 'fcmToken', title: 'FCM токен' },
	{ id: 'webPushToken', title: 'Web Push токен' },
	{ id: 'credential', title: 'Учетные данные', component: <JsonInputField /> },
	{ id: 'frontendCredentials', title: 'Учетные данные фронтенда', component: <JsonInputField /> },
]
