import { Button } from 'components/global/Button'
import styles from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/ServicesList/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render services list
 *
 * @param {Array.<Service>} services
 * @param {Function} switchService
 * @param {string} activeService
 *
 * @return {*}
 * @constructor
 */
export const ServicesList = ({ services, switchService, activeService }) => (
	<section className={styles.container}>
		<div className={styles.title}>Выберите сервис</div>
		<div className={styles.wrapper}>
			{services.map(service => (
				<Button
					key={service.getAlias()}
					className={[styles.service, activeService === service.getAlias() && styles.active].join(' ')}
					onClick={() => switchService(service.getAlias())}
				>
					{service.getTitle()}
				</Button>
			))}
		</div>
	</section>
)

ServicesList.propTypes = {
	activeService: PropTypes.string,
	services:      PropTypes.array,
	switchService: PropTypes.func,
}

ServicesList.defaultProps = {
	activeService: '',
	services:      [],
	switchService: () => null,
}
