import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTHORIZATION_ATTRIBUTES_ACTIONS } from 'reduxm/redux-saga/modules/authorization/attributes/actionTypes'

export const AuthorizationAttributesActions = {
	list:       RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ATTRIBUTES_ACTIONS.LIST),
	create:     RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ATTRIBUTES_ACTIONS.CREATE),
	update:     RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ATTRIBUTES_ACTIONS.UPDATE),
	view:       RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ATTRIBUTES_ACTIONS.VIEW),
	remove:     RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ATTRIBUTES_ACTIONS.REMOVE),
	setList:    RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_ATTRIBUTES_ACTIONS.LIST),
	setView:    RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_ATTRIBUTES_ACTIONS.VIEW),
	searchList: RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ATTRIBUTES_ACTIONS.SEARCH_LIST),
}
