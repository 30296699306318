import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render searching results
 *
 * @param {boolean} isVisible
 * @param {Array} list
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const SearchingResults = ({ isVisible, list, action }) => (
	isVisible && !!list.length &&
	<div className={styles.searchResult}>
		{list.map(item => (
			<Button
				key={item.getAlias()}
				className={styles.searchItem}
				onClick={() => action(item)}
			>
				{item.getTitle()}
			</Button>
		))}
	</div>
)

SearchingResults.propTypes = {
	action:    PropTypes.func,
	isVisible: PropTypes.bool,
	list:      PropTypes.array,
}

SearchingResults.defaultProps = {
	action:    () => null,
	isVisible: false,
	list:      [],
}
