import { SvgIcon } from '@material-ui/core'
import { getIcon } from 'components/project/Home/SideBar/getIcon'
import styles from 'components/project/ServiceConfig/Users/SettingTabs/Users/UserCard/UserInfoItem/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render info item for user card
 *
 * @param {string} alias
 * @param {string} title
 * @param {string} info
 *
 * @return {*}
 * @constructor
 */
export const UserInfoItem = ({ alias, title, info }) => (
	<div className={styles.field}>
		<div className={styles.label}>
			<SvgIcon component={getIcon(alias)} />
			{title}
		</div>
		<div className={styles.info}>
			{info || 'Нет данных'}
		</div>
	</div>
)

UserInfoItem.propTypes = {
	alias: PropTypes.string,
	info:  PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	title: PropTypes.string,
}

UserInfoItem.defaultProps = {
	alias: '',
	info:  '',
	title: '',
}
