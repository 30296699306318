import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	list:   'authorization.filters.list',
	create: 'authorization.filters.create',
	remove: 'authorization.filters.remove',
	update: 'authorization.filters.update',
	view:   'authorization.filters.view',
}

/**
 * Call api to get list of filter models.
 *
 * @param {Object} searchQuery
 *
 * @return {function}
 */
const list = searchQuery => callApi(methods.list, searchQuery)

/**
 * Call api to update filter model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {function}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to remove filter model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

/**
 * Call api to create filter model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to view filter model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

export const AuthorizationFiltersApi = {
	list,
	update,
	remove,
	create,
	view,
}
