import ServicesTable from 'components/home/Tables/ServicesTable/index.store'
import SitesTable from 'components/home/Tables/SitesTable/index.store'
import {
	GatewayPage,
	HomePage,
	ProjectPage,
	ServicePage,
	UserPage,
} from 'containers'

export const routes = [
	{
		id:        1,
		path:      '/',
		component: HomePage,
		exact:     true,
	},
	{
		id:        2,
		path:      '/services',
		component: ServicesTable,
		exact:     true,
	},
	{
		id:        3,
		path:      '/sites',
		component: SitesTable,
		exact:     true,
	},
	{
		id:        4,
		path:      '/project/:alias?/:serviceType?/:service?/:settingTab?',
		component: ProjectPage,
		exact:     true,
	},
	{
		id:        5,
		path:      '/service/:alias',
		component: ServicePage,
		exact:     true,
	},
	{
		id:        6,
		path:      '/user',
		component: UserPage,
		exact:     true,
	},
	{
		id:        7,
		path:      '/gateway',
		component: GatewayPage,
		exact:     true,
	},
]
