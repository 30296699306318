import React from 'react'
import {
	ScalingPolicies,
	ServiceType,
	TaskDefinition,
} from './fields'

export const serviceFormFields = [
	{ id: 'title', title: 'Название' },
	{ id: 'alias', title: 'Алиас' },
	{ id: 'description', title: 'Описание' },
	{ id: 'dockerImage', title: 'Docker Image' },
	{ id: 'serviceType', title: 'Тип сервиса', component: <ServiceType /> },
	{ id: 'scalingPolicies', title: 'Политики масштабирования', component: <ScalingPolicies /> },
	{ id: 'taskDefinition', title: 'Определение задачи', component: <TaskDefinition /> },
]
