import { Footer } from 'components/wrapper/Footer'
import { Header } from 'components/wrapper/Header'
import PropTypes from 'prop-types'
import React from 'react'
import '../../main.scss'

/**
 * Render Wrapper with Header & Footer components
 *
 * @param {Object} children
 *
 * @return {*}
 * @constructor
 */
export const Wrapper = ({ children }) => (
	<>
		<Header />
		<main className="main">
			{children}
		</main>
		<Footer />
	</>
)

Wrapper.propTypes    = {
	children: PropTypes.object,
}

Wrapper.defaultProps = {
	children: {},
}
