import {
	BusinessCenterRounded as Copy,
	Edit,
	MoreVertRounded as More,
} from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { CollapseHeight } from 'components/global/CollapseHeight'
import CopySettingsFrom from 'components/global/CopySettingsFrom/index.store'
import { IosSwitch } from 'components/global/IosSwitch'
import { UpdateModel } from 'components/global/UpdateModel'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { CardHeader } from 'components/project/Home/Cards/ServiceCard/ServiceCardHeader'
import { projectFormFields } from 'forms'
import PropTypes from 'prop-types'
import React, {
	useRef,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render project card model header
 *
 * @param {Project} project
 * @param {Function} projectAction
 *
 * @return {*}
 * @constructor
 */
export const ProjectCardHeader = ({ project, projectAction }) => {
	const [isActionsOpen, setIsActionsOpen] = useState(false)

	const toggleRef = useRef()

	useCheckOutsideClick(toggleRef, isActionsOpen, setIsActionsOpen)

	return (
		<CardHeader model={project} type="project">
			<Button
				ref={toggleRef}
				className={styles.toggle}
				onClick={() => setIsActionsOpen(prev => !prev)}
			>
				<More />
			</Button>
			<CollapseHeight isOpen={isActionsOpen} top={65} right={10}>
				<div className={styles.action}>
					<IosSwitch
						action={() => projectAction(false)}
						checked={project.isPublished()}
						label="Опубликован"
					/>
				</div>
				<div className={styles.update}>
					<UpdateModel
						model={project}
						action={projectAction}
						formFields={projectFormFields(true)}
						title="Редактировать проект"
					>
						<Button className={styles.button}>
							Редактировать
							<Edit />
						</Button>
					</UpdateModel>
				</div>
				<div className={styles.update}>
					<CopySettingsFrom>
						<Button className={styles.button}>
							Копировать настройки
							<Copy />
						</Button>
					</CopySettingsFrom>
				</div>
			</CollapseHeight>
		</CardHeader>
	)
}

ProjectCardHeader.propTypes = {
	project:       PropTypes.object.isRequired,
	projectAction: PropTypes.func.isRequired,
}
