import AuthenticationConfig from 'components/project/ServiceConfig/Authentication/index.store'
import { AuthorizationConfig } from 'components/project/ServiceConfig/Authorization'
import MicroserviceConfig from 'components/project/ServiceConfig/MicroserviceConfig/index.store'
import SiteConfig from 'components/project/ServiceConfig/SiteConfig/index.store'
import { UsersConfig } from 'components/project/ServiceConfig/Users'
import { MicroserviceWrapper } from 'components/project/ServiceConfig/Wrapper'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useParams } from 'react-router'

/**
 * Render microservice details
 *
 * @param {Project} project
 *
 * @return {*}
 * @constructor
 */
export const ServiceConfig = ({ project }) => {
	const { serviceType, service } = useParams()

	let renderData    = null
	const allServices = project.getServices(true).concat(project.getSites())

	const getAlias = useCallback(() => allServices.find(item => item.getAlias() === service)?.getAlias?.(),
		[allServices, service])

	if (serviceType === 'microservice' && service) {
		switch (service) {
			case 'authentication':
				renderData = <AuthenticationConfig project={project} />
				break
			case 'authorization':
				renderData = <AuthorizationConfig
					project={project}
					services={project.getServices()}
				/>
				break
			case 'users':
				renderData = <UsersConfig project={project} />
				break
			default:
				renderData = <MicroserviceConfig
					projectId={project.getId()}
					service={getAlias()}
					type={serviceType}
				/>
		}
	}

	if (serviceType === 'site' && service) {
		renderData = <SiteConfig
			projectId={project.getId()}
			service={getAlias()}
		/>
	}

	return renderData && <MicroserviceWrapper>{renderData}</MicroserviceWrapper>
}

ServiceConfig.propTypes = {
	project: PropTypes.instanceOf(Project),
}

ServiceConfig.defaultProps = {
	project: Project.create({}),
}
