import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import {
	put,
	select,
} from '@redux-saga/core/effects'
import {
	call,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	FILES_ACTIONS,
	FilesActions,
	FilesApi,
	FilesSelectors,
} from 'reduxm/redux-saga/modules/control-panel/files'
import {
	ServiceSettingsActions,
	ServiceSettingsSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/service'
import { UsersManageSettingsActions } from 'reduxm/redux-saga/modules/users/manage/settings'

/**
 * View file model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, FilesApi.view)
}

/**
 * Remove file model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, FilesApi.remove)
}

/**
 * Updated file model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, FilesApi.update, null, null,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, FilesSelectors, FilesActions)
		})
}

/**
 * Create file model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, FilesApi.create)
}

/**
 * Remove file id from config.
 *
 * @param {Object} attributes
 * @param {string} name
 *
 * @return {Object}
 */
const removeFileIdFromConfig = (attributes, name) => {
	const { config: { [`${name}Id`]: removed, ...nextConfig } } = attributes

	return { ...attributes, config: nextConfig }
}

/**
 * Get is service config exist.
 *
 * @return {boolean}
 */
function* isServiceConfigExist() {
	const { result: { model: isModelExist } } = yield select(ServiceSettingsSelectors.view)

	return Boolean(isModelExist)
}

/**
 * Create file & update|create service config.
 *
 * @param {File|[File]} file
 * @param {string} name
 * @param {Object} attributes
 *
 * @return {Generator<*, void, ?>}
 */
export function* createFile(file, name, attributes) {
	const response = yield call(FilesApi.create, DataProvider.buildQuery().addBody({ file }))

	const { id, absoluteUrl } = response.result?.model ?? {}

	if (id) {
		const isConfigExist = yield isServiceConfigExist()

		attributes.config[name]        = absoluteUrl
		attributes.config[`${name}Id`] = id

		yield put(ServiceSettingsActions[isConfigExist ? 'update' : 'create']({ attributes }))
	}
}

/**
 * Upload default user image to settings model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 * @param {Object|Array.<Object>} action.payload.file file(s) to change
 * @param {Function} action.payload.callback Action callback
 */
export function* uploadFiles(action) {
	const { payload: { attributes, files, ids, names, callback } } = action

	function* upload(file, id, name) {
		const isConfigExist = yield isServiceConfigExist()

		if (file[0] && !file[0].isLoaded) {
			yield createFile(file[0], name, attributes)
		} else if (id) {
			yield put(UsersManageSettingsActions.removeDefaultImage({
				id, attributes: removeFileIdFromConfig(attributes, name),
			}))
		} else if (isConfigExist) {
			const nextAttributes = isConfigExist ? removeFileIdFromConfig(attributes, name) : attributes

			yield put(ServiceSettingsActions[isConfigExist ? 'update' : 'create']({ attributes: nextAttributes }))
		}
	}

	if (Array.isArray(ids)) {
		for (let i = 0; i < files.length; i += 1) {
			yield upload(files[i], ids[i], names[i])
		}
	} else {
		yield upload(files, ids, names)
	}

	callback()
}

/**
 * Remove file and update service config.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 */
export function* removeFile(action) {
	const { payload: { id, attributes } } = action
	let removeResponse

	if (id) {
		removeResponse = yield call(FilesApi.remove, DataProvider.buildQuery().addBody({ id }))
	}

	if (!id || removeResponse?.result?.success) {
		yield put(ServiceSettingsActions.update({ attributes }))
	}
}

/**
 * Update/Create config if files not changed.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 * @param {Function} action.payload.callback Action callback
 */
export function* updateConfig(action) {
	const { payload: { attributes, callback } } = action

	const isConfigExist = yield isServiceConfigExist()

	yield put(ServiceSettingsActions[isConfigExist ? 'update' : 'create']({ attributes }))

	callback()
}

export default [
	takeLatest(FILES_ACTIONS.CREATE, create),
	takeLatest(FILES_ACTIONS.REMOVE, remove),
	takeLatest(FILES_ACTIONS.UPDATE, update),
	takeLatest(FILES_ACTIONS.VIEW, view),
]
