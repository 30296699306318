import { callApi as defaultCallApi } from '@kakadu-dev/base-frontend-helpers/api'
import {
	PLATFORM,
	TYPE,
} from '@kakadu-dev/base-frontend-helpers/helpers/Client'
import {
	deleteCookie,
	getCookie,
	setCookie,
} from '@kakadu-dev/base-frontend-helpers/helpers/Cookie'
import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { AuthService } from '@kakadu-dev/base-frontend-helpers/services/AuthService'
import FetchService from '@kakadu-dev/base-frontend-helpers/services/FetchService'
import {
	repeatRequest,
	requestErrorHandler,
} from 'reduxm/redux-saga/api/authHelpers'
import {
	API_URL,
	LANGUAGE_CODE,
} from 'reduxm/redux-saga/config'
import { v4 as uuidv4 } from 'uuid'

/**
 * Get jwt access token
 *
 * @return {string}
 */
export function getJwtAccessToken() {
	return getCookie('JwtAccessToken')
}

/**
 * Get jwt refresh token
 *
 * @return {string}
 */
export function getJwtRefreshToken() {
	return getCookie('JwtRefreshToken')
}

/**
 * Save auth tokens
 *
 * @param {Object} headers
 * @param {{ jwtAccess: string, jwtRefresh: string }|null} payload
 *
 * @return {Promise<boolean[]>|boolean}
 */
export async function saveAuthTokens(headers, payload = null) {
	let accessToken
	let refreshToken

	if (typeof headers === 'object' && DataProvider.getHeader(headers, 'Jwt-Access-Token')) {
		accessToken  = DataProvider.getHeader(headers, 'Jwt-Access-Token')
		refreshToken = DataProvider.getHeader(headers, 'Jwt-Refresh-Token')
	} else {
		accessToken  = payload?.jwtAccess
		refreshToken = payload?.jwtRefresh
	}

	if (!accessToken || !refreshToken) {
		return false
	}

	return Promise.all([
		setCookie('JwtAccessToken', accessToken, true),
		setCookie('JwtRefreshToken', refreshToken, true),
		deleteCookie('Guest-Id'),
	])
}

/**
 * Remove auth tokens
 *
 * @return {Promise<boolean[]>}
 */
export function removeAuthTokens() {
	return Promise.all([
		deleteCookie('JwtAccessToken'),
		deleteCookie('JwtRefreshToken'),
	])
}

/**
 * Save guest identity
 *
 * @param {Object} headers
 *
 * @return {boolean}
 */
export function saveGuestId(headers) {
	if (!headers || typeof headers !== 'object') {
		return false
	}

	const guestId = DataProvider.getHeader(headers, 'Guest-Id')

	if (typeof guestId === 'string' && guestId.length > 0) {
		return setCookie('Guest-Id', guestId, true)
	}

	return true
}

/**
 * Get guest identity
 *
 * @return {string | *}
 */
export function getGuestId() {
	return getCookie('Guest-Id')
}

/**
 * Request config
 *
 * @type {object}
 */
export const requestConfig = {
	domain:         API_URL,
	requestHeaders: {
		'Accept-Language': LANGUAGE_CODE,
		Client:            TYPE,
		ClientDevice:      PLATFORM,
	},
	async successCallback(apiResult, customParams) {
		if (!apiResult.response || !apiResult.response.headers) {
			return
		}

		await saveGuestId(apiResult.response.headers)

		if (customParams.saveAuth) {
			await saveAuthTokens(apiResult.response.headers)
			AuthService.getInstance()
				.setSeamlessLogin(false)
				.setSeamlessError(false)
		}
	},
}

/**
 * Make request and preprocessing response
 *
 * @param {string} endpoint
 * @param {SearchQuery|object} options
 * @param {boolean} isRepeat
 * @param {string} urlPath
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
export function* callApi(endpoint, options, isRepeat = false, urlPath = '/') {
	if (!isRepeat) {
		options.addRequestOptions({
			method: 'POST',
		}, true)

		options.addBody({
			id:     uuidv4(),
			method: endpoint,
			params: { ...options.getBody() },
		})
	}

	const requestResult = yield defaultCallApi(urlPath, options, {
		* initRequest() {
			if (AuthService.getInstance()
				.getIsSeamlessLogin()) {
				return yield repeatRequest(urlPath, options)
			}

			return null
		},

		* beforeRequest(dataProvider) {
			const jwtAccessToken = yield getJwtAccessToken()

			// Add jwt access token to headers if exist
			if (jwtAccessToken) {
				dataProvider.addRequestOptions({
					headers: {
						Authorization: `Jwt ${jwtAccessToken}`,
					},
				}, true)
			} else {
				// Or add guest identity if exist
				const guestId = yield getGuestId()

				if (guestId) {
					dataProvider.addRequestOptions({
						headers: {
							GuestId: guestId,
						},
					}, true)
				}
			}

			dataProvider.addRequestOptions({
				headers: FetchService.getInstance()
							 .getClientHeaders({}),
			}, true)
		},

		* handleError(statusCode, resultError, dataProvider, resultEndpoint) {
			return yield requestErrorHandler(statusCode, resultError, dataProvider, resultEndpoint, options)
		},

		requestConfig,
	})

	AuthService.getInstance().setSeamlessError(false)

	if (isRepeat) {
		return requestResult
	}

	const { response, result, error } = requestResult

	// Check error
	if (error || (result?.error ?? false)) {
		let errorObj = {
			messageData: response,
			message:     '',
		}

		if (error) {
			// Error from fetch (e.g.: 404,401,500)
			errorObj.message = response.statusText
		} else {
			let message

			// Remove "Endpoint exception" from message
			if (result.error?.message && result.error.message.indexOf('Endpoint exception') !== -1) {
				message = result.error?.message?.split(': ')?.[1] ?? null
			}

			// Error from rpc response
			errorObj = {
				...errorObj,
				...result.error,
				...(message ? { message } : {}),
			}
		}

		throw errorObj
	}

	return {
		response,
		error,
		result: result?.result ?? {},
	}
}
