import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { SitesTable } from './index'

const mapStateToProps = state => ({
	sitesState: StateSelector.controlPanel.sites.list(state),
	isFetching:
				StateSelector.controlPanel.sites.list(state).fetching ||
					StateSelector.controlPanel.sites.remove(state).fetching ||
					StateSelector.controlPanel.sites.create(state).fetching ||
					StateSelector.controlPanel.sites.update(state).fetching,
})

const mapDispatchToProps = {
	getSites:    DispatchSelector.controlPanel.sites.list,
	createSites: DispatchSelector.controlPanel.sites.create,
	removeSites: DispatchSelector.controlPanel.sites.remove,
	updateSites: DispatchSelector.controlPanel.sites.update,
}

export default connect(mapStateToProps, mapDispatchToProps)(SitesTable)
