import { AccountCircle } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render menu icon
 *
 * @param {Function} toggleMenu
 * @param {string} userName
 * @param {boolean} isSearching
 *
 * @return {*|null}
 * @constructor
 */
export const MenuIcon = ({ toggleMenu, userName, isSearching }) => (
	!isSearching ?
		<Button
			id="acc"
			onClick={() => toggleMenu(prev => !prev)}
			className={styles.menuBtn}
		>
			<div className={styles.userName}>
				{userName}
			</div>
			<AccountCircle className={styles.accountIcon} />
		</Button> :
		null
)

MenuIcon.propTypes = {
	isSearching: PropTypes.bool,
	toggleMenu:  PropTypes.func,
	userName:    PropTypes.string,
}

MenuIcon.defaultProps = {
	isSearching: false,
	toggleMenu:  () => null,
	userName:    '',
}
