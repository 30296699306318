import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { SITES_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/sites/actionTypes'

export const SitesActions = {
	list:         RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.LIST),
	setList:      RequestActionHelper.getActionCreatorSuccess(SITES_ACTIONS.LIST),
	view:         RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.VIEW),
	create:       RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.CREATE),
	remove:       RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.REMOVE),
	update:       RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.UPDATE),
	uploadFile:   RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.UPLOAD_FILE),
	removeFile:   RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.REMOVE_FILE),
	updateConfig: RequestActionHelper.getActionCreatorFetch(SITES_ACTIONS.UPDATE_CONFIG),
}
