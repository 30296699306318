import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import PropTypes from 'prop-types'
import React from 'react'
import {
	FormFeedback,
	FormGroup,
	FormText,
	Input,
} from 'reactstrap'

/**
 * Render input field for model form
 *
 * @param {string} field
 * @param {string} value
 * @param {Function} onChange
 * @param {Object} invalid
 * @param {Object} inputProps
 *
 * @return {*}
 * @constructor
 */
export const FormField = ({
	field,
	value,
	onChange,
	invalid,
	...inputProps
}) => {
	const { id, title, type, children, formText, disabled, placeholder, labelChildren } = field

	return (
		<FormGroup>
			<FieldLabel title={title} id={id}>
				{labelChildren}
			</FieldLabel>
			<Input
				id={id}
				name={id}
				type={type}
				value={value}
				onChange={onChange}
				placeholder={placeholder || `${title}...`}
				invalid={invalid.result}
				disabled={disabled}
				autoComplete="off"
				{...inputProps}
			>
				{children}
			</Input>
			<FormText>{formText}</FormText>
			<FormFeedback>{invalid.message}</FormFeedback>
		</FormGroup>
	)
}

FormField.propTypes = {
	field:    PropTypes.object,
	invalid:  PropTypes.object,
	onChange: PropTypes.func,
	value:    PropTypes.any,
}

FormField.defaultProps = {
	field: {
		id:            '',
		title:         '',
		type:          'text',
		children:      null,
		labelChildren: null,
	},
	onChange: () => null,
	invalid:  {
		result:  false,
		message: '',
	},
	value:    '',
}
