/* eslint-disable react-hooks/exhaustive-deps */
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/ServiceConfig/Authorization/SettingTabs/Permissions/headerTitles'
import { PermissionsSearch } from 'components/project/ServiceConfig/Authorization/SettingTabs/Permissions/Search'
import { permissionsFields } from 'forms/authorization/permissions'
import _ from 'lodash'
import AuthorizationPermissions from 'models/authorization/Permissions'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import { useParams } from 'react-router'

const tableName    = 'permissions'
const initialState = {
	page:         1,
	searchBy:     'v1',
	searchString: '',
}

/**
 * Render permissions tab
 *
 * @param {Function} getPermissions
 * @param {Object} permissionsState
 * @param {Function} createPermissions
 * @param {Function} removePermissions
 * @param {Function} createBatch
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const PermissionsTab = ({
	getPermissions,
	permissionsState,
	createPermissions,
	removePermissions,
	isFetching,
}) => {
	const [searchParams, setSearchParams] = useState(initialState)

	const searchRef = useRef()

	const { settingTab } = useParams()

	const { page, searchBy, searchString } = searchParams

	const searchQuery = useMemo(() => ({ query: { page, filter: { [searchBy]: searchString } } }),
		[page, searchBy, searchString])

	useEffect(() => {
		getPermissions(searchQuery)
	}, [getPermissions, page, searchString])

	useEffect(() => {
		setSearchParams(initialState)
		searchRef.current.value = ''
	}, [settingTab])

	useEffect(() => {
		setSearchParams(prevState => ({ ...prevState, searchString: '' }))
		searchRef.current.value = ''
	}, [searchBy])

	const searchAction = _.debounce(value => setSearchParams(prevState => ({
		...prevState, page: 1, searchString: value.trim().toLowerCase(),
	})), 400)

	const searchByAction = useCallback(value => setSearchParams(prevState => (
		{ ...prevState, searchBy: value }
	)), [])

	const resetSearch = useCallback(() => {
		searchRef.current.value = ''
		setSearchParams(initialState)
	}, [])

	const createModel = useCallback(query => {
		const { attributes } = query.getBody()
		query.setCallback(() => getPermissions(searchQuery))

		createPermissions(query.addBody(attributes, false))
	}, [createPermissions, getPermissions, searchQuery])

	const removeModel = useCallback(query => {
		query.setCallback(() => getPermissions(searchQuery))

		removePermissions(query)
	}, [getPermissions, removePermissions, searchQuery])

	const updateModel = useCallback((query, prevPermission) => {
		removePermissions(prevPermission.getAttributes())
		createModel(query)
	}, [createModel, removePermissions])

	const permissions        = AuthorizationPermissions.createList(permissionsState)
	const permissionsService = StateService.create(permissionsState)

	return (
		<Table
			paginationData={permissionsService.getPagination()}
			paginationAction={value => setSearchParams(prevState => ({ ...prevState, page: value }))}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={permissions.length} />
				<PermissionsSearch
					searchRef={searchRef}
					searchAction={searchAction}
					searchByAction={searchByAction}
					searchByValue={searchBy}
					cancel={resetSearch}
				/>
				<CreateModelButton
					action={createModel}
					formFields={permissionsFields}
					title="Создать доступ"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{permissions.map(permission => (
				<TableWrapper key={`${permission.getPrimaryKey()}`}>
					<TableRow tableName={tableName}>
						<TableCol title={permission.getPolicyType()} />
						<TableCol>
							<TableNameCol>{permission.getV1()}</TableNameCol>
						</TableCol>
						<TableCol title={permission.getV2()} />
						<TableCol title={permission.getV3()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={permission}
							action={query => updateModel(query, permission)}
							formFields={permissionsFields}
							title="Редактировать доступ"
							tip
						/>
						<RemoveModel
							id={permission.getAttributes()}
							modelName="Удалить"
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

PermissionsTab.propTypes = {
	createPermissions: PropTypes.func,
	getPermissions:    PropTypes.func,
	isFetching:        PropTypes.bool,
	permissionsState:  PropTypes.object,
	removePermissions: PropTypes.func,
}

PermissionsTab.defaultProps = {
	createPermissions: () => null,
	getPermissions:    () => null,
	isFetching:        false,
	permissionsState:  {},
	removePermissions: () => null,
}
