import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { FormField } from 'components/global/AsidePanel/ModelForm/FormField'
import CreateDeploymentModal from 'components/global/CreateDeploymentModal/index.store'
import { Dropzone } from 'components/global/Dropzone'
import styles from 'components/project/ServiceConfig/MicroserviceConfig/styles.module.scss'
import { FileUrlTextField } from 'forms/control-panel/site/fields/FaviconName'
import { ManifestField } from 'forms/control-panel/site/fields/Manifest'
import _ from 'lodash'
import { ServicesConfig } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {
	Button,
	Form,
} from 'reactstrap'

const labelChildren = (
	<span className={styles.labelLink}>
		<a
			rel="noreferrer noopener"
			target="_blank"
			href="https://www.web2generators.com/apache-tools/htpasswd-generator"
		>
			(Создать)
		</a>
	</span>
)

const fields = [
	{ id: 'domain', title: 'Домен', disabled: true },
	{ id: 'password_dir', title: 'Доступ по паролю', labelChildren },
	{ id: 'robots', title: 'Robots', type: 'textarea' },
]

const getPreview = preview => ({ name: 'preview', isLoaded: true, preview })

const getUpdateQuery = (projectId, serviceAlias) => DataProvider
	.buildQuery()
	.addBody({ projectId, serviceAlias, type: 'site' })

/**
 * Render site config component
 *
 * @param {Object} serviceConfigState
 * @param {Function} getServiceConfig
 * @param {Function} updateServiceConfig
 * @param {Function} uploadFile
 * @param {Function} updateConfig
 * @param {number} projectId
 * @param {string} service
 * @param {Function} removeFile
 *
 * @return {*}
 * @constructor
 */
export const SiteConfig = ({
	serviceConfigState,
	getServiceConfig,
	uploadFile,
	removeFile,
	updateConfig,
	projectId,
	service,
}) => {
	const [config, setConfig]               = useState({})
	const [iconFile, setIconFile]           = useState([])
	const [logoFile, setLogoFile]           = useState([])
	const [isActiveModal, setIsActiveModal] = useState(false)

	const serviceConfig = useMemo(() => ServicesConfig.create(serviceConfigState).getConfig(),
		[serviceConfigState])

	const attributes = useMemo(() => ({ projectId, service, config }),
		[config, projectId, service])

	useEffect(() => {
		if (projectId && service) {
			getServiceConfig({ projectId, service })
		}
	}, [getServiceConfig, projectId, service])

	useEffect(() => {
		const { favicon, logo } = serviceConfig

		if (favicon) {
			setIconFile([getPreview(favicon)])
		}

		if (logo) {
			setLogoFile([getPreview(logo)])
		}

		setConfig(serviceConfig)

		return () => {
			setIconFile([])
			setLogoFile([])
		}
	}, [serviceConfig])

	const inputHandler = useCallback((event, manifest) => {
		if (manifest) {
			setConfig(prevState => ({ ...prevState, manifest }))
		} else {
			setConfig({ ...config, [event.target.name]: event.target.value })
		}
	}, [config])

	const isFaviconChanged = useMemo(() => serviceConfig.favicon !== config.favicon ||
		!!serviceConfig.favicon !== !!iconFile[0], [config.favicon, iconFile, serviceConfig.favicon])

	const isLogoChanged = useMemo(() => serviceConfig.logo !== config.logo ||
		!!serviceConfig.logo !== !!logoFile[0], [config.logo, logoFile, serviceConfig.logo])

	const onSubmit = useCallback(event => {
		event.preventDefault()

		const getProp = (icon, logo) => (isFaviconChanged && isLogoChanged && [logo, icon]) ||
			(isLogoChanged && logo) || icon

		const callback = () => setIsActiveModal(true)

		if (!isLogoChanged && !isFaviconChanged) {
			updateConfig({ attributes, callback })
		} else {
			const { faviconId, logoId } = serviceConfig

			uploadFile({
				attributes,
				files: getProp(iconFile, logoFile),
				ids:   getProp(faviconId, logoId),
				names: getProp('favicon', 'logo'),
				callback,
			})
		}
	}, [attributes, iconFile, isFaviconChanged, isLogoChanged, logoFile, serviceConfig, updateConfig, uploadFile])

	const onRemove = useCallback(fileName => {
		const url = serviceConfig[fileName]
		let id
		let nextConfig
		let action

		if (fileName === 'favicon') {
			const { faviconId, favicon, ...otherProps } = config

			id         = faviconId
			nextConfig = otherProps
			action     = setIconFile
		} else {
			const { logoId, logo, ...otherProps } = config

			id         = logoId
			nextConfig = otherProps
			action     = setLogoFile
		}

		if (id || url) {
			removeFile({ id, attributes: { ...attributes, config: nextConfig } })
		} else {
			setConfig(serviceConfig)
			action([])
		}
	}, [attributes, config, removeFile, serviceConfig])

	const isSubmitDisabled = useMemo(() => _.isEqual(config, serviceConfig) && !isFaviconChanged && !isLogoChanged,
		[config, isFaviconChanged, isLogoChanged, serviceConfig])

	const getForm = useCallback(() => fields.map(field => !(field.id === 'password_dir' &&
		service === 'site-admin-panel') &&
		<FormField
			key={field.id}
			value={config[field.id]}
			field={field}
			onChange={inputHandler}
		/>), [config, inputHandler, service])

	return (
		<div className={styles.container}>
			<CreateDeploymentModal
				isActive={isActiveModal}
				toggle={() => setIsActiveModal(false)}
				data={getUpdateQuery(projectId, service)}
			/>
			<Form onSubmit={onSubmit}>
				{getForm()}
				<FileUrlTextField
					id="favicon"
					title="Иконка сайта"
					value={config.favicon}
					onChange={event => setConfig({ ...config, favicon: event.target.value })}
					placeholder="https://www.path-to-favicon.ico"
					removeAction={() => onRemove('favicon')}
				/>
				<Dropzone
					files={iconFile}
					setFiles={setIconFile}
					onDelete={() => onRemove('favicon')}
					accept=".ico"
					maxSize={100 * 1024} // 100kb
					maxFiles={1}
					title="Перетащите иконку сюда"
				/>
				<FileUrlTextField
					id="logo"
					title="Логотип"
					value={config.logo}
					onChange={event => setConfig({ ...config, logo: event.target.value })}
					placeholder="https://www.path-to-logo.jpg"
					removeAction={() => onRemove('logo')}
				/>
				<Dropzone
					files={logoFile}
					setFiles={setLogoFile}
					onDelete={() => onRemove('logo')}
					accept=".png, .jpg, .jpeg, .svg"
					maxFiles={1}
					maxSize={2 * 1024 * 1024} // 2mb
					title="Перетащите логотип сюда"
				/>
				<ManifestField value={config.manifest} onChange={inputHandler} />
				<Button
					type="submit"
					color="success"
					className={styles.updateBtn}
					disabled={isSubmitDisabled}
				>
					Обновить
				</Button>
			</Form>
		</div>
	)
}

SiteConfig.propTypes = {
	getServiceConfig:   PropTypes.func,
	projectId:          PropTypes.number,
	removeFile:         PropTypes.func,
	service:            PropTypes.string,
	serviceConfigState: PropTypes.object,
	updateConfig:       PropTypes.func,
	uploadFile:         PropTypes.func,
}

SiteConfig.defaultProps = {
	getServiceConfig:   () => null,
	projectId:          null,
	removeFile:         () => null,
	service:            '',
	serviceConfigState: {},
	updateConfig:       () => null,
	uploadFile:         () => null,
}
