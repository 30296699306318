import { AuthenticationConfig } from 'components/project/ServiceConfig/Authentication/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { controlPanel: { settings: { authentication: { create, update, remove, view } } } } = StateSelector

const mapStateToProps = state => ({
	configState: view(state),
	isFetching:  _(create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getConfig:    DispatchSelector.controlPanel.settings.authentication.view,
	createConfig: DispatchSelector.controlPanel.settings.authentication.create,
	updateConfig: DispatchSelector.controlPanel.settings.authentication.update,
	removeConfig: DispatchSelector.controlPanel.settings.authentication.remove,
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationConfig)
