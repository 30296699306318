import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { ProjectsTable } from './index'

const mapStateToProps    = state => ({
	projectsState: StateSelector.controlPanel.projects.list(state),
})
const mapDispatchToProps = {
	createProject: DispatchSelector.controlPanel.projects.create,
	updateProject: DispatchSelector.controlPanel.projects.update,
	removeProject: DispatchSelector.controlPanel.projects.remove,
	getProjects:   DispatchSelector.controlPanel.projects.list,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsTable)
