import {
	TabsContainer,
	TabsContent,
	TabsContentItem,
	TabsNavigation,
	TabsNavItem,
} from 'components/global/TabsTable'
import { getSettingTabs } from 'components/project/ServiceConfig/Users/getSettingTabs'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router'

/**
 * Render authorization config
 *
 * @param {Project} project
 *
 * @return {boolean|*}
 * @constructor
 */
export const UsersConfig = ({ project }) => {
	const settingTabs = getSettingTabs(project)

	const { settingTab } = useParams()

	const activeTable = settingTab || 'users'

	return (
		<TabsContainer>
			<TabsNavigation>
				{settingTabs.map(tab => <TabsNavItem
					key={tab.alias}
					to={`/project/${project.getAlias()}/microservice/users/${tab.alias}`}
					isActive={activeTable === tab.alias}
					title={tab.title}
				/>)}
			</TabsNavigation>
			<TabsContent activeTab={activeTable}>
				{settingTabs.map(tab => (
					<TabsContentItem tabId={tab.alias} key={tab.alias}>
						{tab.component}
					</TabsContentItem>
				))}
			</TabsContent>
		</TabsContainer>
	)
}

UsersConfig.propTypes = {
	project: PropTypes.instanceOf(Project),
}

UsersConfig.defaultProps = {
	project: Project.create({}),
}
