import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/ServiceConfig/Authorization/SettingTabs/Filters/headerTitles'
import { filtersFields } from 'forms/authorization/filters'
import AuthorizationFilters from 'models/authorization/Filters'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

const tableName = 'filters'

/**
 * Render filters tab
 *
 * @param {Object} filtersListState
 * @param {Function} getFiltersList
 * @param {Function} createFilters
 * @param {Function} updateFilters
 * @param {Function} removeFilters
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const FiltersTab = ({
	filtersListState,
	getFiltersList,
	createFilters,
	updateFilters,
	removeFilters,
	isFetching,
}) => {
	const [page, setPage] = useState(1)

	const searchQuery = useMemo(() => ({ query: { expands: ['Role'], page } }), [page])

	const filters        = AuthorizationFilters.createList(filtersListState)
	const filtersService = StateService.create(filtersListState)

	useEffect(() => {
		getFiltersList(searchQuery)
	}, [getFiltersList, searchQuery])

	/**
	 * Modify search query
	 *
	 * @param {Object} searchQuery
	 * @param {Function} action
	 *
	 * @return {undefined}
	 *
	 */
	const modifySearchQuery = useCallback((nextSearchQuery, action) => {
		nextSearchQuery.setCallback(() => getFiltersList(searchQuery))

		action(nextSearchQuery)
	}, [getFiltersList, searchQuery])

	const removeModel = useCallback(nextSearchQuery => {
		nextSearchQuery.setCallback(() => setPage(1))

		removeFilters(nextSearchQuery)
	}, [removeFilters])

	return (
		<Table
			paginationData={filtersService.getPagination()}
			paginationAction={nextPage => setPage(nextPage)}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={filters.length} />
				<CreateModelButton
					action={nextSearchQuery => modifySearchQuery(nextSearchQuery, createFilters)}
					formFields={filtersFields}
					title="Создать фильтр"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{(filters || []).map(filter => (
				<TableWrapper key={`${filter.getId()}`}>
					<TableRow tableName={tableName}>
						<TableCol title={filter.getId()} />
						<TableCol>
							<TableNameCol>{filter.getName(true)}</TableNameCol>
						</TableCol>
						<TableCol title={filter.getRole().getName()} />
						<TableCol title={filter.getOperator()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={filter}
							action={nextSearchQuery => modifySearchQuery(nextSearchQuery, updateFilters)}
							formFields={filtersFields}
							title="Редактировать фильтры"
							tip
						/>
						<RemoveModel
							id={{ id: filter.getId() }}
							modelName={filter.getName()}
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

FiltersTab.propTypes = {
	createFilters:    PropTypes.func,
	filtersListState: PropTypes.object,
	getFiltersList:   PropTypes.func,
	isFetching:       PropTypes.bool,
	removeFilters:    PropTypes.func,
	updateFilters:    PropTypes.func,
}

FiltersTab.defaultProps = {
	createFilters:    () => null,
	filtersListState: {},
	getFiltersList:   () => null,
	isFetching:       false,
	removeFilters:    () => null,
	updateFilters:    () => null,
}
