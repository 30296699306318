import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import { Progress } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render sign in button
 *
 * @param {boolean} disabled
 * @param {function} onClick
 *
 * @return {*}
 * @constructor
 */
export const SignInButton = ({ disabled, onClick }) => (
	disabled ?
		<Progress className={styles.loading} animated value="100">
			Проверка...
		</Progress> :
		<Button
			onClick={onClick}
			className={styles.signIn}
			disabled={disabled}
		>
			<div>Войти в панель</div>
		</Button>
)

SignInButton.propTypes = {
	disabled: PropTypes.bool,
	onClick:  PropTypes.func,
}

SignInButton.defaultProps = {
	disabled: false,
	onClick:  () => null,
}
