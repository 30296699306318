import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { SearchList } from 'forms/fields'
import _ from 'lodash'
import { AuthorizationRoles } from 'models'
import User from 'models/user/User'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import {
	FormFeedback,
	Input,
} from 'reactstrap'
import styles from './styles.module.scss'

const emptyList = [AuthorizationRoles.create({})]

const setUserRoleQuery = (userId, role) => DataProvider
	.buildQuery()
	.addBody({ userId, role })

const userRoleQuery = userId => DataProvider
	.buildQuery()
	.addBody({ userId })

/**
 * Render role field
 *
 * @param {Function} setState
 * @param {string} value
 * @param {Object} field
 * @param {Function} getSearchList
 * @param {Object} searchListState
 * @param {Object} invalid
 * @param {Function} getUserRole
 * @param {Function} setUserRole
 * @param {number} userId
 * @param {Object} setRoleState
 *
 * @return {*}
 * @constructor
 */
export const RoleField = ({
	setState,
	value,
	field,
	getSearchList,
	searchListState,
	invalid,
	getUserRole,
	setUserRole,
	userId,
	setRoleState,
}) => {
	const { id, title, disabled } = field

	const [inputValue, setInputValue]           = useState('')
	const [currentRoles, setCurrentRoles]       = useState(emptyList)
	const [isResultShown, setIsResultShown]     = useState(false)
	const [isInputDisabled, setIsInputDisabled] = useState(false)
	const [successMessage, setSuccessMessage]   = useState('')

	const inputRef = useRef()

	const showSuccessMessage = useCallback(message => {
		setSuccessMessage(message)
		setTimeout(() => setSuccessMessage(''), 2000)
	}, [])

	const setRoleService = StateService.create(setRoleState)

	const getSearchQuery = useCallback((searchString, isInitialFetch) => DataProvider.buildQuery()
		.addBody({
			query: {
				filter: {
					...((!isInitialFetch && {
						or: [
							{ alias: { like: `%${searchString}%` } },
							{ name: { like: `%${searchString}%` } },
						],
					}) || { alias: searchString }),
				},
			},
		})
		.setSuccessCallback(res => {
			const result = AuthorizationRoles.createList(res)

			if (isInitialFetch) {
				setIsInputDisabled(false)
				setInputValue(result[0].getName() || '')
			} else {
				setCurrentRoles(result)
			}
		}), [])

	const fetchSearchAttributes = useCallback(_.debounce((searchString, isInitialFetch = false) => {
		if (!searchString) {
			setCurrentRoles(emptyList)
		} else {
			getSearchList(getSearchQuery(searchString, isInitialFetch))
		}
	}, 400), [])

	const fetchList = useCallback(searchString => {
		setInputValue(searchString)
		fetchSearchAttributes(searchString)
	}, [fetchSearchAttributes])

	useEffect(() => {
		if ((value && !inputValue.length) || (userId && !inputValue.length)) {
			setIsInputDisabled(true)
			setInputValue('Загрузка...')

			if (value) {
				fetchSearchAttributes(value, true)
			} else {
				getUserRole(userRoleQuery(userId).setSuccessCallback(response => {
					getSearchList(getSearchQuery(User.create(response).getRole(), true))
				}))
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [userId, value, fetchSearchAttributes])

	const setValues = useCallback(attributeId => {
		setState(id, attributeId)
	}, [id, setState])

	const checkAttributes = useCallback(attribute => {
		setInputValue(attribute.getName())
		setIsResultShown(false)
		setValues(attribute.getAlias())

		if (userId) {
			setUserRole(setUserRoleQuery(userId, attribute.getAlias())
				.setSuccessCallback(() => showSuccessMessage('Роль изменена!')))
		}
	}, [setUserRole, setValues, showSuccessMessage, userId])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<Input
				type="text"
				value={inputValue}
				className={(isResultShown && !!inputValue.length && styles.active) || ''}
				onChange={event => fetchList(event.target.value)}
				onFocus={() => setIsResultShown(true)}
				onBlur={() => setTimeout(() => setIsResultShown(false), 200)}
				innerRef={inputRef}
				invalid={invalid.result}
				disabled={isInputDisabled || setRoleService.isFetching() || disabled}
				placeholder={`${title}...`}
			/>
			{successMessage &&
			<div className={styles.successMessage}>
				{successMessage}
			</div>}
			<FormFeedback>{invalid.message}</FormFeedback>
			<SearchList
				inputValue={inputValue}
				isFetching={StateService.create(searchListState).isFetching()}
				isActive={isResultShown && !!inputValue.length}
				items={currentRoles}
				action={checkAttributes}
			/>
		</div>
	)
}

RoleField.propTypes = {
	field:           PropTypes.object,
	getSearchList:   PropTypes.func,
	getUserRole:     PropTypes.func,
	invalid:         PropTypes.object,
	searchListState: PropTypes.object,
	setRoleState:    PropTypes.object,
	setState:        PropTypes.func,
	setUserRole:     PropTypes.func,
	userId:          PropTypes.number,
	value:           PropTypes.string,
}

RoleField.defaultProps = {
	field:           {},
	getSearchList:   () => null,
	getUserRole:     () => null,
	invalid:         {},
	searchListState: {},
	setRoleState:    {},
	setState:        () => null,
	setUserRole:     () => null,
	userId:          null,
	value:           '',
}
