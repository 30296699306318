export const USERS_MANAGE_SETTINGS_ACTIONS = {
	UPDATE:               'USERS_MANAGE_SETTINGS_UPDATE',
	VIEW:                 'USERS_MANAGE_SETTINGS_VIEW',
	UPLOAD_AVATAR:        'USERS_MANAGE_SETTINGS_UPLOAD_AVATAR',
	DELETE_AVATAR:        'USERS_MANAGE_SETTINGS_DELETE_AVATAR',
	PREPARE_AVATAR:       'USERS_MANAGE_SETTINGS_PREPARE_AVATAR',
	UPLOAD_DEFAULT_IMAGE: 'USERS_MANAGE_SETTINGS_UPLOAD_DEFAULT_IMAGE',
	REMOVE_DEFAULT_IMAGE: 'USERS_MANAGE_SETTINGS_REMOVE_DEFAULT_IMAGE',
	UPDATE_CONFIG:        'USERS_MANAGE_SETTINGS_UPDATE_CONFIG',
}
