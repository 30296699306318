import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { FirebaseConfigTab } from './index'

const { controlPanel: { settings: { firebase: { create, update, remove, view } } } } = StateSelector

const mapStateToProps = state => ({
	configState: view(state),
	isFetching:  _(create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getConfig:    DispatchSelector.controlPanel.settings.firebase.view,
	createConfig: DispatchSelector.controlPanel.settings.firebase.create,
	updateConfig: DispatchSelector.controlPanel.settings.firebase.update,
	removeConfig: DispatchSelector.controlPanel.settings.firebase.remove,
}

export default connect(mapStateToProps, mapDispatchToProps)(FirebaseConfigTab)
