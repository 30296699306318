import {
	useCallback,
	useEffect,
	useState,
} from 'react'

/**
 * Hook to check if window width more then value
 * Return true if window.innerWidth > value
 *
 * @param {number} value
 *
 * @return {boolean}
 */
export const useWidthMoreThanValue = value => {
	const initialState = window.innerWidth > value

	const [result, setResult] = useState(initialState)

	const updateWindowDimensions = useCallback(() => {
		if ((window.innerWidth > value) !== result) {
			setResult(!result)
		}
	}, [result, value])

	useEffect(() => {
		window.addEventListener('resize', updateWindowDimensions)

		return () => window.removeEventListener('resize', updateWindowDimensions)
	}, [result, updateWindowDimensions])

	return result
}
