import {
	put,
	select,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { UsersManageActions } from 'reduxm/redux-saga/modules/users/manage/actionCreators'
import { UsersManageSelectors } from 'reduxm/redux-saga/modules/users/manage/actionSelectors'
import { USERS_MANAGE_ACTIONS } from 'reduxm/redux-saga/modules/users/manage/actionTypes'
import { UsersManageApi } from 'reduxm/redux-saga/modules/users/manage/api'

/**
 * Get user models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, UsersManageApi.list, null, ResponseHandler.addProjectOrigin)
}

/**
 * View user model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, UsersManageApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create user model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, UsersManageApi.create, null, ResponseHandler.addProjectOrigin, function* (response) {
		if (!response?.error) {
			const { result: { model } } = response

			const { result: { list: prevList } } = yield select(UsersManageSelectors.list)

			yield put(UsersManageActions.setList({ list: [...prevList, model] }))
		}
	})
}

/**
 * Update user model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, UsersManageApi.update, null, ResponseHandler.addProjectOrigin)
}

export default [
	takeLatest(USERS_MANAGE_ACTIONS.LIST, list),
	takeLatest(USERS_MANAGE_ACTIONS.UPDATE, update),
	takeLatest(USERS_MANAGE_ACTIONS.CREATE, create),
	takeLatest(USERS_MANAGE_ACTIONS.VIEW, view),
]
