import { JsonInputField } from 'forms/fields/JsonInput'
import React from 'react'
import {
	ScalingPolicies,
	ServiceType,
	TaskDefinition,
} from '../service/fields'
import { SiteType } from './fields'

const dockerType = [
	{ id: 'dockerImage', title: 'Docker Image' },
	{ id: 'serviceType', title: 'Тип сервиса', component: <ServiceType /> },
	{ id: 'scalingPolicies', title: 'Политики масштабирования', component: <ScalingPolicies /> },
	{ id: 'taskDefinition', title: 'Определение задачи', component: <TaskDefinition /> },
]

const amplifyType = [{ id: 'amplifyConfig', title: 'Настройки расширения', component: <JsonInputField /> }]

export const sitesFormFields = (siteType, setSiteType, isUpdating = false) => [
	{ id: 'alias', title: 'Алиас' },
	{ id: 'title', title: 'Название' },
	{ id: 'description', title: 'Описание' },
	{
		id:        'type',
		title:     'Тип сайта',
		component: <SiteType setSiteType={setSiteType} isUpdating={isUpdating} />,
	},
	...((siteType === 'docker' && dockerType) || (siteType === 'amplify' && amplifyType) || []),
]
