import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * SocialConfig model class
 */
class SocialConfig extends BaseModel {
	/**
	 * Create SocialConfig instance
	 *
	 * @param {Object} object
	 *
	 * @returns {SocialConfig}
	 */
	static create(object) {
		return super.create(object?.result?.model)
	}

	/**
	 * Get project id
	 *
	 * @returns {number}
	 */
	getProjectId = () => this.model.projectId

	/**
	 * Get vkontakte
	 *
	 * @returns {Object}
	 */
	getVkontakte = () => this.model.vkontakte

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default SocialConfig
