import { Project } from 'models'
import {
	put,
	select,
} from 'redux-saga/effects'
import { StateSelector } from 'reduxm/modules/selectors'
import { API_URL } from 'reduxm/redux-saga/config'

/**
 * Response handler
 */
class ResponseHandler {
	/**
	 * After create response handler
	 *
	 * @param {Object} responseData
	 * @param {Object} searchQuery
	 * @param {function} getList
	 * @param {function} setList
	 *
	 * @return {undefined}
	 */
	static* afterCreateModel(responseData, searchQuery, getList, setList) {
		const { result: { list = [], pagination } } = yield select(getList)

		const { result: { model }, response } = responseData

		const models = [...list, model]

		const output = {
			list:       models || [],
			response,
			pagination: {
				...pagination,
				totalItems: models.length,
			},
		}

		yield put(setList(output))
	}

	/**
	 * After remove response handler
	 *
	 * @param {Object} responseData
	 * @param {string} responseData.result.id
	 * @param {Object} searchQuery
	 * @param {function} getList
	 * @param {function} setList
	 *
	 * @return {undefined}
	 */
	static* afterRemoveModel(responseData, searchQuery, getList, setList) {
		const { result: { result: removalResult, id }, response } = responseData

		if (removalResult) {
			const { result: { list, pagination } } = yield select(getList)

			const models = list.filter(model => {
				const identifier = (model.id && 'id') || (model.alias && 'alias') || 'service'

				if (identifier === 'service') {
					return `${model.projectId},${model.service}` !== id
				}

				return model[identifier] !== id
			})

			const output = {
				list:       models || [],
				response,
				pagination: {
					...pagination,
					totalItems: models.length,
				},
			}

			yield put(setList(output))
		}
	}

	/**
	 * After update response handler
	 *
	 * @param {Object} responseData
	 * @param {Object} responseData.model
	 * @param {Object} searchQuery
	 * @param {Object} getStore
	 * @param {Object} setStore
	 *
	 * @return {undefined}
	 */
	static* afterUpdateModel(responseData, searchQuery, getStore, setStore) {
		const { result: { model: nextModel }, response } = responseData

		const { result: { list, pagination } } = yield select(getStore.list)

		if (list.length) {
			const identifier = (nextModel.id && 'id') || (nextModel.alias && 'alias') || 'service'

			const models = list.map(model => {
				if (identifier === 'service') {
					return model.projectId === nextModel.projectId &&
					model.service === nextModel.service ? nextModel : model
				}

				return model[identifier] === nextModel[identifier] ? nextModel : model
			})

			const output = {
				list: models || [],
				response,
				pagination,
			}

			yield put(setStore.setList(output))
		}

		const { result: viewResult } = yield select(getStore.view)

		if (viewResult) {
			const output = {
				model: { ...viewResult.model, ...nextModel },
				response,
			}

			yield put(setStore.setView(output))
		}
	}

	/**
	 * Add project origin
	 *
	 * @param {Object} searchQuery
	 *
	 * @return {Object}
	 */
	static* addProjectOrigin(searchQuery) {
		const projectState = yield select(StateSelector.controlPanel.projects.view)

		const domain = Project.create(projectState).getDomain()

		return searchQuery.addRequestOptions({
			headers: {
				'Project-Origin': `${API_URL.split('://')[0]}://${domain}`,
			},
		}, true)
	}
}

export default ResponseHandler
