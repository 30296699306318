import { AUTHORIZATION_ROLES_ACTIONS } from 'reduxm/redux-saga/modules/authorization/roles/actionTypes'

export const AuthorizationRolesSelectors = {
	list:       state => state.authorization.roles[AUTHORIZATION_ROLES_ACTIONS.LIST],
	create:     state => state.authorization.roles[AUTHORIZATION_ROLES_ACTIONS.CREATE],
	update:     state => state.authorization.roles[AUTHORIZATION_ROLES_ACTIONS.UPDATE],
	remove:     state => state.authorization.roles[AUTHORIZATION_ROLES_ACTIONS.REMOVE],
	view:       state => state.authorization.roles[AUTHORIZATION_ROLES_ACTIONS.VIEW],
	searchList: state => state.authorization.roles[AUTHORIZATION_ROLES_ACTIONS.SEARCH_LIST],
}
