import { CloudUploadRounded as Upload } from '@material-ui/icons'
import { fileForm } from 'helpers/prularForms'
import React from 'react'
import ReactDOMServer from 'react-dom/server'

/**
 * Render dropzone default message
 *
 * @param {string} extensions
 * @param {number} maxFiles
 * @param {number} maxSize
 *
 * @return {string}
 */
export const dictDefaultMessage = (extensions, maxFiles, maxSize) => {
	const allowedExtensions = extensions.split(',').join('')

	return ReactDOMServer.renderToStaticMarkup(
		<div className="dz-main-container">
			<div className="dz-main-title">{`Перетащите сюда ${fileForm(maxFiles)}`}</div>
			<div className="dz-btn-wrapper">
				<div className="dz-or-secondary">или</div>
				<div id="upload-btn" className="btn btn-success dz-upload-btn">
					<Upload />
					Выберите
				</div>
			</div>
			<div className="dz-main-other-info">
				<div>
					{`Вы можете загрузить максимум ${maxFiles} ${fileForm(maxFiles)}`}
				</div>
				<div>Доступны следующие форматы:</div>
				<div className="dz-file-extensions">
					{allowedExtensions}
				</div>
				<div className="dz-size-message">
					Размер не должен превышать
					<span>{`${maxSize} мб.`}</span>
				</div>
			</div>
		</div>,
	)
}
