import { CreateModelButton } from 'components/global/CreateModelButton'
import styles from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/EmptyList/styles.module.scss'
import { methodsFields } from 'forms/authorization/methods'
import Service from 'models/control-panel/Service'
import PropTypes from 'prop-types'
import React from 'react'
import { EmptyResultMessage } from './EmptyResultMessage'

/**
 * Render empty services list component
 *
 * @param {Service} currentService
 * @param {Function} createMethod
 * @param {boolean} isActive
 *
 * @return {*}
 * @constructor
 */
export const EmptyMethodsList = ({ currentService, createMethod, isActive }) => (isActive &&
	<div className={styles.container}>
		<EmptyResultMessage>
			<div>
				Созданных методов для
				{' '}
				<span className={styles.title}>{currentService.getTitle()}</span>
				{' '}
				пока нет.
			</div>
		</EmptyResultMessage>
		<CreateModelButton
			action={createMethod}
			initialData={{ serviceAlias: currentService.getAlias() }}
			formFields={methodsFields}
			title="Создать метод"
		/>
	</div>

)

EmptyMethodsList.propTypes = {
	createMethod:   PropTypes.func,
	currentService: PropTypes.instanceOf(Service),
	isActive:       PropTypes.bool,
}

EmptyMethodsList.defaultProps = {
	createMethod:   () => null,
	currentService: Service.create({}),
	isActive:       false,
}
