import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	list:             'control-panel.services.list',
	view:             'control-panel.services.view',
	create:           'control-panel.services.create',
	remove:           'control-panel.services.remove',
	update:           'control-panel.services.update',
	info:             'control-panel.services.info',
	createDeployment: 'control-panel.services.create-deployment',
	changeTaskCount:  'control-panel.services.change-task-count',
}

/**
 * Call api to get services list
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const list = searchQuery => callApi(methods.list, searchQuery)

/**
 * Call api to create service
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to remove service
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

/**
 * Call api to update service
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to view service
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

/**
 * Get project services info
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const info = searchQuery => callApi(methods.info, searchQuery)

/**
 * Create new project service deployment
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const createDeployment = searchQuery => callApi(methods.createDeployment, searchQuery)

/**
 * Change project service task count
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const changeTaskCount = searchQuery => callApi(methods.changeTaskCount, searchQuery)

export const ServiceApi = {
	list,
	create,
	remove,
	update,
	view,
	info,
	createDeployment,
	changeTaskCount,
}
