import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/ServiceConfig/Authorization/SettingTabs/Attributes/headerTitles'
import { attributesFields } from 'forms/authorization/attributes'
import { AuthorizationAttributes } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
} from 'react'

const tableName = 'attributes'

const searchQuery = (page = 1) => ({ query: { page } })

/**
 * Render attributes tab
 *
 * @param {boolean} isFetching
 * @param {Function} getAttributesList
 * @param {Object} attributesListState
 * @param {Function} createAttributes
 * @param {Function} updateAttributes
 * @param {Function} removeAttributes
 *
 * @return {*}
 * @constructor
 */
export const AttributesTab = ({
	isFetching,
	getAttributesList,
	attributesListState,
	createAttributes,
	updateAttributes,
	removeAttributes,
}) => {
	useEffect(() => {
		getAttributesList(searchQuery())
	}, [getAttributesList])

	const attributes        = AuthorizationAttributes.createList(attributesListState)
	const attributesService = StateService.create(attributesListState)

	const removeModel = useCallback(nextSearchQuery => {
		nextSearchQuery.setCallback(() => getAttributesList(searchQuery()))

		removeAttributes(nextSearchQuery)
	}, [getAttributesList, removeAttributes])

	return (
		<Table
			paginationData={attributesService.getPagination()}
			paginationAction={page => getAttributesList(searchQuery(page))}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={attributes.length} />
				<CreateModelButton
					action={createAttributes}
					formFields={attributesFields}
					title="Создать атрибуты"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{(attributes || []).map(attribute => (
				<TableWrapper key={`${attribute.getAlias()}`}>
					<TableRow tableName={tableName}>
						<TableCol title={attribute.getId()} />
						<TableCol>
							<TableNameCol>{attribute.getName(true)}</TableNameCol>
						</TableCol>
						<TableCol title={attribute.getAlias()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={attribute}
							action={updateAttributes}
							formFields={attributesFields}
							title="Редактировать атрибуты"
							tip
						/>
						<RemoveModel
							id={{ id: attribute.getId() }}
							modelName={attribute.getName()}
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

AttributesTab.propTypes = {
	attributesListState: PropTypes.object,
	createAttributes:    PropTypes.func,
	getAttributesList:   PropTypes.func,
	isFetching:          PropTypes.bool,
	removeAttributes:    PropTypes.func,
	updateAttributes:    PropTypes.func,
}

AttributesTab.defaultProps = {
	attributesListState: {},
	createAttributes:    () => null,
	getAttributesList:   () => null,
	isFetching:          false,
	removeAttributes:    () => null,
	updateAttributes:    () => null,
}
