import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	view:   'control-panel.settings.social.view',
	create: 'control-panel.settings.social.create',
	remove: 'control-panel.settings.social.remove',
	update: 'control-panel.settings.social.update',
}

/**
 * Call api to create social config
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to remove social config
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

/**
 * Call api to update social config
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {function}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to view social config
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {function}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

export const SocialConfigApi = {
	create,
	remove,
	update,
	view,
}
