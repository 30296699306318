import { SERVICES_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/services/actionTypes'

export const ServicesSelectors = {
	list:             state => state.controlPanel.services[SERVICES_ACTIONS.LIST],
	view:             state => state.controlPanel.services[SERVICES_ACTIONS.VIEW],
	create:           state => state.controlPanel.services[SERVICES_ACTIONS.CREATE],
	remove:           state => state.controlPanel.services[SERVICES_ACTIONS.REMOVE],
	update:           state => state.controlPanel.services[SERVICES_ACTIONS.UPDATE],
	addToProject:     state => state.controlPanel.services[SERVICES_ACTIONS.ADD_TO_PROJECT],
	info:             state => state.controlPanel.services[SERVICES_ACTIONS.INFO],
	createDeployment: state => state.controlPanel.services[SERVICES_ACTIONS.CREATE_DEPLOYMENT],
	changeTaskCount:  state => state.controlPanel.services[SERVICES_ACTIONS.CHANGE_TASK_COUNT],
}
