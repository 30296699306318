import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { Tip } from 'components/global/Tip'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { MenuContent } from 'components/wrapper/Header/HeaderMenu/MenuContent'
import { MenuIcon } from 'components/wrapper/Header/HeaderMenu/MenuIcon'
import { SearchPanel } from 'components/wrapper/Header/SearchPanel'
import { User } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useRef,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render account component
 *
 * @param {Object} userState
 * @param {Object} logOutState
 * @param {function} logOut
 * @param {boolean} isBurger
 *
 * @return {*}
 * @constructor
 */
export const HeaderMenu = ({ userState, logOutState, logOut, isBurger }) => {
	const [isMenuActive, setIsMenuActive] = useState(false)
	const [isSearching, setIsSearching]   = useState(false)

	const menuRef        = useRef()
	const searchPanelRef = useRef()

	useCheckOutsideClick(menuRef, isMenuActive, setIsMenuActive)
	useCheckOutsideClick(searchPanelRef, isSearching, setIsSearching)

	/**
	 * Show search panel
	 *
	 * @return {undefined}
	 */
	const showSearchPanel = useCallback(() => {
		setIsMenuActive(false)
		setIsSearching(true)
	}, [])

	/**
	 * Action for logout button
	 *
	 * @return {undefined}
	 */
	const exit = useCallback(() => {
		logOut()
		setIsMenuActive(false)
	}, [logOut])

	const user          = User.create(userState)
	const logOutService = StateService.create(logOutState)

	return (
		<div className={styles.wrapper}>
			<div
				ref={searchPanelRef}
				className={[isBurger && styles.searchPanel, isSearching && styles.showSearchPanel].join(' ')}
			>
				<SearchPanel close={() => setIsSearching(false)} />
			</div>
			<Tip title={!isMenuActive ? 'Ваш аккаунт' : ''}>
				<div ref={menuRef}>
					<MenuIcon
						toggleMenu={setIsMenuActive}
						userName={user.getFullName()}
						isSearching={isSearching}
					/>
					<MenuContent
						logOut={exit}
						isMenuActive={isMenuActive}
						setIsMenuActive={setIsMenuActive}
						showSearchPanel={showSearchPanel}
						isBurger={isBurger}
						fetching={logOutService.isFetching()}
					/>
				</div>
			</Tip>
		</div>
	)
}

HeaderMenu.propTypes = {
	isBurger:    PropTypes.bool,
	logOut:      PropTypes.func,
	logOutState: PropTypes.object,
	userState:   PropTypes.object,
}

HeaderMenu.defaultProps = {
	isBurger:    false,
	logOut:      () => null,
	logOutState: {},
	userState:   {},
}
