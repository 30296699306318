import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import Drawer from '@material-ui/core/Drawer'
import {
	Edit,
	SecurityOutlined as Main,
	ContactMailOutlined as Other,
} from '@material-ui/icons'
import { AsidePanel } from 'components/global/AsidePanel'
import { ModelForm } from 'components/global/AsidePanel/ModelForm'
import { Button } from 'components/global/Button'
import { Tip } from 'components/global/Tip'
import {
	usersFields,
	usersSettingsFields,
} from 'forms/user'
import { UserSettings } from 'models'
import User from 'models/user/User'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import styles from './styles.module.scss'

const getPreview = preview => ({ name: 'preview', isLoaded: true, preview })

const getAvatarUploadQuery = (userId, image, cb = () => null) => DataProvider
	.buildQuery()
	.addRequestOptions({
		onUploadProgress(progressEvent) {
			const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total)

			cb(prev => [{ ...prev, progress }])
		},
	})
	.addBody({ userId, image }, false)

/**
 * Render toggle right menu
 *
 * @param {User} userModel
 * @param {UserSettings} settingsModel
 * @param {Function} createUser
 * @param {Function} updateUserSettings
 * @param {Function} uploadAvatar
 * @param {Function} deleteAvatar
 * @param {string} actionName
 * @param {boolean} tip
 * @param {Object} children
 *
 * @return {*}
 * @constructor
 */
export const UserModelAction = ({
	userModel,
	settingsModel,
	createUser,
	updateUserSettings,
	uploadAvatar,
	deleteAvatar,
	actionName,
	tip,
	children,
}) => {
	const [isActive, setIsActive]       = useState(false)
	const [activeTab, setActiveTab]     = useState(1)
	const [createdUser, setCreatedUser] = useState(User.create({}))
	const [avatar, setAvatar]           = useState([])

	const onClose = useCallback(() => {
		setActiveTab(1)
		setIsActive(false)
	}, [])

	const prevAvatar      = useMemo(() => settingsModel.getImage(), [settingsModel])
	const userId          = useMemo(() => userModel?.getId?.() ?? createdUser.getId(), [createdUser, userModel])
	const isOtherDisabled = useMemo(() => !userModel && !createdUser.isExist(),
		[userModel, createdUser])
	const isAvatarLoaded  = useMemo(() => avatar[0] && avatar[0].isLoaded, [avatar])
	const isUserDeleted   = useMemo(() => userModel?.isDeleted?.(), [userModel])

	useEffect(() => {
		setAvatar(prevAvatar ? [getPreview(prevAvatar)] : [])
	}, [prevAvatar])

	const onCreateModel = useCallback(searchQuery => {
		searchQuery.setSuccessCallback(res => {
			setCreatedUser(User.create(res))
			setActiveTab(2)
		})

		createUser(searchQuery)
	}, [createUser])

	const onSubmit = useCallback(searchQuery => {
		if (avatar[0] && !isAvatarLoaded) {
			return uploadAvatar(getAvatarUploadQuery(userId, avatar[0], setAvatar)
				.setSuccessCallback(() => updateUserSettings(searchQuery.setSuccessCallback(onClose))))
		}

		return updateUserSettings(searchQuery.setSuccessCallback(onClose))
	}, [avatar, onClose, isAvatarLoaded, updateUserSettings, uploadAvatar, userId])

	const onDelete = useCallback(() => {
		if (isAvatarLoaded) {
			return deleteAvatar(DataProvider.buildQuery().addBody({ userId }))
		}
		return setAvatar([])
	}, [deleteAvatar, isAvatarLoaded, userId])

	const dropzoneProps = useMemo(() => ({
		files:    avatar,
		maxFiles: 1,
		onDelete,
		maxSize:  2 * 1024 * 1024, // 2Mb
		accept:   '.jpeg, .jpg, .png, .svg',
		setFiles: setAvatar,
	}), [avatar, onDelete])

	return (
		<Tip title={tip ? actionName : ''}>
			<div className={styles.menuWrapper}>
				{children ?
					React.cloneElement(children, { onClick: () => setIsActive(true) }) :
					<Button className={styles.btn} onClick={() => setIsActive(true)}>
						<Edit className={styles.editIcon} />
					</Button>}
				<Drawer anchor="right" open={isActive} onClose={onClose}>
					<AsidePanel title={`${actionName} пользователя`}>
						<div className={styles.modelTabsWrapper}>
							<Button
								className={[styles.modelTabButton, activeTab === 1 && styles.activeTab].join(' ')}
								onClick={() => setActiveTab(1)}
							>
								<Main />
								Основное
							</Button>
							<Button
								className={[styles.modelTabButton, activeTab === 2 && styles.activeTab,
									isOtherDisabled && styles.disabled].join(' ')}
								onClick={() => setActiveTab(2)}
								disabled={isOtherDisabled}
							>
								<Other />
								Прочее
							</Button>
						</div>
						{activeTab === 1 &&
						<ModelForm
							fields={usersFields(isUserDeleted)}
							model={userModel || createdUser}
							action={onCreateModel}
							close={onClose}
							buttonsGroupProps={{ title: 'Далее', isSuccessDisabled: isUserDeleted }}
						/>}
						{activeTab === 2 &&
						<ModelForm
							initialData={{ ...(createdUser.isExist() && { userId: createdUser.getId() }) }}
							fields={usersSettingsFields(dropzoneProps, userId, isUserDeleted)}
							model={settingsModel}
							action={onSubmit}
							close={onClose}
							buttonsGroupProps={{ isSuccessDisabled: isUserDeleted }}
						/>}
					</AsidePanel>
				</Drawer>
			</div>
		</Tip>
	)
}

UserModelAction.propTypes = {
	actionName:         PropTypes.string,
	children:           PropTypes.object,
	deleteAvatar:       PropTypes.func,
	updateUserSettings: PropTypes.func,
	settingsModel:      PropTypes.instanceOf(UserSettings),
	tip:                PropTypes.bool,
	uploadAvatar:       PropTypes.func,
	createUser:         PropTypes.func,
	userModel:          PropTypes.instanceOf(User),
}

UserModelAction.defaultProps = {
	actionName:         '',
	children:           null,
	deleteAvatar:       () => null,
	updateUserSettings: () => null,
	settingsModel:      UserSettings.create({}),
	tip:                false,
	uploadAvatar:       () => null,
	createUser:         () => null,
	userModel:          null,
}
