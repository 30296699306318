import { connect } from 'react-redux'
import { DispatchSelector } from 'reduxm/modules/selectors'
import { UserModelAction } from './index'

const mapDispatchToProps = {
	createUser:         DispatchSelector.users.manage.create,
	updateUser:         DispatchSelector.users.manage.update,
	updateUserSettings: DispatchSelector.users.manageSettings.update,
	uploadAvatar:       DispatchSelector.users.manageSettings.uploadAvatar,
	deleteAvatar:       DispatchSelector.users.manageSettings.deleteAvatar,
}

export default connect(null, mapDispatchToProps)(UserModelAction)
