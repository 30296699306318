import Drawer from '@material-ui/core/Drawer'
import { AddSharp } from '@material-ui/icons'
import { AsidePanel } from 'components/global/AsidePanel'
import { Button } from 'components/global/Button'
import ConnectServiceForm from 'forms/control-panel/project/ConnectService/index.store'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render Create model component
 *
 * @param {string} title
 * @param {Array.<Service|Site>} services
 * @param {number} projectId
 *
 * @return {*}
 * @constructor
 */
export const ConnectService = ({ title, projectId, services }) => {
	const [isActive, setIsActive]       = useState(false)
	const [serviceType, setServiceType] = useState('')

	const close = useCallback(() => {
		setIsActive(false)
		setServiceType('')
	}, [])

	return (
		<>
			<Button onClick={() => setIsActive(true)} className={styles.addBtn}>
				<AddSharp />
				<div className={styles.title}>{title}</div>
			</Button>
			<Drawer
				anchor="right"
				open={isActive}
				onClose={close}
			>
				<AsidePanel title={title}>
					<ConnectServiceForm
						projectId={projectId}
						services={services[serviceType] || []}
						type={serviceType}
						setServiceType={setServiceType}
						close={close}
					/>
				</AsidePanel>
			</Drawer>
		</>
	)
}

ConnectService.propTypes = {
	projectId: PropTypes.number,
	services:  PropTypes.object,
	title:     PropTypes.string,
}

ConnectService.defaultProps = {
	projectId: null,
	services:  {},
	title:     '',
}
