import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * AuthConfig model class
 */
class AuthConfig extends BaseModel {
	/**
	 * Create AuthConfig instance
	 *
	 * @param {Object} object
	 *
	 * @returns {AuthConfig}
	 */
	static create(object) {
		return super.create(object?.result?.model)
	}

	/**
	 * Get project id
	 *
	 * @returns {number}
	 */
	getProjectId = () => this.model.projectId

	/**
	 * Get credential
	 *
	 * @returns {Object}
	 */
	getJwtOptions = () => this.model.jwtOptions

	/**
	 * Get database URL
	 *
	 * @returns {string}
	 */
	getBearerOptions = () => this.model.bearerOptions

	/**
	 * Get fcmToken
	 *
	 * @returns {string}
	 */
	getGuestOptions = () => this.model.guestOptions

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default AuthConfig
