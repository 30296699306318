import { ReloadGateway } from 'components/gateway/GatewayActions/ReloadGateway'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render gateway actions
 *
 * @param {Function} updateGateway
 *
 * @return {*}
 * @constructor
 */
export const GatewayActions = ({ updateGateway }) => (
	<div className={styles.actionsWrapper}>
		<div className={styles.actionsTitle}>
			Доступные действия:
		</div>
		<div className={styles.actionsList}>
			<ReloadGateway action={updateGateway} />
		</div>
	</div>
)

GatewayActions.propTypes = {
	updateGateway: PropTypes.func,
}

GatewayActions.defaultProps = {
	updateGateway: () => null,
}
