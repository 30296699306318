import FormControlLabel from '@material-ui/core/FormControlLabel'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render input label
 *
 * @param {Node} children
 * @param {boolean} checked
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const InputLabel = ({ children, checked, ...props }) => (
	<FormControlLabel
		{...props}
		control={children}
		labelPlacement="start"
		className={[styles.label, checked && styles.checked].join(' ')}
	/>
)

InputLabel.propTypes = {
	checked:  PropTypes.bool,
	children: PropTypes.node,
}

InputLabel.defaultProps = {
	checked:  false,
	children: null,
}
