import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { PROJECT_FIREBASE_CONFIG_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/settings/firebase/actionTypes'

export const FirebaseConfigActions = {
	create:  RequestActionHelper.getActionCreatorFetch(PROJECT_FIREBASE_CONFIG_ACTIONS.CREATE),
	remove:  RequestActionHelper.getActionCreatorFetch(PROJECT_FIREBASE_CONFIG_ACTIONS.REMOVE),
	update:  RequestActionHelper.getActionCreatorFetch(PROJECT_FIREBASE_CONFIG_ACTIONS.UPDATE),
	view:    RequestActionHelper.getActionCreatorFetch(PROJECT_FIREBASE_CONFIG_ACTIONS.VIEW),
	setView: RequestActionHelper.getActionCreatorSuccess(PROJECT_FIREBASE_CONFIG_ACTIONS.VIEW),
}
