import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import { Service } from 'models'
import Site from 'models/control-panel/Site'

/**
 * Project model class
 */
class Project extends BaseModel {
	/**
	 * Create Project instance
	 *
	 * @param {Object} object
	 *
	 * @returns {Project}
	 */
	static create(object) {
		return super.create(object?.result?.model || object.result || object)
	}

	/**
	 * Create list customers
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<Project>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map customers
	 *
	 * @param {Array.<object>} objects
	 * @param {function(Project, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get title
	 *
	 * @returns {string}
	 */
	getTitle = () => this.model.title || ''

	/**
	 * Get alias
	 *
	 * @returns {string}
	 */
	getAlias = () => this.model.alias

	/**
	 * Get description
	 *
	 * @returns {string}
	 */
	getDescription = () => this.model.description

	/**
	 * Get domain
	 *
	 * @returns {string}
	 */
	getDomain = () => this.model.domain

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get timezone
	 *
	 * @returns {string}
	 */
	getTimezone = () => this.model.timezone

	/**
	 * Get other domains
	 *
	 * @returns {Array}
	 */
	getOtherDomains = () => this.model.otherDomains

	/**
	 * Is published
	 *
	 * @returns {boolean}
	 */
	isPublished = () => this.model.published

	/**
	 * Get Services
	 *
	 * @return {Array.<Service>}
	 */
	getServices = (isFiltered = false, services) => {
		const data = this.getRelation('Services', Service, services, true)

		return isFiltered ? data.filter(service => service.getAlias() !== 'control-panel') : data
	}

	/**
	 * Get Sites
	 *
	 * @return {Array.<Site>}
	 */
	getSites = sites => this.getRelation('Sites', Site, sites, true)

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { Services, createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default Project
