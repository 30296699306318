import { RadioButtons } from 'forms/fields/RadioButtons'
import React from 'react'

const effects = [
	{ label: 'Allow', value: 'allow' },
	{ label: 'Deny', value: 'deny' },
]

export const permissionsFields = [
	{ id: 'sub', title: 'Субъект' },
	{ id: 'method', title: 'Метод' },
	{ id: 'effect', title: 'Эффект', component: <RadioButtons buttons={effects} /> },
]
