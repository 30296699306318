import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { USERS_MANAGE_SETTINGS_ACTIONS } from 'reduxm/redux-saga/modules/users/manage/settings/actionTypes'

export const UsersManageSettingsActions = {
	update:             RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_SETTINGS_ACTIONS.UPDATE),
	view:               RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_SETTINGS_ACTIONS.VIEW),
	uploadAvatar:       RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_SETTINGS_ACTIONS.UPLOAD_AVATAR),
	deleteAvatar:       RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_SETTINGS_ACTIONS.DELETE_AVATAR),
	prepareAvatar:      RequestActionHelper.getActionCreatorSuccess(USERS_MANAGE_SETTINGS_ACTIONS.PREPARE_AVATAR),
	uploadDefaultImage: RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_SETTINGS_ACTIONS.UPLOAD_DEFAULT_IMAGE),
	removeDefaultImage: RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_SETTINGS_ACTIONS.REMOVE_DEFAULT_IMAGE),
	updateConfig:       RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_SETTINGS_ACTIONS.UPDATE_CONFIG),
}
