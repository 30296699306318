import {
	DeleteOutlineRounded as Remove,
	CheckRounded as Save,
} from '@material-ui/icons'
import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {
	Button,
	Input,
} from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render token field
 *
 * @param {Object} tokenState
 * @param {Function} createToken
 * @param {Function} removeToken
 * @param {Function} viewToken
 * @param {number} userId
 * @param {Object} field
 *
 * @return {*}
 * @constructor
 */
export const TokenField = ({
	tokenState,
	createToken,
	removeToken,
	viewToken,
	userId,
	field,
}) => {
	const [token, setToken] = useState('')

	const { id, title, disabled } = field

	const prevToken = useMemo(() => tokenState?.result?.model?.token || '', [tokenState])

	useEffect(() => {
		viewToken({ userId })
	}, [userId, viewToken])

	useEffect(() => {
		setToken(prevToken)
	}, [prevToken])

	const onClick = useCallback(() => {
		if (prevToken) {
			return removeToken({ userId })
		}

		return createToken({ userId, token })
	}, [createToken, prevToken, removeToken, token, userId])

	return (
		<div className={styles.container}>
			<FieldLabel id={id} title={title} />
			<div className={styles.inputWrapper}>
				<Input
					type="text"
					value={token}
					onChange={({ target: { value } }) => setToken(value)}
					disabled={prevToken || disabled}
					placeholder="Bearer токен..."
				/>
				<Button
					onClick={onClick}
					color={prevToken ? 'danger' : 'success'}
				>
					{prevToken ? <Remove /> : <Save />}
				</Button>
			</div>
		</div>
	)
}

TokenField.propTypes = {
	createToken: PropTypes.func,
	field:       PropTypes.object,
	removeToken: PropTypes.func,
	tokenState:  PropTypes.object,
	userId:      PropTypes.number.isRequired,
	viewToken:   PropTypes.func,
}

TokenField.defaultProps = {
	createToken: () => null,
	field:       {},
	removeToken: () => null,
	tokenState:  {},
	viewToken:   () => null,
}
