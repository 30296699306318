import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { ServicePage } from './index'

const { controlPanel: { services: { view }, projects: { list } } } = StateSelector

const mapStateToProps = state => ({
	serviceState:  view(state),
	projectsState: list(state),
	isFetching:    _(view(state), list(state)),
})

const mapDispatchToProps = {
	getService:  DispatchSelector.controlPanel.services.view,
	getProjects: DispatchSelector.controlPanel.projects.list,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicePage)
