import {
	put,
	select,
	takeEvery,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	removeFile,
	updateConfig,
	uploadFiles,
} from 'reduxm/redux-saga/modules/control-panel/files/sagas'
import { UsersManageActions } from 'reduxm/redux-saga/modules/users/manage/actionCreators'
import { UsersManageSelectors } from 'reduxm/redux-saga/modules/users/manage/actionSelectors'
import { USERS_MANAGE_SETTINGS_ACTIONS } from 'reduxm/redux-saga/modules/users/manage/settings/actionTypes'
import { UsersManageSettingsApi } from 'reduxm/redux-saga/modules/users/manage/settings/api'

/**
 * Update avatar.
 *
 * @param {Object} response
 * @param {Object} searchQuery
 *
 * @return {Generator<*, void, ?>}
 */
function* updateAvatar(response, searchQuery) {
	if (!response.error) {
		const { params: { userId } } = searchQuery.getBody()

		const { result: { list: prevList } } = yield select(UsersManageSelectors.list)

		const url = response?.result?.url ?? ''

		const list = prevList.map(user => {
			if (user.id === userId) {
				return { ...user, Settings: { ...user?.Settings ?? {}, image: url, absoluteImage: url } }
			}

			return user
		})

		yield put(UsersManageActions.setList({ list }))
	}
}

/**
 * View user settings model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, UsersManageSettingsApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Update user settings model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, UsersManageSettingsApi.update, null, ResponseHandler.addProjectOrigin)
}

/**
 * Upload user avatar to settings model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* uploadAvatar(action) {
	yield defaultAction(action, UsersManageSettingsApi.uploadAvatar, null, ResponseHandler.addProjectOrigin,
		updateAvatar)
}

/**
 * Delete user avatar from settings model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* deleteAvatar(action) {
	yield defaultAction(action, UsersManageSettingsApi.deleteAvatar, null, ResponseHandler.addProjectOrigin,
		updateAvatar)
}

export default [
	takeLatest(USERS_MANAGE_SETTINGS_ACTIONS.VIEW, view),
	takeLatest(USERS_MANAGE_SETTINGS_ACTIONS.UPDATE, update),
	takeLatest(USERS_MANAGE_SETTINGS_ACTIONS.UPLOAD_AVATAR, uploadAvatar),
	takeEvery(USERS_MANAGE_SETTINGS_ACTIONS.DELETE_AVATAR, deleteAvatar),
	takeLatest(USERS_MANAGE_SETTINGS_ACTIONS.UPLOAD_DEFAULT_IMAGE, uploadFiles),
	takeLatest(USERS_MANAGE_SETTINGS_ACTIONS.REMOVE_DEFAULT_IMAGE, removeFile),
	takeLatest(USERS_MANAGE_SETTINGS_ACTIONS.UPDATE_CONFIG, updateConfig),
]
