import {
	AccountBalanceRounded as ProjectIco,
	List,
	RefreshRounded as NoResult,
	SettingsInputComposite as ServiceIco,
} from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { useStopScrolling } from 'components/global/useStopScrolling'
import { lightSearchString } from 'components/wrapper/Header/SearchPanel/SearchResults/lightSearchString'
import {
	Project,
	Service,
} from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
} from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

/**
 * Render result list of search panel
 *
 * @param {Object} projectsState
 * @param {Object} servicesState
 * @param {Function} getProjects
 * @param {Function} getServices
 * @param {Function} stopSearching
 * @param {Function} clearSearchString
 * @param {string} searchString
 *
 * @return {*}
 * @constructor
 */
export const SearchResults = ({
	projectsState,
	servicesState,
	getProjects,
	getServices,
	stopSearching,
	clearSearchString,
	searchString,
}) => {
	useStopScrolling()

	const services = useMemo(() => Service.createList(servicesState), [servicesState])
	const projects = useMemo(() => Project.createList(projectsState), [projectsState])

	useEffect(() => {
		if (!services.length && !projects.length) {
			getProjects()
			getServices()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const findResult = useCallback(data => data.filter(dataItem => dataItem
		.getTitle()
		.toLowerCase()
		.includes(searchString.toLowerCase())), [searchString])

	const servicesResult = useMemo(() => findResult(services), [services, findResult])
	const projectsResult = useMemo(() => findResult(projects), [projects, findResult])

	const isResultExist = !!servicesResult.length || !!projectsResult.length

	return (
		<div className={styles.wrapper}>
			{isResultExist &&
			<div className={styles.header}>
				<List />
				<div className={styles.title}>
					Результаты поиска
				</div>
			</div>}

			{!!projectsResult.length &&
			<div className={styles.resultsList}>
				<div className={styles.blockTitle}>
					<ProjectIco />
					Проекты
				</div>
				{projectsResult.map(project => (
					<Link
						onClick={stopSearching}
						to={`/project/${project.getAlias()}`}
						key={project.getAlias()}
						className={styles.resultItem}
					>
						<div>{lightSearchString(searchString, project.getTitle())}</div>
						<span className={styles.description}>{project.getDescription()}</span>
					</Link>
				))}
			</div>}

			{!!servicesResult.length &&
			<div className={styles.resultsList}>
				<div className={styles.blockTitle}>
					<ServiceIco />
					Сервисы
				</div>
				{servicesResult.map(service => (
					<Link
						to={`/service/${service.getAlias()}`}
						key={service.getAlias()}
						className={styles.resultItem}
					>
						<div>{lightSearchString(searchString, service.getTitle())}</div>
						<span className={styles.description}>{service.getDescription()}</span>
					</Link>
				))}
			</div>}

			{!isResultExist &&
			<div className={styles.noResults}>
				<Button className={styles.noResultIco} onClick={clearSearchString}>
					<NoResult />
				</Button>
				<div>
					Увы, таких результатов нет...
				</div>
			</div>}
		</div>
	)
}

SearchResults.propTypes = {
	clearSearchString: PropTypes.func,
	getProjects:       PropTypes.func,
	getServices:       PropTypes.func,
	projectsState:     PropTypes.object,
	searchString:      PropTypes.string,
	servicesState:     PropTypes.object,
	stopSearching:     PropTypes.func,
}

SearchResults.defaultProps = {
	clearSearchString: () => null,
	getProjects:       () => null,
	getServices:       () => null,
	projectsState:     {},
	searchString:      '',
	servicesState:     {},
	stopSearching:     () => null,
}
