import { callApi } from 'reduxm/redux-saga/api'

const methods = {
	updateServiceConfig: 'authentication.actions.update-service-config',
}

/**
 * Update service config
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
const updateServiceConfig = searchQuery => {
	searchQuery.addRequestOptions({
		headers: {
			// Send request to all workers
			type: 'pub',
		},
	})
	return callApi(methods.updateServiceConfig, searchQuery)
}

export const AuthActionsApi = {
	updateServiceConfig,
}
