export const SERVICES_ACTIONS = {
	LIST:              'SERVICES_LIST',
	VIEW:              'SERVICES_VIEW',
	CREATE:            'SERVICES_CREATE',
	REMOVE:            'SERVICES_REMOVE',
	UPDATE:            'SERVICES_UPDATE',
	ADD_TO_PROJECT:    'SERVICES_ADD_TO_PROJECT',
	INFO:              'SERVICES_INFO',
	CREATE_DEPLOYMENT: 'SERVICES_CREATE_DEPLOYMENT',
	CHANGE_TASK_COUNT: 'SERVICES_CHANGE_TASK_COUNT',
}
