import {
	TabsContainer,
	TabsContent,
	TabsContentItem,
	TabsNavigation,
	TabsNavItem,
} from 'components/global/TabsTable'
import { getSettingTabs } from 'components/project/ServiceConfig/Authorization/getSettingTabs'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router'

/**
 * Render authorization config
 *
 * @param {Project} project
 * @param {Array.<Service>} services
 *
 * @return {boolean|*}
 * @constructor
 */
export const AuthorizationConfig = ({ project, services }) => {
	const settingTabs = getSettingTabs(services, project.getId())

	const { settingTab } = useParams()

	const activeTable = settingTab || 'role'

	return (
		<TabsContainer>
			<TabsNavigation>
				{settingTabs.map(tab => <TabsNavItem
					key={tab.alias}
					to={`/project/${project.getAlias()}/microservice/authorization/${tab.alias}`}
					isActive={activeTable === tab.alias}
					title={tab.title}
				/>)}
			</TabsNavigation>
			<TabsContent activeTab={activeTable}>
				{settingTabs.map(tab => (
					<TabsContentItem tabId={tab.alias} key={tab.alias}>
						{tab.component}
					</TabsContentItem>
				))}
			</TabsContent>
		</TabsContainer>
	)
}

AuthorizationConfig.propTypes = {
	project:  PropTypes.instanceOf(Project),
	services: PropTypes.array,
}

AuthorizationConfig.defaultProps = {
	project:  Project.create({}),
	services: [],
}
