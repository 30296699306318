export const PROJECTS_ACTIONS = {
	LIST:                  'PROJECTS_LIST',
	CREATE:                'PROJECT_CREATE',
	REMOVE:                'PROJECT_REMOVE',
	UPDATE:                'PROJECT_UPDATE',
	VIEW:                  'PROJECT_VIEW',
	CONNECT_SERVICE:       'PROJECT_CONNECT_SERVICE',
	DISCONNECT_SERVICE:    'PROJECT_DISCONNECT_SERVICE',
	UPDATE_GATEWAY:        'PROJECT_UPDATE_GATEWAY',
	SEARCH_LIST:           'PROJECT_SEARCH_LIST',
	COPY_PROJECT_QUERY:    'PROJECT_COPY_PROJECT_QUERY',
	COPY_PROJECT_SETTINGS: 'PROJECT_COPY_PROJECT_SETTINGS',
}
