import { Component } from 'react'
import { store } from 'react-notifications-component'

/**
 * Notification service
 *
 * https://github.com/teodosii/react-notifications-component
 */
export class Notification extends Component {
	/**
	 * Notification types
	 *
	 * @type {object}
	 */
	static types = {
		success: 'success',
		warning: 'warning',
		info:    'info',
		danger:  'danger',
	}

	static container = {
		topLeft:      'top-left',
		topRight:     'top-right',
		topCenter:    'top-center',
		center:       'center',
		bottomLeft:   'bottom-left',
		bottomRight:  'bottom-right',
		bottomCenter: 'bottom-center',
	}

	static insert = {
		top:    'top',
		bottom: 'bottom',
	}

	/**
	 * Default params
	 *
	 * @type {object}
	 */
	static defaultParams = {
		title:        '',
		message:      '',
		type:         this.types.danger,
		insert:       this.insert.top,
		container:    this.container.topRight,
		animationIn:  ['animated', 'fadeIn'],
		animationOut: ['animated', 'fadeOut'],
		dismiss:      {
			duration:     this.setDuration(6),
			showIcon:     true,
			pauseOnHover: true,
		},
	}

	/**
	 * Set duration in seconds
	 *
	 * @param {number} seconds
	 *
	 * @return {number}
	 */
	static setDuration(seconds) {
		return 1000 * seconds
	}

	/**
	 * Add new notification
	 *
	 * @param {object} params
	 *
	 * @return {undefined}
	 */
	static add(params) {
		store.addNotification({
			...this.defaultParams,
			...params,
		})
	}
}
