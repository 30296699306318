import { dictDefaultMessage } from 'forms/fields/Image/DefaultMessage'
import { previewTemplate } from 'forms/fields/Image/PreviewTemplate'

/**
 * Dropzone helper class
 */
class DropZoneHelper {
	/**
	 * Dropzone post url config
	 *
	 * @type {Object}
	 */
	static config = { postUrl: 'no-url' }

	/**
	 * Mock file for render preview images
	 *
	 * @private
	 * @type {Object}
	 */
	static mockFile = {
		id:     null,
		name:   'Avatar',
		type:   null,
		size:   0,
		status: 'success',
	}

	/**
	 * Get dropzone event handlers
	 *
	 * @param {string} prevImage
	 * @param {Function} addFile
	 * @return {Object}
	 */
	static eventHandlers = (prevImage, addFile) => ({
		init(dropzone) {
			if (prevImage) {
				dropzone.emit('addedfile', DropZoneHelper.mockFile, prevImage)
				dropzone.emit('thumbnail', DropZoneHelper.mockFile, prevImage)
				dropzone.emit('complete', DropZoneHelper.mockFile)
			}

			const submitButton = document.querySelector('button[type=submit]')

			submitButton.onclick = () => {
				if (dropzone.files.length) {
					document.getElementById('dz-progress-wrapper').classList.remove('hidden')
				}
			}
		},
		addedfile(file) {
			addFile(file)
		},
		maxfilesreached(files) {
			files.forEach(file => !file.accepted && file.previewElement.remove())
		},
	})

	/**
	 * Get dropzone config
	 *
	 * @param {Function} deleteImage
	 * @param {number} maxFiles Max files.
	 * @param {number}  maxFilesize Max file size (Mb).
	 * @param {string} paramName
	 * @param {string} acceptedFiles
	 *
	 * @return {Object}
	 */
	static djsConfig = (
		deleteImage,
		maxFiles      = 1,
		maxFilesize   = 2,
		acceptedFiles = '.jpg, .jpeg, .png, .svg',
		paramName     = 'file',
	) => ({
		autoProcessQueue:     false,
		maxFiles,
		maxFilesize,
		paramName,
		acceptedFiles,
		dictFileTooBig:       'Файл слишком большой',
		dictMaxFilesExceeded: `Вы можете загрузить только ${maxFiles} файл`,
		dictInvalidFileType:  'Такое расширение файла не поддерживается',
		dictResponseError:    'Возникла ошибка при загрузке',
		dictDefaultMessage:   dictDefaultMessage(acceptedFiles, maxFiles, maxFilesize),
		removedfile:          file => {
			if (file?.status === 'success') {
				deleteImage()
			}

			file.previewElement.remove()
		},
		previewTemplate,
	})
}

export default DropZoneHelper
