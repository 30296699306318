import {
	put,
	select,
} from 'redux-saga/effects'

class ProjectResponseHandler {
	/**
	 * After connect service response handler
	 *
	 * @param {Object} responseData
	 * @param {Object} searchQuery
	 * @param {function} getView
	 * @param {function} setView
	 *
	 * @return {undefined}
	 */
	static* afterConnectService(responseData, searchQuery, getView, setView) {
		const { result: { service }, response } = responseData

		const { result: { model, model: { Services } } } = yield select(getView)

		const nextModel = {
			...model,
			Services: [...Services, service],
		}

		const output = {
			model: nextModel,
			response,
		}

		yield put(setView(output))
	}

	/**
	 * After disconnect service response handler
	 *
	 * @param {Object} responseData
	 * @param {Object} searchQuery
	 * @param {function} getView
	 * @param {function} setView
	 *
	 * @return {undefined}
	 */
	static* afterDisconnectService(responseData, searchQuery, getView, setView) {
		const { result: { result: removalResult, alias }, response } = responseData

		if (removalResult) {
			const { params: { type } } = searchQuery.getBody()
			const targetInstance       = (type === 'site' && 'Sites') ||
				(type === 'microservice' && 'Services') || 'MobileApp'

			const { result: { model } } = yield select(getView)

			const nextModel = {
				...model,
				[targetInstance]: model[targetInstance].filter(service => service.alias !== alias),
			}

			const output = {
				model: nextModel,
				response,
			}

			yield put(setView(output))
		}
	}
}

export default ProjectResponseHandler
