import {
	Tooltip,
	Zoom,
} from '@material-ui/core'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'

/**
 * Render tips
 *
 * @param {Node} children
 * @param {string} title
 *
 * @return {*}
 * @constructor
 */
export const Tip = ({ children, title }) => {
	const [isTitleHidden, setIsTitleHidden] = useState(false)

	const hideTitle = useCallback(() => {
		if (!isTitleHidden) {
			setIsTitleHidden(true)
		}
	}, [isTitleHidden])

	const showTitle = useCallback(() => {
		if (isTitleHidden) setIsTitleHidden(false)
	}, [isTitleHidden])

	return (
		<div
			onMouseLeave={showTitle}
			onMouseEnter={showTitle}
		>
			<Tooltip
				onClick={hideTitle}
				TransitionComponent={Zoom}
				title={isTitleHidden ? '' : title}
			>
				{children}
			</Tooltip>
		</div>
	)
}

Tip.propTypes = {
	children: PropTypes.node,
	title:    PropTypes.string,
}

Tip.defaultProps = {
	children: null,
	title:    '',
}
