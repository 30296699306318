import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { Button as ServiceType } from 'components/global/Button'
import { CollapseHeight } from 'components/global/CollapseHeight'
import { CollapseHeightItem } from 'components/global/CollapseHeight/CollapseHeightItem'
import { InputLabel } from 'components/global/Inputs/InputLabel'
import { RadioButton } from 'components/global/Inputs/RadioButton'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { ValidationError } from 'components/global/ValidationMessage'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { Button } from 'reactstrap'
import styles from './styles.module.scss'

const operator = [
	{ name: 'ONLY', alias: 'only' },
	{ name: 'SKIP_EXTEND', alias: 'skipExtend' },
	{ name: 'AND', alias: 'and' },
	{ name: 'OR', alias: 'or' },
]

/**
 * Render operator type field
 *
 * @param {Function} setState
 * @param {string} value
 * @param {Object} field
 * @param {Object} invalid
 *
 * @return {*}
 * @constructor
 */
export const OperatorField = ({ setState, value, field, invalid }) => {
	const { id, title } = field

	const [serviceType, setServiceType] = useState('')
	const [isOpen, setIsOpen]           = useState(false)

	const buttonRef = useRef()

	useCheckOutsideClick(buttonRef, isOpen, setIsOpen)

	useEffect(() => {
		if (value) {
			const currentValue = operator.find(type => type.alias === value)

			setServiceType(currentValue.name)
		}
	}, [value])

	const setValues = useCallback(service => {
		setState(id, service)
	}, [id, setState])

	const handler = useCallback(alias => {
		setValues(alias)
	}, [setValues])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<div className={styles.inputsWrapper}>
				<Button
					className={styles.button}
					innerRef={buttonRef}
					color={invalid.result && !serviceType ? 'danger' : 'warning'}
					onClick={() => setIsOpen(!isOpen)}
				>
					{serviceType ? 'Изменить' : 'Выбрать'}
				</Button>
				{serviceType &&
				<ServiceType
					onClick={() => setServiceType('')}
					className={styles.choice}
				>
					{serviceType}
				</ServiceType>}
				<CollapseHeight left={0} isOpen={isOpen}>
					{operator.map(type => {
						const isChecked = type.name === serviceType

						return (
							<CollapseHeightItem
								key={type.alias}
								className={styles.operator}
								onClick={() => handler(type.alias)}
							>
								<InputLabel className={styles.label} label={type.name} checked={isChecked}>
									<RadioButton checked={isChecked} />
								</InputLabel>
							</CollapseHeightItem>
						)
					})}
				</CollapseHeight>
				<ValidationError message={!serviceType ? invalid.message : ''} />
			</div>
		</div>
	)
}

OperatorField.propTypes = {
	field:    PropTypes.object,
	invalid:  PropTypes.object,
	setState: PropTypes.func,
	value:    PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

OperatorField.defaultProps = {
	field:    {},
	invalid:  {},
	setState: () => null,
	value:    '',
}
