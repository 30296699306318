import { UsersTab } from 'components/project/ServiceConfig/Users/SettingTabs'
import UserConfig from 'components/project/ServiceConfig/Users/SettingTabs/Config/index.store'
import React from 'react'

/**
 * Get setting tabs to render table
 *
 * @return {Array}
 */
export const getSettingTabs = project => ([
	{ alias: 'users', title: 'Пользователи', component: <UsersTab /> },
	{ alias: 'config', title: 'Конфигурация', component: <UserConfig projectId={project.getId()} /> },
])
