import { Spinner } from 'components/global/Spinner'
import ProjectsTable from 'components/home/Tables/ProjectsTable/index.store'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render HomePage Page
 *
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const HomePage = ({ isFetching }) => (
	<>
		<Spinner block isFetching={isFetching} />
		<ProjectsTable />
	</>
)

HomePage.propTypes = {
	isFetching: PropTypes.bool,
}

HomePage.defaultProps = {
	isFetching: false,
}
