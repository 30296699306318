import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	searchList: 'geo.cities.index',
	view:       'geo.cities.view',
}

/**
 * Call api to view city model.
 *
 * @param {Object} searchQuery
 *
 * @return {function}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

/**
 * Call api to get search list of cities models.
 *
 * @param {Object} searchQuery
 *
 * @return {function}
 */
const searchList = searchQuery => callApi(methods.searchList, searchQuery)

export const BaseCitiesApi = {
	view,
	searchList,
}
