import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { PROJECTS_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/projects/actionTypes'

export const ProjectsActions = {
	list:                RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.LIST),
	create:              RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.CREATE),
	update:              RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.UPDATE),
	view:                RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.VIEW),
	remove:              RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.REMOVE),
	connectService:      RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.CONNECT_SERVICE),
	disconnectService:   RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.DISCONNECT_SERVICE),
	updateGateway:       RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.UPDATE_GATEWAY),
	setList:             RequestActionHelper.getActionCreatorSuccess(PROJECTS_ACTIONS.LIST),
	setView:             RequestActionHelper.getActionCreatorSuccess(PROJECTS_ACTIONS.VIEW),
	searchList:          RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.SEARCH_LIST),
	setSearchList:       RequestActionHelper.getActionCreatorSuccess(PROJECTS_ACTIONS.SEARCH_LIST),
	copyProjectSettings: RequestActionHelper.getActionCreatorFetch(PROJECTS_ACTIONS.COPY_PROJECT_SETTINGS),
	setCopyProjectQuery: RequestActionHelper.getActionCreatorSuccess(PROJECTS_ACTIONS.COPY_PROJECT_QUERY),
}
