import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * Site model class
 */
class Site extends BaseModel {
	/**
	 * Create Site instance
	 *
	 * @param {Object} object
	 *
	 * @returns {Site}
	 */
	static create(object) {
		return super.create(object?.result?.model || object.result || object)
	}

	/**
	 * Create Site list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<Site>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map customers
	 *
	 * @param {Array.<object>} objects
	 * @param {function(Site, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get title
	 *
	 * @returns {string}
	 */
	getTitle = () => this.model.title

	/**
	 * Get alias
	 *
	 * @returns {string}
	 */
	getAlias = () => this.model.alias

	/**
	 * Get description
	 *
	 * @returns {string}
	 */
	getDescription = () => this.model.description

	/**
	 * Get type
	 *
	 * @returns {string}
	 */
	getType = () => this.model.type

	/**
	 * Get docker image
	 *
	 * @return {string}
	 */
	getDockerImage = () => this.model.dockerImage

	/**
	 * Get service type
	 *
	 * @return {string}
	 */
	getServiceType = () => this.model.serviceType

	/**
	 * Get scaling policies
	 *
	 * @return {JSON}
	 */
	getScalingPolicies = () => this.model.scalingPolicies

	/**
	 * Get task definition
	 *
	 * @return {JSON}
	 */
	getTaskDefinition = () => this.model.taskDefinition

	/**
	 * Get amplify config
	 *
	 * @return {JSON}
	 */
	getAmplifyConfig = () => this.model.amplifyConfig

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default Site
