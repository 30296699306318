import {
	AwsCredentialsTable,
	FirebaseConfigTab,
	MailCredentialsTable,
	MySqlCredentialsTable,
	RedisCredentialsTable,
	SocialConfigTab,
} from 'components/project/Setting/SettingTabs'
import React from 'react'

/**
 * Get setting tabs to render table
 *
 * @param {Project} project
 *
 * @return {Array}
 */
export const getSettingTabs = project => ([
	{ alias: 'mysql', title: 'MySql', component: <MySqlCredentialsTable project={project} /> },
	{ alias: 'redis', title: 'Redis', component: <RedisCredentialsTable project={project} /> },
	{ alias: 'mail', title: 'Mail', component: <MailCredentialsTable project={project} /> },
	{ alias: 'aws', title: 'AWS', component: <AwsCredentialsTable project={project} /> },
	{ alias: 'firebase', title: 'Firebase', component: <FirebaseConfigTab project={project} /> },
	{ alias: 'social', title: 'Social', component: <SocialConfigTab project={project} /> },
])
