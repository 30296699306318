import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

/**
 * Render table actions
 *
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const TableActions = ({ children }) => (
	<div className={styles.actions}>
		{children}
	</div>
)

TableActions.propTypes = {
	children: PropTypes.node,
}

TableActions.defaultProps = {
	children: null,
}
