import styles from 'containers/User/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import {
	Input,
	InputGroup,
	InputGroupAddon,
	InputGroupText,
} from 'reactstrap'

/**
 * Render input with data
 *
 * @param {string} title
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const InputWrapper = ({ title, ...props }) => (
	<>
		<InputGroup>
			<InputGroupAddon addonType="prepend">
				<InputGroupText className={styles.title}>{title}</InputGroupText>
			</InputGroupAddon>
			<Input {...props} />
		</InputGroup>
		<br />
	</>
)

InputWrapper.propTypes = {
	title: PropTypes.string,
}

InputWrapper.defaultProps = {
	title: '',
}
