import styles from 'components/global/ModelsCounter/styles.module.scss'
import {
	femaleFindForm,
	recordForm,
} from 'helpers/prularForms'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render models counter
 *
 * @param {number} count
 *
 * @return {*}
 * @constructor
 */
export const ModelsCounter = ({ count }) => (
	<div className={styles.counter}>
		{_.startCase(femaleFindForm(count))}
		{' '}
		<div className={styles.number}>{count}</div>
		{' '}
		{recordForm(count)}
	</div>
)

ModelsCounter.propTypes = {
	count: PropTypes.number,
}

ModelsCounter.defaultProps = {
	count: 0,
}
