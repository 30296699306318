import { PROJECTS_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/projects/actionTypes'

export const ProjectsSelectors = {
	list:                state => state.controlPanel.projects[PROJECTS_ACTIONS.LIST],
	create:              state => state.controlPanel.projects[PROJECTS_ACTIONS.CREATE],
	update:              state => state.controlPanel.projects[PROJECTS_ACTIONS.UPDATE],
	remove:              state => state.controlPanel.projects[PROJECTS_ACTIONS.REMOVE],
	view:                state => state.controlPanel.projects[PROJECTS_ACTIONS.VIEW],
	connectService:      state => state.controlPanel.projects[PROJECTS_ACTIONS.CONNECT_SERVICE],
	updateGateway:       state => state.controlPanel.projects[PROJECTS_ACTIONS.UPDATE_GATEWAY],
	disconnectService:   state => state.controlPanel.projects[PROJECTS_ACTIONS.DISCONNECT_SERVICE],
	searchList:          state => state.controlPanel.projects[PROJECTS_ACTIONS.SEARCH_LIST],
	copyProjectQuery:    state => state.controlPanel.projects[PROJECTS_ACTIONS.COPY_PROJECT_QUERY],
	copyProjectSettings: state => state.controlPanel.projects[PROJECTS_ACTIONS.COPY_PROJECT_SETTINGS],
}
