import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import { ButtonsGroup } from 'components/global/AsidePanel/ButtonsGroup'
import { FormField } from 'components/global/AsidePanel/ModelForm/FormField'
import FormValidator from 'helpers/FormValidator'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Form } from 'reactstrap'

let prevState = {}

/**
 * Render form for update & create the model
 *
 * @param {BaseModel} model
 * @param {Function} action
 * @param {Array} fields
 * @param {Function} close
 * @param {Object} initialData
 * @param {Object} buttonsGroupProps
 *
 * @return {*}
 * @constructor
 */
export const ModelForm = ({
	model,
	action,
	fields,
	close,
	initialData,
	buttonsGroupProps,
}) => {
	const [attributes, setAttributes]         = useState(prevState)
	const [validationInfo, setValidationInfo] = useState({})

	const nextState = useMemo(() => ({ ...model.getAttributes?.() ?? {}, ...initialData }),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[JSON.stringify(initialData), JSON.stringify(model.getRawModel())])

	useEffect(() => {
		if (!_.isEqual(prevState, nextState)) {
			prevState = nextState

			setAttributes(nextState)
		}

		return () => {
			prevState = {}
		}
	}, [nextState])

	/**
	 * Save click listener
	 *
	 * @return {undefined}
	 */
	const onSubmit = useCallback(event => {
		event.preventDefault()

		const searchQuery = DataProvider
			.buildQuery()
			.addBody({ attributes })
			.setSuccessCallback(close)
			.setErrorCallback(errors => FormValidator.handleErrors(errors, setValidationInfo))

		action(searchQuery)
	}, [action, attributes, close])

	/**
	 * Set value to state
	 *
	 * @param {Event|string} event
	 * @param {Object|string} value
	 *
	 * @return {undefined}
	 */
	const setValue = (event, value) => {
		const nextAttributes = typeof value !== 'undefined' ?
			{ [event]: value } :
			{ [event.target.name]: event?.target?.value }

		setAttributes(prevAttributes => ({
			...prevAttributes,
			...nextAttributes,
		}))
	}

	/** Get element with all passed props
	 *
	 * @param {Object} field
	 *
	 * @return {React.FunctionComponentElement<unknown>}
	 */
	const getElement = useCallback(field => {
		const props = {
			...field.component.type.props,
			setState: setValue,
			key:      field.id,
			value:    attributes[field.id],
			invalid:  validationInfo[field.id],
			field,
		}

		return React.cloneElement(field.component, props)
	}, [attributes, validationInfo])

	return (
		<Form onSubmit={onSubmit}>
			{fields.map(field => (!field.component ?
				<FormField
					key={field.id}
					field={field}
					onChange={setValue}
					value={attributes[field.id] || ''}
					invalid={validationInfo[field.id]}
				/> :
				getElement(field)))}
			<ButtonsGroup cancelAction={close} {...buttonsGroupProps} />
		</Form>
	)
}

ModelForm.propTypes = {
	model:             PropTypes.instanceOf(BaseModel),
	action:            PropTypes.func,
	buttonsGroupProps: PropTypes.object,
	close:             PropTypes.func,
	fields:            PropTypes.array.isRequired,
	initialData:       PropTypes.object,
}

ModelForm.defaultProps = {
	model:             BaseModel.create({}),
	action:            () => null,
	buttonsGroupProps: {},
	close:             () => null,
	initialData:       {},
}
