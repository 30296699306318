import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/ServiceConfig/Authorization/SettingTabs/Roles/headerTitles'
import { rolesFields } from 'forms/authorization/roles'
import { AuthorizationRoles } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
} from 'react'

const tableName = 'roles'

const searchQuery = (page = 1) => ({ query: { page } })

/**
 * Render roles tab
 *
 * @param {Project} project
 * @param {boolean} isFetching
 * @param {Function} getRoles
 * @param {Object} rolesState
 * @param {Function} createRole
 * @param {Function} updateRole
 * @param {Function} removeRole
 *
 * @return {*}
 * @constructor
 */
export const RolesTab = ({
	isFetching,
	getRoles,
	rolesState,
	createRole,
	updateRole,
	removeRole,
}) => {
	useEffect(() => {
		getRoles(searchQuery())
	}, [getRoles])

	const roles        = AuthorizationRoles.createList(rolesState)
	const rolesService = StateService.create(rolesState)

	const removeModel = useCallback(nextSearchQuery => {
		nextSearchQuery.setCallback(() => getRoles(searchQuery()))

		removeRole(nextSearchQuery)
	}, [getRoles, removeRole])

	return (
		<Table
			paginationData={rolesService.getPagination()}
			paginationAction={page => getRoles(searchQuery(page))}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={roles.length} />
				<CreateModelButton
					action={createRole}
					formFields={rolesFields(roles)}
					title="Создать роль"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{(roles || []).map(role => (
				<TableWrapper key={`${role.getAlias()}`}>
					<TableRow tableName={tableName}>
						<TableCol title={role.getId()} />
						<TableCol
							title={AuthorizationRoles.getParentName(roles, role)}
						/>
						<TableCol>
							<TableNameCol>{role.getName(true)}</TableNameCol>
						</TableCol>
						<TableCol title={role.getAlias()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={role}
							action={updateRole}
							formFields={rolesFields(roles)}
							title="Редактировать роль"
							tip
						/>
						<RemoveModel
							id={{ id: role.getId() }}
							modelName={role.getName()}
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

RolesTab.propTypes = {
	createRole: PropTypes.func,
	getRoles:   PropTypes.func,
	removeRole: PropTypes.func,
	updateRole: PropTypes.func,
	rolesState: PropTypes.object,
	isFetching: PropTypes.bool,
}

RolesTab.defaultProps = {
	createRole: () => null,
	getRoles:   () => null,
	removeRole: () => null,
	updateRole: () => null,
	rolesState: {},
	isFetching: false,
}
