import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * AwsCredentials model class
 */
class AwsCredentials extends BaseModel {
	/**
	 * Create AwsCredentials instance
	 *
	 * @param {Object} object
	 *
	 * @returns {AwsCredentials}
	 */
	static create(object) {
		return super.create(object?.result?.model || object.result || object)
	}

	/**
	 * Create AwsCredentials list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<AwsCredentials>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map AwsCredentials
	 *
	 * @param {Array.<object>} objects
	 * @param {function(AwsCredentials, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get project id
	 *
	 * @returns {number}
	 */
	getProjectId = () => this.model.projectId

	/**
	 * Get service alias
	 *
	 * @returns {string}
	 */
	getServiceAlias = (asLabel = false) => (asLabel &&
		this.model.service === '*' && 'Все') || this.model.service

	/**
	 * Get accessKeyId
	 *
	 * @returns {string}
	 */
	getAccessKeyId = () => this.model.accessKeyId

	/**
	 * Get accessSecretKey
	 *
	 * @returns {string}
	 */
	getAccessSecretKey = () => this.model.accessSecretKey

	/**
	 * Get region
	 *
	 * @returns {string}
	 */
	getRegion = () => this.model.region

	/**
	 * Get bucketName
	 *
	 * @returns {string}
	 */
	getBucketName = () => this.model.bucketName

	/**
	 * Get bucket Cdn's
	 *
	 * @returns {JSON}
	 */
	getBucketCdn= () => this.model.bucketCdn

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default AwsCredentials
