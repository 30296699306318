import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import storage from '@kakadu-dev/base-frontend-helpers/storage/AsyncStorage'
import { persistReducer } from 'redux-persist'
import { AUTH_ACTIONS } from 'reduxm/redux-saga/modules/authentication/actions/actionTypes'

const persistConfig = {
	key:       'auth',
	storage,
	whitelist: [
		AUTH_ACTIONS.SIGN_IN,
	],
}

export default persistReducer(persistConfig, RequestActionHelper.createReducerActions(Object.values(AUTH_ACTIONS)))
