import { AllInclusiveRounded as Home } from '@material-ui/icons'
import { Tip } from 'components/global/Tip'
import styles from 'components/wrapper/Header/HeaderLink/styles.module.scss'
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Render home link
 *
 * @return {*}
 * @constructor
 */
export const HeaderLink = () => (
	<Tip title="На главную">
		<Link className={styles.link} to="/">
			<Home />
			<div className={styles.title}>
				Панель управления проектами
			</div>
		</Link>
	</Tip>
)
