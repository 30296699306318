import { connect } from 'react-redux'
import { DispatchSelector } from 'reduxm/modules/selectors'
import { ServiceCardActions } from './index'

const mapDispatchToProps = {
	disconnectService: DispatchSelector.controlPanel.projects.disconnectService,
	createDeployment:  DispatchSelector.controlPanel.services.createDeployment,
	changeTaskCount:   DispatchSelector.controlPanel.services.changeTaskCount,
}

export default connect(null, mapDispatchToProps)(ServiceCardActions)
