export const SITES_ACTIONS = {
	LIST:          'SITES_LIST',
	VIEW:          'SITES_VIEW',
	CREATE:        'SITES_CREATE',
	REMOVE:        'SITES_REMOVE',
	UPDATE:        'SITES_UPDATE',
	UPLOAD_FILE:   'SITES_UPLOAD_FILE',
	REMOVE_FILE:   'SITES_REMOVE_FILE',
	UPDATE_CONFIG: 'SITES_UPDATE_CONFIG',
}
