import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTHORIZATION_ROLES_ACTIONS } from 'reduxm/redux-saga/modules/authorization/roles/actionTypes'

export const AuthorizationRolesActions = {
	list:       RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ROLES_ACTIONS.LIST),
	create:     RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ROLES_ACTIONS.CREATE),
	update:     RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ROLES_ACTIONS.UPDATE),
	view:       RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ROLES_ACTIONS.VIEW),
	remove:     RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ROLES_ACTIONS.REMOVE),
	setList:    RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_ROLES_ACTIONS.LIST),
	setView:    RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_ROLES_ACTIONS.VIEW),
	searchList: RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_ROLES_ACTIONS.SEARCH_LIST),
}
