import styles from 'components/global/TabsTable/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { Nav } from 'reactstrap'

/**
 * Render tabs navigation
 *
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const TabsNavigation = ({ children }) => (
	<Nav tabs className={styles.nav}>
		{children}
	</Nav>
)

TabsNavigation.propTypes = {
	children: PropTypes.node,
}

TabsNavigation.defaultProps = {
	children: null,
}
