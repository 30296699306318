import { RadioButtons } from 'forms/fields/RadioButtons'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

const serviceTypes = [{ label: 'Docker', value: 'docker' }, { label: 'Amplify', value: 'amplify' }]

/**
 * Render site type field
 *
 * @param {string} value
 * @param {Object} props
 * @param {boolean} isUpdating
 * @param {Function} setSiteType
 *
 * @return {*}
 * @constructor
 */
export const SiteType = ({ value, setSiteType, isUpdating, ...props }) => {
	useEffect(() => {
		if (value) {
			setSiteType(value)
		}
	}, [value, setSiteType])

	return (
		<RadioButtons
			{...props}
			value={value}
			buttons={serviceTypes}
			action={setSiteType}
			disabled={isUpdating}
		/>
	)
}

SiteType.propTypes = {
	props:       PropTypes.object,
	setSiteType: PropTypes.func,
	value:       PropTypes.string,
	isUpdating:  PropTypes.bool,
}

SiteType.defaultProps = {
	props:       {},
	setSiteType: () => null,
	value:       '',
	isUpdating:  false,
}
