import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from '../styles.module.scss'

/**
 * Render table row
 *
 * @param {Node} children
 * @param {string} className
 * @param {"service"|"project"|"mysqlCredentials"} tableName
 *
 * @return {*}
 * @constructor
 */
export const TableRow = ({ children, tableName, className }) => {
	const sendProps = useCallback(child => React.cloneElement(child, { ...child.props, tableName }),
		[tableName])

	return (
		<div className={[styles.row, className].join(' ')}>
			{React.Children.map(children, child => sendProps(child))}
		</div>
	)
}

TableRow.propTypes = {
	children:  PropTypes.node,
	tableName: PropTypes.string,
	className: PropTypes.string,
}

TableRow.defaultProps = {
	children:  null,
	tableName: '',
	className: '',
}
