import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import { AuthorizationRoles } from 'models'

/**
 * AuthorizationFilters model class
 */
class AuthorizationFilters extends BaseModel {
	/**
	 * Create AuthorizationFilters instance
	 *
	 * @param {Object} object
	 *
	 * @returns {AuthorizationFilters}
	 */
	static create(object) {
		return super.create(object?.result?.model)
	}

	/**
	 * Create list AuthorizationFilters
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<AuthorizationFilters>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get name
	 *
	 * @returns {Object}
	 */
	getName = isShort => (isShort ? this.model.name?.replace(/\[(.*?)]\[(.*?)]/, '') ?? '' : this.model.name)

	/**
	 * Get alias
	 *
	 * @returns {string}
	 */
	getRoleAlias = () => this.model.roleAlias

	/**
	 * Get operator
	 *
	 * @returns {string}
	 */
	getOperator = () => this.model.operator

	/**
	 * Get condition
	 *
	 * @returns {Object}
	 */
	getCondition = () => this.model.condition

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get role
	 *
	 * @param role
	 *
	 * @return {*}
	 */
	getRole = role => this.getRelation('Role', AuthorizationRoles, role)

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default AuthorizationFilters
