import {
	VpnKeyOutlined as accessKeyId,
	LockOpenOutlined as accessSecretKey,
	People as alias,
	FormatTextdirectionLToROutlined as attributeId,
	ArrowForward as attributeInId,
	ArrowBack as attributeOutId,
	AccountBoxOutlined as authentication,
	VerifiedUserOutlined as authorization,
	SpaOutlined as base,
	DevicesRounded as bearerOptions,
	CakeOutlined as birthDay,
	CloudDownloadOutlined as bucketCdn,
	CloudOutlined as bucketName,
	LocationCityRounded as cityId,
	ExposureOutlined as condition,
	SettingsOutlined as config,
	BusinessCenterRounded as control_panel,
	QueuePlayNextRounded as created,
	BorderColorRounded as credential,
	StorageRounded as database,
	HttpRounded as databaseURL,
	WallpaperOutlined as defaultUserImage,
	PostAddRounded as description,
	WhatshotRounded as dockerImage,
	Computer as domain,
	PanToolOutlined as effect,
	MailOutlineRounded as email,
	HttpsOutlined as encryption,
	AddPhotoAlternateOutlined as favicon,
	VpnKeyRounded as fcmToken,
	PermIdentityOutlined as firstName,
	BorderColorRounded as frontendCredentials,
	RouterRounded as gatewayPage,
	WcRounded as gender,
	PeopleRounded as guestOptions,
	VpnLockRounded as host,
	FingerprintRounded as id,
	AccountCircleOutlined as image,
	VerifiedUserOutlined as isEmailVerified,
	VerifiedUserOutlined as isPhoneVerified,
	ImportantDevicesRounded as jwtOptions,
	PermIdentityOutlined as lastName,
	WallpaperOutlined as logo,
	ArtTrackOutlined as manifest,
	SettingsRemoteOutlined as method,
	WorkOutlineRounded as microservice,
	PermIdentityOutlined as middleName,
	MobileScreenShareOutlined as mobile_app,
	AssignmentIndRounded as name,
	CenterFocusWeakRounded as operator,
	AssignmentRounded as other,
	ImportantDevicesRounded as otherDomains,
	TuneRounded as params,
	HowToRegRounded as parentId,
	VpnKeyOutlined as password,
	AccountBalanceWalletOutlined as pay,
	ContactPhoneOutlined as phone,
	UsbRounded as port,
	AccountBalanceRounded as project,
	AccountBalanceRounded as projectsPage,
	LocationOnOutlined as region,
	BusinessCenterOutlined as robots,
	EmojiObjectsOutlined as roleAlias,
	OpenWithRounded as scalingPolicies,
	AspectRatio as schema,
	ImageSearchOutlined as seo,
	WorkOutlineRounded as service,
	AddToQueue as serviceAlias,
	SettingsInputComposite as servicesPage,
	InputRounded as serviceType,
	TuneRounded as settings,
	LanguageRounded as site,
	LanguageRounded as sitesPage,
	QueuePlayNextOutlined as slaves,
	SaveOutlined as srv,
	AssignmentTurnedInOutlined as status,
	HowToReg as sub,
	TuneRounded as taskDefinition,
	AlarmOnRounded as timezone,
	AccountBalanceRounded as title,
	VpnLockOutlined as token,
	MergeTypeRounded as type,
	AutorenewRounded as updated,
	PersonAddOutlined as user,
	FingerprintOutlined as userId,
	SupervisorAccount as users,
	VpnKeyRounded as webPushToken,
} from '@material-ui/icons'

const icons = {
	authentication,
	attributeId,
	databaseURL,
	fcmToken,
	authorization,
	isEmailVerified,
	isPhoneVerified,
	id,
	site,
	microservice,
	jwtOptions,
	users,
	other,
	frontendCredentials,
	token,
	webPushToken,
	project,
	timezone,
	title,
	schema,
	updated,
	favicon,
	robots,
	manifest,
	seo,
	pay,
	created,
	type,
	sitesPage,
	bearerOptions,
	birthDay,
	gender,
	status,
	firstName,
	lastName,
	middleName,
	defaultUserImage,
	userId,
	cityId,
	image,
	email,
	base,
	phone,
	params,
	attributeOutId,
	attributeInId,
	dockerImage,
	operator,
	accessKeyId,
	accessSecretKey,
	encryption,
	region,
	bucketName,
	bucketCdn,
	sub,
	effect,
	method,
	condition,
	roleAlias,
	serviceAlias,
	alias,
	config,
	description,
	domain,
	otherDomains,
	projectsPage,
	servicesPage,
	gatewayPage,
	taskDefinition,
	scalingPolicies,
	parentId,
	serviceType,
	settings,
	port,
	password,
	logo,
	user,
	database,
	host,
	service,
	guestOptions,
	srv,
	slaves,
	credential,
	name,
}

/**
 * Get icon for services
 *
 * @param {string} identifier
 *
 * @return {Object}
 */
export const getIcon = identifier => {
	switch (identifier) {
		case 'control-panel':
			return control_panel

		case 'mobile-app':
			return mobile_app

		default:
			return icons[identifier] || icons.project
	}
}
