import { CheckCircle as Icon } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import styles from 'components/global/CreateDeploymentModal/styles.module.scss'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useState,
} from 'react'

/**
 * Render create deployment modal
 *
 * @param {boolean} isActive
 * @param {Function} toggle
 * @param {Function} createDeployment
 * @param {Object} data
 *
 * @return {boolean|*}
 * @constructor
 */
export const CreateDeploymentModal = ({ isActive, toggle, createDeployment, data }) => {
	const [seconds, setSeconds] = useState(10)

	useEffect(() => {
		if (!seconds) {
			setSeconds(10)
			toggle()
		}
	}, [seconds, toggle])

	useEffect(() => {
		let timer

		if (isActive) {
			timer = setInterval(() => setSeconds(prev => prev - 1), 1000)
		}

		return () => clearInterval(timer)
	}, [isActive])

	return (
		!!seconds && isActive &&
		<div className={styles.container}>
			<div className={styles.header}>
				<Icon />
			</div>
			<div className={styles.content}>
				<div className={styles.title}>
					Обновить микросервис?
				</div>
				<div className={styles.description}>
					Некоторые изменения вступят в силу только после перезагрузки.
				</div>
			</div>

			<div className={styles.footer}>
				<Button
					className={styles.successBtn}
					onClick={() => {
						createDeployment(data)
						setSeconds(0)
					}}
				>
					Да
				</Button>
				<Button
					className={styles.cancelBtn}
					onClick={() => setSeconds(0)}
				>
					Нет
				</Button>
				<div className={styles.timer}>{seconds}</div>
			</div>
		</div>
	)
}

CreateDeploymentModal.propTypes = {
	createDeployment: PropTypes.func,
	data:             PropTypes.object,
	isActive:         PropTypes.bool,
	toggle:           PropTypes.func,
}

CreateDeploymentModal.defaultProps = {
	createDeployment: () => null,
	data:             {},
	isActive:         false,
	toggle:           () => null,
}
