import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfo,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { Title } from 'components/global/Title'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/home/Tables/ServicesTable/headerTitles'
import { TableToggle } from 'components/home/TableToggle'
import { sitesFormFields } from 'forms/control-panel/site'
import Site from 'models/control-panel/Site'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import styles from '../styles.module.scss'

const tableName = 'service'

const searchQuery = (page = 1) => ({ query: { page } })

/**
 * Render SitesTable
 *
 * @param {Function} getSites
 * @param {Object} sitesState
 * @param {Function} createSites
 * @param {Function} removeSites
 * @param {Function} updateSites
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const SitesTable = ({
	getSites,
	sitesState,
	createSites,
	removeSites,
	updateSites,
	isFetching,
}) => {
	const [siteType, setSiteType] = useState(null)

	useEffect(() => {
		getSites(searchQuery())
	}, [getSites])

	const sites           = Site.createList(sitesState)
	const servicesService = StateService.create(sitesState)

	const info = sites.length ?
		'Кликните по сайту, чтобы увидеть подробную информацию' :
		'Список сайтов пуст...'

	const modelsActionCallBack = useCallback(() => setSiteType(null), [])

	return (
		<section className={styles.section}>
			<TableToggle id="sitesPage" />
			<Table
				paginationData={servicesService.getPagination()}
				paginationAction={page => getSites(searchQuery(page))}
				fit
			>
				<Spinner block isFetching={isFetching} />
				<Title name="Список сайтов" />
				<TableInfoWrapper>
					<ModelsCounter count={sites.length} />
					<CreateModelButton
						action={createSites}
						callBack={modelsActionCallBack}
						formFields={sitesFormFields(siteType, setSiteType)}
						title="Создать сайт"
					/>
				</TableInfoWrapper>
				<TableHeader titles={headerTitles} tableName={tableName} />
				<TableInfo info={info} />
				{(sites || []).map((site, idx) => (
					<TableWrapper key={site.getAlias()}>
						<TableRow tableName={tableName}>
							<TableCol title={idx + 1} />
							<TableCol>
								<TableNameCol>
									{site.getTitle()}
								</TableNameCol>
							</TableCol>
							<TableCol title={site.getAlias()} />
							<TableCol title={site.getDescription()} />
						</TableRow>
						<TableActions>
							<UpdateModel
								model={site}
								action={updateSites}
								callBack={modelsActionCallBack}
								formFields={sitesFormFields(siteType, setSiteType, true)}
								title="Редактировать сайт"
								tip
							/>
							<RemoveModel
								action={removeSites}
								actionName="удалить"
								id={{ alias: site.getAlias() }}
								modelName={site.getTitle()}
								tip
							/>
						</TableActions>
					</TableWrapper>))}
			</Table>
		</section>
	)
}

SitesTable.propTypes = {
	createSites: PropTypes.func,
	getSites:    PropTypes.func,
	isFetching:  PropTypes.bool,
	removeSites: PropTypes.func,
	sitesState:  PropTypes.object,
	updateSites: PropTypes.func,
}

SitesTable.defaultProps = {
	createSites: () => null,
	getSites:    () => null,
	isFetching:  false,
	removeSites: () => null,
	sitesState:  {},
	updateSites: () => null,
}
