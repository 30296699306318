import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { AttributesField } from './index'

const { authorization: { attributes: { searchList } } }                = StateSelector
const { authorization: { attributes: { searchList: getSearchList } } } = DispatchSelector

const mapStateToProps = state => ({ searchListState: searchList(state) })

const mapDispatchToProps = { getSearchList }

export default connect(mapStateToProps, mapDispatchToProps)(AttributesField)
