import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * AuthorizationPermissions model class
 */
class AuthorizationPermissions extends BaseModel {
	/**
	 * Get object from array
	 *
	 * @param {Array} array
	 *
	 * @return {{policyType: string, v1: string, v2: string, v3: string}} Permissions model
	 */
	static getObjectFromArray = array => ({
		policyType: array[3],
		v1:         array[0],
		v2:         array[1],
		v3:         array[2],
	})

	/**
	 * Create AuthorizationMethods instance
	 *
	 * @param {Object} object
	 *
	 * @returns {AuthorizationPermissions}
	 */
	static create(object) {
		return super.create(AuthorizationPermissions.getObjectFromArray(object?.result?.model))
	}

	/**
	 * Create AuthorizationPermissions list
	 *
	 * @param {Object} objects
	 *
	 * @return {Array.<AuthorizationPermissions>}
	 */
	static createList(objects) {
		const { result: { list: prevList = [] } } = objects

		const nextObjects = {
			...objects,
			result: {
				...objects.result,
				list:
					prevList.map(item => AuthorizationPermissions.getObjectFromArray(item)),
			},
		}

		return super.createList(nextObjects)
	}

	/**
	 * Get policy type
	 *
	 * @returns {string}
	 */
	getPolicyType = () => this.model.policyType

	/**
	 * Get v1
	 *
	 * @returns {string}
	 */
	getV1 = () => this.model.v1

	/**
	 * Get v2
	 *
	 * @returns {string}
	 */
	getV2 = () => this.model.v2

	/**
	 * Get v3
	 *
	 * @returns {string}
	 */
	getV3 = () => this.model.v3

	/**
	 * Get primary key
	 *
	 * @returns {string}
	 */
	getPrimaryKey = () => `${this.model.policyType}-${this.model.v1}-${this.model.v2}`

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { v1: sub, v2: method, v3: effect } = this.model

		return {
			sub,
			method,
			effect,
		}
	}
}

export default AuthorizationPermissions
