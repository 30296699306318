import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/Setting/SettingTabs/MySqlCredentialsTable/headerTitles'
import { mySqlCredentialFields } from 'forms/control-panel/settings/mysql'
import { getCredentialsForm } from 'forms/control-panel/settings/utils'
import { MySqlCredentials } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
} from 'react'

const tableName = 'mysqlCredentials'

const searchQuery = (projectId, page = 1) => ({ query: { filter: { projectId }, page } })

/**
 * Render project settings
 *
 * @param {Object} mysqlCredentialsState
 * @param {Function} getMysqlCredentials
 * @param {Function} createMysqlCredentials
 * @param {Function} updateMysqlCredentials
 * @param {Function} removeMysqlCredentials
 * @param {Project} project
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const MySqlCredentialsTable = ({
	mysqlCredentialsState,
	getMysqlCredentials,
	createMysqlCredentials,
	updateMysqlCredentials,
	removeMysqlCredentials,
	project,
	isFetching,
}) => {
	const projectId = project.getId()

	useEffect(() => {
		getMysqlCredentials(searchQuery(projectId))
	}, [getMysqlCredentials, projectId])

	const mySqlCredentials        = MySqlCredentials.createList(mysqlCredentialsState)
	const mySqlCredentialsService = StateService.create(mysqlCredentialsState)

	const removeModel = useCallback(nextSearchQuery => {
		nextSearchQuery.setCallback(() => getMysqlCredentials(searchQuery(projectId)))

		removeMysqlCredentials(nextSearchQuery)
	}, [getMysqlCredentials, projectId, removeMysqlCredentials])

	const getForm = getCredentialsForm(mySqlCredentialFields, mySqlCredentials)

	return (
		<Table
			paginationData={mySqlCredentialsService.getPagination()}
			paginationAction={page => getMysqlCredentials(searchQuery(projectId, page))}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={mySqlCredentials.length} />
				<CreateModelButton
					initialData={{ projectId: project.getId() }}
					action={createMysqlCredentials}
					formFields={getForm()}
					title="Создать настройки MySql"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{(mySqlCredentials || []).map(credential => (
				<TableWrapper key={`${credential.getProjectId()}-${credential.getServiceAlias()}`}>
					<TableRow tableName={tableName}>
						<TableCol>
							<TableNameCol>
								{credential.getServiceAlias(true)}
							</TableNameCol>
						</TableCol>
						<TableCol title={credential.getDatabase()} />
						<TableCol title={credential.getHost()} />
						<TableCol title={credential.getUser()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={credential}
							action={updateMysqlCredentials}
							formFields={getForm(credential.getServiceAlias())}
							title="Редактировать MySql настройки"
							tip
						/>
						<RemoveModel
							id={{
								projectId: credential.getProjectId(),
								service:   credential.getServiceAlias(),
							}}
							modelName={credential.getServiceAlias()}
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

MySqlCredentialsTable.propTypes = {
	createMysqlCredentials: PropTypes.func,
	getMysqlCredentials:    PropTypes.func,
	isFetching:             PropTypes.bool,
	mysqlCredentialsState:  PropTypes.object,
	project:                PropTypes.object.isRequired,
	removeMysqlCredentials: PropTypes.func,
	updateMysqlCredentials: PropTypes.func,
}

MySqlCredentialsTable.defaultProps = {
	createMysqlCredentials: () => null,
	getMysqlCredentials:    () => null,
	isFetching:             false,
	mysqlCredentialsState:  {},
	removeMysqlCredentials: () => null,
	updateMysqlCredentials: () => null,
}
