import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { CityField } from './index'

const { geo: { cities: { searchList } } }                               = StateSelector
const { geo: { cities: { searchList: getSearchList, view: getCity } } } = DispatchSelector

const mapStateToProps = state => ({
	searchListState: searchList(state),
})

const mapDispatchToProps = {
	getCity,
	getSearchList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CityField)
