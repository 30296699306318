import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfo,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { Title } from 'components/global/Title'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/home/Tables/ServicesTable/headerTitles'
import { TableToggle } from 'components/home/TableToggle'
import { serviceFormFields } from 'forms'
import { Service } from 'models'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles.module.scss'

const tableName = 'service'

const searchQuery = (page = 1) => ({ query: { page } })

/**
 * Render ServicesTable
 *
 * @param {Function} getServices
 * @param {Object} servicesState
 * @param {Function} createService
 * @param {Function} removeService
 * @param {Function} updateService
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const ServicesTable = ({
	getServices,
	servicesState,
	createService,
	removeService,
	updateService,
	isFetching,
}) => {
	useEffect(() => {
		getServices(searchQuery())
	}, [getServices])

	const services        = Service.createList(servicesState)
	const servicesService = StateService.create(servicesState)

	const info = services.length ?
		'Кликните по сервису, чтобы увидеть подробную информацию' :
		'Список сервисов пуст...'

	return (
		<section className={styles.section}>
			<TableToggle id="servicesPage" />
			<Table
				paginationData={servicesService.getPagination()}
				paginationAction={page => getServices(searchQuery(page))}
				fit
			>
				<Spinner block isFetching={isFetching} />
				<Title name="Список микросервисов" />
				<TableInfoWrapper>
					<ModelsCounter count={services.length} />
					<CreateModelButton
						action={createService}
						formFields={serviceFormFields}
						title="Создать сервис"
					/>
				</TableInfoWrapper>
				<TableHeader titles={headerTitles} tableName={tableName} />
				<TableInfo info={info} />
				{(services || []).map((service, idx) => (
					<TableWrapper key={service.getAlias()}>
						<TableRow tableName={tableName}>
							<TableCol title={idx + 1} />
							<TableCol>
								<Link className={styles.link} to={`${tableName}/${service.getAlias()}`}>
									<TableNameCol active>
										{service.getTitle()}
									</TableNameCol>
								</Link>
							</TableCol>
							<TableCol title={service.getAlias()} />
							<TableCol title={service.getDescription()} />
						</TableRow>
						<TableActions>
							<UpdateModel
								model={service}
								action={updateService}
								formFields={serviceFormFields}
								title="Редактировать сервис"
								tip
							/>
							<RemoveModel
								action={removeService}
								actionName="удалить"
								id={{ alias: service.getAlias() }}
								modelName={service.getTitle()}
								tip
							/>
						</TableActions>
					</TableWrapper>))}
			</Table>
		</section>
	)
}

ServicesTable.propTypes = {
	createService: PropTypes.func,
	getServices:   PropTypes.func,
	isFetching:    PropTypes.bool,
	removeService: PropTypes.func,
	servicesState: PropTypes.object,
	updateService: PropTypes.func,
}

ServicesTable.defaultProps = {
	createService: () => null,
	getServices:   () => null,
	isFetching:    false,
	removeService: () => null,
	servicesState: {},
	updateService: () => null,
}
