import { SvgIcon } from '@material-ui/core'
import { NavigateNextRounded as More } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { getIcon } from 'components/project/Home/SideBar/getIcon'
import styles from 'components/project/Home/SideBar/styles.module.scss'
import PropTypes from 'prop-types'
import React, {
	useMemo,
	useRef,
	useState,
} from 'react'
import { Link } from 'react-router-dom'

/**
 * Render sidebar items group
 *
 * @param {string} title
 * @param {string} alias
 * @param {Array.<Site>|Array.<Service>} items
 * @param {string} projectAlias
 * @param {string} service
 *
 * @return {*}
 * @constructor
 */
export const SideBarItemsGroup = ({
	title,
	alias,
	items,
	projectAlias,
	service,
}) => {
	const [isItemsVisible, setIsItemsVisible] = useState(false)

	const wrapperRef = useRef()

	const isItemsExist = useMemo(() => !!items.length, [items.length])

	useCheckOutsideClick(wrapperRef, isItemsVisible, setIsItemsVisible, isItemsExist)

	const isActive = items.find(item => item.getAlias() === service)

	return (isItemsExist &&
		<Button
			ref={wrapperRef}
			onClick={() => setIsItemsVisible(!isItemsVisible)}
			className={[styles.tab, isActive && styles.activeTab].join(' ')}
		>
			<More className={[styles.more, isItemsVisible && styles.moreActive].join(' ')} />
			<SvgIcon className={styles.icon} component={getIcon(alias)} />
			<div className={styles.title}>
				{title}
			</div>
			{isItemsVisible &&
			<div className={styles.items}>
				{items.map(item => (
					<Link
						key={item.getAlias()}
						to={`/project/${projectAlias}/${alias}/${item.getAlias()}`}
						className={[styles.item, service === item.getAlias() && styles.activeItem].join(' ')}
					>
						<SvgIcon component={getIcon(item.getAlias())} />
						{item.getTitle()}
					</Link>
				))}
			</div>}
		</Button>
	)
}

SideBarItemsGroup.propTypes = {
	service:      PropTypes.string,
	alias:        PropTypes.string,
	items:        PropTypes.array,
	projectAlias: PropTypes.string,
	title:        PropTypes.string,
}

SideBarItemsGroup.defaultProps = {
	service:      '',
	alias:        '',
	items:        [],
	projectAlias: '',
	title:        '',
}
