import { LinkRounded as Doc } from '@material-ui/icons'
import React from 'react'

const awsUrl = 'https://docs.aws.amazon.com/AmazonECS/latest/APIReference/API_RegisterTaskDefinition.html'

/**
 * Render link to aws documentation
 *
 * @return {*}
 * @constructor
 */
export const AwsDocLink = () => (
	<a
		href={awsUrl}
		rel="noreferrer noopener"
		target="_blank"
		className="aws-doc-link"
	>
		<Doc />
		См. документацию
	</a>
)
