import {
	CloseOutlined as Cancel,
	DeleteOutlineOutlined as Remove,
} from '@material-ui/icons'
import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import { Progress } from 'reactstrap'

/**
 * Render images preview
 *
 * @param {string} title
 * @param {Array} files
 * @param {Function} onDelete
 *
 * @return {boolean|*}
 * @constructor
 */
export const ImagesPreview = ({ title, files, onDelete }) => files.length > 0 && (
	<aside className="images-preview">
		<div>
			{title}
			:
		</div>
		<div className="images-preview__container">
			{files.map(file => {
				const { isLoaded } = file
				const progress     = file?.progress ?? false

				return (
					<div
						className={['images-preview-item', isLoaded && '_uploaded'].join(' ')}
						key={Date.now()}
					>
						<Button
							className="images-preview-item__btn"
							onClick={() => onDelete(file)}
						>
							{isLoaded ? <Remove /> : <Cancel />}
						</Button>
						{typeof progress === 'number' && (
							<>
								<Progress
									className="images-preview-item__progress"
									bar
									animated
									color="success"
									value={progress}
								/>
								<span className="images-preview-item__progress-count">
									{progress}
									%
								</span>
							</>
						)}
						<img
							src={file.preview}
							className="images-preview-item__image"
							alt={`preview-${file.name}`}
						/>
					</div>
				)
			})}
		</div>
	</aside>
)

ImagesPreview.propTypes = {
	files:    PropTypes.array,
	onDelete: PropTypes.func,
	title:    PropTypes.string,
}

ImagesPreview.defaultProps = {
	files:    [],
	onDelete: () => null,
	title:    '',
}
