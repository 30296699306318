import { SvgIcon } from '@material-ui/core'
import { getIcon } from 'components/project/Home/SideBar/getIcon'
import PropTypes from 'prop-types'
import React from 'react'
import { Label } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render label for form field
 *
 * @param {string} id
 * @param {string} title
 * @param {string} color
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const FieldLabel = ({ id, title, color, children }) => (
	<div className={styles.labelWrapper}>
		<SvgIcon color={color} component={getIcon(id)} />
		<Label for={id} className={styles.label}>
			{title}
		</Label>
		{children}
	</div>
)

FieldLabel.propTypes = {
	children: PropTypes.node,
	color:    PropTypes.string,
	id:       PropTypes.string,
	title:    PropTypes.string,
}

FieldLabel.defaultProps = {
	children: null,
	color:    'primary',
	id:       '',
	title:    '',
}
