import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { SiteConfig } from './index'

const mapStateToProps = state => ({
	serviceConfigState: StateSelector.controlPanel.settings.service.view(state),
})

const mapDispatchToProps = {
	getServiceConfig:    DispatchSelector.controlPanel.settings.service.view,
	updateServiceConfig: DispatchSelector.controlPanel.settings.service.update,
	uploadFile:          DispatchSelector.controlPanel.sites.uploadFile,
	removeFile:          DispatchSelector.controlPanel.sites.removeFile,
	updateConfig:        DispatchSelector.controlPanel.sites.updateConfig,
}

export default connect(mapStateToProps, mapDispatchToProps)(SiteConfig)
