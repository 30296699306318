import { FindInPageOutlined as Empty } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render empty result message
 *
 * @param {Node} children
 * @param {string} message
 *
 * @return {*}
 * @constructor
 */
export const EmptyResultMessage = ({ children, message }) => (
	<div className={styles.message}>
		<Empty />
		{children || message}
	</div>
)

EmptyResultMessage.propTypes = {
	children: PropTypes.node,
	message:  PropTypes.node,
}

EmptyResultMessage.defaultProps = {
	children: null,
	message:  null,
}
