import { SideBarItem } from 'components/project/Home/SideBar/SideBarItem'
import { SideBarItemsGroup } from 'components/project/Home/SideBar/SiteBarItemsGroup'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router'
import styles from './styles.module.scss'

/**
 * Render project side bar
 *
 * @param {Project} project
 *
 * @return {*}
 * @constructor
 */
export const SideBar = ({ project }) => {
	const { serviceType, service } = useParams()

	const services     = project.getServices(true)
	const sites        = project.getSites()
	const projectAlias = project.getAlias()

	return (
		<div className={styles.container}>
			<div className={styles.tabs}>
				<SideBarItem
					title="О проекте"
					projectAlias={projectAlias}
					serviceType={serviceType}
				/>
				<SideBarItem
					alias="settings"
					title="Настройки"
					projectAlias={projectAlias}
					serviceType={serviceType}
				/>
				<SideBarItemsGroup
					title="Микросервисы"
					alias="microservice"
					items={services}
					projectAlias={projectAlias}
					service={service}
				/>
				<SideBarItemsGroup
					title="Сайты"
					alias="site"
					items={sites}
					projectAlias={projectAlias}
					service={service}
				/>
			</div>
		</div>
	)
}

SideBar.propTypes = {
	project: PropTypes.instanceOf(Project),
}

SideBar.defaultProps = {
	project: Project.create({}),
}
