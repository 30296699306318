import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'

/**
 * Get correct date
 *
 * @param {string} data
 *
 * @return {string|number}
 */
export const getDate = data => {
	if (typeof data === 'string') {
		const timeArray = data.split('T')

		const date = timeArray[0]
		const time = timeArray[1].split('.')[0]

		return `${date} в ${time}`
	}

	return 0
}

/**
 * Get isFetching from states
 *
 * @param {Object} args
 *
 * @return {boolean}
 */
export const isFetching = (...args) => {
	if (args.length > 1) {
		return !!args.map(arg => StateService.create(arg).isFetching()).filter(element => element).length
	}
	return StateService.create(args[0]).isFetching()
}
