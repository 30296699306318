import styles from 'components/global/AsidePanel/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'reactstrap'

/**
 * Render buttons group
 *
 * @param {Function} cancelAction
 * @param {string} title
 * @param {string} cancelTitle
 * @param {boolean} isSuccessDisabled
 * @param {boolean} isCancelDisabled
 * @param {Node} successBtn
 * @param {Node} cancelBtn
 *
 * @return {*}
 * @constructor
 */
export const ButtonsGroup = ({
	title,
	cancelTitle,
	cancelAction,
	isSuccessDisabled,
	isCancelDisabled,
	successBtn,
	cancelBtn,
}) => (
	<div className={styles.buttonsWrapper}>
		{successBtn ||
		<Button
			color="success"
			className={styles.btn}
			type="submit"
			disabled={isSuccessDisabled}
		>
			{title}
		</Button>}
		{cancelBtn ||
		<Button
			color="danger"
			className={styles.btn}
			onClick={cancelAction}
			disabled={isCancelDisabled}
		>
			{cancelTitle}
		</Button>}
	</div>
)

ButtonsGroup.propTypes = {
	cancelTitle:       PropTypes.string,
	title:             PropTypes.string,
	cancelAction:      PropTypes.func,
	cancelBtn:         PropTypes.node,
	isCancelDisabled:  PropTypes.bool,
	isSuccessDisabled: PropTypes.bool,
	successBtn:        PropTypes.node,
}

ButtonsGroup.defaultProps = {
	cancelTitle:       'Отмена',
	title:             'Сохранить',
	cancelAction:      () => null,
	cancelBtn:         null,
	isCancelDisabled:  false,
	isSuccessDisabled: false,
	successBtn:        null,
}
