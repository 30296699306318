import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { JsonInputField } from 'forms/fields'
import { AwsDocLink } from 'forms/fields/JsonInput/AwsDocLink'
import { PopoverInfo } from 'forms/fields/JsonInput/PopoverInfo'
import React, {
	useRef,
	useState,
} from 'react'

/**
 * Render task definition field
 *
 * @param {Object} props
 * @param {Object} props.value
 * @param {Object} props.field
 * @param {Function} props.setState
 *
 * @return {*}
 * @constructor
 */
export const TaskDefinition = props => {
	const [isPopoverOpen, setIsPopoverOpen] = useState(false)

	const infoRef = useRef()

	useCheckOutsideClick(infoRef, isPopoverOpen, setIsPopoverOpen)
	return (
		<JsonInputField {...props}>
			<PopoverInfo
				ref={infoRef}
				isOpen={isPopoverOpen}
				toggle={() => setIsPopoverOpen(!isPopoverOpen)}
			/>
			<AwsDocLink />
		</JsonInputField>
	)
}
