import Drawer from '@material-ui/core/Drawer'
import noPhoto from 'assets/images/no_photo.png'
import { AsidePanel } from 'components/global/AsidePanel'
import styles from 'components/project/ServiceConfig/Users/SettingTabs/Users/UserCard/styles.module.scss'
import { UserInfoItem } from 'components/project/ServiceConfig/Users/SettingTabs/Users/UserCard/UserInfoItem'
import PropTypes from 'prop-types'
import React, { useState } from 'react'

const userFields = [
	{ alias: 'firstName', title: 'Имя' },
	{ alias: 'lastName', title: 'Фамилия' },
	{ alias: 'middleName', title: 'Отчество' },
	{ alias: 'birthDay', title: 'День рождения' },
	{ alias: 'password', title: 'Пароль' },
	{ alias: 'cityId', title: 'Город' },
	{ alias: 'email', title: 'E-mail' },
	{ alias: 'phone', title: 'Номер телефона' },
]

/**
 * Render user info card
 *
 * @param {User} user
 * @param {Object} children
 *
 * @return {*}
 * @constructor
 */
export const UserCard = ({ user, children }) => {
	const [isActive, setIsActive] = useState(false)

	const userSettings     = user.getSettings()
	const userRawModel     = user.getRawModel()
	const settingsRawModel = userSettings.getRawModel()

	return (
		<div className={styles.container}>
			{React.cloneElement(children, { onClick: () => setIsActive(true) })}
			<Drawer
				anchor="left"
				open={isActive}
				onClose={() => setIsActive(false)}
			>
				<AsidePanel title={user.getFullName()}>
					<div
						className={styles.avatar}
						style={{ backgroundImage: `url(${userSettings.getImage() || noPhoto})` }}
					/>
					{userFields.map(field => <UserInfoItem
						key={field.alias}
						alias={field.alias}
						title={field.title}
						info={userRawModel[field.alias] || settingsRawModel[field.alias]}
					/>)}
					<UserInfoItem
						alias="status"
						title="Статус"
						info={user.getStatus(true)}
					/>
					<UserInfoItem
						alias="gender"
						title="Пол"
						info={user.getGender(true)}
					/>
					<UserInfoItem
						alias="isEmailVerified"
						title="Верификация E-mail"
						info={userSettings.isEmailVerified(true)}
					/>
					<UserInfoItem
						alias="isPhoneVerified"
						title="Верификация номера телефона"
						info={userSettings.isPhoneVerified(true)}
					/>
				</AsidePanel>
			</Drawer>
		</div>
	)
}

UserCard.propTypes = {
	children: PropTypes.element,
	user:     PropTypes.object,
}

UserCard.defaultProps = {
	children: null,
	user:     {},
}
