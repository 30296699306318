import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render Collapse height item
 *
 * @param {Node} children
 * @param {string} className
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const CollapseHeightItem = ({ children, className, ...props }) => (
	<div className={[styles.item, className].join(' ')} {...props}>
		{children}
	</div>
)

CollapseHeightItem.propTypes = {
	children:  PropTypes.node,
	className: PropTypes.string,
}

CollapseHeightItem.defaultProps = {
	children:  null,
	className: '',
}
