import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import {
	call,
	put,
	select,
	takeLatest,
} from 'redux-saga/effects'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import {
	removeAuthTokens,
	saveAuthTokens,
} from 'reduxm/redux-saga/api'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import { AuthActionsApi } from 'reduxm/redux-saga/modules/authentication/actions/api'
import { AuthorizationApi } from 'reduxm/redux-saga/modules/authorization/actions/api'
import { UsersApi } from 'reduxm/redux-saga/modules/users/actions/api'
import { AUTH_ACTIONS } from './actionTypes'

/**
 * Sign in
 *
 * @param {Object} action
 *
 * @return {Generator<<"CALL", CallEffectDescriptor>|<"PUT", PutEffectDescriptor<*>>|Object|boolean, void, *>}
 */
function* fetchSignIn(action) {
	const { payload, type }           = action
	const { request, success, error } = RequestActionHelper.getAllActions(type)

	const saveUser     = DispatchSelector.users.actions.setCurrent
	const searchQuery  = DataProvider.getSearchQueryBody(payload)
	const customParams = searchQuery.getCustomParams()
	const bodyParams   = searchQuery.getBody()

	try {
		yield put(request(searchQuery))

		// Remove auth tokens
		yield removeAuthTokens()

		const response = yield call(UsersApi.signIn, searchQuery)

		// Check response auth tokens
		if (!response?.result?.payload?.jwtAccess) {
			throw new Error('Ошибка авторизации. Отсутствуют токены')
		}

		yield saveAuthTokens(null, response.result.payload)

		const user           = response?.result?.user ?? {}
		let userRole         = null
		let hasAuthorization = true

		try {
			const userRoleQuery = DataProvider
				.buildQuery()
				.addBody({ userId: user?.id })
			userRole            = yield call(AuthorizationApi.userRole, userRoleQuery)
		} catch (e) {
			// Skip this error if authorization service not found
			if (e?.code !== 5 || e?.status !== 2) {
				// Authorization service attached to project, throw original error
				throw e
			}
			// Authorization service not attached to project.
			hasAuthorization = false
		}

		user.role = userRole?.result?.role ?? 'user'

		yield put(success({
			provider:     bodyParams.provider,
			realProvider: customParams.realProvider || bodyParams.provider,
			hasAuthorization,
		}, searchQuery))
		yield put(saveUser(user))

		searchQuery.runSuccessCallback(response)
	} catch (e) {
		yield put(error(searchQuery.addReduxRequestParams({ error: e })))

		searchQuery.runErrorCallback(e)
	}

	searchQuery.runCallback()
}

/**
 * Log out
 *
 * @param {Object} action
 *
 * @return {Generator<Promise<string>|<"CALL">, void, *>}
 */
function* fetchLogOut(action) {
	const { payload, type }           = action
	const { request, success, error } = RequestActionHelper.getAllActions(type)

	const removeUser  = DispatchSelector.users.actions.setCurrent
	const removeAuth  = DispatchSelector.auth.actions.setSignIn
	const searchQuery = DataProvider.getSearchQueryBody(payload)

	try {
		const auth = yield select(StateSelector.auth.actions.signIn)

		yield put(request(searchQuery))

		yield put(success())
		yield put(removeUser(null))
		yield put(removeAuth(null))

		// Remove auth tokens
		yield removeAuthTokens()

		// Don`t need result
		const response = yield call(UsersApi.logOut, searchQuery)

		searchQuery.runSuccessCallback(response, auth)
	} catch (e) {
		// Remove auth tokens
		yield removeAuthTokens()

		yield put(error(searchQuery.addReduxRequestParams({ error: e })))

		searchQuery.runErrorCallback(e)
	}

	searchQuery.runCallback()
}

function* updateServiceConfig(action) {
	yield defaultAction(action, AuthActionsApi.updateServiceConfig)
}

export default [
	takeLatest(AUTH_ACTIONS.SIGN_IN, fetchSignIn),
	takeLatest(AUTH_ACTIONS.LOG_OUT, fetchLogOut),
	takeLatest(AUTH_ACTIONS.UPDATE_SERVICE_CONFIG, updateServiceConfig),
]
