import {
	applyMiddleware,
	combineReducers,
	compose,
	createStore,
} from 'redux'
import { persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import { all } from 'redux-saga/effects'
import { Notifications } from 'reduxm/middlewares/Notifications'
import { allReducers } from './modules/redux'
import modulesSagas from './modules/sagas'

const sagaMiddleware = createSagaMiddleware()

const wind          = typeof (window) !== 'undefined' ? window : {}
const reduxDevTools =
		  (wind.__REDUX_DEVTOOLS_EXTENSION__ &&
			  wind.__REDUX_DEVTOOLS_EXTENSION__()) ||
		  (a => a)

function* sagas() {
	yield all([
		...modulesSagas,
	])
}

const middleware = [
	sagaMiddleware,
	Notifications,
]

const store     = createStore(
	combineReducers({ ...allReducers }),
	compose(applyMiddleware(...middleware), reduxDevTools),
)
const persistor = persistStore(store)

sagaMiddleware.run(sagas)

export default () => ({
	store,
	persistor,
})
