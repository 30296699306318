import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { MySqlCredentialsTable } from './index'

const { controlPanel: { settings: { mysql: { list, create, update, remove, view } } } } = StateSelector

const mapStateToProps = state => ({
	mysqlCredentialsState: list(state),
	isFetching:            _(list(state), create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getMysqlCredentials:    DispatchSelector.controlPanel.settings.mysql.list,
	createMysqlCredentials: DispatchSelector.controlPanel.settings.mysql.create,
	updateMysqlCredentials: DispatchSelector.controlPanel.settings.mysql.update,
	removeMysqlCredentials: DispatchSelector.controlPanel.settings.mysql.remove,
	viewMysqlCredentials:   DispatchSelector.controlPanel.settings.mysql.view,
}

export default connect(mapStateToProps, mapDispatchToProps)(MySqlCredentialsTable)
