import {
	Close,
	SearchRounded as Search,
} from '@material-ui/icons'
import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

/**
 * Render table search
 *
 * @param {Object} innerRef
 * @param {Function} onChange
 * @param {Function} cancel
 *
 * @return {*}
 * @constructor
 */
export const TableSearch = ({ innerRef, onChange, cancel }) => (
	<div className={styles.searchWrapper}>
		<Search />
		<input
			ref={innerRef}
			type="text"
			placeholder="Найти метод..."
			onChange={event => onChange(event.target.value)}
			className={styles.searchInput}
		/>
		{cancel &&
		<Button onClick={cancel} className={styles.cancelSearchBtn}>
			<Close />
		</Button>}
	</div>
)

TableSearch.propTypes = {
	innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
	onChange: PropTypes.func,
	cancel:   PropTypes.func,
}

TableSearch.defaultProps = {
	innerRef: null,
	onChange: () => null,
	cancel:   null,
}
