import {
	TabsContainer,
	TabsContent,
	TabsContentItem,
	TabsNavigation,
	TabsNavItem,
} from 'components/global/TabsTable'
import { getSettingTabs } from 'components/project/Setting/getSettingTabs'
import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router'

/**
 * Render project settings panel
 *
 * @param {Project} project
 *
 * @return {*}
 * @constructor
 */
export const ProjectSettings = ({ project }) => {
	const settingTabs = getSettingTabs(project)

	const { serviceType, service } = useParams()

	const activeTable = service || 'mysql'

	return (serviceType === 'settings' &&
		<TabsContainer top={20}>
			<TabsNavigation>
				{settingTabs.map(tab => <TabsNavItem
					key={tab.alias}
					to={`/project/${project.getAlias()}/settings/${tab.alias}`}
					isActive={activeTable === tab.alias}
					title={tab.title}
				/>)}
			</TabsNavigation>
			<TabsContent activeTab={activeTable}>
				{settingTabs.map(tab => (
					<TabsContentItem tabId={tab.alias} key={tab.alias}>
						{tab.component}
					</TabsContentItem>
				))}
			</TabsContent>
		</TabsContainer>
	)
}

ProjectSettings.propTypes = {
	project: PropTypes.object,
}

ProjectSettings.defaultProps = {
	project: {},
}
