import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	view:   'control-panel.files.view',
	create: 'control-panel.files.create',
	remove: 'control-panel.files.delete',
	update: 'control-panel.files.update',
}

/**
 * Call api to create file
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to remove file
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

/**
 * Call api to update file
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to view file
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

export const FilesApi = {
	create,
	remove,
	update,
	view,
}
