import { SvgIcon } from '@material-ui/core'
import styles from 'components/home/TableToggle/styles.module.scss'
import { getIcon } from 'components/project/Home/SideBar/getIcon'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Render table toggle item
 *
 * @param {string} id
 * @param {string} link
 * @param {string} title
 * @param {string} activeId
 *
 * @return {*}
 * @constructor
 */
export const TableToggleItem = ({ activeId, id, link, title }) => (
	<Link
		to={link}
		className={[styles.li, activeId === id && styles.activeLi].join(' ')}
	>
		<SvgIcon component={getIcon(id)} />
		<div className={styles.title}>
			{title}
		</div>
	</Link>
)

TableToggleItem.propTypes = {
	id:       PropTypes.string,
	title:    PropTypes.string,
	link:     PropTypes.string,
	activeId: PropTypes.string,
}

TableToggleItem.defaultProps = {
	id:       '',
	title:    '',
	link:     '',
	activeId: '',
}
