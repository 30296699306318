import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { ModelForm } from 'components/global/AsidePanel/ModelForm'
import CreateDeploymentModal from 'components/global/CreateDeploymentModal/index.store'
import { Spinner } from 'components/global/Spinner'
import styles from 'components/project/ServiceConfig/MicroserviceConfig/styles.module.scss'
import { RemoveModelButton } from 'components/project/Setting/RemoveModelButton'
import { microserviceConfigFields } from 'forms/serviceConfig'
import { ServicesConfig } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'

const getUpdateQuery = (projectId, serviceAlias, type) => DataProvider
	.buildQuery()
	.addBody({ projectId, serviceAlias, type })

/**
 * Render default service config component
 *
 * @param {Object} serviceConfigState
 * @param {Function} getServiceConfig
 * @param {Function} createServiceConfig
 * @param {Function} updateServiceConfig
 * @param {Function} removeServiceConfig
 * @param {Function} updateService
 * @param {Array} fields
 * @param {number} projectId
 * @param {string} service
 * @param {string} className
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const MicroserviceConfig = ({
	serviceConfigState,
	getServiceConfig,
	createServiceConfig,
	updateServiceConfig,
	removeServiceConfig,
	updateService,
	projectId,
	service,
	type,
	className,
	isFetching,
}) => {
	const [isActiveModal, setIsActiveModal] = useState(false)

	const requestData = useMemo(() => ({ projectId, service }), [projectId, service])

	useEffect(() => {
		if (requestData.projectId && requestData.service) {
			getServiceConfig(requestData)
		}
	}, [getServiceConfig, requestData])

	const serviceConfig = ServicesConfig.create(serviceConfigState)

	const buttonsGroupProps = {
		title:            serviceConfig.isExist() ? 'Обновить' : 'Создать',
		isCancelDisabled: !serviceConfig.isExist(),
		cancelBtn:        (<RemoveModelButton
			disabled={!serviceConfig.isExist()}
			id={requestData}
			action={removeServiceConfig}
		/>),
	}

	const onUpdate = () => {
		updateService()
		setIsActiveModal(true)
	}

	const modelFormProps = {
		...(serviceConfig.isExist() && { model: serviceConfig }),
		...(!serviceConfig.isExist() && { initialData: requestData }),
		fields: microserviceConfigFields,
		action: (serviceConfig.isExist() ?
			query => updateServiceConfig(query.setCallback(onUpdate)) :
			query => createServiceConfig(query.setCallback(onUpdate))),
	}

	const renderModelForm = useCallback(() => (
		<ModelForm
			buttonsGroupProps={buttonsGroupProps}
			{...modelFormProps}
		/>
	), [buttonsGroupProps, modelFormProps])

	return (
		<div className={[styles.container, className].join(' ')}>
			<CreateDeploymentModal
				isActive={isActiveModal}
				toggle={() => setIsActiveModal(false)}
				data={getUpdateQuery(projectId, service, type)}
			/>
			<Spinner block isFetching={isFetching} />
			{renderModelForm()}
		</div>
	)
}

MicroserviceConfig.propTypes = {
	className:           PropTypes.string,
	createServiceConfig: PropTypes.func,
	getServiceConfig:    PropTypes.func,
	isFetching:          PropTypes.bool,
	projectId:           PropTypes.number,
	removeServiceConfig: PropTypes.func,
	service:             PropTypes.string,
	serviceConfigState:  PropTypes.object,
	type:                PropTypes.string,
	updateService:       PropTypes.func,
	updateServiceConfig: PropTypes.func,
}

MicroserviceConfig.defaultProps = {
	className:           '',
	createServiceConfig: () => null,
	getServiceConfig:    () => null,
	isFetching:          false,
	projectId:           null,
	removeServiceConfig: () => null,
	service:             '',
	serviceConfigState:  {},
	type:                '',
	updateService:       () => null,
	updateServiceConfig: () => null,
}
