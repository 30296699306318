import { SvgIcon } from '@material-ui/core'
import { getIcon } from 'components/project/Home/SideBar/getIcon'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render model card header
 *
 * @param {Service|Project} model
 * @param {Node} children
 * @param {string} type
 * @param {string} iconAlias
 *
 * @return {*}
 * @constructor
 */
export const CardHeader = ({ model, children, type, iconAlias }) => (
	<div className={[styles.wrapper, children && styles.haveChildren].join(' ')}>
		<div className={styles.cardInfo}>
			<div className={styles.icon}>
				<SvgIcon component={getIcon(iconAlias || model.getAlias())} />
			</div>
			<div className={styles.title}>
				<p>{model.getTitle()}</p>
				<p>{type}</p>
			</div>
		</div>
		{children}
	</div>
)

CardHeader.propTypes = {
	children:  PropTypes.node,
	iconAlias: PropTypes.string,
	model:     PropTypes.object,
	type:      PropTypes.string,
}

CardHeader.defaultProps = {
	children:  null,
	iconAlias: null,
	model:     {},
	type:      '',
}
