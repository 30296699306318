import logo from 'assets/images/logo.png'
import { Tip } from 'components/global/Tip'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render Footer
 *
 * @return {*}
 * @constructor
 */
export const Footer = () => (
	<footer className={styles.wrapper}>
		<Tip title="Наш сайт">
			<a className={styles.logo} href="https://kakadu.bz/" rel="noreferrer noopener" target="_blank">
				<img src={logo} alt="logo" />
			</a>
		</Tip>
		<div className={styles.info}>
			<div className={styles.company}>Kakadu Development LLC</div>
			<div className={styles.rights}>© All Right Reserved</div>
		</div>
		<Tip title="Напишите нам!">
			<a className={styles.email} href="mailto:info@kakadu.bz">info@kakadu.bz</a>
		</Tip>
	</footer>
)
