import { ArrowForwardIos as Arrow } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import { toggleItems } from 'components/home/TableToggle/data'
import { TableToggleItem } from 'components/home/TableToggle/TableToggleItem'
import PropTypes from 'prop-types'
import React, {
	useRef,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render switch panel
 *
 * @param {string} id
 *
 * @return {*}
 * @constructor
 */
export const TableToggle = ({ id }) => {
	const [isActive, setIsActive] = useState(false)

	const navRef = useRef()

	useCheckOutsideClick(navRef, isActive, setIsActive)

	return (
		<div className={[styles.wrapper, isActive && styles.active].join(' ')}>
			<div className={styles.nav} ref={navRef}>
				{toggleItems.map(item => (
					<TableToggleItem
						key={item.id}
						id={item.id}
						activeId={id}
						title={item.title}
						link={item.link}
					/>))}
			</div>
			<Button
				className={styles.toggle}
				onClick={() => setIsActive(!isActive)}
			>
				<Arrow />
			</Button>
		</div>
	)
}

TableToggle.propTypes = {
	id: PropTypes.string,
}

TableToggle.defaultProps = {
	id: '',
}
