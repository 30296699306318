import { useWidthMoreThanValue } from 'components/global/useWidthMoreThanValue'
import { HeaderLink } from 'components/wrapper/Header/HeaderLink'
import HeaderMenu from 'components/wrapper/Header/HeaderMenu/index.store'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render Header
 *
 * @returns {*}
 * @constructor
 */
export const Header = () => {
	const isBurger = !useWidthMoreThanValue(860)

	return (
		<header className={styles.wrapper}>
			<HeaderLink />
			<HeaderMenu isBurger={isBurger} />
		</header>
	)
}
