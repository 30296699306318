import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { SearchResults } from './index'

const mapStateToProps = state => ({
	projectsState: StateSelector.controlPanel.projects.list(state),
	servicesState: StateSelector.controlPanel.services.list(state),
})

const mapDispatchToProps = {
	getProjects: DispatchSelector.controlPanel.projects.list,
	getServices: DispatchSelector.controlPanel.services.list,
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults)
