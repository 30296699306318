import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { RoleField } from './index'

const { authorization: { roles: { searchList }, actions: { userRole, setRole } } } =
		  StateSelector

const { authorization: { roles: { searchList: getSearchList }, actions: { setUserRole, userRole: getUserRole } } } =
		  DispatchSelector

const mapStateToProps = state => ({
	searchListState: searchList(state),
	userRole:        userRole(state),
	setRoleState:    setRole(state),
})

const mapDispatchToProps = {
	getSearchList,
	getUserRole,
	setUserRole,
}

export default connect(mapStateToProps, mapDispatchToProps)(RoleField)
