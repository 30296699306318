import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTHORIZATION_PERMISSIONS_ACTIONS } from 'reduxm/redux-saga/modules/authorization/permissions/actionTypes'

export const AuthorizationPermissionsActions = {
	list:        RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_PERMISSIONS_ACTIONS.LIST),
	create:      RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_PERMISSIONS_ACTIONS.CREATE),
	update:      RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_PERMISSIONS_ACTIONS.UPDATE),
	view:        RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_PERMISSIONS_ACTIONS.VIEW),
	remove:      RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_PERMISSIONS_ACTIONS.REMOVE),
	batchCreate: RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_PERMISSIONS_ACTIONS.BATCH_CREATE),
}
