import { MethodsTable } from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/MethodsTable/index'
import { connect } from 'react-redux'
import { DispatchSelector } from 'reduxm/modules/selectors'

const mapDispatchToProps = {
	createMethods:    DispatchSelector.authorization.methods.create,
	updateMethods:    DispatchSelector.authorization.methods.update,
	removeMethods:    DispatchSelector.authorization.methods.remove,
	updateAttributes: DispatchSelector.authorization.attributes.update,
}

export default connect(null, mapDispatchToProps)(MethodsTable)
