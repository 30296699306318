import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { SERVICES_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/services/actionTypes'

export const ServicesActions = {
	list:             RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.LIST),
	setList:          RequestActionHelper.getActionCreatorSuccess(SERVICES_ACTIONS.LIST),
	view:             RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.VIEW),
	create:           RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.CREATE),
	remove:           RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.REMOVE),
	update:           RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.UPDATE),
	info:             RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.INFO),
	createDeployment: RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.CREATE_DEPLOYMENT),
	changeTaskCount:  RequestActionHelper.getActionCreatorFetch(SERVICES_ACTIONS.CHANGE_TASK_COUNT),
}
