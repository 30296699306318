import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	list:        'authorization.permissions.list',
	create:      'authorization.permissions.create',
	remove:      'authorization.permissions.delete',
	update:      'authorization.permissions.update',
	view:        'authorization.permissions.view',
	batchCreate: 'authorization.permissions.batch-create',
}

/**
 * Call api to get list of permissions models.
 *
 * @param {Object} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const list = searchQuery => callApi(methods.list, searchQuery)

/**
 * Call api to update permissions model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to remove permissions model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

/**
 * Call api to create permissions model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to view permissions model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

/**
 * Call api to create batch.
 *
 * @param {Object} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const createBatch = searchQuery => callApi(methods.batchCreate, searchQuery)

export const AuthorizationPermissionsApi = {
	list,
	update,
	remove,
	create,
	view,
	createBatch,
}
