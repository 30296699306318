import React from 'react'

/**
 * Render parent roles options
 *
 *
 * @return {*}
 * @constructor
 */
export const GenderField = () => (
	<>
		<option value="" disabled>Выберите пол</option>
		<option value="male">Мужской</option>
		<option value="female">Женский</option>
	</>
)
