import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { InputLabel } from 'components/global/Inputs/InputLabel'
import { RadioButton } from 'components/global/Inputs/RadioButton'
import { serviceTypes } from 'forms/control-panel/project/ConnectService/data'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 *  Render service type field
 *
 * @param {string} type
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const ServiceTypeField = ({ type, action }) => (
	<>
		<FieldLabel id="type" title="Тип" />
		<div className={styles.services}>
			{serviceTypes.map(serviceType => (
				<InputLabel
					key={serviceType.value}
					{...serviceType}
					checked={serviceType.value === type}
				>
					<RadioButton
						checked={serviceType.value === type}
						onChange={action}
					/>
				</InputLabel>
			))}
		</div>
	</>
)

ServiceTypeField.propTypes = {
	action: PropTypes.func,
	type:   PropTypes.string,
}

ServiceTypeField.defaultProps = {
	action: () => null,
	type:   '',
}
