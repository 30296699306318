import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import { USER_ACTIONS } from 'reduxm/redux-saga/modules/users/actions'
import { UsersApi } from 'reduxm/redux-saga/modules/users/actions/api'

/**
 * View current user model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* current(action) {
	yield defaultAction(action, UsersApi.current, response => {
		const { user, role } = response?.result || {}

		return {
			...user,
			role,
		}
	})
}

export default [
	takeLatest(USER_ACTIONS.CURRENT, current),
]
