import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTHORIZATION_ATTRIBUTES_ACTIONS } from 'reduxm/redux-saga/modules/authorization/attributes/actionTypes'

const ACTIONS = {
	...AUTHORIZATION_ATTRIBUTES_ACTIONS,
	[AUTHORIZATION_ATTRIBUTES_ACTIONS.LIST]:
		[AUTHORIZATION_ATTRIBUTES_ACTIONS.LIST, DataProvider.getDefaultState()],
	[AUTHORIZATION_ATTRIBUTES_ACTIONS.SEARCH_LIST]:
		[AUTHORIZATION_ATTRIBUTES_ACTIONS.SEARCH_LIST, DataProvider.getDefaultState()],
}
export default RequestActionHelper.createReducerActions(Object.values(ACTIONS))
