import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import AuthorizationAttributes from 'models/authorization/Attributes'

/**
 * AuthorizationMethods model class
 */
class AuthorizationMethods extends BaseModel {
	/**
	 * Create AuthorizationMethods instance
	 *
	 * @param {Object} object
	 *
	 * @returns {AuthorizationMethods}
	 */
	static create(object) {
		return super.create(object?.result?.model)
	}

	/**
	 * Create list AuthorizationMethods
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<AuthorizationMethods>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get name
	 *
	 * @returns {Object}
	 */
	getName = isShort => (isShort ? this.model.name?.replace(/\[(.*?)]\[(.*?)]/, '') ?? '' : this.model.name)

	/**
	 * Get service alias
	 *
	 * @returns {string}
	 */
	getServiceAlias = () => this.model.serviceAlias

	/**
	 * Get alias
	 *
	 * @returns {string}
	 */
	getAlias = () => this.model.alias

	/**
	 * Get attribute in id
	 *
	 * @returns {Object}
	 */
	getAttributeInId = () => this.model.attributeInId

	/**
	 * Get attribute out id
	 *
	 * @returns {Object}
	 */
	getAttributeOutId = () => this.model.attributeOutId

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attribute
	 *
	 * @param attribute in
	 *
	 * @return {*}
	 */
	getAttributeIn = attribute => this.getRelation('AttributeIn', AuthorizationAttributes, attribute)

	/**
	 * Get attribute out
	 *
	 * @param attribute
	 *
	 * @return {*}
	 */
	getAttributeOut = attribute => this.getRelation('AttributeOut', AuthorizationAttributes, attribute)

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default AuthorizationMethods
