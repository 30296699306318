import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { AUTHORIZATION_FILTERS_ACTIONS } from 'reduxm/redux-saga/modules/authorization/filters'
import { AuthorizationFiltersApi } from 'reduxm/redux-saga/modules/authorization/filters/api'

/**
 * Get filter models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, AuthorizationFiltersApi.list, null, ResponseHandler.addProjectOrigin)
}

/**
 * View filter model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, AuthorizationFiltersApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create filter model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, AuthorizationFiltersApi.create, null, ResponseHandler.addProjectOrigin)
}

/**
 * Update filter model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, AuthorizationFiltersApi.update, null, ResponseHandler.addProjectOrigin)
}

/**
 * Remove filter model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, AuthorizationFiltersApi.remove, null, ResponseHandler.addProjectOrigin)
}

export default [
	takeLatest(AUTHORIZATION_FILTERS_ACTIONS.LIST, list),
	takeLatest(AUTHORIZATION_FILTERS_ACTIONS.UPDATE, update),
	takeLatest(AUTHORIZATION_FILTERS_ACTIONS.REMOVE, remove),
	takeLatest(AUTHORIZATION_FILTERS_ACTIONS.CREATE, create),
	takeLatest(AUTHORIZATION_FILTERS_ACTIONS.VIEW, view),
]
