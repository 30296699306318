import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import {
	CancelOutlined as Down,
	CheckCircleOutlineRounded as Up,
} from '@material-ui/icons'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfo,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { Title } from 'components/global/Title'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/home/Tables/ProjectsTable/headerTitles'
import { TableToggle } from 'components/home/TableToggle'
import { projectFormFields } from 'forms'
import Project from 'models/control-panel/Project'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
} from 'react'
import { Link } from 'react-router-dom'
import styles from '../styles.module.scss'

const tableName = 'project'

const searchQuery = (page = 1) => ({ query: { page } })

/**
 * Render Project tableName component
 *
 * @param {Function} getProjects
 * @param {Function} createProject
 * @param {Function} updateProject
 * @param {Function} removeProject
 * @param {Function} reloadGateway
 * @param {Object} projectsState
 *
 * @return {*}
 * @constructor
 */
export const ProjectsTable = ({
	projectsState,
	getProjects,
	createProject,
	updateProject,
	removeProject,
}) => {
	useEffect(() => {
		getProjects(searchQuery())
	}, [getProjects])

	const projects        = Project.createList(projectsState)
	const projectsService = StateService.create(projectsState)

	const info = useMemo(() => (projects.length ?
		'Кликните по проеку, чтобы увидеть подробную информацию' :
		'Для начала работы добавьте проект в список'), [projects])

	return (
		<section className={styles.section}>
			<TableToggle id="projectsPage" />
			<Table
				paginationData={projectsService.getPagination()}
				paginationAction={page => getProjects(searchQuery(page))}
				fit
			>
				<Title name="Список проектов" />
				<TableInfoWrapper>
					<ModelsCounter count={projects.length} />
					<CreateModelButton
						action={createProject}
						formFields={projectFormFields()}
						title="Создать проект"
					/>
				</TableInfoWrapper>
				<TableHeader titles={headerTitles} tableName={tableName} />
				<TableInfo info={info} />
				{(projects || []).map(project => (
					<TableWrapper key={project.getId()}>
						{project.isPublished() ? <Up className={styles.up} /> : <Down className={styles.down} />}
						<TableRow className={!project.isPublished() ? styles.disabled : ''} tableName={tableName}>
							<TableCol title={project.getId()} />
							<TableCol>
								<Link className={styles.link} to={`${tableName}/${project.getAlias()}`}>
									<TableNameCol active>
										{project.getTitle()}
									</TableNameCol>
								</Link>
							</TableCol>
							<TableCol title={project.getAlias()} />
							<TableCol title={project.getDomain()} />
							<TableCol title={project.getDescription()} />
						</TableRow>
						<TableActions>
							<UpdateModel
								model={project}
								action={updateProject}
								formFields={projectFormFields(true)}
								title="Редактировать проект"
								tip
							/>
							<RemoveModel
								id={{ id: project.getId() }}
								modelName={project.getTitle()}
								action={removeProject}
								actionName="удалить"
								tip
							/>
						</TableActions>
					</TableWrapper>))}
			</Table>
		</section>
	)
}

ProjectsTable.propTypes = {
	createProject: PropTypes.func,
	getProjects:   PropTypes.func,
	projectsState: PropTypes.object,
	removeProject: PropTypes.func,
	updateProject: PropTypes.func,
}

ProjectsTable.defaultProps = {
	createProject: () => null,
	getProjects:   () => null,
	projectsState: {},
	removeProject: () => null,
	updateProject: () => null,
}
