import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

/**
 * Render table col
 *
 * @param {string} title
 * @param {Node} children
 * @param {string} tableName
 *
 * @return {*}
 * @constructor
 */
export const TableCol = ({ title, children, tableName }) => (
	<div className={styles[tableName]}>
		{children ||
		<span className={styles.title}>
			{title}
		</span>}
	</div>
)

TableCol.propTypes = {
	children:  PropTypes.node,
	tableName: PropTypes.string,
	title:     PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

TableCol.defaultProps = {
	children:  null,
	tableName: '',
	title:     '',
}
