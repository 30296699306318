import { AddSharp } from '@material-ui/icons'
import noPhoto from 'assets/images/no_photo.png'
import { Button } from 'components/global/Button'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { headerTitles } from 'components/project/ServiceConfig/Users/SettingTabs/Users/headerTitles'
import styles from 'components/project/ServiceConfig/Users/SettingTabs/Users/styles.module.scss'
import { UserCard } from 'components/project/ServiceConfig/Users/SettingTabs/Users/UserCard'
import UserModelAction from 'components/project/ServiceConfig/Users/SettingTabs/Users/UserModelAction/index.store'
import { User } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
} from 'react'

const tableName        = 'users'
const usersSearchQuery = { query: { expands: ['Settings'] } }

/**
 * Render users tab
 *
 * @param {Function} getUsers
 * @param {Object} usersState
 * @param {Function} updateUser
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const UsersTab = ({
	getUsers,
	usersState,
	updateUser,
	isFetching,
}) => {
	useEffect(() => {
		getUsers(usersSearchQuery)
	}, [getUsers])

	const users = User.createList(usersState)

	const onRemove = useCallback(searchQuery => updateUser(searchQuery
		.addBody({ attributes: searchQuery.getBody() })
		.setCallback(() => getUsers(usersSearchQuery))), [updateUser, getUsers])

	return (
		<Table>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={users.length} />
				<UserModelAction actionName="Создать">
					<Button className={styles.createBtn}>
						<AddSharp />
						Создать пользователя
					</Button>
				</UserModelAction>
			</TableInfoWrapper>
			<div>
				<TableHeader titles={headerTitles} tableName={tableName} />
				{(users || []).map(user => (
					<TableWrapper key={`${user.getId()}`}>
						<TableRow
							tableName={tableName}
							className={user.getStatus() === 'deleted' ? styles.deletedRow : null}
						>
							<TableCol title={user.getId()} />
							<TableCol>
								<div className={styles.avatar}>
									<img src={user.getSettings().getImage() || noPhoto} alt="avatar" />
								</div>
							</TableCol>
							<TableCol>
								<UserCard user={user}>
									<Button className={styles.nameBtn}>
										{user.getFullName()}
									</Button>
								</UserCard>
							</TableCol>
							<TableCol title={user.getSettings().getPhone() || 'Нет данных'} />
							<TableCol title={user.getSettings().getEmail() || 'Нет данных'} />
							<TableCol>
								<span className={[styles.status, styles[user.getStatus()]].join(' ')}>
									{user.getStatus(true)}
								</span>
							</TableCol>
						</TableRow>
						<TableActions>
							<UserModelAction
								actionName="Редактировать"
								userModel={user}
								settingsModel={user.getSettings()}
								tip
							/>
							{!user.isDeleted() &&
							<RemoveModel
								id={{ id: user.getId(), status: 'deleted' }}
								action={onRemove}
								modelName="Удалить"
								actionName="удалить"
								tip
							/>}
						</TableActions>
					</TableWrapper>))}
			</div>
		</Table>
	)
}

UsersTab.propTypes = {
	getUsers:   PropTypes.func,
	isFetching: PropTypes.bool,
	updateUser: PropTypes.func,
	usersState: PropTypes.object,
}

UsersTab.defaultProps = {
	getUsers:   () => null,
	isFetching: false,
	updateUser: () => null,
	usersState: {},
}
