import { FargateDocLink } from 'forms/control-panel/service/fields/ServiceType/FargateDocLink'
import { RadioButtons } from 'forms/fields/RadioButtons'
import PropTypes from 'prop-types'
import React from 'react'

const serviceTypes = [{ label: 'EC2', value: 'EC2' }, { label: 'FARGATE', value: 'FARGATE' }]

/**
 * Render service type field
 *
 * @param {string} value
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const ServiceType = ({ value, ...props }) => (
	<RadioButtons {...props} value={value} buttons={serviceTypes}>
		<FargateDocLink serviceType={value} />
	</RadioButtons>

)

ServiceType.propTypes = {
	props: PropTypes.object,
	value: PropTypes.string,
}

ServiceType.defaultProps = {
	props: {},
	value: '',
}
