import { Project } from 'models'
import Service from 'models/control-panel/Service'
import PropTypes from 'prop-types'
import React from 'react'

const all = Service.create({ alias: '*', title: 'Все' })

/**
 * Render services options
 *
 * @param {Object} servicesState
 * @param {Object} projectState
 * @param {Array} excluded
 * @param {Object} currentService
 *
 * @return {*}
 * @constructor
 */
export const ServiceOptions = ({
	servicesState,
	excluded,
	projectState,
	currentService,
}) => {
	const project  = Project.create(projectState)
	const services = [all, ...Service.createList(servicesState)]
		.filter(service => !excluded.includes(service.getAlias()) || service.getAlias() === currentService)

	const isConnectedToProject = title => (project.getServices().find(service => service.getTitle() === title) ?
		`${title} *` : title)

	return (
		<>
			<option value="" disabled>Выберите сервис</option>
			{services.map(service => (
				<option key={service.getAlias()} value={service.getAlias()}>
					{isConnectedToProject(service.getTitle())}
				</option>))}
			<option value="" disabled>* - подключен к проекту</option>
		</>
	)
}

ServiceOptions.propTypes = {
	currentService: PropTypes.string,
	excluded:       PropTypes.array,
	projectState:   PropTypes.object,
	servicesState:  PropTypes.object,
}

ServiceOptions.defaultProps = {
	currentService: '',
	excluded:       [],
	projectState:   {},
	servicesState:  {},
}
