import { SvgIcon } from '@material-ui/core'
import { getIcon } from 'components/project/Home/SideBar/getIcon'
import styles from 'components/project/Home/SideBar/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Render side bar item
 *
 * @param {string} alias
 * @param {string} title
 * @param {string} serviceType
 * @param {Function} setActive
 *
 * @return {*}
 * @constructor
 */
export const SideBarItem = ({ alias, title, serviceType, projectAlias }) => (
	<Link
		to={`/project/${projectAlias}${alias ? '/' : ''}${alias}`}
		className={[styles.tab, serviceType === alias && styles.activeTab].join(' ')}
	>
		<SvgIcon className={styles.icon} component={getIcon(alias)} />
		<div className={styles.title}>
			{title}
		</div>
	</Link>
)

SideBarItem.propTypes = {
	serviceType:    PropTypes.string,
	alias:        PropTypes.string,
	projectAlias: PropTypes.string,
	title:        PropTypes.string,
}

SideBarItem.defaultProps = {
	serviceType:    '',
	alias:        '',
	projectAlias: '',
	title:        '',
}
