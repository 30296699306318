import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * AuthorizationRoles model class
 */
class AuthorizationRoles extends BaseModel {
	/**
	 * Create AuthorizationRoles instance
	 *
	 * @param {Object} object
	 *
	 * @returns {AuthorizationRoles}
	 */
	static create(object) {
		return super.create(object?.result?.model)
	}

	/**
	 * Create list AuthorizationRoles
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<AuthorizationRoles>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get parent id
	 *
	 * @returns {number}
	 */
	getParentId = () => this.model.parentId

	/**
	 * Get parent name
	 *
	 * @returns {string}
	 */
	static getParentName = (roles, role) => roles.find(r => +r.getId() === +role.getParentId())?.getName?.() || '-'

	/**
	 * Get name
	 *
	 * @returns {Object}
	 */
	getName = () => this.model.name

	/**
	 * Get alias
	 *
	 * @returns {string}
	 */
	getAlias = () => this.model.alias

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default AuthorizationRoles
