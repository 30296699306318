import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render Text Field component
 *
 * @param {string} label
 * @param {string} name
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const TextInput = ({ label, name, ...props }) => (
	<TextField
		variant="outlined"
		margin="normal"
		fullWidth
		id={name}
		label={label}
		name={name}
		autoComplete={name}
		{...props}
	/>
)

TextInput.propTypes = {
	label: PropTypes.string,
	name:  PropTypes.string,
}

TextInput.defaultProps = {
	label: '',
	name:  '',
}
