import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	AUTHORIZATION_METHODS_ACTIONS,
	AuthorizationMethodsActions,
	AuthorizationMethodsSelectors,
} from 'reduxm/redux-saga/modules/authorization/methods'
import { AuthorizationMethodsApi } from 'reduxm/redux-saga/modules/authorization/methods/api'

/**
 * Get methods models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, AuthorizationMethodsApi.list, null, ResponseHandler.addProjectOrigin)
}

/**
 * View methods model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, AuthorizationMethodsApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create methods model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, AuthorizationMethodsApi.create, null, ResponseHandler.addProjectOrigin)
}

/**
 * Update methods model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, AuthorizationMethodsApi.update, null, ResponseHandler.addProjectOrigin)
}

/**
 * Remove methods model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, AuthorizationMethodsApi.remove, null, ResponseHandler.addProjectOrigin,
		function* (...params) {
			yield ResponseHandler.afterRemoveModel(...params, AuthorizationMethodsSelectors.list,
				AuthorizationMethodsActions.setList)
		})
}

export default [
	takeLatest(AUTHORIZATION_METHODS_ACTIONS.LIST, list),
	takeLatest(AUTHORIZATION_METHODS_ACTIONS.UPDATE, update),
	takeLatest(AUTHORIZATION_METHODS_ACTIONS.REMOVE, remove),
	takeLatest(AUTHORIZATION_METHODS_ACTIONS.CREATE, create),
	takeLatest(AUTHORIZATION_METHODS_ACTIONS.VIEW, view),
]
