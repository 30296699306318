import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { MailCredentialsTable } from './index'

const { controlPanel: { settings: { mail: { list, create, update, remove, view } } } } = StateSelector

const mapStateToProps = state => ({
	mailCredentialsState: list(state),
	isFetching:           _(list(state), create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getMailCredentials:    DispatchSelector.controlPanel.settings.mail.list,
	createMailCredentials: DispatchSelector.controlPanel.settings.mail.create,
	updateMailCredentials: DispatchSelector.controlPanel.settings.mail.update,
	removeMailCredentials: DispatchSelector.controlPanel.settings.mail.remove,
	viewMailCredentials:   DispatchSelector.controlPanel.settings.mail.view,
}

export default connect(mapStateToProps, mapDispatchToProps)(MailCredentialsTable)
