import TextField from '@material-ui/core/TextField'
import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import {
	Col,
	Row,
} from 'reactstrap'
import styles from './styles.module.scss'

const services = [
	{ value: 'all', label: 'Все' },
	{ value: 'microservice', label: 'Микросервис' },
	{ value: 'mobile-app', label: 'Приложение' },
	{ value: 'site', label: 'Сайт' },
]

/**
 * Render service sort panel
 *
 * @param {Array.<Project>} projects
 * @param {Function} setSearchInfo
 *
 * @return {*}
 * @constructor
 */
export const ServiceSearchPanel = ({ includes, setSearchInfo }) => (
	<Row className={styles.wrapper}>
		<Col sm={12} md={6} className={styles.projectsWrapper}>
			{services.map(service => (
				<Button
					className={[styles.project, includes === service.value && styles.activeProject].join(' ')}
					key={service.value}
					onClick={() => setSearchInfo(prev => ({ ...prev, includes: service.value }))}
				>
					{service.label}
				</Button>
			))}
		</Col>
		<Col sm={12} md={6} className={styles.search}>
			<div>
				<TextField
					onChange={({ target: { value } }) => setSearchInfo(prev => ({
						...prev, title: value.toLowerCase(),
					}))}
					label="Поиск..."
					className={styles.input}
				/>
			</div>
		</Col>
	</Row>
)

ServiceSearchPanel.propTypes = {
	includes:      PropTypes.string,
	setSearchInfo: PropTypes.func,
}

ServiceSearchPanel.defaultProps = {
	includes:      'all',
	setSearchInfo: () => null,
}
