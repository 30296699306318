import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { JsonEditor } from 'components/global/JsonEditor'
import 'forms/fields/JsonInput/styles.css'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useState,
} from 'react'

const modes        = ['tree', 'code']
const initialState = JSON.stringify({})

/**
 * Render task definition field
 *
 * @param {Function} setState
 * @param {Object} value
 * @param {Object} field
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const JsonInputField = ({ setState, value, field, children }) => {
	const { id, title } = field

	const [text, setText] = useState(initialState)
	const [mode, setMode] = useState('tree')

	useEffect(() => {
		if (!_.isEmpty(value)) {
			setText(JSON.stringify(value, null, 2))
		} else {
			setText(initialState)
		}
	}, [value])

	const jsonHandler = json => {
		setText(json)
		setState(id, JSON.parse(json))
	}

	const changeMode = nextMode => setMode(nextMode)

	return (
		<div className="app">
			{title && <FieldLabel title={title} id={id} />}
			<div className="contents">
				<JsonEditor
					text={text}
					indentation={4}
					mode={mode}
					modes={modes}
					onChangeText={jsonHandler}
					onModeChange={changeMode}
				/>
				{children}
			</div>
		</div>
	)
}

JsonInputField.propTypes = {
	children: PropTypes.node,
	field:    PropTypes.object,
	setState: PropTypes.func,
	value:    PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

JsonInputField.defaultProps = {
	children: null,
	field:    {},
	setState: () => null,
	value:    {},
}
