import { SvgIcon } from '@material-ui/core'
import { getIcon } from 'components/project/Home/SideBar/getIcon'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render card content item
 *
 * @param {string} title
 * @param {string} text
 * @param {string} id
 * @param {Node} children
 * @param {boolean} condition
 *
 * @return {*}
 * @constructor
 */
export const CardContentItem = ({ title, text, id, children, condition }) => (
	condition &&
	<li>
		<div className={styles.contentHeader}>
			{condition}
			<SvgIcon className={styles.icon} component={getIcon(id)} />
			{title}
		</div>
		<div className={styles.contentInfo}>
			{children || text}
		</div>
	</li>
)

CardContentItem.propTypes = {
	children:  PropTypes.node,
	condition: PropTypes.bool,
	id:        PropTypes.string,
	text:      PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	title:     PropTypes.string,
}

CardContentItem.defaultProps = {
	children:  null,
	condition: true,
	id:        '',
	text:      '',
	title:     '',
}
