import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { JsonInputField } from 'forms/fields/JsonInput'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render manifest field
 *
 * @param {string} value
 * @param {Function} onChange
 *
 * @return {*}
 * @constructor
 */
export const ManifestField = ({ value, onChange }) => (
	<>
		<FieldLabel title="Манифест" id="manifest" />
		<JsonInputField value={value} setState={onChange} />
	</>
)

ManifestField.propTypes = {
	onChange: PropTypes.func,
	value:    PropTypes.object,
}

ManifestField.defaultProps = {
	onChange: () => null,
	value:    {},
}
