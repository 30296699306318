import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { HeaderMenu } from './index'

const mapStateToProps    = state => ({
	userState:   StateSelector.users.actions.current(state),
	logOutState: StateSelector.auth.actions.logOut(state),
})
const mapDispatchToProps = {
	logOut: DispatchSelector.auth.actions.logOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMenu)
