import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { CachedRounded as Reload } from '@material-ui/icons'
import styles from 'components/gateway/GatewayActions/ReloadGateway/styles.module.scss'
import { Notification } from 'helpers/Notification'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'reactstrap'

const searchQuery = DataProvider
	.buildQuery()
	.setSuccessCallback(() => Notification.add({
		title:   'Успех!',
		message: 'Шлюз перезапущен.',
		type:    Notification.types.success,
	}))

/**
 * Render reload gateway button
 *
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const ReloadGateway = ({ action }) => (
	<Button
		onClick={() => action(searchQuery)}
		className={styles.reloadBtn}
		color="warning"
	>
		<Reload />
		Перезапустить шлюз
	</Button>
)

ReloadGateway.propTypes = {
	action: PropTypes.func,
}

ReloadGateway.defaultProps = {
	action: () => null,
}
