export const domainRegExp = new RegExp('^https?:\\/\\/(www.)?[a-zA-Z0-9\\-]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z.]*)$')

export const serviceTypes = [
	{ label: 'Микросервис', value: 'microservice' },
	{ label: 'Сайт', value: 'site' },
	{ label: 'Приложение', value: 'mobile-app' },
]

export const domainValidState = {
	result:  false,
	message: '',
}

export const domainInvalidState = {
	result:  true,
	message: 'Введите корректный домен.',
}
