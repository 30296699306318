import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import storage from '@kakadu-dev/base-frontend-helpers/storage/AsyncStorage'
import { persistReducer } from 'redux-persist'
import { USER_ACTIONS } from 'reduxm/redux-saga/modules/users/actions/actionTypes'

const persistConfig = {
	key:       'users',
	storage,
	whitelist: [
		USER_ACTIONS.CURRENT,
	],
}

export default persistReducer(persistConfig, RequestActionHelper.createReducerActions(Object.values(USER_ACTIONS)))
