import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { Pagination } from 'components/global/Pagination'
import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

/**
 * Render table wrapper
 *
 * @param {Node} children
 * @param {boolean} fit
 * @param {Object} paginationData
 * @param {Function} paginationAction
 *
 * @return {*}
 * @constructor
 */
export const Table = ({ children, fit, paginationData, paginationAction }) => (
	<>
		<div className={[styles.table, fit && styles.fitTable].join(' ')}>
			{children}
		</div>
		<Pagination data={paginationData} action={paginationAction} />
	</>
)

Table.propTypes = {
	children:         PropTypes.node,
	fit:              PropTypes.bool,
	paginationAction: PropTypes.func,
	paginationData:   PropTypes.object,
}

Table.defaultProps = {
	children:         null,
	fit:              false,
	paginationAction: () => null,
	paginationData:   StateService.create({}).getPagination(),
}
