import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import { UserSettings } from 'models'

/**
 * User model class
 */
class User extends BaseModel {
	static statuses = {
		active:  'Активен',
		blocked: 'Заблокирован',
		deleted: 'Удален',
		unknown: 'Не известно',
	}

	static genders = {
		male:    'Мужской',
		female:  'Женский',
		unknown: 'Не известно',
	}

	/**
	 * Create Service instance
	 *
	 * @param {Object} object
	 *
	 * @returns {User}
	 */
	static create(object) {
		return super.create(object?.result?.model || object.result || object)
	}

	/**
	 * Create Service list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<User>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map customers
	 *
	 * @param {Array.<object>} objects
	 * @param {function(User, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get first name
	 *
	 * @returns {string}
	 */
	getFirstName = () => this.model.firstName

	/**
	 * Get last name
	 *
	 * @returns {string}
	 */
	getLastName = () => this.model.lastName

	/**
	 * Get full name
	 *
	 * @returns {string}
	 */
	getFullName = () => this.model.fullName

	/**
	 * Get middle name
	 *
	 * @returns {string}
	 */
	getMiddleName = () => this.model.middleName

	/**
	 * Get birthday
	 *
	 * @returns {string}
	 */
	getBirthday = () => this.model.birthDay

	/**
	 * Get status
	 *
	 * @returns {string}
	 */
	getStatus = (asLabel = false) => (asLabel ?
		User.statuses[this.model.status || 'unknown'] || User.statuses.unknown : this.model.status)

	/**
	 * Get gender
	 *
	 * @returns {string}
	 */
	getGender = (asLabel = false) => (asLabel ?
		User.genders[this.model.gender || 'unknown'] || User.genders.unknown : this.model.gender)

	/**
	 * Get user role
	 *
	 * @return {*}
	 */
	getRole = () => this.model.role

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get settings relation
	 *
	 * @param settings
	 *
	 * @return {UserSettings}
	 */
	getSettings = settings => this.getRelation('Settings', UserSettings, settings)

	/**
	 * Is super admin
	 *
	 * @param {Object} authState
	 *
	 * @returns {boolean}
	 */
	isSuperAdmin = authState => {
		const hasAuthorization = authState?.result?.hasAuthorization ?? true

		let checkedRole = 'super_admin'

		// If authorization service not attached, we don't  check user role
		if (!hasAuthorization) {
			checkedRole = 'user'
		}

		return this.model.role?.includes?.(checkedRole) ?? false
	}

	/**
	 * Is user deleted
	 *
	 * @returns {boolean}
	 */
	isDeleted = () => this.model.status === 'deleted'

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, role, fullName, ...attributes } = this.model

		return attributes
	}
}

export default User
