import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { ButtonsGroup } from 'forms/control-panel/project/fields/CopyProjectSettings/ButtonsGroup'
import { SearchingResults } from 'forms/control-panel/project/fields/CopyProjectSettings/SearchingResults'
import { SettingCheckboxes } from 'forms/control-panel/project/fields/CopyProjectSettings/SettingCheckboxes'
import _ from 'lodash'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import { Input } from 'reactstrap'
import styles from './styles.module.scss'

const initialState = ['AwsCredentials', 'MysqlCredentials', 'RedisCredentials', 'MailCredentials']

const getProjectsQuery = value => DataProvider
	.buildQuery()
	.addBody({
		query: {
			filter: {
				or: [
					{ alias: { like: `%${value}%` } },
					{ title: { like: `%${value}%` } },
				],
			},
		},
	})

const getCopyProjectQuery = (projectFromId, settings) => DataProvider
	.buildQuery()
	.addBody({ settings, projectFromId })

/**
 * Render copy project settings component
 *
 * @param {Object} field
 * @param {Object} projectsState
 * @param {Function} getProjects
 * @param {Function} setCopyProjectQuery
 * @param {Function} setSearchList
 * @param {boolean} isEditing
 *
 * @return {*}
 * @constructor
 */
export const CopyProjectSettings = ({
	field,
	getProjects,
	projectsState,
	setCopyProjectQuery,
	setSearchList,
	isEditing,
}) => {
	const [isActive, setIsActive]               = useState(false)
	const [settings, setSettings]               = useState(initialState)
	const [inputValue, setInputValue]           = useState('')
	const [isResultVisible, setIsResultVisible] = useState(false)

	const { id, title } = field

	const projects = Project.createList(projectsState)

	const cleanStore = useCallback(() => {
		setCopyProjectQuery(null)
		setSearchList(null)
	}, [setCopyProjectQuery, setSearchList])

	useEffect(() => cleanStore, [cleanStore])

	const hide = useCallback(() => {
		setIsActive(false)
		setSettings(initialState)
		setInputValue('')
		cleanStore()
	}, [cleanStore])

	const checkboxHandler = useCallback((nextValue, isChecked) => {
		setSettings(isChecked ? settings.filter(value => value !== nextValue) : [...settings, nextValue])
	}, [settings])

	const getProjectsList = useCallback(_.debounce(value => getProjects(getProjectsQuery(value)), 400),
		[])

	const inputHandler = useCallback(({ target: { value: nextValue } }) => {
		setInputValue(nextValue)
		getProjectsList(nextValue)
	}, [getProjectsList])

	const selectProject = useCallback(project => {
		setInputValue(project.getTitle())
		setIsResultVisible(false)
		setCopyProjectQuery(getCopyProjectQuery(project.getId(), settings))
	}, [setCopyProjectQuery, settings])

	const handleBlur = useCallback(({ currentTarget }) => setTimeout(() => {
		if (!currentTarget.contains(document.activeElement)) {
			setIsResultVisible(false)
		}
	}, 0), [])

	return (
		<div className={styles.container}>
			<ButtonsGroup
				isActive={isActive}
				isEditing={isEditing}
				title={title}
				hide={hide}
				show={() => setIsActive(true)}
			/>
			{(isEditing || isActive) &&
			<div className={styles.content}>
				<div
					className={styles.searchWrapper}
					onFocus={() => setIsResultVisible(true)}
					onBlur={handleBlur}
				>
					<Input
						id={id}
						name={id}
						value={inputValue}
						onChange={inputHandler}
						className={styles.searchInput}
						placeholder="Выберите проект..."
						disabled={false}
						autoComplete="off"
					/>
					<SearchingResults
						isVisible={isResultVisible}
						list={projects}
						action={selectProject}
					/>
				</div>
				<SettingCheckboxes
					handler={checkboxHandler}
					currentValues={settings}
				/>
			</div>}
		</div>
	)
}

CopyProjectSettings.propTypes = {
	field:               PropTypes.object,
	getProjects:         PropTypes.func,
	isEditing:           PropTypes.bool,
	projectsState:       PropTypes.object,
	setCopyProjectQuery: PropTypes.func,
	setSearchList:       PropTypes.func,
}

CopyProjectSettings.defaultProps = {
	field:               {},
	getProjects:         () => null,
	isEditing:           false,
	projectsState:       {},
	setCopyProjectQuery: () => null,
	setSearchList:       () => null,
}
