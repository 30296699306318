import { DeleteOutlineRounded as Remove } from '@material-ui/icons'
import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { Button } from 'components/global/Button'
import { RemoveFileWarning } from 'components/project/ServiceConfig/SiteConfig/RemoveFileWarning'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import { Input } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render file url text field
 *
 * @param {string} value
 * @param {Node} children
 * @param {string} title
 * @param {string} id
 * @param {string} placeholder
 * @param {Function} removeAction
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const FileUrlTextField = ({
	value,
	children,
	title,
	id,
	placeholder,
	removeAction,
	...props
}) => {
	const [isWarning, setIsWarning] = useState(false)

	const removeFile = useCallback(() => {
		removeAction()
		setIsWarning(false)
	}, [removeAction])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<div className={styles.inputWrapper}>
				<Input
					id={id}
					name={id}
					type="text"
					value={value}
					placeholder={placeholder || `${title}...`}
					{...props}
				/>
				{value &&
				<Button onClick={() => setIsWarning(true)} className={styles.removeBtn}>
					<Remove />
				</Button>}
				<RemoveFileWarning
					isActive={isWarning}
					setActive={setIsWarning}
					action={removeFile}
				/>
			</div>
		</div>
	)
}

FileUrlTextField.propTypes = {
	children:     PropTypes.node,
	id:           PropTypes.string,
	placeholder:  PropTypes.string,
	removeAction: PropTypes.func,
	title:        PropTypes.string,
	value:        PropTypes.string,
}

FileUrlTextField.defaultProps = {
	children:     null,
	id:           '',
	placeholder:  '',
	removeAction: () => null,
	title:        '',
	value:        '',
}
