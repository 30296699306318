import styles from 'components/global/Button/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render simple button with styles without outline
 *
 * @param {Node} children
 * @param {string} className
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const Button = React.forwardRef(({ children, className, ...props }, ref) => (
	<button
		ref={ref}
		type="button"
		className={[styles.button, className].join(' ')}
		{...props}
	>
		{children}
	</button>
))

Button.propTypes = {
	children:  PropTypes.node,
	className: PropTypes.string,
	onClick:   PropTypes.func,
	disabled:  PropTypes.bool,
}

Button.defaultProps = {
	children:  null,
	className: '',
	onClick:   () => null,
	disabled:  false,
}
