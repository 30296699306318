import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import React from 'react'

/**
 * Render copyright component
 *
 * @return {*}
 * @constructor
 */
export const Copyright = () => (
	<Typography variant="body2" color="textSecondary" align="center">
		{'Copyright © '}
		<Link color="inherit" href="https://kakadu.bz/">
			Kakadu.bz
		</Link>
		{' '}
		{new Date().getFullYear()}
		{'.'}
	</Typography>
)
