import {
	takeEvery,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	AUTHORIZATION_ATTRIBUTES_ACTIONS,
	AuthorizationAttributesActions,
	AuthorizationAttributesSelectors,
} from 'reduxm/redux-saga/modules/authorization/attributes'
import { AuthorizationAttributesApi } from 'reduxm/redux-saga/modules/authorization/attributes/api'

/**
 * Get attributes models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, AuthorizationAttributesApi.list, null, ResponseHandler.addProjectOrigin)
}

/**
 * View attributes model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, AuthorizationAttributesApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create attributes model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, AuthorizationAttributesApi.create, null, ResponseHandler.addProjectOrigin,
		function* (...params) {
			yield ResponseHandler.afterCreateModel(...params, AuthorizationAttributesSelectors.list,
				AuthorizationAttributesActions.setList)
		})
}

/**
 * Update attributes model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, AuthorizationAttributesApi.update, null, ResponseHandler.addProjectOrigin,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, AuthorizationAttributesSelectors,
				AuthorizationAttributesActions)
		})
}

/**
 * Remove attributes model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, AuthorizationAttributesApi.remove, null, ResponseHandler.addProjectOrigin)
}

/**
 * Get attributes models search list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* searchList(action) {
	yield defaultAction(action, AuthorizationAttributesApi.searchList, null, ResponseHandler.addProjectOrigin)
}

export default [
	takeLatest(AUTHORIZATION_ATTRIBUTES_ACTIONS.LIST, list),
	takeLatest(AUTHORIZATION_ATTRIBUTES_ACTIONS.UPDATE, update),
	takeLatest(AUTHORIZATION_ATTRIBUTES_ACTIONS.REMOVE, remove),
	takeLatest(AUTHORIZATION_ATTRIBUTES_ACTIONS.CREATE, create),
	takeLatest(AUTHORIZATION_ATTRIBUTES_ACTIONS.VIEW, view),
	takeEvery(AUTHORIZATION_ATTRIBUTES_ACTIONS.SEARCH_LIST, searchList),
]
