import {
	ExitToApp as LogOut,
	People as Account,
	Search,
} from '@material-ui/icons'
import { CollapseHeight } from 'components/global/CollapseHeight'
import { CollapseHeightItem } from 'components/global/CollapseHeight/CollapseHeightItem'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

/**
 * Render content of header menu
 *
 * @param {Function} logOut
 * @param {Function} setIsMenuActive
 * @param {Function} showSearchPanel
 * @param {boolean} isBurger
 * @param {boolean} isMenuActive
 * @param {boolean} fetching
 *
 * @return {*}
 * @constructor
 */
export const MenuContent = ({ logOut, isMenuActive, setIsMenuActive, isBurger, showSearchPanel, fetching }) => (
	<CollapseHeight right={20} isOpen={isMenuActive}>
		<CollapseHeightItem onClick={() => setIsMenuActive(false)}>
			<Link className={styles.action} to="/user">
				<Account className={styles.icon} />
				Аккаунт
			</Link>
		</CollapseHeightItem>
		{isBurger &&
		<CollapseHeightItem className={styles.action} onClick={showSearchPanel}>
			<Search className={styles.icon} />
			Поиск
		</CollapseHeightItem>}
		<CollapseHeightItem
			onClick={logOut}
			disabled={fetching}
			className={styles.action}
		>
			<LogOut className={styles.icon} />
			Выход
		</CollapseHeightItem>
	</CollapseHeight>
)

MenuContent.propTypes = {
	fetching:        PropTypes.bool,
	isBurger:        PropTypes.bool,
	isMenuActive:    PropTypes.bool,
	logOut:          PropTypes.func,
	setIsMenuActive: PropTypes.func,
	showSearchPanel: PropTypes.func,
}

MenuContent.defaultProps = {
	fetching:        false,
	isBurger:        false,
	isMenuActive:    false,
	logOut:          () => null,
	setIsMenuActive: () => null,
	showSearchPanel: () => null,
}
