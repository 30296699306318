import graphic from 'assets/images/graphic.png'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render project graphic
 *
 * @param {string} title
 *
 * @return {*}
 * @constructor
 */
export const Graphic = ({ title }) => (
	<>
		<div className={styles.graphic}>
			{`График ${title}`}
		</div>
		<img className={styles.graphicImage} src={graphic} alt="graphic" />
	</>
)

Graphic.propTypes = {
	title: PropTypes.string,
}

Graphic.defaultProps = {
	title: '',
}
