import {
	AddCircleOutlineRounded as Add,
	DeleteOutlineRounded as Remove,
} from '@material-ui/icons'
import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { Button } from 'components/global/Button'
import styles from 'forms/fields/MultipleInput/styles.module.scss'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { Input } from 'reactstrap'

/**
 * Render add domain fields
 *
 * @param {Function} setState
 * @param {Array} value
 * @param {Object} field
 *
 * @return {*}
 * @constructor
 */
export const MultipleInput = ({ setState, value, field }) => {
	const { id, title, keyName } = field

	const initialState = keyName ? [{ [keyName]: '' }] : ['']

	const [inputsData, setInputsData] = useState(initialState)

	useEffect(() => {
		if (value?.length) {
			setInputsData(value)
		}
	}, [value])

	const setValues = useCallback(values => {
		setState(id, values)
		setInputsData(values)
	}, [id, setState])

	const removeValue = useCallback(idx => {
		if (inputsData.length > 1) {
			const values = inputsData.filter((data, index) => index !== idx)

			return setValues(values)
		}

		return setValues(initialState)
	}, [initialState, inputsData, setValues])

	const inputHandler = useCallback((event, idx) => {
		const nextState = inputsData.map((data, index) => {
			if (index === idx) {
				data = keyName ? { [keyName]: event.target.value } : event.target.value
			}

			return data
		})

		setValues(nextState)
	}, [keyName, setValues, inputsData])

	const isNextFieldNeeded = useMemo(() => (keyName ? !!inputsData?.[inputsData.length - 1]?.[keyName]?.length :
		!!inputsData?.[inputsData.length - 1]?.length), [keyName, inputsData])

	return (
		<div className={styles.wrapper}>
			<FieldLabel title={title} id={id} />
			{inputsData.map((data, idx) => {
				const inputData = keyName ? data[keyName] : data

				return (
					<div className={styles.inputGroup} key={idx}>
						<Input
							type="text"
							value={inputData}
							onChange={event => inputHandler(event, idx)}
							placeholder={`${title}...`}
						/>
						<Button
							className={[styles.removeBtn, !inputData.length &&
							styles.hideRemoveBtn].join(' ')}
							onClick={() => removeValue(idx)}
						>
							<Remove />
						</Button>
					</div>
				)
			})}
			{isNextFieldNeeded &&
			<Button
				className={styles.addDomainBtn}
				onClick={() => setInputsData([...inputsData, ...initialState])}
			>
				<Add />
				Добавить поле
			</Button>}
		</div>
	)
}

MultipleInput.propTypes = {
	field:    PropTypes.object,
	setState: PropTypes.func,
	value:    PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

MultipleInput.defaultProps = {
	field:    {},
	setState: () => null,
	value:    null,
}
