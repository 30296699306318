import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import Fingerprint2 from 'fingerprintjs2'

/**
 * Sign in via credentials
 *
 * @param {function} signInCallback
 * @param {string} email
 * @param {string} password
 *
 * @return {Promise.<Object>}
 */
export default async function credentialsLogin(signInCallback, email, password) {
	return new Promise((resolve, reject) => {
		try {
			Fingerprint2.get({}, async components => {
				const result = await components.map(item => item.value)
				const finger = await Fingerprint2.x64hash128(result.join(''), 31)

				if (!finger) {
					throw new Error('Ошибка получения отпечатка.')
				}

				const searchQuery = DataProvider
					.buildQuery()
					.addBody({
						provider: 'credentials',
						token:    email,
						params:   {
							browserId: finger,
							password,
						},
					})
					.setSuccessCallback(resolve)
					.setErrorCallback(reject)

				signInCallback(searchQuery)
			})
		} catch (e) {
			reject(e)
		}
	})
}
