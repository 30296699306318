import { EcoOutlined as Restore } from '@material-ui/icons'
import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { Button } from 'components/global/Button'
import { IosSwitch } from 'components/global/IosSwitch'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render active field
 *
 * @param {Function} setState
 * @param {string} value
 * @param {Object} field
 *
 * @return {*}
 * @constructor
 */
export const StatusField = ({ setState, value, field }) => {
	const { id, title } = field

	const [isActive, setIsActive] = useState(true)

	useEffect(() => {
		if (value) {
			setIsActive(value === 'active')
		}
	}, [value])

	const handler = () => setState(id, isActive ? 'blocked' : 'active')

	return (
		<div>
			<FieldLabel title={title} id={id} />
			<div className={styles.switchWrapper}>
				<div className={[styles.status, isActive && styles.active].join(' ')}>
					{(value === 'deleted' && 'Удален') || 'Активен'}
				</div>
				{value !== 'deleted' ?
					<div>
						<IosSwitch action={handler} checked={isActive} />
					</div> :
					<Button
						onClick={handler}
						className={styles.restoreBtn}
					>
						<Restore />
						Восстановить
					</Button>}
			</div>
		</div>
	)
}

StatusField.propTypes = {
	field:    PropTypes.object,
	setState: PropTypes.func,
	value:    PropTypes.string,
}

StatusField.defaultProps = {
	field:    {},
	setState: () => null,
	value:    null,
}
