import { CardContentItem } from 'components/project/Home/Cards/CardContent/CardContentItem'
import { getDate } from 'helpers/methods'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

/**
 * Render model card content
 *
 * @param {Service|Project} model
 * @param {boolean} fullInfo
 *
 * @return {*}
 * @constructor
 */
export const CardContent = ({ model, fullInfo }) => {
	const isProject = useMemo(() => !!model.getId?.(), [model])

	return (
		<div className={styles.content}>
			<ul className={styles.list}>
				<CardContentItem
					id="description"
					title="Описание"
					text={model.getDescription()}
				/>
				{(isProject || fullInfo) &&
				<>
					<CardContentItem
						id="id"
						title="ID"
						condition={!!model.getId?.()}
						text={model.getId?.()}
					/>
					<CardContentItem
						id="alias"
						title="Алиас"
						text={model.getAlias()}
					/>
					<CardContentItem
						id="domain"
						title="Домен"
						condition={!!model.getDomain?.()}
						text={model.getDomain?.()}
					/>
					<CardContentItem
						id="otherDomains"
						title="Другие домены"
						condition={!!model.getOtherDomains?.()}
						text={String(model.getOtherDomains?.()) || 'Других доменов нет'}
					/>
					<CardContentItem
						id="timezone"
						title="Временная зона"
						condition={!!model.getTimezone?.()}
						text={model.getTimezone?.()}
					/>
					<CardContentItem
						id="created"
						title="Создан"
						text={getDate(model.getCreatedAt())}
					/>
					<CardContentItem
						id="updated"
						title="Обновлен"
						text={getDate(model.getUpdatedAt())}
					/>
					<CardContentItem
						id="dockerImage"
						title="Образ Docker"
						condition={!!model.getDockerImage?.()}
						text={getDate(model.getUpdatedAt?.())}
					>
						<Link
							to={`${model.getDockerImage?.()}`}
							className={styles.link}
							target="_blank"
						>
							{model.getDockerImage?.()}
						</Link>
					</CardContentItem>
				</>}
			</ul>
		</div>
	)
}

CardContent.propTypes = {
	fullInfo: PropTypes.bool,
	model:    PropTypes.object,
}

CardContent.defaultProps = {
	fullInfo: false,
	model:    {},
}
