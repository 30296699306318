import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/Setting/SettingTabs/AwsCredentialsTable/headerTitles'
import { awsCredentialFields } from 'forms/control-panel/settings/aws'
import { getCredentialsForm } from 'forms/control-panel/settings/utils'
import { AwsCredentials } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
} from 'react'

const tableName = 'awsCredentials'

const searchQuery = (projectId, page = 1) => ({ query: { filter: { projectId }, page } })

/**
 * Render AWS settings
 *
 * @param {Object} awsCredentialsState
 * @param {Function} getAwsCredentials
 * @param {Function} createAwsCredentials
 * @param {Function} updateAwsCredentials
 * @param {Function} removeAwsCredentials
 * @param {Project} project
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const AwsCredentialsTable = ({
	awsCredentialsState,
	getAwsCredentials,
	createAwsCredentials,
	updateAwsCredentials,
	removeAwsCredentials,
	project,
	isFetching,
}) => {
	const projectId = project.getId()

	useEffect(() => {
		getAwsCredentials(searchQuery(projectId))
	}, [getAwsCredentials, projectId])

	const awsCredentials       = AwsCredentials.createList(awsCredentialsState)
	const awsCredentialService = StateService.create(awsCredentialsState)

	const removeModel = useCallback(nextSearchQuery => {
		nextSearchQuery.setCallback(() => getAwsCredentials(searchQuery(projectId)))

		removeAwsCredentials(nextSearchQuery)
	}, [getAwsCredentials, projectId, removeAwsCredentials])

	const getForm = getCredentialsForm(awsCredentialFields, awsCredentials)

	return (
		<Table
			paginationData={awsCredentialService.getPagination()}
			paginationAction={page => getAwsCredentials(searchQuery(projectId, page))}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={awsCredentials.length} />
				<CreateModelButton
					initialData={{ projectId: project.getId() }}
					action={createAwsCredentials}
					formFields={getForm()}
					title="Создать настройки AWS"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{(awsCredentials || []).map(credential => (
				<TableWrapper key={`${credential.getProjectId()}-${credential.getServiceAlias()}`}>
					<TableRow tableName={tableName}>
						<TableCol title={credential.getAccessKeyId()} />
						<TableCol>
							<TableNameCol>
								{credential.getServiceAlias(true)}
							</TableNameCol>
						</TableCol>
						<TableCol title={credential.getRegion()} />
						<TableCol title={credential.getBucketName()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={credential}
							action={updateAwsCredentials}
							formFields={getForm(credential.getServiceAlias())}
							title="Редактировать AWS настройки"
							tip
						/>
						<RemoveModel
							id={{
								projectId: credential.getProjectId(),
								service:   credential.getServiceAlias(),
							}}
							modelName={credential.getServiceAlias()}
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

AwsCredentialsTable.propTypes = {
	createAwsCredentials: PropTypes.func,
	getAwsCredentials:    PropTypes.func,
	isFetching:           PropTypes.bool,
	awsCredentialsState:  PropTypes.object,
	project:              PropTypes.object.isRequired,
	removeAwsCredentials: PropTypes.func,
	updateAwsCredentials: PropTypes.func,
}

AwsCredentialsTable.defaultProps = {
	createAwsCredentials: () => null,
	getAwsCredentials:    () => null,
	isFetching:           false,
	awsCredentialsState:  {},
	removeAwsCredentials: () => null,
	updateAwsCredentials: () => null,
}
