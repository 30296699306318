import { callApi } from 'reduxm/redux-saga/api'

/**
 * Get current user
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
function current(searchQuery) {
	return callApi('users.actions.current', searchQuery)
}

/**
 * Sign in
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
const signIn = searchQuery => callApi('users.actions.sign-in', searchQuery)

/**
 * Log out
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
const logOut = searchQuery => {
	searchQuery.addRequestOptions({
		headers: {
			// Don`t need result, async call without await result
			type: 'async',
		},
	})
	return callApi('users.actions.logout', searchQuery)
}

export const UsersApi = {
	current,
	signIn,
	logOut,
}
