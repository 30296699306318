import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTHORIZATION_METHODS_ACTIONS } from 'reduxm/redux-saga/modules/authorization/methods/actionTypes'

export const AuthorizationMethodsActions = {
	list:    RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_METHODS_ACTIONS.LIST),
	create:  RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_METHODS_ACTIONS.CREATE),
	update:  RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_METHODS_ACTIONS.UPDATE),
	view:    RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_METHODS_ACTIONS.VIEW),
	remove:  RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_METHODS_ACTIONS.REMOVE),
	setList: RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_METHODS_ACTIONS.LIST),
	setView: RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_METHODS_ACTIONS.VIEW),
}
