import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { AwsCredentialsTable } from './index'

const { controlPanel: { settings: { aws: { list, create, update, remove, view } } } } = StateSelector

const mapStateToProps = state => ({
	awsCredentialsState: list(state),
	isFetching:          _(list(state), create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getAwsCredentials:    DispatchSelector.controlPanel.settings.aws.list,
	createAwsCredentials: DispatchSelector.controlPanel.settings.aws.create,
	updateAwsCredentials: DispatchSelector.controlPanel.settings.aws.update,
	removeAwsCredentials: DispatchSelector.controlPanel.settings.aws.remove,
	viewAwsCredentials:   DispatchSelector.controlPanel.settings.aws.view,
}

export default connect(mapStateToProps, mapDispatchToProps)(AwsCredentialsTable)
