import Drawer from '@material-ui/core/Drawer'
import {
	AddRounded as Plus,
	RemoveRounded as Minus,
} from '@material-ui/icons'
import { AsidePanel } from 'components/global/AsidePanel'
import { ButtonsGroup } from 'components/global/AsidePanel/ButtonsGroup'
import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import {
	Form,
	Input,
} from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render change task count
 *
 * @param {boolean} isOpen
 * @param {Function} close
 * @param {Function} action
 * @param {number} taskCount
 *
 * @return {*}
 * @constructor
 */
export const ChangeTaskCount = ({ isOpen, close, action, taskCount }) => {
	const [count, setCount]               = useState(0)
	const [isWrongCount, setIsWrongCount] = useState(false)

	useEffect(() => {
		setCount(taskCount)
	}, [taskCount])

	const showWrongCount = () => {
		setIsWrongCount(true)
		setTimeout(() => setIsWrongCount(false), 1500)
	}

	const changeCount = useCallback((isIncrease = true) => {
		if (isIncrease && count < 100) {
			return setCount(count < 100 ? count + 1 : count)
		}
		if (!isIncrease && count > 0) {
			return setCount(count > 0 ? count - 1 : count)
		}
		return showWrongCount()
	}, [count])

	const inputToggle = event => {
		const value = Number(event.target.value)

		if (value >= 0 && value <= 100) {
			return setCount(value)
		}

		if (!isNaN(value)) {
			setCount(value > 100 ? 100 : 0)
		}

		return showWrongCount()
	}

	return (
		<Drawer
			anchor="right"
			open={isOpen}
			onClose={close}
		>
			<AsidePanel title="Изменить количество задач">
				<Form
					onSubmit={event => {
						event.preventDefault()
						action(count)
					}}
				>
					<div className={styles.container}>
						<Button
							onClick={changeCount}
							className={[styles.changeCount, styles.plus].join(' ')}
							disabled={isWrongCount}
						>
							<Plus />
						</Button>
						<Input
							value={count}
							type="text"
							className={styles.count}
							onChange={inputToggle}
							disabled={isWrongCount}
						/>
						<Button
							onClick={() => changeCount(false)}
							className={[styles.changeCount, styles.minus].join(' ')}
							disabled={isWrongCount}
						>
							<Minus />
						</Button>
						{isWrongCount &&
						<div className={styles.wrongCount}>
							Допустимое количество задач от 0 до 100
						</div>}
					</div>
					<ButtonsGroup cancelAction={close} />
				</Form>
			</AsidePanel>
		</Drawer>
	)
}

ChangeTaskCount.propTypes = {
	action:    PropTypes.func,
	close:     PropTypes.func,
	isOpen:    PropTypes.bool,
	taskCount: PropTypes.number,
}

ChangeTaskCount.defaultProps = {
	action:    () => null,
	close:     () => null,
	isOpen:    false,
	taskCount: 0,
}
