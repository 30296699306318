import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * FirebaseConfig model class
 */
class FirebaseConfig extends BaseModel {
	/**
	 * Create FirebaseConfig instance
	 *
	 * @param {Object} object
	 *
	 * @returns {FirebaseConfig}
	 */
	static create(object) {
		return super.create(object?.result?.model)
	}

	/**
	 * Get project id
	 *
	 * @returns {number}
	 */
	getProjectId = () => this.model.projectId

	/**
	 * Get credential
	 *
	 * @returns {Object}
	 */
	getCredential = () => this.model.credential

	/**
	 * Get database URL
	 *
	 * @returns {string}
	 */
	getDatabaseURL = () => this.model.databaseURL

	/**
	 * Get fcmToken
	 *
	 * @returns {string}
	 */
	getFcmToken = () => this.model.fcmToken

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default FirebaseConfig
