import styles from 'components/global/TabsTable/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { TabContent } from 'reactstrap'

/**
 * Render tabs content
 *
 * @param children
 * @param activeTab
 * @return {*}
 * @constructor
 */
export const TabsContent = ({ children, activeTab }) => (
	<TabContent className={styles.tabContent} activeTab={activeTab}>
		{children}
	</TabContent>
)

TabsContent.propTypes = {
	activeTab: PropTypes.string,
	children:  PropTypes.node,
}

TabsContent.defaultProps = {
	activeTab: '',
	children:  null,
}
