import { Dropzone } from 'components/global/Dropzone'
import RoleField from 'forms/authorization/filters/fields/Role/index.store'
import {
	JsonInputField,
	Toggle,
} from 'forms/fields'
import {
	BirthdayField,
	GenderField,
	StatusField,
} from 'forms/user/manage/fields'
import {
	CityField,
	TokenField,
} from 'forms/user/settings/fields'
import React from 'react'

export const usersFields = disabled => [
	{ id: 'firstName', title: 'Имя', disabled },
	{ id: 'lastName', title: 'Фамилия', disabled },
	{ id: 'middleName', title: 'Отчество', disabled },
	{ id: 'status', title: 'Статус', component: <StatusField /> },
	{ id: 'gender', title: 'Пол', type: 'select', children: <GenderField />, disabled },
	{ id: 'birthDay', title: 'Дата рождения', component: <BirthdayField />, disabled },
	{ id: 'password', title: 'Пароль', disabled },
]

export const usersSettingsFields = (dropzoneProps, userId, disabled) => ([
	{ id: 'cityId', title: 'Город', component: <CityField />, disabled },
	{ id: 'image', title: 'Изображение', component: <Dropzone {...dropzoneProps} disabled={disabled} /> },
	{ id: 'email', title: 'E-mail', disabled },
	{ id: 'isEmailVerified', title: 'Верификация E-mail', component: <Toggle />, disabled },
	{ id: 'isPhoneVerified', title: 'Верификация номера телефона', component: <Toggle />, disabled },
	{ id: 'phone', title: 'Телефон', disabled },
	{ id: 'token', title: 'Токен', component: <TokenField userId={userId} />, disabled },
	{ id: 'roleAlias', title: 'Роль', component: <RoleField userId={userId} />, disabled },
	{ id: 'params', title: 'Параметры', component: <JsonInputField />, disabled },
])
