import { PermissionsTab } from 'components/project/ServiceConfig/Authorization/SettingTabs/Permissions/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { authorization: { permissions: { list, create, update, remove } } } = StateSelector

const mapStateToProps = state => ({
	permissionsState: list(state),
	isFetching:       _(list(state), create(state), update(state), remove(state)),
})

const mapDispatchToProps = {
	getPermissions:    DispatchSelector.authorization.permissions.list,
	createPermissions: DispatchSelector.authorization.permissions.create,
	updatePermissions: DispatchSelector.authorization.permissions.update,
	removePermissions: DispatchSelector.authorization.permissions.remove,
	createBatch:       DispatchSelector.authorization.permissions.batchCreate,
}

export default connect(mapStateToProps, mapDispatchToProps)(PermissionsTab)
