import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { UserData } from './index'

const mapStateToProps = state => ({
	userState: StateSelector.users.actions.current(state),
})

const mapDispatchToProps = {
	updateUser: DispatchSelector.users.actions.update,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserData)
