import {
	put,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import {
	PROJECT_SOCIAL_CONFIG_ACTIONS,
	SocialConfigActions,
	SocialConfigApi,
} from 'reduxm/redux-saga/modules/control-panel/settings/social'

/**
 * Worker for create/update methods
 *
 * @param {Object} data
 * @param {Object} data.response Response data
 * @param {Object} data.result Firebase config model
 *
 * @return {Generator<*, void, ?>}
 * */
function* creationWorker(data) {
	const { response, result: { model } } = data

	yield put(SocialConfigActions.setView({ model, response }))
}

/**
 * View social config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, SocialConfigApi.view)
}

/**
 * Remove social config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, SocialConfigApi.remove, null, null, function* (data) {
		if (data?.result?.result) {
			yield put(SocialConfigActions.setView(null))
		}
	})
}

/**
 * Updated social config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, SocialConfigApi.update, null, null, creationWorker)
}

/**
 * Create social config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, SocialConfigApi.create, null, null, creationWorker)
}

export default [
	takeLatest(PROJECT_SOCIAL_CONFIG_ACTIONS.CREATE, create),
	takeLatest(PROJECT_SOCIAL_CONFIG_ACTIONS.REMOVE, remove),
	takeLatest(PROJECT_SOCIAL_CONFIG_ACTIONS.UPDATE, update),
	takeLatest(PROJECT_SOCIAL_CONFIG_ACTIONS.VIEW, view),
]
