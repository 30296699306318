import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { AuthorizationRolesActions } from 'reduxm/redux-saga/modules/authorization/roles/actionCreators'
import { AuthorizationRolesSelectors } from 'reduxm/redux-saga/modules/authorization/roles/actionSelectors'
import { AUTHORIZATION_ROLES_ACTIONS } from 'reduxm/redux-saga/modules/authorization/roles/actionTypes'
import { AuthorizationRolesApi } from 'reduxm/redux-saga/modules/authorization/roles/api'

/**
 * Get role models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, AuthorizationRolesApi.list, null, ResponseHandler.addProjectOrigin)
}

/**
 * View role model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, AuthorizationRolesApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create role model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, AuthorizationRolesApi.create, null, ResponseHandler.addProjectOrigin,
		function* (...params) {
			yield ResponseHandler.afterCreateModel(...params, AuthorizationRolesSelectors.list,
				AuthorizationRolesActions.setList)
		})
}

/**
 * Update role model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, AuthorizationRolesApi.update, null, ResponseHandler.addProjectOrigin,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, AuthorizationRolesSelectors, AuthorizationRolesActions)
		})
}

/**
 * Remove role model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, AuthorizationRolesApi.remove, null, ResponseHandler.addProjectOrigin)
}

/**
 * Get attributes models roles list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* searchList(action) {
	yield defaultAction(action, AuthorizationRolesApi.searchList, null, ResponseHandler.addProjectOrigin)
}

export default [
	takeLatest(AUTHORIZATION_ROLES_ACTIONS.LIST, list),
	takeLatest(AUTHORIZATION_ROLES_ACTIONS.UPDATE, update),
	takeLatest(AUTHORIZATION_ROLES_ACTIONS.REMOVE, remove),
	takeLatest(AUTHORIZATION_ROLES_ACTIONS.CREATE, create),
	takeLatest(AUTHORIZATION_ROLES_ACTIONS.VIEW, view),
	takeLatest(AUTHORIZATION_ROLES_ACTIONS.SEARCH_LIST, searchList),
]
