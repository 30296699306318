import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render parent roles options
 *
 * @param {Array.<AuthorizationRoles>} roles
 *
 * @return {*}
 * @constructor
 */
export const ParentRoles = ({ roles }) => (
	<>
		<option value="" disabled>Выберите родительскую роль</option>
		{roles.map(role => (
			<option key={role.getAlias()} value={role.getId()}>
				{role.getName()}
			</option>))}
	</>
)

ParentRoles.propTypes = {
	roles: PropTypes.array,
}

ParentRoles.defaultProps = {
	roles: [],
}
