import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

/**
 * Render table header
 *
 * @param {Array} titles
 * @param {string} tableName
 *
 * @return {*}
 * @constructor
 */
export const TableHeader = ({ titles, tableName }) => (
	<div className={styles.header}>
		{titles.map(item => (
			<div key={item.id} className={styles[tableName]}>
				<span className={styles.title}>{item.title}</span>
			</div>))}
	</div>
)

TableHeader.propTypes = {
	tableName: PropTypes.string,
	titles:    PropTypes.array,
}

TableHeader.defaultProps = {
	tableName: '',
	titles:    [],
}
