import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	AwsCredentialsActions,
	AwsCredentialsSelectors,
	PROJECT_AWS_CREDENTIALS_ACTIONS,
} from 'reduxm/redux-saga/modules/control-panel/settings/aws'
import { AwsCredentialsApi } from 'reduxm/redux-saga/modules/control-panel/settings/aws/api'

/**
 *  Get aws credentials list
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, AwsCredentialsApi.list)
}

/**
 * View aws credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, AwsCredentialsApi.view)
}

/**
 * Remove aws credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, AwsCredentialsApi.remove)
}

/**
 * Update aws credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, AwsCredentialsApi.update, null, null,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, AwsCredentialsSelectors, AwsCredentialsActions)
		})
}

/**
 * Create aws credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, AwsCredentialsApi.create, null, null,
		function* (...params) {
			yield ResponseHandler.afterCreateModel(...params, AwsCredentialsSelectors.list,
				AwsCredentialsActions.setList)
		})
}

export default [
	takeLatest(PROJECT_AWS_CREDENTIALS_ACTIONS.LIST, list),
	takeLatest(PROJECT_AWS_CREDENTIALS_ACTIONS.CREATE, create),
	takeLatest(PROJECT_AWS_CREDENTIALS_ACTIONS.REMOVE, remove),
	takeLatest(PROJECT_AWS_CREDENTIALS_ACTIONS.UPDATE, update),
	takeLatest(PROJECT_AWS_CREDENTIALS_ACTIONS.VIEW, view),
]
