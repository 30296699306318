import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { ServicesTable } from './index'

const mapStateToProps = state => ({
	servicesState: StateSelector.controlPanel.services.list(state),
	isFetching:
		StateSelector.controlPanel.services.list(state).fetching ||
		StateSelector.controlPanel.services.remove(state).fetching ||
		StateSelector.controlPanel.services.create(state).fetching ||
		StateSelector.controlPanel.services.update(state).fetching,
})

const mapDispatchToProps = {
	getServices:   DispatchSelector.controlPanel.services.list,
	createService: DispatchSelector.controlPanel.services.create,
	removeService: DispatchSelector.controlPanel.services.remove,
	updateService: DispatchSelector.controlPanel.services.update,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServicesTable)
