import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/Setting/SettingTabs/RedisCredentialsTable/headerTitles'
import { redisCredentialFields } from 'forms/control-panel/settings/redis'
import { getCredentialsForm } from 'forms/control-panel/settings/utils'
import { RedisCredentials } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
} from 'react'

const tableName = 'redisCredentials'

const searchQuery = (projectId, page = 1) => ({ query: { filter: { projectId }, page } })

/**
 * Render redis credentials table
 *
 * @param {Object} redisCredentialsState
 * @param {Function} getRedisCredentials
 * @param {Function} createRedisCredentials
 * @param {Function} updateRedisCredentials
 * @param {Function} removeRedisCredentials
 * @param {Project} project
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const RedisCredentialsTable = ({
	redisCredentialsState,
	getRedisCredentials,
	createRedisCredentials,
	updateRedisCredentials,
	removeRedisCredentials,
	project,
	isFetching,
}) => {
	const projectId = project.getId()

	useEffect(() => {
		getRedisCredentials(searchQuery(projectId))
	}, [getRedisCredentials, projectId])

	const redisCredentials        = RedisCredentials.createList(redisCredentialsState)
	const redisCredentialsService = StateService.create(redisCredentialsState)

	const removeModel = useCallback(nextSearchQuery => {
		nextSearchQuery.setCallback(() => getRedisCredentials(searchQuery(projectId)))

		removeRedisCredentials(nextSearchQuery)
	}, [getRedisCredentials, projectId, removeRedisCredentials])

	const getForm = getCredentialsForm(redisCredentialFields, redisCredentials)

	return (
		<Table
			paginationData={redisCredentialsService.getPagination()}
			paginationAction={page => getRedisCredentials(searchQuery(projectId, page))}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={redisCredentials.length} />
				<CreateModelButton
					initialData={{ projectId: project.getId() }}
					action={createRedisCredentials}
					formFields={getForm()}
					title="Создать настройки Redis"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{(redisCredentials || []).map(credential => (
				<TableWrapper key={`${credential.getProjectId()}-${credential.getServiceAlias()}`}>
					<TableRow tableName={tableName}>
						<TableCol>
							<TableNameCol>
								{credential.getServiceAlias(true)}
							</TableNameCol>
						</TableCol>
						<TableCol title={credential.getDatabase()} />
						<TableCol title={credential.getHost()} />
						<TableCol title={credential.getPort()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={credential}
							action={updateRedisCredentials}
							formFields={getForm(credential.getServiceAlias())}
							title="Редактировать Redis настройки"
							tip
						/>
						<RemoveModel
							id={{
								projectId: credential.getProjectId(),
								service:   credential.getServiceAlias(),
							}}
							modelName={credential.getServiceAlias()}
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

RedisCredentialsTable.propTypes = {
	createRedisCredentials: PropTypes.func,
	getRedisCredentials:    PropTypes.func,
	isFetching:             PropTypes.bool,
	project:                PropTypes.object.isRequired,
	redisCredentialsState:  PropTypes.object,
	removeRedisCredentials: PropTypes.func,
	updateRedisCredentials: PropTypes.func,
}

RedisCredentialsTable.defaultProps = {
	createRedisCredentials: () => null,
	getRedisCredentials:    () => null,
	isFetching:             false,
	redisCredentialsState:  {},
	removeRedisCredentials: () => null,
	updateRedisCredentials: () => null,
}
