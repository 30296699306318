import {
	CloseRounded as Close,
	CheckRounded as Success,
} from '@material-ui/icons'
import { Button } from 'components/global/Button'
import styles from 'forms/control-panel/project/fields/CopyProjectSettings/styles.module.scss'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useState,
} from 'react'

/**
 * Render buttons group
 *
 * @param {boolean} isActive
 * @param {Function} show
 * @param {Function} hide
 * @param {string} title
 * @param {boolean} isEditing
 *
 * @return {*}
 * @constructor
 */
export const ButtonsGroup = ({ isActive, isEditing, show, hide, title }) => {
	const [isCancelVisible, setIsCancelVisible] = useState(false)

	useEffect(() => {
		if (isActive) {
			setIsCancelVisible(true)
		}
	}, [isActive])

	return (!isEditing &&
		<>
			<Button
				className={[styles.toggleBtn, isActive && styles.disable].join(' ')}
				onClick={show}
			>
				{isActive && <Success />}
				{title}
			</Button>
			{isCancelVisible &&
			<Button
				onClick={hide}
				className={[styles.closeBtn, !isActive && styles.hidden].join(' ')}
			>
				<Close />
			</Button>}
		</>
	)
}

ButtonsGroup.propTypes = {
	hide:      PropTypes.func,
	isActive:  PropTypes.bool,
	isEditing: PropTypes.bool,
	show:      PropTypes.func,
	title:     PropTypes.string,
}

ButtonsGroup.defaultProps = {
	hide:      () => null,
	isActive:  false,
	isEditing: false,
	show:      () => null,
	title:     '',
}
