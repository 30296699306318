import { InfoOutlined } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'


export const TableInfo = ({ info }) => (
	<div className={styles.info}>
		<InfoOutlined className={styles.icon} />
		{info}
	</div>
)

TableInfo.propTypes = {
	info: PropTypes.string,
}

TableInfo.defaultProps = {
	info: '',
}
