import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { CheckBox } from 'components/global/Inputs/Checkbox'
import { InputLabel } from 'components/global/Inputs/InputLabel'
import styles from 'forms/control-panel/service/fields/styles.module.scss'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'

const policiesList = [
	{ label: 'CPU', value: 'ECSServiceAverageCPUUtilization' },
	{ label: 'MEMORY', value: 'ECSServiceAverageMemoryUtilization' },
]

/**
 * Render scaling policies field
 *
 * @param {Function} setState
 * @param {string} value
 * @param {Object} field
 *
 * @return {*}
 * @constructor
 */
export const ScalingPolicies = ({ setState, value, field }) => {
	const { id, title } = field

	const [policies, setPolicies] = useState([])

	useEffect(() => {
		if (value) {
			setPolicies(value)
		}
	}, [value])

	const setValues = useCallback(service => {
		setState(id, service)
	}, [id, setState])

	const inputHandler = useCallback(event => {
		let nextValue

		if (event.target.checked) {
			nextValue = [...policies, event.target.value]
		} else {
			nextValue = policies.filter(policy => policy !== event.target.value)
		}

		setValues(nextValue)
	}, [setValues, policies])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<div className={styles.inputsWrapper}>
				{policiesList.map(policy => {
					const isChecked = policies.includes(policy.value)

					return (
						<div className={styles.inputGroup} key={policy.label}>
							<InputLabel
								label={policy.label}
								checked={isChecked}
							>
								<CheckBox
									value={policy.value}
									onChange={inputHandler}
									checked={isChecked}
								/>
							</InputLabel>
						</div>
					)
				})}
			</div>
		</div>
	)
}

ScalingPolicies.propTypes = {
	field:    PropTypes.object,
	setState: PropTypes.func,
	value:    PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

ScalingPolicies.defaultProps = {
	field:    {},
	setState: () => null,
	value:    [],
}
