import {
	CloudDoneRounded as Connection,
	NotInterestedRounded as Empty,
} from '@material-ui/icons'
import styles from 'containers/Service/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

/**
 * Render connected projects list
 *
 * @param {Array.<Project>} projects
 *
 * @return {*}
 * @constructor
 */
export const ConnectedProjects = ({ projects }) => (
	<div className={styles.connectedProjects}>
		<div className={styles.title}>
			<Connection />
			Сервис подключен к проектам:
		</div>
		<div className={styles.projectsWrapper}>
			{projects.length ?
				projects.map(project => (
					<Link
						key={project.getAlias()}
						to={`/project/${project.getAlias()}`}
						className={styles.connectedProject}
					>
						{project.getTitle()}
					</Link>)) :
				<div className={styles.emptyList}>
					<Empty />
					Список подключенных проектов пуст.
				</div>}
		</div>
	</div>
)

ConnectedProjects.propTypes = {
	projects: PropTypes.array,
}

ConnectedProjects.defaultProps = {
	projects: [],
}
