import { Loupe as Arrow } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import styles from 'forms/fields/SearchList/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { Spinner } from 'reactstrap'

/**
 * Render search list
 *
 * @param {string} inputValue
 * @param {Array} items
 * @param {boolean} isFetching
 * @param {boolean} isActive
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const SearchList = ({
	isActive,
	items,
	action,
	isFetching,
	inputValue,
}) => (isActive &&
	<div className={styles.container}>
		<div className={[styles.fetching, isFetching && styles.active].join(' ')}>
			{isFetching && <Spinner className={styles.spinner} />}
		</div>
		{items.length ? items.map(item => {
			const name       = item.getName?.() ?? item.getTitle?.()
			const identifier = item.getAlias?.() ?? item.getId?.()

			return ((name && identifier &&
				<Button
					key={identifier}
					className={styles.attribute}
					onClick={() => action(item)}
				>
					<Arrow />
					<div className={styles.attributeInfo}>
						<div className={styles.name}>{name}</div>
						<div className={styles.alias}>{identifier}</div>
					</div>
				</Button>) ||
				<div key={inputValue} className={styles.emptyResult}>
					{inputValue}
				</div>)
		}) : <div className={styles.emptyResult}>{`По запросу ${inputValue} ничего не найдено...`}</div>}
	</div>
)

SearchList.propTypes = {
	action:     PropTypes.func,
	items:      PropTypes.array,
	inputValue: PropTypes.string,
	isActive:   PropTypes.bool,
	isFetching: PropTypes.bool,
}

SearchList.defaultProps = {
	action:     () => null,
	items:      [],
	inputValue: '',
	isActive:   false,
	isFetching: false,
}
