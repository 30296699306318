import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

/**
 * Render table wrapper
 *
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const TableWrapper = ({ children }) => (
	<div className={styles.wrapper}>
		{children}
	</div>
)

TableWrapper.propTypes = {
	children: PropTypes.node,
}

TableWrapper.defaultProps = {
	children: null,
}
