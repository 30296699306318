import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render service options component
 *
 * @param {Array.<Service|Site>} services
 *
 * @return {*}
 * @constructor
 */
export const ServiceOptions = ({ services }) => (
	<>
		<option disabled value="default">
			{services.length ? 'Выберите из списка' : 'Список пуст'}
		</option>
		{services.map(service => (
			<option value={service.getAlias()} key={service.getAlias()}>
				{service.getTitle()}
			</option>
		))}
	</>
)

ServiceOptions.propTypes = {
	services: PropTypes.array,
}

ServiceOptions.defaultProps = {
	services: [],
}
