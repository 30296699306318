import { OperatorField } from 'forms/authorization/filters/fields/Operator'
import RoleField from 'forms/authorization/filters/fields/Role/index.store'
import { JsonInputField } from 'forms/fields'
import React from 'react'

export const filtersFields = [
	{ id: 'name', title: 'Имя' },
	{ id: 'roleAlias', title: 'Роль', component: <RoleField /> },
	{ id: 'operator', title: 'Оператор', component: <OperatorField /> },
	{ id: 'condition', title: 'Условие', component: <JsonInputField /> },
]
