import { ConfigTab } from 'components/global/ConfigTab'
import { authConfigFields } from 'forms/authentication/authConfig'
import {
	AuthConfig,
	Project,
} from 'models'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render authentication config
 *
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const AuthenticationConfig = props => (
	<ConfigTab
		fields={authConfigFields}
		model={AuthConfig}
		tabName="authentication"
		createDeployment
		type="microservice"
		{...props}
	/>
)

AuthenticationConfig.propTypes = {
	project:      PropTypes.instanceOf(Project),
	createConfig: PropTypes.func,
	getConfig:    PropTypes.func,
	removeConfig: PropTypes.func,
	updateConfig: PropTypes.func,
	configState:  PropTypes.object,
	isFetching:   PropTypes.bool,
}

AuthenticationConfig.defaultProps = {
	project:      Project.create({}),
	createConfig: () => null,
	getConfig:    () => null,
	removeConfig: () => null,
	updateConfig: () => null,
	configState:  {},
	isFetching:   false,
}
