import { combineReducers } from 'redux'
import AuthActionsReducer from 'reduxm/redux-saga/modules/authentication/actions/reducer'
import AuthBearerReducer from 'reduxm/redux-saga/modules/authentication/bearer/reducer'
import AuthJwtReducer from 'reduxm/redux-saga/modules/authentication/jwt/reducer'
import AuthorizationReducer from 'reduxm/redux-saga/modules/authorization/actions/reducer'
import AuthorizationAttributesReducer from 'reduxm/redux-saga/modules/authorization/attributes/reducer'
import AuthorizationFiltersReducer from 'reduxm/redux-saga/modules/authorization/filters/reducer'
import AuthorizationMethodsReducer from 'reduxm/redux-saga/modules/authorization/methods/reducer'
import AuthorizationPermissionsReducer from 'reduxm/redux-saga/modules/authorization/permissions/reducer'
import AuthorizationRolesReducer from 'reduxm/redux-saga/modules/authorization/roles/reducer'
import FilesReducer from 'reduxm/redux-saga/modules/control-panel/files/reducer'
import ProjectReducer from 'reduxm/redux-saga/modules/control-panel/projects/reducer'
import ServicesReducer from 'reduxm/redux-saga/modules/control-panel/services/reducer'
import AuthenticationConfigReducer from 'reduxm/redux-saga/modules/control-panel/settings/authentication/reducer'
import AwsCredentialsReducer from 'reduxm/redux-saga/modules/control-panel/settings/aws/reducer'
import FirebaseConfigReducer from 'reduxm/redux-saga/modules/control-panel/settings/firebase/reducer'
import MailCredentialsReducer from 'reduxm/redux-saga/modules/control-panel/settings/mail/reducer'
import MySqlCredentialsReducer from 'reduxm/redux-saga/modules/control-panel/settings/mysql/reducer'
import RedisCredentialsReducer from 'reduxm/redux-saga/modules/control-panel/settings/redis/reducer'
import ServiceSettingsReducer from 'reduxm/redux-saga/modules/control-panel/settings/service/reducer'
import SocialReducer from 'reduxm/redux-saga/modules/control-panel/settings/social/reducer'
import SitesReducer from 'reduxm/redux-saga/modules/control-panel/sites/reducer'
import BaseCitiesReducer from 'reduxm/redux-saga/modules/geo/cities/reducer'
import UserReducer from 'reduxm/redux-saga/modules/users/actions/reducer'
import UsersManageReducer from 'reduxm/redux-saga/modules/users/manage/reducer'
import UsersManageSettingsReducer from 'reduxm/redux-saga/modules/users/manage/settings/reducer'

export const allReducers = {
	controlPanel:  combineReducers({
		projects: ProjectReducer,
		services: ServicesReducer,
		sites:    SitesReducer,
		files:    FilesReducer,
		settings: combineReducers({
			mysql:          MySqlCredentialsReducer,
			redis:          RedisCredentialsReducer,
			mail:           MailCredentialsReducer,
			aws:            AwsCredentialsReducer,
			firebase:       FirebaseConfigReducer,
			authentication: AuthenticationConfigReducer,
			service:        ServiceSettingsReducer,
			social:         SocialReducer,
		}),
	}),
	auth:          combineReducers({
		actions: AuthActionsReducer,
		bearer:  AuthBearerReducer,
		jwt:     AuthJwtReducer,
	}),
	authorization: combineReducers({
		actions:     AuthorizationReducer,
		roles:       AuthorizationRolesReducer,
		attributes:  AuthorizationAttributesReducer,
		methods:     AuthorizationMethodsReducer,
		filters:     AuthorizationFiltersReducer,
		permissions: AuthorizationPermissionsReducer,
	}),
	users:         combineReducers({
		actions:        UserReducer,
		manage:         UsersManageReducer,
		manageSettings: UsersManageSettingsReducer,
	}),
	geo:           combineReducers({
		cities: BaseCitiesReducer,
	}),
}
