import { useEffect } from 'react'

/**
 * Hook to block scrolling
 */
export const useStopScrolling = (isBlockNeeded = true) => {
	useEffect(() => {
		document.body.style.overflow = isBlockNeeded ? 'hidden' : 'unset'

		return () => document.body.style.overflow = 'unset'
	}, [isBlockNeeded])
}
