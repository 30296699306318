import { takeEvery } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { BASE_CITIES_ACTIONS } from 'reduxm/redux-saga/modules/geo/cities/actionTypes'
import { BaseCitiesApi } from 'reduxm/redux-saga/modules/geo/cities/api'

/**
 * Get city model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, BaseCitiesApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Get city models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* searchList(action) {
	yield defaultAction(action, BaseCitiesApi.searchList, null, ResponseHandler.addProjectOrigin)
}

export default [
	takeEvery(BASE_CITIES_ACTIONS.SEARCH_LIST, searchList),
	takeEvery(BASE_CITIES_ACTIONS.VIEW, view),
]
