import PropTypes from 'prop-types'
import React from 'react'
import styles from '../styles.module.scss'

/**
 * Render table name col
 *
 * @param {Node} children
 * @param {boolean} active
 * @param {string} className
 *
 * @return {*}
 * @constructor
 */
export const TableNameCol = ({ children, active, className }) => (
	<div className={[active ? styles.activeName : styles.name, className].join(' ')}>
		{children}
	</div>
)

TableNameCol.propTypes = {
	active:    PropTypes.bool,
	children:  PropTypes.node,
	className: PropTypes.string,
}

TableNameCol.defaultProps = {
	active:    false,
	children:  null,
	className: '',
}
