import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { AUTHORIZATION_PERMISSIONS_ACTIONS } from 'reduxm/redux-saga/modules/authorization/permissions'
import { AuthorizationPermissionsApi } from 'reduxm/redux-saga/modules/authorization/permissions/api'

/**
 * Get permissions models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, AuthorizationPermissionsApi.list, null, ResponseHandler.addProjectOrigin)
}

/**
 * View permissions model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, AuthorizationPermissionsApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create permissions model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, AuthorizationPermissionsApi.create, null, ResponseHandler.addProjectOrigin)
}

/**
 * Update permissions model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, AuthorizationPermissionsApi.update, null, ResponseHandler.addProjectOrigin)
}

/**
 * Remove permissions model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, AuthorizationPermissionsApi.remove, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create batch.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* batchCreate(action) {
	yield defaultAction(action, AuthorizationPermissionsApi.remove, null, ResponseHandler.addProjectOrigin)
}

export default [
	takeLatest(AUTHORIZATION_PERMISSIONS_ACTIONS.LIST, list),
	takeLatest(AUTHORIZATION_PERMISSIONS_ACTIONS.UPDATE, update),
	takeLatest(AUTHORIZATION_PERMISSIONS_ACTIONS.REMOVE, remove),
	takeLatest(AUTHORIZATION_PERMISSIONS_ACTIONS.CREATE, create),
	takeLatest(AUTHORIZATION_PERMISSIONS_ACTIONS.VIEW, view),
	takeLatest(AUTHORIZATION_PERMISSIONS_ACTIONS.BATCH_CREATE, batchCreate),
]
