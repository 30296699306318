import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { SocialConfigTab } from './index'

const { controlPanel: { settings: { social: { create, update, remove, view } } } } = StateSelector

const mapStateToProps = state => ({
	configState: view(state),
	isFetching:        _(create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getConfig:    DispatchSelector.controlPanel.settings.social.view,
	createConfig: DispatchSelector.controlPanel.settings.social.create,
	updateConfig: DispatchSelector.controlPanel.settings.social.update,
	removeConfig: DispatchSelector.controlPanel.settings.social.remove,
}

export default connect(mapStateToProps, mapDispatchToProps)(SocialConfigTab)
