import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { CopySettingsFrom } from './index'

const mapStateToProps = state => ({
	projectState:     StateSelector.controlPanel.projects.view(state),
	copyProjectQuery: StateSelector.controlPanel.projects.copyProjectQuery(state),
})

const mapDispatchToProps = {
	copyProjectSettings: DispatchSelector.controlPanel.projects.copyProjectSettings,
}

export default connect(mapStateToProps, mapDispatchToProps)(CopySettingsFrom)
