import { Spinner } from 'components/global/Spinner'
import React from 'react'
import { render } from 'react-dom'
import ReactNotification from 'react-notifications-component'
import { Provider } from 'react-redux'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { PersistGate } from 'redux-persist/integration/react'
import configureStore from 'reduxm/store'
import 'styles/necessaryStyles'
import App from './App'

const { store, persistor } = configureStore()

const app = (
	<Provider store={store}>
		<PersistGate loading={<Spinner block isFetching />} persistor={persistor}>
			<Router>
				<ToastContainer autoClose={5000} />
				<ReactNotification />
				<App persistor={persistor} />
			</Router>
		</PersistGate>
	</Provider>
)
render(app, document.getElementById('root'))
