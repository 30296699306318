import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { ButtonsGroup } from 'components/global/AsidePanel/ButtonsGroup'
import { FormField } from 'components/global/AsidePanel/ModelForm/FormField'
import {
	domainInvalidState,
	domainRegExp,
	domainValidState,
	serviceTypes,
} from 'forms/control-panel/project/ConnectService/data'
import {
	servicesField,
	ServiceTypeField,
} from 'forms/control-panel/project/index'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
	useState,
} from 'react'
import {
	Form,
	FormGroup,
} from 'reactstrap'

/**
 * Render connect service form groups component
 *
 * @param {Function} createServiceConfig
 * @param {Function} connectService
 * @param {number} projectId
 * @param {Array.<Service|Site>} services
 * @param {string} type
 * @param {Function} setServiceType
 * @param {Function} close
 *
 * @return {*}
 * @constructor
 */
export const ConnectServiceForm = ({
	createServiceConfig,
	connectService,
	projectId,
	services,
	type,
	setServiceType,
	close,
}) => {
	const [serviceAlias, setServiceAlias]       = useState('default')
	const [domain, setDomain]                   = useState('')
	const [isDomainVisible, setIsDomainVisible] = useState(false)
	const [isDomainValid, setIsDomainValid]     = useState(domainValidState)

	const setDefaultState = () => {
		setServiceAlias('default')
		setDomain('')
		setIsDomainVisible(false)
	}

	const getIsDomainValid = useCallback(domainToCheck => domainRegExp.test(domainToCheck), [])

	const getConnectServiceQuery = useCallback(() => DataProvider
		.buildQuery()
		.addBody({
			projectId,
			serviceAlias,
			type,
		})
		.setCallback(close), [close, projectId, serviceAlias, type])

	const getCreateServiceQuery = useCallback(() => DataProvider
		.buildQuery()
		.addBody({
			attributes: {
				projectId,
				service: serviceAlias,
				config:  { domain },
			},
		})
		.setSuccessCallback(() => connectService(getConnectServiceQuery())),
	[connectService, domain, getConnectServiceQuery, projectId, serviceAlias])

	const save = useCallback(event => {
		event.preventDefault()

		if (type === 'site') {
			if (!isDomainVisible) {
				return setIsDomainVisible(true)
			}

			if (isDomainVisible && !getIsDomainValid(domain)) {
				return setIsDomainValid(domainInvalidState)
			}

			if (isDomainVisible && getIsDomainValid(domain)) {
				setIsDomainValid(domainValidState)

				return createServiceConfig(getCreateServiceQuery())
			}
		}

		return connectService(getConnectServiceQuery())
	}, [type, connectService, getConnectServiceQuery, isDomainVisible, getIsDomainValid, domain,
		createServiceConfig, getCreateServiceQuery])

	const isSuccessDisabled = useMemo(() => !projectId || serviceAlias === 'default' || !type,
		[projectId, serviceAlias, type])

	const currentType = serviceTypes.find(serviceType => serviceType.value === type)

	const serviceTypeToggle = event => {
		setServiceType(event.target.value)
		setDefaultState()
	}

	return (
		<Form onSubmit={save}>
			<FormGroup>
				<ServiceTypeField
					action={serviceTypeToggle}
					type={type}
				/>
				{type &&
				<FormField
					field={servicesField(currentType, services)}
					onChange={event => setServiceAlias(event.target.value)}
					value={serviceAlias}
				/>}
				{isDomainVisible &&
				<FormField
					field={{ id: 'domain', title: 'Домен', placeholder: 'https://example.com' }}
					onChange={event => setDomain(event.target.value)}
					value={domain}
					invalid={isDomainValid}
				/>}
			</FormGroup>
			<ButtonsGroup
				title="Добавить"
				cancelAction={close}
				isSuccessDisabled={isSuccessDisabled}
			/>
		</Form>
	)
}

ConnectServiceForm.propTypes = {
	close:               PropTypes.func,
	connectService:      PropTypes.func,
	createServiceConfig: PropTypes.func,
	projectId:           PropTypes.number,
	services:            PropTypes.array,
	setServiceType:      PropTypes.func,
	type:                PropTypes.string,
}

ConnectServiceForm.defaultProps = {
	close:               () => null,
	connectService:      () => null,
	createServiceConfig: () => null,
	projectId:           null,
	services:            [],
	setServiceType:      () => null,
	type:                '',
}
