import { AUTHORIZATION_ATTRIBUTES_ACTIONS } from 'reduxm/redux-saga/modules/authorization/attributes/actionTypes'

export const AuthorizationAttributesSelectors = {
	list:       state => state.authorization.attributes[AUTHORIZATION_ATTRIBUTES_ACTIONS.LIST],
	create:     state => state.authorization.attributes[AUTHORIZATION_ATTRIBUTES_ACTIONS.CREATE],
	update:     state => state.authorization.attributes[AUTHORIZATION_ATTRIBUTES_ACTIONS.UPDATE],
	remove:     state => state.authorization.attributes[AUTHORIZATION_ATTRIBUTES_ACTIONS.REMOVE],
	view:       state => state.authorization.attributes[AUTHORIZATION_ATTRIBUTES_ACTIONS.VIEW],
	searchList: state => state.authorization.attributes[AUTHORIZATION_ATTRIBUTES_ACTIONS.SEARCH_LIST],
}
