import { AttributesTab } from 'components/project/ServiceConfig/Authorization/SettingTabs/Attributes/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { authorization: { attributes: { list, create, update, remove, view } } } = StateSelector

const mapStateToProps = state => ({
	attributesState:     view(state),
	attributesListState: list(state),
	isFetching:          _(list(state), create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getAttributes:     DispatchSelector.authorization.attributes.view,
	getAttributesList: DispatchSelector.authorization.attributes.list,
	createAttributes:  DispatchSelector.authorization.attributes.create,
	updateAttributes:  DispatchSelector.authorization.attributes.update,
	removeAttributes:  DispatchSelector.authorization.attributes.remove,
}

export default connect(mapStateToProps, mapDispatchToProps)(AttributesTab)
