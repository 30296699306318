import { RemoveModel } from 'components/global/RemoveModel'
import PropTypes from 'prop-types'
import React from 'react'
import { Button } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render remove model button
 *
 * @param {Object} id
 * @param {Function} action
 * @param {boolean} disabled
 *
 * @return {*}
 * @constructor
 */
export const RemoveModelButton = ({ id, action, disabled }) => (
	<div className={[styles.removeBtn, disabled && styles.disabled].join(' ')}>
		<RemoveModel
			id={id}
			action={action}
			modelName="Удалить"
			actionName="удалить"
		>
			<Button color="danger">
				Удалить
			</Button>
		</RemoveModel>
	</div>
)

RemoveModelButton.propTypes = {
	action:   PropTypes.func,
	disabled: PropTypes.bool,
	id:       PropTypes.object,
}

RemoveModelButton.defaultProps = {
	action:   () => null,
	disabled: false,
	id:       {},
}
