import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import styles from 'forms/authorization/methods/fields/Attributes/styles.module.scss'
import { SearchList } from 'forms/fields'
import _ from 'lodash'
import { AuthorizationAttributes } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { Input } from 'reactstrap'

const emptyList = [AuthorizationAttributes.create({})]

/**
 * Render items field
 *
 * @param {Function} setState
 * @param {number} value
 * @param {Object} field
 * @param {Function} getSearchList
 * @param {Object} searchListState
 *
 * @return {*}
 * @constructor
 */
export const AttributesField = ({
	setState,
	value,
	field,
	getSearchList,
	searchListState,
}) => {
	const { id, title } = field

	const [inputValue, setInputValue]               = useState('')
	const [currentAttributes, setCurrentAttributes] = useState(emptyList)
	const [isResultShown, setIsResultShown]         = useState(false)
	const [isInputDisabled, setIsInputDisabled]     = useState(false)

	const inputRef = useRef()

	const getSearchQuery = useCallback(searchString => DataProvider.buildQuery()
		.addBody({
			query: {
				filter: {
					...((typeof searchString === 'string' && {
						or: [
							{ alias: { like: `%${searchString}%` } },
							{ name: { like: `%${searchString}%` } },
						],
					}) || { id: searchString }),
				},
			},
		})
		.setSuccessCallback(res => {
			if (typeof searchString === 'number') {
				setIsInputDisabled(false)
				setInputValue(AuthorizationAttributes.createList(res)[0]?.getName?.() ?? '')
			} else {
				setCurrentAttributes(AuthorizationAttributes.createList(res))
			}
		}), [])

	const fetchSearchAttributes = useCallback(_.debounce(searchString => {
		if (!searchString) {
			setCurrentAttributes(emptyList)
		} else {
			getSearchList(getSearchQuery(searchString))
		}
	}, 400), [])

	const fetchList = useCallback(searchString => {
		setInputValue(searchString)
		fetchSearchAttributes(searchString)
	}, [fetchSearchAttributes])

	useEffect(() => {
		if (value && !inputValue.length) {
			setIsInputDisabled(true)
			setInputValue('Загрузка атрибутов...')
			fetchSearchAttributes(value)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value, fetchSearchAttributes])

	const setValues = useCallback(attributeId => {
		setState(id, attributeId)
	}, [id, setState])

	const checkAttributes = useCallback(attribute => {
		setInputValue(attribute.getName())
		setIsResultShown(false)
		setValues(attribute.getId())
	}, [setValues])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<Input
				type="text"
				value={inputValue}
				className={(isResultShown && !!inputValue.length && styles.active) || ''}
				onChange={event => fetchList(event.target.value)}
				onFocus={() => setIsResultShown(true)}
				onBlur={() => setTimeout(() => setIsResultShown(false), 200)}
				innerRef={inputRef}
				disabled={isInputDisabled}
				placeholder={`${title}...`}
			/>
			<SearchList
				inputValue={inputValue}
				isFetching={StateService.create(searchListState).isFetching()}
				isActive={isResultShown && !!inputValue.length}
				items={currentAttributes}
				action={checkAttributes}
			/>
		</div>
	)
}

AttributesField.propTypes = {
	field:           PropTypes.object,
	getSearchList:   PropTypes.any,
	searchListState: PropTypes.any,
	setState:        PropTypes.func,
	value:           PropTypes.number,
}

AttributesField.defaultProps = {
	field:           {},
	getSearchList:   () => null,
	searchListState: {},
	setState:        () => null,
	value:           null,
}
