import styles from 'components/project/ServiceConfig/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render service details wrapper
 *
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const MicroserviceWrapper = ({ children }) => (
	<div className={styles.wrapper}>
		<div className={styles.title}>
			Управление сервисом
		</div>
		{children}
	</div>
)

MicroserviceWrapper.propTypes = {
	children: PropTypes.node,
}

MicroserviceWrapper.defaultProps = {
	children: null,
}
