import authActionsSaga from 'reduxm/redux-saga/modules/authentication/actions/sagas'
import authBearerSaga from 'reduxm/redux-saga/modules/authentication/bearer/sagas'
import authJwtSaga from 'reduxm/redux-saga/modules/authentication/jwt/sagas'
import authorizationSaga from 'reduxm/redux-saga/modules/authorization/actions/sagas'
import authorizationAttributesSaga from 'reduxm/redux-saga/modules/authorization/attributes/sagas'
import authorizationFiltersSaga from 'reduxm/redux-saga/modules/authorization/filters/sagas'
import authorizationMethodsSaga from 'reduxm/redux-saga/modules/authorization/methods/sagas'
import authorizationPermissionsSaga from 'reduxm/redux-saga/modules/authorization/permissions/sagas'
import authorizationRolesSaga from 'reduxm/redux-saga/modules/authorization/roles/sagas'
import filesSaga from 'reduxm/redux-saga/modules/control-panel/files/sagas'
import projectsSaga from 'reduxm/redux-saga/modules/control-panel/projects/sagas'
import servicesSaga from 'reduxm/redux-saga/modules/control-panel/services/sagas'
import authenticationConfigSaga from 'reduxm/redux-saga/modules/control-panel/settings/authentication/sagas'
import awsCredentialsSaga from 'reduxm/redux-saga/modules/control-panel/settings/aws/sagas'
import firebaseConfigSaga from 'reduxm/redux-saga/modules/control-panel/settings/firebase/sagas'
import mailCredentialsSaga from 'reduxm/redux-saga/modules/control-panel/settings/mail/sagas'
import mySqlCredentialsSaga from 'reduxm/redux-saga/modules/control-panel/settings/mysql/sagas'
import redisCredentialsSaga from 'reduxm/redux-saga/modules/control-panel/settings/redis/sagas'
import serviceSettingsSagas from 'reduxm/redux-saga/modules/control-panel/settings/service/sagas'
import socialSaga from 'reduxm/redux-saga/modules/control-panel/settings/social/sagas'
import sitesSagas from 'reduxm/redux-saga/modules/control-panel/sites/sagas'
import geoCitiesSaga from 'reduxm/redux-saga/modules/geo/cities/sagas'
import userSaga from 'reduxm/redux-saga/modules/users/actions/sagas'
import usersManageSaga from 'reduxm/redux-saga/modules/users/manage/sagas'
import usersManageSettingsSaga from 'reduxm/redux-saga/modules/users/manage/settings/sagas'

export default [
	...projectsSaga,
	...servicesSaga,
	...sitesSagas,
	...authorizationSaga,
	...userSaga,
	...mySqlCredentialsSaga,
	...redisCredentialsSaga,
	...firebaseConfigSaga,
	...mailCredentialsSaga,
	...awsCredentialsSaga,
	...authenticationConfigSaga,
	...authorizationRolesSaga,
	...authorizationAttributesSaga,
	...authorizationMethodsSaga,
	...authorizationFiltersSaga,
	...serviceSettingsSagas,
	...usersManageSaga,
	...usersManageSettingsSaga,
	...geoCitiesSaga,
	...authorizationPermissionsSaga,
	...filesSaga,
	...socialSaga,
	...authActionsSaga,
	...authBearerSaga,
	...authJwtSaga,
]
