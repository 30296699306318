import styles from 'components/global/TabsTable/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { NavItem } from 'reactstrap'

/**
 * Render tab item
 *
 * @param {string} to
 * @param {boolean} isActive
 * @param {string} title
 *
 * @return {*}
 * @constructor
 */
export const TabsNavItem = ({ to, isActive, title }) => (
	<NavItem className={styles.navItem}>
		<Link
			to={to}
			className={[isActive && styles.active, styles.navLink].join(' ')}
		>
			{title}
		</Link>
	</NavItem>
)

TabsNavItem.propTypes = {
	isActive: PropTypes.bool,
	title:    PropTypes.string,
	to:       PropTypes.string,
}

TabsNavItem.defaultProps = {
	isActive: false,
	title:    '',
	to:       '',
}
