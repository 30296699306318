import styles from 'components/global/Popover/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render popover
 *
 * @param {boolean} isOpen
 * @param {string} title
 * @param {Array} body
 * @param {number} body.id
 * @param {string} body.text
 *
 * @return {*}
 * @constructor
 */
export const Popover = ({ isOpen, title, body }) => (
	<div className={[styles.container, isOpen && styles.active].join(' ')}>
		<div className={styles.title}>{title}</div>
		<div className={styles.body}>
			{body.map(item => (
				<p className={styles.infoItem} key={item.id}>{item.text}</p>
			))}
		</div>
	</div>
)

Popover.propTypes = {
	body:   PropTypes.array,
	isOpen: PropTypes.bool,
	title:  PropTypes.string,
}

Popover.defaultProps = {
	body:   [],
	isOpen: false,
	title:  '',
}
