import { RolesTab } from 'components/project/ServiceConfig/Authorization/SettingTabs/Roles/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { authorization: { roles: { list, create, update, remove, view } } } = StateSelector

const mapStateToProps = state => ({
	roleState:    view(state),
	rolesState:   list(state),
	isFetching:   _(list(state), create(state), update(state), remove(state), view(state)),
	projectState: StateSelector.controlPanel.projects.view(state),
})

const mapDispatchToProps = {
	getRoles:   DispatchSelector.authorization.roles.list,
	getRole:    DispatchSelector.authorization.roles.view,
	createRole: DispatchSelector.authorization.roles.create,
	updateRole: DispatchSelector.authorization.roles.update,
	removeRole: DispatchSelector.authorization.roles.remove,
}

export default connect(mapStateToProps, mapDispatchToProps)(RolesTab)
