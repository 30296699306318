import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'


/**
 * Render title with breadcrumbs if it need
 *
 * @param {string} name
 * @param {boolean} breadcrumbs
 *
 * @return {*}
 * @constructor
 */
export const Title = ({ name, breadcrumbs }) => {
	const initialData = [{
		title: 'Главная',
		link:  '/',
	}]
	let id            = 1

	initialData.push({
		title: name,
		link:  '',
	})

	return (
		<div className={styles.title}>
			{name}
			{breadcrumbs &&
			<div className={styles.crumbWrapper}>
				{initialData.map((crumb, idx) => (
					<div className={styles.crumbItem} key={id += 1}>
						{idx < initialData.length - 1 ?
							<Link className={styles.link} to={crumb.link}>
								{crumb.title}
							</Link> : crumb.title}
					</div>))}
			</div>}
		</div>
	)
}

Title.propTypes = {
	breadcrumbs: PropTypes.bool,
	name:        PropTypes.string,
}

Title.defaultProps = {
	breadcrumbs: false,
	name:        '',
}
