import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render validation error message
 *
 * @param {string} message
 *
 * @return {*}
 * @constructor
 */
export const ValidationError = ({ message }) => (
	<div className={styles.message}>
		{message}
	</div>
)

ValidationError.propTypes = {
	message: PropTypes.string,
}

ValidationError.defaultProps = {
	message: '',
}
