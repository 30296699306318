import { HelpOutlineOutlined as Info } from '@material-ui/icons'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

const fargateDocLink = 'https://aws.amazon.com/ru/fargate/pricing/#Supported_Configurations'

/**
 * Render fargate doc link
 *
 * @param {string} serviceType
 *
 * @return {*}
 * @constructor
 */
export const FargateDocLink = ({ serviceType }) => (
	<a
		rel="noreferrer noopener"
		target="_blank"
		href={fargateDocLink}
		className={[styles.link, serviceType === 'FARGATE' && styles.active].join(' ')}
	>
		<Info />
		Ознакомиться с распределением ресурсов
	</a>
)

FargateDocLink.propTypes = {
	serviceType: PropTypes.string,
}

FargateDocLink.defaultProps = {
	serviceType: '',
}
