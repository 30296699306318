import React from 'react'
import { timezonesList } from './timezonesList'

/**
 * Render timezone options
 *
 * @return {*[]}
 * @constructor
 */
export const TimezoneOptions = () => timezonesList.map(timezone => {
	const { title, ...otherProps } = timezone

	return <option {...otherProps}>{title}</option>
})
