import { ConfigTab } from 'components/global/ConfigTab'
import { firebaseConfigFields } from 'forms/control-panel/settings/firebase'
import {
	FirebaseConfig,
	Project,
} from 'models'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render firebase config setting tab
 *
 * @param {Object} props
 *
 * @return {*}
 * @constructor
 */
export const FirebaseConfigTab = props => (
	<ConfigTab
		fields={firebaseConfigFields}
		model={FirebaseConfig}
		tabName="firebase"
		{...props}
	/>
)

FirebaseConfigTab.propTypes = {
	project:      PropTypes.instanceOf(Project),
	createConfig: PropTypes.func,
	getConfig:    PropTypes.func,
	removeConfig: PropTypes.func,
	updateConfig: PropTypes.func,
	configState:  PropTypes.object,
	isFetching:   PropTypes.bool,
}

FirebaseConfigTab.defaultProps = {
	project:      Project.create({}),
	createConfig: () => null,
	getConfig:    () => null,
	removeConfig: () => null,
	updateConfig: () => null,
	configState:  {},
	isFetching:   false,
}
