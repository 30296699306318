import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	ServicesActions,
	ServicesSelectors,
} from 'reduxm/redux-saga/modules/control-panel/services'
import { SERVICES_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/services/actionTypes'
import { ServiceApi } from 'reduxm/redux-saga/modules/control-panel/services/api'

/**
 *  Get services list
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, ServiceApi.list)
}

/**
 * View service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, ServiceApi.view)
}

/**
 * Remove service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, ServiceApi.remove, null, null,
		function* (...params) {
			yield ResponseHandler.afterRemoveModel(...params, ServicesSelectors.list, ServicesActions.setList)
		})
}

/**
 * Updated service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, ServiceApi.update, null, null,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, ServicesSelectors, ServicesActions)
		})
}

/**
 * Create service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, ServiceApi.create, null, null,
		function* (...params) {
			yield ResponseHandler.afterCreateModel(...params, ServicesSelectors.list, ServicesActions.setList)
		})
}

/**
 * Get project services info
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* info(action) {
	yield defaultAction(action, ServiceApi.info)
}

/**
 * Create new project service deployment
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* createDeployment(action) {
	yield defaultAction(action, ServiceApi.createDeployment)
}

/**
 * Change project service task count
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* changeTaskCount(action) {
	yield defaultAction(action, ServiceApi.changeTaskCount)
}

export default [
	takeLatest(SERVICES_ACTIONS.LIST, list),
	takeLatest(SERVICES_ACTIONS.CREATE, create),
	takeLatest(SERVICES_ACTIONS.REMOVE, remove),
	takeLatest(SERVICES_ACTIONS.UPDATE, update),
	takeLatest(SERVICES_ACTIONS.VIEW, view),
	takeLatest(SERVICES_ACTIONS.INFO, info),
	takeLatest(SERVICES_ACTIONS.CREATE_DEPLOYMENT, createDeployment),
	takeLatest(SERVICES_ACTIONS.CHANGE_TASK_COUNT, changeTaskCount),
]
