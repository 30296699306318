import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { ServiceOptions } from './index'

const { controlPanel: { services: { list }, projects: { view } } } = StateSelector

const mapStateToProps = state => ({
	servicesState: list(state),
	projectState:  view(state),
})

const mapDispatchToProps = {
	getServices: DispatchSelector.controlPanel.services.list,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServiceOptions)
