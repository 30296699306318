import { GatewayActions } from 'components/gateway/GatewayActions'
import { Spinner } from 'components/global/Spinner'
import { Title } from 'components/global/Title'
import { TableToggle } from 'components/home/TableToggle'
import PropTypes from 'prop-types'
import React from 'react'
import {
	Col,
	Row,
} from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render gateway page
 *
 * @param {Function} updateGateway
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const GatewayPage = ({ updateGateway, isFetching }) => (
	<>
		<Spinner block isFetching={isFetching} />
		<TableToggle id="gatewayPage" />
		<Title name="Управление шлюзом" />
		<section className={styles.section}>
			<Row>
				<Col xs={12} md={6}>
					<GatewayActions updateGateway={updateGateway} />
				</Col>
			</Row>
		</section>
	</>
)

GatewayPage.propTypes = {
	isFetching:    PropTypes.bool,
	updateGateway: PropTypes.func,
}

GatewayPage.defaultProps = {
	isFetching:    false,
	updateGateway: () => null,
}
