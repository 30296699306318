import Drawer from '@material-ui/core/Drawer'
import { AddSharp } from '@material-ui/icons'
import { AsidePanel } from 'components/global/AsidePanel'
import { ModelForm } from 'components/global/AsidePanel/ModelForm'
import { Button } from 'components/global/Button'
import styles from 'components/global/CreateModelButton/styles.module.scss'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'

/**
 * Render Create model component
 *
 * @param {function} action
 * @param {Array} formFields
 * @param {string} title
 * @param {Object} initialData
 * @param {Function} callBack
 *
 * @return {*}
 * @constructor
 */
export const CreateModelButton = ({
	action,
	formFields,
	title,
	initialData,
	callBack,
}) => {
	const [isActive, setIsActive] = useState(false)

	const close = useCallback(() => {
		setIsActive(false)

		if (callBack) {
			callBack()
		}
	}, [callBack])

	return (
		<>
			<Button onClick={() => setIsActive(true)} className={styles.button}>
				<AddSharp />
				<div className={styles.title}>{title}</div>
			</Button>
			<Drawer
				anchor="right"
				open={isActive}
				onClose={close}
			>
				<AsidePanel title={title}>
					<ModelForm
						initialData={initialData}
						fields={formFields}
						action={action}
						close={close}
					/>
				</AsidePanel>
			</Drawer>
		</>
	)
}

CreateModelButton.propTypes = {
	action:      PropTypes.func.isRequired,
	callBack:    PropTypes.func,
	formFields:  PropTypes.array.isRequired,
	initialData: PropTypes.object,
	title:       PropTypes.string,
}

CreateModelButton.defaultProps = {
	callBack:    null,
	initialData: {},
	title:       '',
}
