import { DeleteForever } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { RemoveModal } from 'components/global/RemoveModel/RemoveModal'
import styles from 'components/global/RemoveModel/styles.module.scss'
import { Tip } from 'components/global/Tip'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render remove model component
 *
 * @param {function} action
 * @param {string} actionName
 * @param {Object} id
 * @param {string} modelName
 * @param {Node} children
 * @param {boolean} tip Show tip if it needed
 *
 * @return {*}
 * @constructor
 */
export const RemoveModel = ({
	action,
	actionName,
	id,
	modelName,
	children,
	tip,
}) => (
	<Tip title={tip ? 'Удалить' : ''}>
		<div className={styles.modalWrapper}>
			<RemoveModal
				action={action}
				id={id}
				modelName={modelName}
				actionName={actionName}
			>
				{children ||
				<Button className={styles.iconWrapper}>
					<DeleteForever className={styles.removeIcon} />
				</Button>}
			</RemoveModal>
		</div>
	</Tip>
)

RemoveModel.propTypes = {
	action:     PropTypes.func.isRequired,
	actionName: PropTypes.string.isRequired,
	id:         PropTypes.object.isRequired,
	modelName:  PropTypes.string.isRequired,
	children:   PropTypes.node,
	tip:        PropTypes.bool,
}

RemoveModel.defaultProps = {
	children: null,
	tip:      false,
}
