import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import {
	call,
	put,
	takeLatest,
} from 'redux-saga/effects'
import {
	getJwtRefreshToken,
	saveAuthTokens,
} from 'reduxm/redux-saga/api'
import { AUTH_JWT } from './actionTypes'
import { AuthJwtApi } from './api'

/**
 * Renew auth tokens
 *
 * @param {Object} action
 *
 * @return {Generator<Promise<string>|<"CALL">, void, *>}
 */
function* renewToken(action) {
	const { payload, type }           = action
	const { request, success, error } = RequestActionHelper.getAllActions(type)
	const searchQuery                 = DataProvider
		.getSearchQueryBody(payload)
		.addRequestOptions({
			headers: {
				'Authorization-Refresh': yield getJwtRefreshToken(),
			},
		}, true)

	try {
		yield put(request(searchQuery))

		const response = yield call(AuthJwtApi.renewToken, searchQuery)

		// Check response headers
		if (!response?.result?.jwtAccess) {
			throw new Error('Ошибка обновления аутентификации. Отсутствуют токены')
		}

		yield saveAuthTokens(null, response.result)

		yield put(success())

		searchQuery.runSuccessCallback(response)
	} catch (e) {
		yield put(error(searchQuery.addReduxRequestParams({ error: e })))

		searchQuery.runErrorCallback(e)
	}

	searchQuery.runCallback()
}

export default [
	takeLatest(AUTH_JWT.RENEW_TOKEN, renewToken),
]
