import SearchQuery from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider/SearchQuery'
import _ from 'lodash'
import {
	call,
	put,
	select,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { ProjectsActions } from 'reduxm/redux-saga/modules/control-panel/projects/actionCreators'
import { ProjectsSelectors } from 'reduxm/redux-saga/modules/control-panel/projects/actionSelectors'
import { PROJECTS_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/projects/actionTypes'
import { ProjectApi } from 'reduxm/redux-saga/modules/control-panel/projects/api'
import ProjectResponseHandler from 'reduxm/redux-saga/modules/control-panel/projects/ResponseHandler'

/**
 * Get project models list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, ProjectApi.list)
}

/**
 * View project model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, ProjectApi.view)
}

/**
 * Create project model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, ProjectApi.create, null, null,
		function* (responseData, searchQuery) {
			yield ResponseHandler.afterCreateModel(responseData, searchQuery, ProjectsSelectors.list,
				ProjectsActions.setList)

			const { result: copyProjectQuery } = yield select(ProjectsSelectors.copyProjectQuery)

			const projectToId = responseData?.result?.model?.id

			if (copyProjectQuery instanceof SearchQuery && projectToId) {
				copyProjectQuery.addBody({ projectToId }, true)

				yield call(ProjectApi.copyProjectSettings, copyProjectQuery)
				yield put(ProjectsActions.setCopyProjectQuery(null))
			}
		})
}

/**
 * Update project model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, ProjectApi.update, null, null,
		function* (...params) {
			const [response] = params

			const { result: { model: nextModel } = {} } = response

			if (nextModel) {
				let prevModel

				const { result: { list: prevList } } = yield select(ProjectsSelectors.list)

				if (!prevList.length) {
					const { result: { model } } = yield select(ProjectsSelectors.view)

					prevModel = model
				} else {
					prevModel = prevList.find(model => model.id === nextModel.id)
				}

				if (prevModel) {
					const { domain, otherDomains }                               = nextModel
					const { domain: prevDomain, otherDomains: prevOtherDomains } = prevModel

					if (domain !== prevDomain || !_.isEqual(otherDomains, prevOtherDomains)) {
						yield put(ProjectsActions.updateGateway())
					}
				}
			}

			yield ResponseHandler.afterUpdateModel(...params, ProjectsSelectors, ProjectsActions)
		})
}

/**
 * Remove project model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, ProjectApi.remove, null, null,
		function* (...params) {
			yield ResponseHandler.afterRemoveModel(...params, ProjectsSelectors.list, ProjectsActions.setList)
		})
}

/**
 * Update gateway.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* updateGateway(action) {
	yield defaultAction(action, ProjectApi.updateGateway)
}

/**
 * Bind service to project model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* connectService(action) {
	yield defaultAction(action, ProjectApi.connectService, null, null,
		function* (...params) {
			yield ProjectResponseHandler
				.afterConnectService(...params, ProjectsSelectors.view, ProjectsActions.setView)
		})
}

/**
 * Disconnect service model from project.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* disconnectService(action) {
	yield defaultAction(action, ProjectApi.disconnectService, null, null,
		function* (...params) {
			yield ProjectResponseHandler
				.afterDisconnectService(...params, ProjectsSelectors.view, ProjectsActions.setView)
		})
}

/**
 * Get project models search list.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* searchList(action) {
	yield defaultAction(action, ProjectApi.list)
}

/**
 * Copy project settings.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* copyProjectSettings(action) {
	yield defaultAction(action, ProjectApi.copyProjectSettings)
}

export default [
	takeLatest(PROJECTS_ACTIONS.LIST, list),
	takeLatest(PROJECTS_ACTIONS.UPDATE, update),
	takeLatest(PROJECTS_ACTIONS.REMOVE, remove),
	takeLatest(PROJECTS_ACTIONS.CREATE, create),
	takeLatest(PROJECTS_ACTIONS.VIEW, view),
	takeLatest(PROJECTS_ACTIONS.UPDATE_GATEWAY, updateGateway),
	takeLatest(PROJECTS_ACTIONS.CONNECT_SERVICE, connectService),
	takeLatest(PROJECTS_ACTIONS.DISCONNECT_SERVICE, disconnectService),
	takeLatest(PROJECTS_ACTIONS.SEARCH_LIST, searchList),
	takeLatest(PROJECTS_ACTIONS.COPY_PROJECT_SETTINGS, copyProjectSettings),
]
