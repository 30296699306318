import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	update:       'users.manage.settings.update',
	view:         'users.manage.settings.view',
	uploadAvatar: 'users.manage.settings.upload-avatar',
	deleteAvatar: 'users.manage.settings.delete-avatar',
}

/**
 * Call api to update user settings model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {function}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to view user settings model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

/**
 * Call api to upload user avatar to settings model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const uploadAvatar = searchQuery => callApi(methods.uploadAvatar, searchQuery)

/**
 * Call api to delete user avatar from settings model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const deleteAvatar = searchQuery => callApi(methods.deleteAvatar, searchQuery)

export const UsersManageSettingsApi = {
	view,
	update,
	uploadAvatar,
	deleteAvatar,
}
