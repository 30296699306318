import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { UserConfig } from './index'

const mapStateToProps = state => ({
	serviceConfigState: StateSelector.controlPanel.settings.service.view(state),
})

const mapDispatchToProps = {
	getServiceConfig:   DispatchSelector.controlPanel.settings.service.view,
	uploadDefaultImage: DispatchSelector.users.manageSettings.uploadDefaultImage,
	removeDefaultImage: DispatchSelector.users.manageSettings.removeDefaultImage,
	updateConfig:       DispatchSelector.users.manageSettings.updateConfig,
}

export default connect(mapStateToProps, mapDispatchToProps)(UserConfig)
