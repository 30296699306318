import PropTypes from 'prop-types'
import React from 'react'
import { Alert } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render sign in error message
 *
 * @param {string} message
 *
 * @return {*}
 * @constructor
 */
export const SignInHelper = ({ message }) => (
	<div className={styles.alert}>
		<Alert color="danger">
			<span>{message}</span>
		</Alert>
	</div>
)

SignInHelper.propTypes = {
	message: PropTypes.string,
}

SignInHelper.defaultProps = {
	message: '',
}
