import { ContactSupportRounded as Info } from '@material-ui/icons'
import { Popover } from 'components/global/Popover'
import { info } from 'forms/fields/JsonInput/PopoverInfo/info'
import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

/**
 * Render popover info component
 *
 * @param {Function} toggle
 * @param {boolean} isOpen
 *
 * @type {React.ForwardRefExoticComponent}
 */
export const PopoverInfo = forwardRef(({ toggle, isOpen }, ref) => (
	<div ref={ref}>
		<Info
			onClick={toggle}
			className="definition-info"
		/>
		<Popover
			title="Доступные переменные:"
			body={info}
			isOpen={isOpen}
		/>
	</div>
))

PopoverInfo.propTypes = {
	toggle: PropTypes.func,
	isOpen: PropTypes.bool,
}

PopoverInfo.defaultProps = {
	toggle: () => null,
	isOpen: false,
}
