import Checkbox from '@material-ui/core/Checkbox'
import {
	AddCircleOutlineRounded as Unchecked,
	CheckCircleRounded as Checked,
} from '@material-ui/icons'
import { withStyles } from '@material-ui/styles'
import React from 'react'

const color = '#009b00'

/**
 * Render custom colored checkbox
 *
 * @param {string} color
 *
 * @return {React.ComponentType}
 * @constructor
 */
export const CheckBox = withStyles({
	root:    { '&$checked': { color } },
	checked: {},
})(({ icon, checkedIcon, ...props }) => <Checkbox
	color="default"
	icon={icon || <Unchecked />}
	checkedIcon={checkedIcon || <Checked />}
	{...props}
/>)
