import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { PROJECT_SERVICE_SETTINGS_ACTIONS } from './actionTypes'

export const ServiceSettingsActions = {
	create:  RequestActionHelper.getActionCreatorFetch(PROJECT_SERVICE_SETTINGS_ACTIONS.CREATE),
	update:  RequestActionHelper.getActionCreatorFetch(PROJECT_SERVICE_SETTINGS_ACTIONS.UPDATE),
	view:    RequestActionHelper.getActionCreatorFetch(PROJECT_SERVICE_SETTINGS_ACTIONS.VIEW),
	remove:  RequestActionHelper.getActionCreatorFetch(PROJECT_SERVICE_SETTINGS_ACTIONS.REMOVE),
	setView: RequestActionHelper.getActionCreatorSuccess(PROJECT_SERVICE_SETTINGS_ACTIONS.VIEW),
}
