import { Button } from 'components/global/Button'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import PropTypes from 'prop-types'
import React, {
	useRef,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render pagination dropdown
 *
 * @param {number} currentPage
 * @param {Function} action
 * @param {Array} numbers
 *
 * @return {*}
 * @constructor
 */
export const PaginationDropdown = ({ currentPage, action, numbers }) => {
	const [isActive, setIsActive] = useState(false)

	const containerRef = useRef()

	useCheckOutsideClick(containerRef, isActive, setIsActive)

	return numbers.length > 0 && (
		<div ref={containerRef} className={styles.container}>
			<Button className={styles.currentPoint} onClick={() => setIsActive(!isActive)}>
				<div>{currentPage}</div>
				<div className={styles.up} />
			</Button>
			{isActive &&
			<div className={styles.collapsePoints}>
				{numbers.map(number => (
					<Button
						key={number}
						onClick={() => {
							if (number !== currentPage) {
								action(number)
								setIsActive(!isActive)
							}
						}}
						className={[styles.number,
							number === currentPage && styles.current,
							number % 5 === 0 && styles.roundPoint].join(' ')}
					>
						{number}
					</Button>
				))}
			</div>}
		</div>
	)
}

PaginationDropdown.propTypes = {
	currentPage: PropTypes.number,
	action:      PropTypes.func,
	numbers:     PropTypes.array,
}

PaginationDropdown.defaultProps = {
	currentPage: 1,
	action:      () => null,
	numbers:     [],
}
