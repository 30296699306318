import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import SignIn from 'containers/SignIn/index.store'
import { Wrapper } from 'containers/Wrapper'
import { User } from 'models'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
	Redirect,
	Route,
	Switch,
} from 'react-router-dom'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { Routes } from 'routing'

/**
 * Render Entry point component
 *
 * @param {Object} authState
 * @param {Object} userState
 * @param {function} getCurrentUser
 * @param {function} logOut
 * @param {Object} persistor
 *
 * @return {*}
 * @constructor
 */
const App = ({ authState, userState, getCurrentUser, logOut, persistor }) => {
	const user = User.create(userState)

	useEffect(() => {
		const searchQuery = DataProvider.buildQuery()
			.setErrorCallback(() => {
				logOut()
				persistor.purge()
			})

		getCurrentUser(searchQuery)
	}, [getCurrentUser, logOut, persistor])

	return (
		<Switch>
			<Route path="/sign-in" exact component={SignIn} />
			{(user.isSuperAdmin(authState) &&
				<Wrapper>
					<Routes />
				</Wrapper>) || <Redirect to="/sign-in" />}
		</Switch>
	)
}

const mapStateToProps    = state => ({
	userState: StateSelector.users.actions.current(state),
	authState: StateSelector.auth.actions.signIn(state),
})
const mapDispatchToProps = {
	getCurrentUser: DispatchSelector.users.actions.current,
	logOut:         DispatchSelector.auth.actions.logOut,
}

export default connect(mapStateToProps, mapDispatchToProps)(App)

App.propTypes = {
	persistor:      PropTypes.object.isRequired,
	userState:      PropTypes.object,
	authState:      PropTypes.object,
	getCurrentUser: PropTypes.func,
	logOut:         PropTypes.func,
}

App.defaultProps = {
	userState:      {},
	authState:      {},
	getCurrentUser: () => null,
	logOut:         () => null,
}
