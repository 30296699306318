export const timezonesList = [
	{ key: 0, title: 'Выберите временную зону', value: '', disabled: true },
	{ key: 1, title: '(GMT -12:00) Эниветок, Кваджалейн', value: '-12: 00' },
	{ key: 2, title: '(GMT -11:00) Мидуэй, Самоа', value: '-11: 00' },
	{ key: 3, title: '(GMT -10 00) Гавайи', value: '-10:00' },
	{ key: 4, title: '(GMT -9:30) Таиоахе', value: '-09:50' },
	{ key: 5, title: '(GMT -9:00) Аляска', value: '-09:00' },
	{ key: 6, title: '(GMT -8:00) по тихоокеанскому времени (США и Канада)', value: '-08:00' },
	{ key: 7, title: '(GMT -7:00) Горное время (США и Канада)', value: '-07:00' },
	{ key: 8, title: '(GMT -6:00) Центральное время (США и Канада), Мехико', value: '-06:00' },
	{ key: 9, title: '(GMT -5: 00) по восточному времени (США и Канада), Богота, Лима', value: '-05:00' },
	{ key: 10, title: '(GMT -4:30) Каракас', value: '-04:50' },
	{ key: 11, title: '(GMT -4:00) Атлантическое время (Канада), Каракас, Ла-Пас', value: '-04:00' },
	{ key: 12, title: '(GMT -3:30) Ньюфаундленд', value: '-03:50' },
	{ key: 13, title: '(GMT -3:00) Бразилия, Буэнос-Айрес, Джорджтаун', value: '-03:00' },
	{ key: 14, title: '(GMT -2:00) Северная-Атлинтика', value: '-02:00' },
	{ key: 15, title: '(GMT -: 00) Азорские острова, острова Кабо-Верде', value: '-01:​0' },
	{ key: 16, title: '(GMT) Западноевропейское время, Лондон, Лиссабон, Касабланка', value: '+00:00' },
	{ key: 17, title: '(GMT +1:00) Брюссель, Копенгаген, Мадрид, Париж', value: '+01:00' },
	{ key: 18, title: '(GMT +2:00) Калининград, Южная Африка', value: '+02:00' },
	{ key: 19, title: '(GMT +3:00) Минск, Эр-Рияд, Москва, Санкт-Петербург', value: '+03:00' },
	{ key: 20, title: '(GMT +3:30) Тегеран', value: '+03:50' },
	{ key: 21, title: '(GMT +4:00) Абу-Даби, Маскат, Баку, Тбилиси', value: '+04:00' },
	{ key: 22, title: '(GMT +4:30) Кабул', value: '+04:30' },
	{ key: 23, title: '(GMT +5:00) Екатеринбург, Исламабад, Карачи, Ташкент', value: '+05:00' },
	{ key: 24, title: '(GMT +5:30) Бомбей, Калькутта, Мадрас, Нью-Дели', value: '+05:50' },
	{ key: 25, title: '(GMT +5:45) Катманду, Покхара', value: '+05:75' },
	{ key: 26, title: '(GMT +6:00) Алматы, Дакка, Коломбо', value: '+06:00' },
	{ key: 27, title: '(GMT +6:30) Янгон, Мандалай', value: '+06:50' },
	{ key: 28, title: '(GMT +7:00) Бангкок, Ханой, Джакарта', value: '+07:00' },
	{ key: 29, title: '(GMT +8:00) Пекин, Перт, Сингапур, Гонконг', value: '+08:00' },
	{ key: 30, title: '(GMT +8:45) Еукла', value: '+08:75' },
	{ key: 31, title: '(GMT +9:00) Токио, Сеул, Осака, Саппоро, Якутск', value: '+09:00' },
	{ key: 32, title: '(GMT +9:30) Аделаида, Дарвин', value: '+09:50' },
	{ key: 33, title: '(GMT +10 00) Восточная Австралия, Гуам, Владивосток', value: '+10:00' },
	{ key: 34, title: '(GMT +10 30) Остров Лорд-Хау', value: '+10:50' },
	{ key: 35, title: '(GMT +11 00) Магадан, Соломоновы Острова, Новая Каледония', value: '+11:00' },
	{ key: 36, title: '(GMT +11 30) Остров Норфолк', value: '+11:50' },
	{ key: 37, title: '(GMT +12 00) Окленд, Веллингтон, Фиджи, Камчатка', value: '+12:00' },
	{ key: 38, title: '(GMT +12 45) Острова Чатем', value: '+12:75' },
	{ key: 39, title: '(GMT +13 00) Апиа, Нукуалофа', value: '+13:00' },
	{ key: 40, title: '(GMT +14 00) Линии островов, Токелау', value: '+14:00' }]
