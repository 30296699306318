import { connect } from 'react-redux'
import { DispatchSelector } from 'reduxm/modules/selectors'
import { ConnectServiceForm } from './index'

const {
		  controlPanel: {
		settings: { service: { create: createServiceConfig } },
		projects: { connectService },
	},
	  } = DispatchSelector

const mapDispatchToProps = { createServiceConfig, connectService }

export default connect(null, mapDispatchToProps)(ConnectServiceForm)
