import { CardContent } from 'components/project/Home/Cards/CardContent'
import { CardHeader } from 'components/project/Home/Cards/ServiceCard/ServiceCardHeader'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render full info card
 *
 * @param {Service} service
 *
 * @return {*}
 * @constructor
 */
export const ServiceFullInfoCard = ({ service }) => (
	<div className={styles.wrapper}>
		<CardHeader model={service} type="microservice" />
		<CardContent fullInfo model={service} />
	</div>
)

ServiceFullInfoCard.propTypes = {
	service: PropTypes.object,
}

ServiceFullInfoCard.defaultProps = {
	service: {},
}
