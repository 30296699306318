import SearchQuery from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider/SearchQuery'
import Drawer from '@material-ui/core/Drawer'
import { AsidePanel } from 'components/global/AsidePanel'
import CopyProjectSettings from 'forms/control-panel/project/fields/CopyProjectSettings/index.store'
import Project from 'models/control-panel/Project'
import PropTypes from 'prop-types'
import React, {
	useMemo,
	useState,
} from 'react'
import { Button } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render copy settings from
 *
 * @param {Object} projectState
 * @param {Function} copyProjectQuery
 * @param {Function} copyProjectSettings
 * @param {Object} children
 *
 * @return {*}
 * @constructor
 */
export const CopySettingsFrom = ({
	projectState,
	copyProjectQuery,
	copyProjectSettings,
	children,
}) => {
	const [isActive, setIsActive] = useState(false)

	const close = () => setIsActive(false)

	const projectToId = useMemo(() => Project.create(projectState).getId(), [projectState])

	const { result: searchQuery } = copyProjectQuery

	const copySettings = () => copyProjectSettings(searchQuery
		.addBody({ projectToId }, true)
		.setSuccessCallback(close))

	return (
		<div className={styles.menuWrapper}>
			{React.cloneElement(children, { onClick: () => setIsActive(true) })}
			<Drawer
				anchor="right"
				open={isActive}
				onClose={close}
			>
				<AsidePanel title="Копировать настройки из проекта">
					<CopyProjectSettings isEditing />
					<div className={styles.btnsGroup}>
						<Button
							onClick={copySettings}
							disabled={!(searchQuery instanceof SearchQuery)}
							color="success"
						>
							Копировать
						</Button>
						<Button onClick={close} color="danger">
							Отмена
						</Button>
					</div>
				</AsidePanel>
			</Drawer>
		</div>
	)
}

CopySettingsFrom.propTypes = {
	children:            PropTypes.node.isRequired,
	copyProjectQuery:    PropTypes.object,
	projectState:        PropTypes.object,
	copyProjectSettings: PropTypes.func,
}

CopySettingsFrom.defaultProps = {
	copyProjectQuery:    {},
	projectState:        {},
	copyProjectSettings: () => null,
}
