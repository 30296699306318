import { UsersTab } from 'components/project/ServiceConfig/Users/SettingTabs/Users/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const {
		  users: {
					 manage:         { list, create, update, view },
					 manageSettings: { update: updateSettings, uploadAvatar, deleteAvatar, prepareAvatar },
				 },
		  auth:  { bearer: { view: bearerView, remove: bearerRemove, create: bearerCreate } },
	  } = StateSelector

const mapStateToProps = state => ({
	userState:      view(state),
	usersState:     list(state),
	preparedAvatar: prepareAvatar(state),
	isFetching:     _(list(state), create(state), update(state), view(state), updateSettings(state),
		uploadAvatar(state), deleteAvatar(state), bearerView(state), bearerRemove(state), bearerCreate(state)),
})

const mapDispatchToProps = {
	getUsers:   DispatchSelector.users.manage.list,
	updateUser: DispatchSelector.users.manage.update,
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersTab)
