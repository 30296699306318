import { DeleteOutlineRounded as Remove } from '@material-ui/icons'
import React from 'react'
import ReactDOMServer from 'react-dom/server'

export const previewTemplate = ReactDOMServer.renderToStaticMarkup(
	<div className="dz-preview dz-file-preview">
		<div className="dz-image-wrapper">
			<div className="dz-remove-btn-wrapper">
				<Remove id="dz-remove-btn" alt="Удалить файл" data-dz-remove />
			</div>
			<div id="dz-progress-wrapper" className="hidden">
				{/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
				<div
					id="progress-bar"
					className="progress-bar progress-bar-animated bg-success progress-bar-striped"
					style={{ width: '0%' }}
					role="progressbar"
					aria-valuenow="0"
					aria-valuemin="0"
					aria-valuemax="100"
				/>
				<div id="progress-bar-value">0%</div>
			</div>
			<img className="dz-image-item" data-dz-thumbnail alt="preview" />
		</div>
		<div className="dz-success-mark">
			<span>✔</span>
		</div>
		<div className="dz-error-mark">
			<span>✘</span>
		</div>
		<div className="dz-error-message">
			<span data-dz-errormessage />
		</div>
	</div>,
)
