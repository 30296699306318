import { MicroserviceConfig } from 'components/project/ServiceConfig/MicroserviceConfig/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { controlPanel: { settings: { service: { create, update, view } } } } = StateSelector

const mapStateToProps = state => ({
	serviceConfigState: view(state),
	isFetching:         _(create(state), update(state), view(state)),
})

const mapDispatchToProps = {
	getServiceConfig:    DispatchSelector.controlPanel.settings.service.view,
	createServiceConfig: DispatchSelector.controlPanel.settings.service.create,
	updateServiceConfig: DispatchSelector.controlPanel.settings.service.update,
	removeServiceConfig: DispatchSelector.controlPanel.settings.service.remove,
}

export default connect(mapStateToProps, mapDispatchToProps)(MicroserviceConfig)
