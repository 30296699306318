import {
	AttributesTab,
	ConfigTab,
	FilterTab,
	MethodsTab,
	PermissionsTab,
	RolesTab,
} from 'components/project/ServiceConfig/Authorization/SettingTabs'
import React from 'react'

/**
 * Get setting tabs to render table
 *
 * @return {Array}
 */
export const getSettingTabs = (services, projectId) => ([
	{ alias: 'role', title: 'Роли', component: <RolesTab /> },
	{ alias: 'methods', title: 'Методы', component: <MethodsTab services={services} /> },
	{ alias: 'attributes', title: 'Атрибуты', component: <AttributesTab /> },
	{ alias: 'filters', title: 'Фильтры', component: <FilterTab /> },
	{ alias: 'permissions', title: 'Доступ', component: <PermissionsTab /> },
	{ alias: 'config', title: 'Конфигурация', component: <ConfigTab projectId={projectId} /> },
])
