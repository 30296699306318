import { FiltersTab } from 'components/project/ServiceConfig/Authorization/SettingTabs/Filters/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { authorization: { filters: { list, create, update, remove, view } } } = StateSelector

const mapStateToProps = state => ({
	filtersState:     view(state),
	filtersListState: list(state),
	isFetching:       _(list(state), create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getFilters:     DispatchSelector.authorization.filters.view,
	getFiltersList: DispatchSelector.authorization.filters.list,
	createFilters:  DispatchSelector.authorization.filters.create,
	updateFilters:  DispatchSelector.authorization.filters.update,
	removeFilters:  DispatchSelector.authorization.filters.remove,
}

export default connect(mapStateToProps, mapDispatchToProps)(FiltersTab)
