import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { ProjectPage } from './index'

const {
		  controlPanel: {
		services: { list, changeTaskCount, createDeployment },
		settings: { service: { create: serviceCreate, update: updateService, view: viewService } },
		projects: { view, connectService, disconnectService, update },
		sites:    { list: siteList },
	},
	  } = StateSelector

const mapStateToProps = state => ({
	projectState:        StateSelector.controlPanel.projects.view(state),
	connectServiceState: connectService(state),
	servicesState:       StateSelector.controlPanel.services.list(state),
	sitesState:          StateSelector.controlPanel.sites.list(state),
	servicesInfoState:   StateSelector.controlPanel.services.info(state),
	isFetching:          _(view(state), list(state), connectService(state), update(state), serviceCreate(state),
		disconnectService(state), changeTaskCount(state), createDeployment(state), siteList(state),
		updateService(state), viewService(state)),
})

const mapDispatchToProps = {
	getProject:      DispatchSelector.controlPanel.projects.view,
	getServices:     DispatchSelector.controlPanel.services.list,
	changeTaskCount: DispatchSelector.controlPanel.services.changeTaskCount,
	getSites:        DispatchSelector.controlPanel.sites.list,
	getServicesInfo: DispatchSelector.controlPanel.services.info,
	updateProject:   DispatchSelector.controlPanel.projects.update,
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectPage)
