import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render pagination item
 *
 * @param {number} number
 * @param {number} currentPage
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const PaginationItem = ({ number, currentPage, action }) => (
	<Button
		key={number}
		className={[styles.point, currentPage === number && styles.active].join(' ')}
		onClick={() => currentPage !== number && action(number)}
	>
		{number}
	</Button>
)

PaginationItem.propTypes = {
	currentPage: PropTypes.number,
	action:      PropTypes.func,
	number:      PropTypes.number,
}

PaginationItem.defaultProps = {
	currentPage: 1,
	action:      () => null,
	number:      1,
}
