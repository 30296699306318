import {
	put,
	takeEvery,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import { ServiceSettingsActions } from './actionCreators'
import { PROJECT_SERVICE_SETTINGS_ACTIONS } from './actionTypes'
import { ServiceSettingsApi } from './api'

/**
 * Worker for create/update methods
 *
 * @param {Object} data
 * @param {Object} data.response Response data
 * @param {Object} data.result Firebase config model
 *
 * @return {Generator<*, void, ?>}
 * */
function* creationWorker(data) {
	const { response, result: { model } } = data

	yield put(ServiceSettingsActions.setView({ model, response }))
}

/**
 * View service settings model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, ServiceSettingsApi.view)
}

/**
 * Update service settings model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, ServiceSettingsApi.update, null, null, creationWorker)
}

/**
 * Create service settings model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, ServiceSettingsApi.create, null, null, creationWorker)
}

/**
 * Create service settings model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, ServiceSettingsApi.remove, null, null, function* (response) {
		if (response?.result?.result) {
			yield put(ServiceSettingsActions.setView(null))
		}
	})
}

export default [
	takeEvery(PROJECT_SERVICE_SETTINGS_ACTIONS.CREATE, create),
	takeEvery(PROJECT_SERVICE_SETTINGS_ACTIONS.UPDATE, update),
	takeLatest(PROJECT_SERVICE_SETTINGS_ACTIONS.VIEW, view),
	takeLatest(PROJECT_SERVICE_SETTINGS_ACTIONS.REMOVE, remove),
]
