export const getFile = data => new Promise(resolve => {
	const reader = new FileReader()

	reader.readAsDataURL(data)
	reader.onload = () => {
		resolve({
			name:     data.name,
			type:     data.type,
			preview:  URL.createObjectURL(data),
			base64:   reader.result,
			progress: 0,
		})
	}
})
