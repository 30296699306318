import SagasHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/SagasHelper'
import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { AUTHORIZATION_ACTIONS } from 'reduxm/redux-saga/modules/authorization/actions/actionTypes'
import { AuthorizationApi } from 'reduxm/redux-saga/modules/authorization/actions/api'

/**
 * Get user role
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* userRole(action) {
	yield SagasHelper.defaultAction(action, AuthorizationApi.userRole, null, ResponseHandler.addProjectOrigin)
}

/**
 * Set user role
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* setUserRole(action) {
	yield defaultAction(action, AuthorizationApi.setUserRole, null, ResponseHandler.addProjectOrigin)
}

/**
 * Update service config
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* updateServiceConfig(action) {
	yield defaultAction(action, AuthorizationApi.updateServiceConfig)
}

export default [
	takeLatest(AUTHORIZATION_ACTIONS.USER_ROLE, userRole),
	takeLatest(AUTHORIZATION_ACTIONS.SET_USER_ROLE, setUserRole),
	takeLatest(AUTHORIZATION_ACTIONS.UPDATE_SERVICE_CONFIG, updateServiceConfig),
]
