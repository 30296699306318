import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import {
	BuildOutlined as Build,
	EditRounded as Edit,
	DeleteForeverRounded as Remove,
	SystemUpdateOutlined as Update,
} from '@material-ui/icons'
import { CollapseHeight } from 'components/global/CollapseHeight'
import { CollapseHeightItem } from 'components/global/CollapseHeight/CollapseHeightItem'
import { RemoveModel } from 'components/global/RemoveModel'
import { ChangeTaskCount } from 'components/project/Home/Cards/ServiceCard/ServiceCardActions/ChangeTaskCount'
import { Notification } from 'helpers/Notification'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
	useState,
} from 'react'
import { Link } from 'react-router-dom'
import styles from './styles.module.scss'

/**
 * Render service actions
 *
 * @param {boolean} isOpen
 * @param {number} top
 * @param {number} right
 * @param {string} type
 * @param {Project} project
 * @param {Service} service
 * @param {Function} createDeployment
 * @param {Function} changeTaskCount
 * @param {Function} disconnectService
 * @param {number} taskCount
 * @param {Function} close
 *
 * @return {*}
 * @constructor
 */
export const ServiceCardActions = ({
	project,
	service,
	type,
	disconnectService,
	createDeployment,
	changeTaskCount,
	taskCount,
	top,
	right,
	isOpen,
	close,
}) => {
	const actionData = useMemo(() => ({
		projectId:    project.getId(),
		serviceAlias: service.getAlias(),
		type,
	}), [project, service, type])

	const [isOpenChangeCount, setIsOpenChangeCount] = useState(false)

	const changeCountSearchQuery = useCallback(count => DataProvider
		.buildQuery()
		.addBody({ ...actionData, count })
		.setSuccessCallback(() => {
			setIsOpenChangeCount(false)
			Notification.add({
				title:   'Успех!',
				message: 'Количество задач изменено.',
				type:    Notification.types.success,
			})
		}), [actionData])

	const deploymentSearchQuery = DataProvider
		.buildQuery()
		.addBody({ ...actionData })
		.setSuccessCallback(() => {
			Notification.add({
				title:   'Успех!',
				message: 'Обновление создано.',
				type:    Notification.types.success,
			})
		})

	return (
		<CollapseHeight top={top} right={right} isOpen={isOpen}>
			<Link className={styles.link} to={`/project/${project.getAlias()}/${type}/${service.getAlias()}`}>
				<CollapseHeightItem
					onClick={close}
					className={styles.action}
				>
					<Edit />
					Настройки
				</CollapseHeightItem>
			</Link>
			<CollapseHeightItem onClick={close}>
				<RemoveModel
					action={disconnectService}
					actionName="отключить"
					id={actionData}
					modelName={service.getTitle()}
				>
					<div className={styles.action}>
						<Remove />
						Отключить
					</div>
				</RemoveModel>
			</CollapseHeightItem>
			<CollapseHeightItem
				onClick={() => {
					close()
					createDeployment(deploymentSearchQuery)
				}}
				className={styles.action}
			>
				<Update />
				Создать обновление
			</CollapseHeightItem>
			<CollapseHeightItem
				onClick={() => {
					close()
					setIsOpenChangeCount(true)
				}}
				className={styles.action}
			>
				<Build />
				Количество задач
			</CollapseHeightItem>
			<ChangeTaskCount
				isOpen={isOpenChangeCount}
				close={() => setIsOpenChangeCount(false)}
				taskCount={taskCount}
				action={count => changeTaskCount(changeCountSearchQuery(count))}
			/>
		</CollapseHeight>
	)
}

ServiceCardActions.propTypes = {
	changeTaskCount:   PropTypes.func,
	close:             PropTypes.func,
	createDeployment:  PropTypes.func,
	disconnectService: PropTypes.func,
	isOpen:            PropTypes.bool,
	project:           PropTypes.instanceOf(Project),
	right:             PropTypes.number,
	service:           PropTypes.instanceOf(BaseModel),
	taskCount:         PropTypes.number,
	top:               PropTypes.number,
	type:              PropTypes.string,
}

ServiceCardActions.defaultProps = {
	changeTaskCount:   () => null,
	close:             () => null,
	createDeployment:  () => null,
	disconnectService: () => null,
	isOpen:            false,
	project:           Project.create({}),
	right:             0,
	service:           BaseModel.create({}),
	taskCount:         0,
	top:               32,
	type:              '',
}
