import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	list:   'authorization.roles.list',
	create: 'authorization.roles.create',
	remove: 'authorization.roles.remove',
	update: 'authorization.roles.update',
	view:   'authorization.roles.view',
}

/**
 * Call api to get list of role models.
 *
 * @param {Object} searchQuery
 *
 * @return {SearchQuery}
 */
const list = searchQuery => callApi(methods.list, searchQuery)

/**
 * Call api to update role model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {function}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to remove role model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

/**
 * Call api to create role model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to view role model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

/**
 * Call api to get search list of role models.
 *
 * @param {Object} searchQuery
 *
 * @return {function}
 */
const searchList = searchQuery => callApi(methods.list, searchQuery)

export const AuthorizationRolesApi = {
	list,
	update,
	remove,
	create,
	view,
	searchList,
}
