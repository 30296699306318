import { ServiceOptions } from 'forms/fields'
import React from 'react'

export const redisCredentialFields = (excluded, currentService) => [
	{ id:         'service',
		title:    'Сервис',
		type:     'select',
		children: <ServiceOptions excluded={excluded} currentService={currentService} /> },
	{ id: 'host', title: 'Хост' },
	{ id: 'port', title: 'Порт', type: 'number' },
	{ id: 'database', title: 'База данных', type: 'number' },
	{ id: 'password', title: 'Пароль' },
	{ id: 'srv', title: 'SRV-запись' },
]
