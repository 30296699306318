import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { InputLabel } from 'components/global/Inputs/InputLabel'
import { RadioButton } from 'components/global/Inputs/RadioButton'
import { ValidationError } from 'components/global/ValidationMessage'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render radio buttons
 *
 * @param {Function} setState
 * @param {string} value
 * @param {Object} field
 * @param {Object} children
 * @param {Array.<{label: string, value: string}>} buttons
 * @param {Function} action
 * @param {boolean} disabled
 * @param {Object} invalid
 *
 * @return {*}
 * @constructor
 */
export const RadioButtons = ({
	setState,
	value,
	field,
	buttons,
	children,
	action,
	disabled,
	invalid,
}) => {
	const { id, title } = field

	const [currentValue, setCurrentValue] = useState('')

	useEffect(() => {
		if (value) {
			setCurrentValue(value)
		}
	}, [value])

	const setValues = useCallback(service => {
		setState(id, service)
	}, [id, setState])

	const inputHandler = useCallback(event => {
		if (!disabled) {
			setValues(event.target.value)

			if (action) {
				action(event.target.value)
			}
		}
	}, [setValues, action, disabled])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<div className={styles.content}>
				<div className={[styles.inputsWrapper, invalid.result ? styles.invalid : ''].join(' ')}>
					<div className={disabled ? styles.disabled : styles.allowed} />
					{buttons.map(button => {
						const isChecked = button.value === currentValue

						return (
							<div className={styles.inputGroup} key={button.value}>
								<InputLabel
									label={button.label}
									value={button.value}
									checked={isChecked}
								>
									<RadioButton
										checked={isChecked}
										onChange={inputHandler}
									/>
								</InputLabel>
							</div>
						)
					})}
				</div>
				<ValidationError message={invalid.result ? invalid.message : ''} />
			</div>
			{children}
		</div>
	)
}

RadioButtons.propTypes = {
	action:   PropTypes.func,
	buttons:  PropTypes.array,
	children: PropTypes.object,
	field:    PropTypes.object,
	setState: PropTypes.func,
	disabled: PropTypes.bool,
	invalid:  PropTypes.object,
	value:    PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

RadioButtons.defaultProps = {
	action:   null,
	buttons:  [],
	children: null,
	field:    {},
	invalid:  {},
	setState: () => null,
	value:    '',
	disabled: false,
}
