import { MethodsTab } from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/index'
import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'

const { authorization: { methods: { list, create, update, remove, view } } } = StateSelector

const mapStateToProps = state => ({
	methodsListState: list(state),
	isFetching:       _(list(state), create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getMethodsList: DispatchSelector.authorization.methods.list,
	createMethods:  DispatchSelector.authorization.methods.create,
}

export default connect(mapStateToProps, mapDispatchToProps)(MethodsTab)
