import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { IosSwitch } from 'components/global/IosSwitch'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render is toggle field
 *
 * @param {Function} setState
 * @param {number} value
 * @param {Object} field
 *
 * @return {*}
 * @constructor
 */
export const Toggle = ({ setState, value, field }) => {
	const { id, title, disabled } = field

	const [isActive, setIsActive] = useState(0)

	useEffect(() => {
		setIsActive(Number(value))
	}, [value])

	const handler = useCallback(() => setState(id, (isActive ? 0 : 1)),
		[id, isActive, setState])

	return (
		<div className={styles.container}>
			<FieldLabel title={title} id={id} />
			<IosSwitch style={{ width: 'auto' }} action={!disabled ? handler : () => null} checked={!!isActive} />
		</div>
	)
}

Toggle.propTypes = {
	field:    PropTypes.object,
	setState: PropTypes.func,
	value:    PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

Toggle.defaultProps = {
	field:    {},
	setState: () => null,
	value:    0,
}
