import { Search } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { Tip } from 'components/global/Tip'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import SearchResults from 'components/wrapper/Header/SearchPanel/SearchResults/index.store'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react'
import styles from './styles.module.scss'

/**
 * Render search panel
 *
 * @param {Function} close
 *
 * @returns {*}
 * @constructor
 */
export const SearchPanel = ({ close }) => {
	const [isSearching, setIsSearching]   = useState(false)
	const [searchString, setSearchString] = useState('')

	const inputRef   = useRef()
	const wrapperRef = useRef()

	const stopSearching = useCallback(() => {
		setIsSearching(false)
		setSearchString('')
	}, [])

	const startSearching = useCallback(() => {
		setIsSearching(true)
		inputRef.current.focus()
	}, [])

	const clearSearchString = useCallback(() => {
		setSearchString('')
		inputRef.current.focus()
	}, [])

	const escKeyListener = useCallback(event => {
		if (event.key === 'Escape') {
			stopSearching()
			close()
		}
	}, [close, stopSearching])

	useCheckOutsideClick(wrapperRef, isSearching, stopSearching)

	const showSearchResults = useMemo(() => isSearching && searchString.length > 2,
		[isSearching, searchString])

	return (
		<Tip title={isSearching ? '' : 'Нажмите чтобы начать'}>
			<div
				ref={wrapperRef}
				className={[styles.wrapper, isSearching && styles.active].join(' ')}
			>
				<Button
					className={styles.searchWrapper}
					onClick={startSearching}
				>
					<Search className={styles.searchIcon} />
					<input
						ref={inputRef}
						onChange={event => setSearchString(event.target.value)}
						onKeyDown={escKeyListener}
						className={styles.search}
						type="text"
						placeholder="Проекты, сервисы..."
						value={searchString}
					/>

				</Button>
				{showSearchResults &&
				<SearchResults
					clearSearchString={clearSearchString}
					stopSearching={stopSearching}
					searchString={searchString}
				/>}
			</div>
		</Tip>
	)
}

SearchPanel.propTypes = {
	close: PropTypes.func,
}

SearchPanel.defaultProps = {
	close: () => null,
}
