import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { PROJECT_MAIL_CREDENTIALS_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/settings/mail/actionTypes'

export const MailCredentialsActions = {
	list:    RequestActionHelper.getActionCreatorFetch(PROJECT_MAIL_CREDENTIALS_ACTIONS.LIST),
	setList: RequestActionHelper.getActionCreatorSuccess(PROJECT_MAIL_CREDENTIALS_ACTIONS.LIST),
	create:  RequestActionHelper.getActionCreatorFetch(PROJECT_MAIL_CREDENTIALS_ACTIONS.CREATE),
	remove:  RequestActionHelper.getActionCreatorFetch(PROJECT_MAIL_CREDENTIALS_ACTIONS.REMOVE),
	update:  RequestActionHelper.getActionCreatorFetch(PROJECT_MAIL_CREDENTIALS_ACTIONS.UPDATE),
	view:    RequestActionHelper.getActionCreatorFetch(PROJECT_MAIL_CREDENTIALS_ACTIONS.VIEW),
}
