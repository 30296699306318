import PropTypes from 'prop-types'
import React from 'react'
import { TabPane } from 'reactstrap'
import styles from '../styles.module.scss'

/**
 * Render tabs content item
 *
 * @param {string} tabId
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const TabsContentItem = ({ tabId, children }) => (
	<TabPane className={styles.tabPane} tabId={tabId}>
		{children}
	</TabPane>
)

TabsContentItem.propTypes = {
	children: PropTypes.node,
	tabId:    PropTypes.string,
}

TabsContentItem.defaultProps = {
	children: null,
	tabId:    '',
}
