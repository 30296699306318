import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * UserSettings model class
 */
class UserSettings extends BaseModel {
	static isVerified = {
		1:       'Да',
		0:       'Нет',
		unknown: 'Нет данных',
	}

	/**
	 * Create Setting instance
	 *
	 * @param {Object} object
	 *
	 * @returns {UserSettings}
	 */
	static create(object) {
		return super.create(object)
	}

	/**
	 * Create UserSettings list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<UserSettings>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map settings
	 *
	 * @param {Array.<object>} objects
	 * @param {function(UserSettings, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get user id
	 *
	 * @returns {number}
	 */
	getUserId = () => this.model.userId

	/**
	 * Get user image
	 *
	 * @returns {string}
	 */
	getImage = () => this.model.absoluteImage

	/**
	 * Get email
	 *
	 * @returns {string}
	 */
	getEmail = () => this.model.email

	/**
	 * Is e-mail verified
	 *
	 * @returns {boolean}
	 */
	isEmailVerified = (asLabel = false) => (asLabel ?
		UserSettings.isVerified[this.model.isEmailVerified || 'unknown'] || UserSettings.isVerified.unknown :
		!!this.model.isEmailVerified)

	/**
	 * Get phone
	 *
	 * @returns {string}
	 */
	getPhone = () => this.model.phone

	/**
	 * Is phone verified
	 *
	 * @returns {boolean}
	 */
	isPhoneVerified = (asLabel = false) => (asLabel ?
		UserSettings.isVerified[this.model.isPhoneVerified || 'unknown'] || UserSettings.isVerified.unknown :
		!!this.model.isPhoneVerified)

	/**
	 * Get params
	 *
	 * @returns {Object}
	 */
	getParams = () => this.model.params

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, absoluteImage, ...attributes } = this.model

		return attributes
	}
}

export default UserSettings
