import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	MailCredentialsActions,
	MailCredentialsSelectors,
	PROJECT_MAIL_CREDENTIALS_ACTIONS,
} from 'reduxm/redux-saga/modules/control-panel/settings/mail'
import { MailCredentialsApi } from 'reduxm/redux-saga/modules/control-panel/settings/mail/api'

/**
 *  Get mail credentials list
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, MailCredentialsApi.list)
}

/**
 * View mail credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, MailCredentialsApi.view)
}

/**
 * Remove Mail credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, MailCredentialsApi.remove)
}

/**
 * Updated mail credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, MailCredentialsApi.update, null, null,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, MailCredentialsSelectors, MailCredentialsActions)
		})
}

/**
 * Create mail credentials model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, MailCredentialsApi.create, null, null,
		function* (...params) {
			yield ResponseHandler.afterCreateModel(...params, MailCredentialsSelectors.list,
				MailCredentialsActions.setList)
		})
}

export default [
	takeLatest(PROJECT_MAIL_CREDENTIALS_ACTIONS.LIST, list),
	takeLatest(PROJECT_MAIL_CREDENTIALS_ACTIONS.CREATE, create),
	takeLatest(PROJECT_MAIL_CREDENTIALS_ACTIONS.REMOVE, remove),
	takeLatest(PROJECT_MAIL_CREDENTIALS_ACTIONS.UPDATE, update),
	takeLatest(PROJECT_MAIL_CREDENTIALS_ACTIONS.VIEW, view),
]
