import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import CssBaseline from '@material-ui/core/CssBaseline'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { Copyright } from 'components/sign-in/Copyright'
import { SignInButton } from 'components/sign-in/SignInButton'
import { SignInHelper } from 'components/sign-in/SignInHelper'
import { TextInput } from 'components/sign-in/TextInput'
import { useStyles } from 'containers/SignIn/styles'
import CredentialsAuth from 'helpers/authMethods/credentials'
import { User } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import { Redirect } from 'react-router-dom'

/**
 * Render Sign-in page
 *
 * @param {authState} authState
 * @param {function} signIn
 * @param {Object} userState
 * @param {Object} signInState
 *
 * @returns {*}
 * @constructor
 */
export const SignInPage = ({ authState, signIn, userState, signInState }) => {
	const [signInData, setSignInData]     = useState({
		email:    '',
		password: '',
	})
	const [errorMessage, setErrorMessage] = useState()

	const classes = useStyles()

	/**
	 * Set text of the inputs to state
	 *
	 * @param {Object} event
	 *
	 * @return {undefined}
	 */
	const setInputData = useCallback(event => {
		setSignInData({
			...signInData,
			[event.target.name]: event.target.value,
		})
	}, [signInData])

	/**
	 * Sign-in method
	 *
	 * @return {undefined}
	 */
	const signInUser = useCallback(event => {
		event.preventDefault()

		const { email, password } = signInData

		if (errorMessage) {
			setErrorMessage(null)
		}

		CredentialsAuth(signIn, email, password)
			.then(() => errorMessage && setErrorMessage(null))
			.catch(e => setErrorMessage(e.message))
	}, [signIn, signInData, errorMessage])

	/**
	 * Enter key press handler
	 *
	 * @type {undefined}
	 */
	const enterPressButton = useCallback(event => {
		if (event.key === 'Enter') {
			signInUser(event)
		}
	}, [signInUser])

	const user          = User.create(userState)
	const signInService = StateService.create(signInState)

	return (
		<Grid container component="main" className={classes.root}>
			<CssBaseline />
			<Grid item xs={false} sm={4} md={7} className={classes.image} />
			<Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square className={classes.signIn}>
				{user.isSuperAdmin(authState) && <Redirect to="/" />}
				{errorMessage && <SignInHelper message={errorMessage} />}
				<div className={classes.paper}>
					<Avatar className={classes.avatar}>
						<LockOutlinedIcon />
					</Avatar>
					<Typography component="h1" variant="h5">
						Вход в панель управления
					</Typography>
					<form className={classes.form} noValidate>
						<TextInput
							onChange={setInputData}
							onKeyPress={enterPressButton}
							name="email"
							label="Адрес электронной почты"
							required
							autoFocus
						/>
						<TextInput
							onChange={setInputData}
							onKeyPress={enterPressButton}
							name="password"
							type="password"
							label="Пароль"
						/>
						<SignInButton
							onClick={signInUser}
							disabled={signInService.isFetching()}
						/>
						<Box mt={5}>
							<Copyright />
						</Box>
					</form>
				</div>
			</Grid>
		</Grid>
	)
}

SignInPage.propTypes = {
	userState:   PropTypes.object,
	authState:   PropTypes.object,
	signInState: PropTypes.object,
	signIn:      PropTypes.func,
}

SignInPage.defaultProps = {
	userState:   {},
	signInState: {},
	signIn:      () => null,
}
