import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { CopyProjectSettings } from './index'

const { controlPanel: { projects: { searchList: list } } }                                            = StateSelector
const { controlPanel: { projects: { searchList: getProjects, setCopyProjectQuery, setSearchList } } } = DispatchSelector

const mapStateToProps = state => ({
	projectsState: list(state),
})

const mapDispatchToProps = {
	getProjects,
	setCopyProjectQuery,
	setSearchList,
}

export default connect(mapStateToProps, mapDispatchToProps)(CopyProjectSettings)
