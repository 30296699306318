import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { GatewayPage } from './index'

const { controlPanel: { projects: { updateGateway } } } = StateSelector

const mapStateToProps = state => ({
	gatewayState: updateGateway(state),
	isFetching:   _(updateGateway(state)),
})

const mapDispatchToProps = {
	updateGateway: DispatchSelector.controlPanel.projects.updateGateway,
}

export default connect(mapStateToProps, mapDispatchToProps)(GatewayPage)
