import {
	takeEvery,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	removeFile,
	updateConfig,
	uploadFiles,
} from 'reduxm/redux-saga/modules/control-panel/files/sagas'
import {
	SITES_ACTIONS,
	SitesActions,
	SitesApi,
	SitesSelectors,
} from 'reduxm/redux-saga/modules/control-panel/sites'

/**
 *  Get site list
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, SitesApi.list)
}

/**
 * View site model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, SitesApi.view)
}

/**
 * Remove site model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, SitesApi.remove, null, null,
		function* (...params) {
			yield ResponseHandler.afterRemoveModel(...params, SitesSelectors.list, SitesActions.setList)
		})
}

/**
 * Updated site model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, SitesApi.update, null, null,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, SitesSelectors, SitesActions)
		})
}

/**
 * Create site model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, SitesApi.create, null, null,
		function* (...params) {
			yield ResponseHandler.afterCreateModel(...params, SitesSelectors.list, SitesActions.setList)
		})
}

export default [
	takeLatest(SITES_ACTIONS.LIST, list),
	takeLatest(SITES_ACTIONS.CREATE, create),
	takeLatest(SITES_ACTIONS.REMOVE, remove),
	takeLatest(SITES_ACTIONS.UPDATE, update),
	takeLatest(SITES_ACTIONS.VIEW, view),
	takeEvery(SITES_ACTIONS.UPLOAD_FILE, uploadFiles),
	takeLatest(SITES_ACTIONS.REMOVE_FILE, removeFile),
	takeLatest(SITES_ACTIONS.UPDATE_CONFIG, updateConfig),
]
