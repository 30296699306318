import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	list:                'control-panel.projects.list',
	create:              'control-panel.projects.create',
	remove:              'control-panel.projects.remove',
	update:              'control-panel.projects.update',
	view:                'control-panel.projects.view',
	updateGateway:       'control-panel.projects.update-gateways',
	connectService:      'control-panel.projects.connect-service',
	disconnectService:   'control-panel.projects.disconnect-service',
	copyProjectSettings: 'control-panel.projects.copy-project-settings',
}

/**
 * Call api to get list of project models.
 *
 * @param {Object} searchQuery
 *
 * @return {function}
 */
const list = searchQuery => callApi(methods.list, searchQuery)

/**
 * Call api to update project model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {function}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to remove projec tmodel.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

/**
 * Call api to create project model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to view project model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

/**
 * Call api to update gateway.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const updateGateway = searchQuery => callApi(methods.updateGateway, searchQuery)

/**
 * Call api to connect service model to project.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const connectService = searchQuery => callApi(methods.connectService, searchQuery)

/**
 * Call api to disconnect service model from project.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const disconnectService = searchQuery => callApi(methods.disconnectService, searchQuery)

/**
 * Call api to copy project settings.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const copyProjectSettings = searchQuery => callApi(methods.copyProjectSettings, searchQuery)

export const ProjectApi = {
	list,
	update,
	remove,
	create,
	view,
	updateGateway,
	connectService,
	disconnectService,
	copyProjectSettings,
}
