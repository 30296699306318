import styles from 'components/global/CollapseHeight/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render Collapse height content
 *
 * @param {Object} children
 * @param {boolean} isOpen
 * @param {number} top Number of top position
 * @param {number} right Number of right position
 * @param {number} left Number of left position
 *
 * @return {*}
 * @constructor
 */
export const CollapseHeight = ({ left, right, top, children, isOpen }) => (
	<div
		style={{ top, right, left }}
		className={[styles.content, isOpen && styles.show].join(' ')}
	>
		{children}
	</div>
)

CollapseHeight.propTypes = {
	children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	isOpen:   PropTypes.bool,
	right:    PropTypes.number,
	left:     PropTypes.number,
	top:      PropTypes.number,
}

CollapseHeight.defaultProps = {
	children: {},
	isOpen:   false,
	right:    null,
	left:     null,
	top:      50,
}
