import { callApi } from 'reduxm/redux-saga/api'

/**
 * Get user role
 *
 * @param {SearchQuery} searchQuery
 *
 */
function userRole(searchQuery) {
	return callApi('authorization.actions.get-user-role', searchQuery)
}

/**
 * Set user role
 *
 * @param {SearchQuery} searchQuery
 *
 */
function setUserRole(searchQuery) {
	return callApi('authorization.actions.set-user-role', searchQuery)
}

/**
 * Update service config
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
function updateServiceConfig(searchQuery) {
	searchQuery.addRequestOptions({
		headers: {
			// Send request to all workers
			type: 'pub',
		},
	})
	return callApi('authorization.actions.update-service-config', searchQuery)
}

export const AuthorizationApi = {
	userRole,
	setUserRole,
	updateServiceConfig,
}
