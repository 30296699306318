import { callApi } from 'reduxm/redux-saga/api'

const methods = {
	renewJwt: 'authentication.jwt.renew',
}

/**
 * Renew auth token
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
const renewToken = searchQuery => callApi(methods.renewJwt, searchQuery)

export const AuthJwtApi = {
	renewToken,
}
