import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import CreateDeploymentModal from 'components/global/CreateDeploymentModal/index.store'
import { Dropzone } from 'components/global/Dropzone'
import styles from 'components/project/ServiceConfig/MicroserviceConfig/styles.module.scss'
import { FileUrlTextField } from 'forms/control-panel/site/fields/FaviconName'
import { AuthMethodsField } from 'forms/user/config/fields/AuthMethods'
import _ from 'lodash'
import { ServicesConfig } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import {
	Button,
	Form,
} from 'reactstrap'

const service = 'users'

const getUpdateQuery = (projectId, serviceAlias) => DataProvider
	.buildQuery()
	.addBody({ projectId, serviceAlias, type: 'microservice' })

const getPreview = preview => ({ name: 'preview', isLoaded: true, preview })

/**
 * Render site config component
 *
 * @param {Object} serviceConfigState
 * @param {Function} getServiceConfig
 * @param {Function} uploadDefaultImage
 * @param {Function} removeDefaultImage
 * @param {Function} updateConfig
 * @param {number} projectId
 *
 * @return {*}
 * @constructor
 */

export const UserConfig = ({
	serviceConfigState,
	getServiceConfig,
	uploadDefaultImage,
	removeDefaultImage,
	updateConfig,
	projectId,
}) => {
	const [config, setConfig]               = useState({})
	const [file, setFile]                   = useState([])
	const [isActiveModal, setIsActiveModal] = useState(false)

	const serviceConfig = useMemo(() => ServicesConfig.create(serviceConfigState).getConfig(),
		[serviceConfigState])

	const attributes = useMemo(() => ({ projectId, service, config }),
		[config, projectId])

	useEffect(() => {
		if (projectId && service) {
			getServiceConfig({ projectId, service })
		}
	}, [getServiceConfig, projectId])

	useEffect(() => {
		const { defaultUserImage } = serviceConfig

		if (defaultUserImage) {
			setFile([getPreview(defaultUserImage)])
		}

		setConfig(serviceConfig)

		return () => setFile([])
	}, [serviceConfig])

	const isFileChanged = useMemo(() => serviceConfig.defaultUserImage !== config.defaultUserImage ||
		!!serviceConfig.defaultUserImage !== !!file[0], [config.defaultUserImage, file, serviceConfig.defaultUserImage])

	const onSubmit = useCallback(event => {
		event.preventDefault()

		const { defaultUserImageId } = serviceConfig

		const callback = () => setIsActiveModal(true)

		if (isFileChanged) {
			uploadDefaultImage({
				attributes, files: file, ids: defaultUserImageId, names: 'defaultUserImage', callback,
			})
		} else {
			updateConfig({ attributes, callback })
		}
	}, [attributes, file, isFileChanged, serviceConfig, updateConfig, uploadDefaultImage])

	const onRemove = useCallback(() => {
		const { defaultUserImageId: id, defaultUserImage, ...nextConfig } = config
		const { defaultUserImage: url }                                   = serviceConfig

		if (id || url) {
			removeDefaultImage({ id, attributes: { ...attributes, config: nextConfig } })
		} else {
			setConfig(serviceConfig)
			setFile([])
		}
	}, [attributes, config, removeDefaultImage, serviceConfig])

	const isSubmitDisabled = useMemo(() => _.isEqual(config, serviceConfig) && !isFileChanged,
		[config, isFileChanged, serviceConfig])

	const checkboxHandler = useCallback((nextValue, isChecked) => {
		const { enabledAuthMethods: prevValue } = config

		const enabledAuthMethods = (prevValue && !isChecked && [...prevValue, nextValue]) ||
			(prevValue && isChecked && prevValue.filter(value => value !== nextValue)) ||
			[nextValue]

		setConfig({ ...config, enabledAuthMethods })
	}, [config])

	const addFile = useCallback(nextFile => {
		const { defaultUserImage, ...nextConfig } = config

		setConfig(nextConfig)
		setFile(nextFile)
	}, [config])

	return (
		<div className={styles.container}>
			<CreateDeploymentModal
				isActive={isActiveModal}
				toggle={() => setIsActiveModal(false)}
				data={getUpdateQuery(projectId, service)}
			/>
			<Form onSubmit={onSubmit}>
				<FileUrlTextField
					id="defaultUserImage"
					title="Изображение по умолчанию"
					value={config.defaultUserImage}
					onChange={event => setConfig({ ...config, defaultUserImage: event.target.value })}
					disabled={file[0] && file[0].isLoaded}
					placeholder="http://example.com/path-to-image.jpg"
					removeAction={onRemove}
				/>
				<Dropzone
					files={file}
					setFiles={addFile}
					onDelete={onRemove}
					maxFiles={1}
					accept=".png, .jpg, .jpeg, .svg"
					maxSize={2 * 1024 * 1024} // 2mb
					title="Перетащите изображение сюда"
				/>
				<AuthMethodsField
					enabledMethods={config.enabledAuthMethods}
					handler={checkboxHandler}
				/>
				<Button
					type="submit"
					color="success"
					className={styles.updateBtn}
					disabled={isSubmitDisabled}
				>
					Обновить
				</Button>
			</Form>
		</div>
	)
}

UserConfig.propTypes = {
	getServiceConfig:   PropTypes.func,
	projectId:          PropTypes.number,
	removeDefaultImage: PropTypes.func,
	serviceConfigState: PropTypes.object,
	updateConfig:       PropTypes.func,
	uploadDefaultImage: PropTypes.func,
}

UserConfig.defaultProps = {
	getServiceConfig:   () => null,
	projectId:          null,
	removeDefaultImage: () => null,
	serviceConfigState: {},
	updateConfig:       () => null,
	uploadDefaultImage: () => null,
}
