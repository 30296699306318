import { FieldLabel } from 'components/global/AsidePanel/ModelForm/FormField/FieldLabel'
import { BirthdayInput } from 'components/user/BirthdayInput'
import 'forms/fields/JsonInput/styles.css'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useState,
} from 'react'

/**
 * Render birthday field
 *
 * @param {Function} setState
 * @param {string} value
 * @param {Object} field
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const BirthdayField = ({ setState, value, field }) => {
	const { id, title, disabled } = field

	const [date, setDate] = useState('')

	useEffect(() => {
		if (!_.isEmpty(value)) {
			setDate(value)
		}
	}, [value])

	const handler = useCallback(nextDate => {
		setDate(nextDate)
		setState(id, nextDate)
	}, [setState, id])

	return (
		<>
			<FieldLabel title={title} id={id} />
			<BirthdayInput id={id} value={date} onChange={handler} disabled={disabled} />
		</>
	)
}

BirthdayField.propTypes = {
	field:    PropTypes.object,
	setState: PropTypes.func,
	value:    PropTypes.any,
}

BirthdayField.defaultProps = {
	field:    {},
	setState: () => null,
	value:    '',
}
