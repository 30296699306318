import SearchQuery from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider/SearchQuery'
import { Notification } from 'helpers/Notification'

/**
 * Get search query params if exist
 *
 * @param {Object|SearchQuery} params
 *
 * @return {Object|SearchQuery}
 */
const getRequestParams = params => (params instanceof SearchQuery ? params.getReduxRequestParams().error : params)

/**
 * Show error messages when request failed
 *
 * @return {function(*): function(*=): *}
 * @constructor
 */
export const Notifications = () => next => action => {
	const { type, params } = action

	if (type.startsWith('REQUEST_ERROR')) {
		const { code, message } = getRequestParams(params)

		if (code !== 422) {
			Notification.add({
				title:   'Ошибка.',
				message: `${message}`,
				type:    Notification.types.danger,
			})
		}
	}
	return next(action)
}
