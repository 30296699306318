import { ServiceCard } from 'components/project/Home/Cards/ServiceCard'
import { ConnectService } from 'components/project/Home/ServicesPanel/ConnectService'
import { ServiceSearchPanel } from 'components/project/Home/ServicesPanel/ServiceSearchPanel'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
	useState,
} from 'react'
import { useParams } from 'react-router'
import {
	Col,
	Container,
	Row,
} from 'reactstrap'
import styles from './styles.module.scss'

const types = {
	all:          'Сервиса',
	site:         'Сайта',
	microservice: 'Микросервиса',
	'mobile-app': 'Приложения',
}

/**
 * Render Service home page
 *
 * @param {Function} createService
 * @param {Array.<Service>} notConnectedServices
 * @param {Array.<Site>} notConnectedSites
 * @param {Project} project
 * @param {Object} servicesInfo
 * @param {Function} changeTaskCount
 *
 * @return {*}
 * @constructor
 */
export const ServicesPanel = ({
	project,
	notConnectedServices,
	notConnectedSites,
	changeTaskCount,
	servicesInfo,
}) => {
	const [searchInfo, setSearchInfo] = useState({
		includes: 'all',
		title:    '',
	})

	const { serviceType } = useParams()

	const connectedServices = project.getServices(true)
	const connectedSites    = project.getSites()

	const getFilteredList = useCallback(values => values.filter(value => value.getTitle()
		.toLowerCase()
		.includes(searchInfo.title)), [searchInfo.title])

	const filteredServices = [
		{ type: 'microservice', values: getFilteredList(connectedServices) },
		{ type: 'site', values: getFilteredList(connectedSites) },
		{ type: 'mobile-app', values: getFilteredList([]) },
	].filter(service => (searchInfo.includes === 'all' ? service : service.type === searchInfo.includes))

	const notConnectedList = useMemo(() => ({
		site:         notConnectedSites,
		microservice: notConnectedServices,
		'mobile-app': [],
	}), [notConnectedServices, notConnectedSites])

	const servicesCount = filteredServices.map(service => service.values.length).reduce((acc, cv) => acc + cv)

	return (!serviceType &&
		<div className={styles.services}>
			{!![...connectedServices, ...connectedSites].length &&
			<>
				<div className={styles.title}>
					{`Управление сервисами ${project.getTitle()}`}
				</div>
				<Container className={styles.container}>
					<ServiceSearchPanel includes={searchInfo.includes} setSearchInfo={setSearchInfo} />
					<Row>
						{servicesCount ?
							filteredServices.map(servicesData => servicesData.values.map(service => (
								<Col xs={12} md={6} lg={4} xl={3} className={styles.col} key={service.getAlias()}>
									<ServiceCard
										type={servicesData.type}
										project={project}
										service={service}
										serviceInfo={servicesInfo?.result?.[service.getAlias()] || { workers: 1 }}
										toggle={count => changeTaskCount({
											projectId:    project.getId(),
											serviceAlias: service.getAlias(),
											type:         servicesData.type,
											count,
										})}
									/>
								</Col>
							))) :
							<div className={styles.emptyList}>
								<span>{types[searchInfo.includes]}</span>
								<span>{` ${searchInfo.title} `}</span>
								<span>нет в списке...</span>
							</div>}
					</Row>
				</Container>
			</>}
			<div className={styles.addService}>
				<ConnectService
					services={notConnectedList}
					projectId={project.getId()}
					title="Добавить сервис"
				/>
			</div>
		</div>
	)
}

ServicesPanel.propTypes = {
	changeTaskCount:      PropTypes.func,
	notConnectedServices: PropTypes.array,
	project:              PropTypes.instanceOf(Project),
	servicesInfo:         PropTypes.object,
	notConnectedSites:    PropTypes.array,
}

ServicesPanel.defaultProps = {
	changeTaskCount:      () => null,
	notConnectedServices: [],
	project:              Project.create({}),
	servicesInfo:         {},
	notConnectedSites:    [],
}
