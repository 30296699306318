import { ServiceOptions } from 'forms/fields'
import React from 'react'

export const mailCredentialFields = (excluded, currentService) => [
	{ id:         'service',
		title:    'Сервис',
		type:     'select',
		children: <ServiceOptions excluded={excluded} currentService={currentService} /> },
	{ id: 'user', title: 'Пользователь' },
	{ id: 'host', title: 'Хост' },
	{ id: 'port', title: 'Порт', type: 'number' },
	{ id: 'password', title: 'Пароль' },
	{ id: 'encryption', title: 'Шифрование' },
]
