import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { FILES_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/files/actionTypes'

export const FilesActions = {
	view:         RequestActionHelper.getActionCreatorFetch(FILES_ACTIONS.VIEW),
	create:       RequestActionHelper.getActionCreatorFetch(FILES_ACTIONS.CREATE),
	remove:       RequestActionHelper.getActionCreatorFetch(FILES_ACTIONS.REMOVE),
	update:       RequestActionHelper.getActionCreatorFetch(FILES_ACTIONS.UPDATE),
	preparedIcon: RequestActionHelper.getActionCreatorSuccess(FILES_ACTIONS.PREPARED_ICON),
}
