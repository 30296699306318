import {
	put,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import { AuthActions } from 'reduxm/redux-saga/modules/authentication/actions'
import { AuthenticationConfigActions } from './actionCreators'
import { PROJECT_AUTHENTICATION_CONFIG_ACTIONS } from './actionTypes'
import { AuthenticationConfigApi } from './api'

/**
 * Worker for create/update methods
 *
 * @param {Object} data
 * @param {Object} data.response Response data
 * @param {Object} data.result Firebase config model
 *
 * @return {Generator<*, void, ?>}
 * */
function* creationWorker(data) {
	const { response, result: { model } } = data

	yield put(AuthenticationConfigActions.setView({ model, response }))
}

/**
 * View authentication config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, AuthenticationConfigApi.view)
}

/**
 * Remove authentication config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, AuthenticationConfigApi.remove, null, null, function* (data) {
		if (data?.result?.result) {
			yield put(AuthenticationConfigActions.setView(null))
		}
	})

	yield put(AuthActions.updateServiceConfig())
}

/**
 * Updated authentication config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, AuthenticationConfigApi.update, null, null, function* (...params) {
		yield creationWorker(...params)
		yield put(AuthActions.updateServiceConfig())
	})
}

/**
 * Create authentication config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, AuthenticationConfigApi.create, null, null, function* (...params) {
		yield creationWorker(...params)
	})

	yield put(AuthActions.updateServiceConfig())
}

export default [
	takeLatest(PROJECT_AUTHENTICATION_CONFIG_ACTIONS.CREATE, create),
	takeLatest(PROJECT_AUTHENTICATION_CONFIG_ACTIONS.REMOVE, remove),
	takeLatest(PROJECT_AUTHENTICATION_CONFIG_ACTIONS.UPDATE, update),
	takeLatest(PROJECT_AUTHENTICATION_CONFIG_ACTIONS.VIEW, view),
]
