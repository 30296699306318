import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import { ModelForm } from 'components/global/AsidePanel/ModelForm'
import CreateDeploymentModal from 'components/global/CreateDeploymentModal/index.store'
import { Spinner } from 'components/global/Spinner'
import { RemoveModelButton } from 'components/project/Setting/RemoveModelButton'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useParams } from 'react-router'
import styles from './styles.module.scss'

const getUpdateQuery = (projectId, serviceAlias, type) => DataProvider
	.buildQuery()
	.addBody({ projectId, serviceAlias, type })

/**
 * Render config setting tab
 *
 * @param {FirebaseConfig} configState
 * @param {Function} getConfig
 * @param {Function} createConfig
 * @param {Function} updateConfig
 * @param {Function} removeConfig
 * @param {Function} createDeployment
 * @param {Project} project
 * @param {boolean} isFetching
 * @param {Class} model
 * @param {Object} fields
 * @param {string} tabName
 * @param {string} type
 *
 * @return {*}
 * @constructor
 */
export const ConfigTab = ({
	configState,
	getConfig,
	createConfig,
	updateConfig,
	removeConfig,
	project,
	isFetching,
	model,
	fields,
	tabName,
	createDeployment,
	type,
}) => {
	const [isActiveModal, setIsActiveModal] = useState(false)

	const config = model.create(configState)

	const projectId = useMemo(() => project.getId(), [project])

	const { service } = useParams()

	useEffect(() => {
		if (projectId) {
			getConfig({ projectId })
		}
	}, [getConfig, projectId])

	const buttonsGroupProps = {
		title:     config.isExist() ? 'Обновить' : 'Создать',
		cancelBtn: (<RemoveModelButton
			disabled={!config.isExist()}
			id={{ projectId }}
			action={removeConfig}
		/>),
	}

	const actionWrapper = useCallback(action => searchQuery => {
		if (createDeployment) {
			searchQuery.setCallback(() => setIsActiveModal(true))
		}

		return action(searchQuery)
	}, [createDeployment])

	const modelFormProps = {
		...(config.isExist() && { model: config }),
		...(!config.isExist() && { initialData: { projectId } }),
		fields,
		action: config.isExist() ? actionWrapper(updateConfig) : actionWrapper(createConfig),
	}

	const renderModelForm = useCallback(() => (
		<div className={styles.form}>
			<ModelForm
				buttonsGroupProps={buttonsGroupProps}
				{...modelFormProps}
			/>
		</div>
	), [buttonsGroupProps, modelFormProps])

	return (service === tabName &&
		<div className={styles.container}>
			<CreateDeploymentModal
				isActive={isActiveModal}
				toggle={() => setIsActiveModal(false)}
				data={getUpdateQuery(projectId, service, type)}
			/>
			<Spinner block isFetching={isFetching} />
			{renderModelForm()}
		</div>
	)
}

ConfigTab.propTypes = {
	configState:      PropTypes.object,
	createConfig:     PropTypes.func,
	createDeployment: PropTypes.bool,
	fields:           PropTypes.array.isRequired,
	getConfig:        PropTypes.func,
	isFetching:       PropTypes.bool,
	model:            PropTypes.func.isRequired,
	project:          PropTypes.instanceOf(Project),
	removeConfig:     PropTypes.func,
	tabName:          PropTypes.string.isRequired,
	type:             PropTypes.string,
	updateConfig:     PropTypes.func,
}

ConfigTab.defaultProps = {
	configState:      {},
	createConfig:     () => null,
	createDeployment: false,
	getConfig:        () => null,
	isFetching:       false,
	project:          Project.create({}),
	removeConfig:     () => null,
	type:             '',
	updateConfig:     () => null,
}
