import { TableSearch } from 'components/global/Table/TableSearch'
import styles from 'components/project/ServiceConfig/Authorization/SettingTabs/Permissions/Search/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render permissions search
 *
 * @param {Function} searchAction
 * @param {Object} searchRef
 * @param {Function} searchByAction
 * @param {Function} cancel
 * @param {string} searchByValue
 * @return {*}
 *
 * @constructor
 */
export const PermissionsSearch = ({ searchAction, searchRef, searchByAction, searchByValue, cancel }) => (
	<div className={styles.search}>
		<select
			className={styles.select}
			value={searchByValue}
			onChange={event => searchByAction(event.target.value)}
		>
			<option value="v1">V1</option>
			<option value="v2">V2</option>
			<option value="v3">V3</option>
		</select>
		<TableSearch onChange={searchAction} innerRef={searchRef} cancel={cancel} />
	</div>
)

PermissionsSearch.propTypes = {
	searchAction:   PropTypes.func,
	searchByAction: PropTypes.func,
	cancel:         PropTypes.func,
	searchRef:      PropTypes.oneOfType([PropTypes.object, PropTypes.func, PropTypes.string]),
	searchByValue:  PropTypes.string,
}

PermissionsSearch.defaultProps = {
	searchAction:   () => null,
	searchByAction: () => null,
	searchRef:      null,
	cancel:         null,
	searchByValue:  'v1',
}
