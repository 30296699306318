/**
 * Form validator class
 */
class FormValidator {
	/**
	 * Handle form errors
	 *
	 * @param {Object} errors
	 * @param {Array|undefined} errors.payload
	 * @param {Function} setState
	 *
	 * @return {undefined}
	 */
	static handleErrors = (errors, setState) => {
		const { payload }        = errors
		const nextValidationInfo = {}

		payload.forEach(error => {
			nextValidationInfo[error.path] = {
				result:  true,
				message: error.message,
			}
		})

		setState(nextValidationInfo)
	}
}

export default FormValidator
