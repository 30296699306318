import { Button } from 'components/global/Button'
import { PaginationDropdown } from 'components/global/Pagination/PaginationDropdown'
import { PaginationItem } from 'components/global/Pagination/PaginationItem'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import styles from './styles.module.scss'

/**
 * Render pagination
 *
 * @param {Object} data
 * @param {Function} action
 *
 * @return {*}
 * @constructor
 */
export const Pagination = ({ data, action }) => {
	const currentPage = data.getCurrentPage()
	const totalPages  = data.getPageCount()

	/**
	 * Generate array of page numbers
	 *
	 * @return {Array}
	 */
	const getPageNumbers = useCallback(() => {
		const pageNumbers = Array.from(Array(totalPages).keys(), i => i + 1)

		if (totalPages > 6) {
			return [1, 2, 3, pageNumbers, ...pageNumbers.slice(pageNumbers.length - 3)]
		}

		return pageNumbers
	}, [totalPages])

	return (!!totalPages &&
		<div className={styles.wrapper}>
			<Button
				disabled={currentPage === 1}
				className={[styles.pageItem, styles.prev].join(' ')}
				onClick={() => action(currentPage - 1)}
			/>
			{getPageNumbers().map(number => {
				if (typeof number === 'number') {
					return <PaginationItem
						key={number}
						currentPage={currentPage}
						action={action}
						number={number}
					/>
				}
				return <PaginationDropdown
					key="pagination-dropdown"
					currentPage={currentPage}
					action={action}
					numbers={number}
				/>
			})}
			<Button
				disabled={currentPage === totalPages}
				className={[styles.pageItem, styles.next].join(' ')}
				onClick={() => action(currentPage + 1)}
			/>

		</div>
	)
}

Pagination.propTypes = {
	action: PropTypes.func,
	data:   PropTypes.object,
}

Pagination.defaultProps = {
	action: () => null,
	data:   {},
}
