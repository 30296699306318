import {
	AuthActions,
	AuthSelectors,
} from 'reduxm/redux-saga/modules/authentication/actions'
import {
	AuthBearerActions,
	AuthBearerSelectors,
} from 'reduxm/redux-saga/modules/authentication/bearer'
import { AuthJwtActions } from 'reduxm/redux-saga/modules/authentication/jwt/actionCreators'
import { AuthJwtSelectors } from 'reduxm/redux-saga/modules/authentication/jwt/actionSelectors'
import {
	AuthorizationActions,
	AuthorizationSelectors,
} from 'reduxm/redux-saga/modules/authorization/actions'
import {
	AuthorizationAttributesActions,
	AuthorizationAttributesSelectors,
} from 'reduxm/redux-saga/modules/authorization/attributes'
import {
	AuthorizationFiltersActions,
	AuthorizationFiltersSelectors,
} from 'reduxm/redux-saga/modules/authorization/filters'
import {
	AuthorizationMethodsActions,
	AuthorizationMethodsSelectors,
} from 'reduxm/redux-saga/modules/authorization/methods'
import {
	AuthorizationPermissionsActions,
	AuthorizationPermissionsSelectors,
} from 'reduxm/redux-saga/modules/authorization/permissions'
import {
	AuthorizationRolesActions,
	AuthorizationRolesSelectors,
} from 'reduxm/redux-saga/modules/authorization/roles'
import {
	FilesActions,
	FilesSelectors,
} from 'reduxm/redux-saga/modules/control-panel/files'
import {
	ProjectsActions,
	ProjectsSelectors,
} from 'reduxm/redux-saga/modules/control-panel/projects'
import {
	ServicesActions,
	ServicesSelectors,
} from 'reduxm/redux-saga/modules/control-panel/services'
import {
	AuthenticationConfigActions,
	AuthenticationConfigSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/authentication'
import {
	AwsCredentialsActions,
	AwsCredentialsSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/aws'
import {
	FirebaseConfigActions,
	FirebaseConfigSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/firebase'
import {
	MailCredentialsActions,
	MailCredentialsSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/mail'
import {
	MySqlCredentialsActions,
	MySqlCredentialsSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/mysql'
import {
	RedisCredentialsActions,
	RedisCredentialsSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/redis'
import {
	ServiceSettingsActions,
	ServiceSettingsSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/service'
import {
	SocialConfigActions,
	SocialConfigSelectors,
} from 'reduxm/redux-saga/modules/control-panel/settings/social'
import {
	SitesActions,
	SitesSelectors,
} from 'reduxm/redux-saga/modules/control-panel/sites'
import {
	BaseCitiesActions,
	BaseCitiesSelectors,
} from 'reduxm/redux-saga/modules/geo/cities'
import {
	UserActions,
	UserSelectors,
} from 'reduxm/redux-saga/modules/users/actions'
import {
	UsersManageActions,
	UsersManageSelectors,
} from 'reduxm/redux-saga/modules/users/manage'
import {
	UsersManageSettingsActions,
	UsersManageSettingsSelectors,
} from 'reduxm/redux-saga/modules/users/manage/settings'

export const StateSelector = {
	controlPanel:  {
		projects: ProjectsSelectors,
		services: ServicesSelectors,
		sites:    SitesSelectors,
		files:    FilesSelectors,
		settings: {
			mysql:          MySqlCredentialsSelectors,
			redis:          RedisCredentialsSelectors,
			firebase:       FirebaseConfigSelectors,
			authentication: AuthenticationConfigSelectors,
			mail:           MailCredentialsSelectors,
			aws:            AwsCredentialsSelectors,
			service:        ServiceSettingsSelectors,
			social:         SocialConfigSelectors,
		},
	},
	auth:          {
		actions: AuthSelectors,
		bearer:  AuthBearerSelectors,
		jwt:     AuthJwtSelectors,
	},
	authorization: {
		actions:     AuthorizationSelectors,
		roles:       AuthorizationRolesSelectors,
		attributes:  AuthorizationAttributesSelectors,
		methods:     AuthorizationMethodsSelectors,
		filters:     AuthorizationFiltersSelectors,
		permissions: AuthorizationPermissionsSelectors,
	},
	users:         {
		actions:        UserSelectors,
		manage:         UsersManageSelectors,
		manageSettings: UsersManageSettingsSelectors,
		bearer:         AuthBearerSelectors,
	},
	geo:           {
		cities: BaseCitiesSelectors,
	},
}

export const DispatchSelector = {
	controlPanel:  {
		projects: ProjectsActions,
		services: ServicesActions,
		sites:    SitesActions,
		files:    FilesActions,
		settings: {
			mysql:          MySqlCredentialsActions,
			redis:          RedisCredentialsActions,
			firebase:       FirebaseConfigActions,
			mail:           MailCredentialsActions,
			aws:            AwsCredentialsActions,
			authentication: AuthenticationConfigActions,
			service:        ServiceSettingsActions,
			social:         SocialConfigActions,
		},
	},
	auth:          {
		actions: AuthActions,
		bearer:  AuthBearerActions,
		jwt:     AuthJwtActions,
	},
	authorization: {
		actions:     AuthorizationActions,
		roles:       AuthorizationRolesActions,
		attributes:  AuthorizationAttributesActions,
		methods:     AuthorizationMethodsActions,
		filters:     AuthorizationFiltersActions,
		permissions: AuthorizationPermissionsActions,
	},
	users:         {
		actions:        UserActions,
		manage:         UsersManageActions,
		manageSettings: UsersManageSettingsActions,
	},
	geo:           {
		cities: BaseCitiesActions,
	},
}
