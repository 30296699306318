import { callApi } from 'reduxm/redux-saga/api'

const methods = {
	create: 'authentication.bearer.create',
	remove: 'authentication.bearer.delete',
	view:   'authentication.bearer.view',
}

/**
 * Create bearer token.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * View bearer token.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

/**
 * Remove bearer token.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: *}}|{error: (*|string)}>|*>}
 */
const remove = searchQuery => callApi(methods.remove, searchQuery)

export const UsersBearerApi = {
	create,
	view,
	remove,
}
