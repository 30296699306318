import styles from 'components/global/TabsTable/styles.module.scss'
import PropTypes from 'prop-types'
import React from 'react'

/**
 * Render tabs container
 *
 * @param {Node} children
 * @param {Node} top
 *
 * @return {*}
 * @constructor
 */
export const TabsContainer = ({ children, top }) => (
	<div className={styles.container} style={{ marginTop: top }}>
		{children}
	</div>
)

TabsContainer.propTypes = {
	children: PropTypes.node,
	top:      PropTypes.number,
}

TabsContainer.defaultProps = {
	children: null,
	top:      0,
}
