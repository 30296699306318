import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * City model class
 */
class City extends BaseModel {
	/**
	 * Create City instance
	 *
	 * @param {Object} object
	 *
	 * @returns {City}
	 */
	static create(object) {
		return super.create(object?.result?.model)
	}

	/**
	 * Create list City
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<City>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Get id
	 *
	 * @returns {number}
	 */
	getId = () => this.model.id

	/**
	 * Get country id
	 *
	 * @returns {number}
	 */
	getCountryId = () => this.model.countryId

	/**
	 * Get type
	 *
	 * @returns {string}
	 */
	getType = () => this.model.type

	/**
	 * Get code
	 *
	 * @returns {string}
	 */
	getCode = () => this.model.code

	/**
	 * Get title
	 *
	 * @returns {string}
	 */
	getTitle = () => this.model.title

	/**
	 * Get title prepositional
	 *
	 * @returns {string}
	 */
	getTitlePrepositional = () => this.model.titlePrepositional

	/**
	 * Get region
	 *
	 * @returns {string}
	 */
	getRegion = () => this.model.region

	/**
	 * Get area
	 *
	 * @returns {string}
	 */
	getArea = () => this.model.area

	/**
	 * Get village council
	 *
	 * @returns {string}
	 */
	getVillageCouncil = () => this.model.villageCouncil

	/**
	 * Is capital
	 *
	 * @returns {number}
	 */
	getIsCapital = () => this.model.isCapital

	/**
	 * Is regional center
	 *
	 * @returns {number}
	 */
	getIsRegionalCenter = () => this.model.isRegionalCenter

	/**
	 * Is virtual
	 *
	 * @returns {number}
	 */
	getIsVirtual = () => this.model.isVirtual

	/**
	 * Get time zone
	 *
	 * @returns {number}
	 */
	getTimeZone = () => this.model.timeZone

	/**
	 * Is default
	 *
	 * @returns {number}
	 */
	getIsDefault = () => this.model.isDefault

	/**
	 * Is enable
	 *
	 * @returns {number}
	 */
	getIsEnable = () => this.model.isEnable

	/**
	 * Get latitude
	 *
	 * @returns {number}
	 */
	getLatitude = () => this.model.latitude

	/**
	 * Get longitude
	 *
	 * @returns {number}
	 */
	getLongitude = () => this.model.longitude

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default City
