import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { TokenField } from './index'

const { auth: { bearer: { view } } }                                                      = StateSelector
const { auth: { bearer: { create: createToken, remove: removeToken, view: viewToken } } } = DispatchSelector

const mapStateToProps = state => ({
	tokenState: view(state),
})

const mapDispatchToProps = { createToken, removeToken, viewToken }

export default connect(mapStateToProps, mapDispatchToProps)(TokenField)
