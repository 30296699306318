import { makeStyles } from '@material-ui/core/styles'
import backgroundImg from 'assets/images/home.jpg'

export const useStyles = makeStyles(theme => ({
	root:   { height: '100vh' },
	signIn: { position: 'relative' },
	image:  {
		backgroundImage:    `url(${backgroundImg})`,
		backgroundRepeat:   'no-repeat',
		backgroundColor:
							theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
		backgroundSize:     'cover',
		backgroundPosition: 'center',
	},
	paper:  {
		margin:        theme.spacing(8, 4),
		display:       'flex',
		flexDirection: 'column',
		alignItems:    'center',
	},
	avatar: {
		margin:          theme.spacing(1),
		backgroundColor: theme.palette.secondary.main,
	},
	form:   {
		width:     '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
}))
