import AttributesField from 'forms/authorization/methods/fields/Attributes/index.store'
import React from 'react'

export const methodsFields = [
	{ id: 'name', title: 'Имя' },
	{ id: 'alias', title: 'Алиас' },
	{ id: 'serviceAlias', title: 'Алиас сервиса' },
	{ id: 'attributeInId', title: 'Входящие атрибуты', component: <AttributesField /> },
	{ id: 'attributeOutId', title: 'Исходящие атрибуты', component: <AttributesField /> },
]
