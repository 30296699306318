import {
	MultipleInput,
	ServiceOptions,
} from 'forms/fields'
import React from 'react'

export const mySqlCredentialFields = (excluded, currentService) => [
	{
		id:       'service',
		title:    'Сервис',
		type:     'select',
		children: <ServiceOptions excluded={excluded} currentService={currentService} />,
	},
	{ id: 'host', title: 'Хост' },
	{ id: 'port', title: 'Порт', type: 'number' },
	{ id: 'database', title: 'База данных' },
	{ id: 'user', title: 'Пользователь' },
	{ id: 'password', title: 'Пароль' },
	{ id: 'slaves', title: 'Дополнительные хосты', keyName: '', component: <MultipleInput /> },
]
