import { connect } from 'react-redux'
import { StateSelector } from 'reduxm/modules/selectors'
import { HomePage } from './index'

const mapStateToProps = state => ({
	isFetching:
		StateSelector.controlPanel.projects.list(state).fetching ||
		StateSelector.controlPanel.projects.remove(state).fetching ||
		StateSelector.controlPanel.projects.create(state).fetching ||
		StateSelector.controlPanel.projects.update(state).fetching,
})

export default connect(mapStateToProps, null)(HomePage)
