import {
	put,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import { AuthBearerActions } from 'reduxm/redux-saga/modules/authentication/bearer/actionCreators'
import { AUTH_BEARER_ACTIONS } from 'reduxm/redux-saga/modules/authentication/bearer/actionTypes'
import { UsersBearerApi } from 'reduxm/redux-saga/modules/authentication/bearer/api'

/**
 * Token response handler
 *
 * @param {Object}response
 *
 * @return {Generator<*, void, ?>}
 */
function* tokenResponseHandler(response) {
	if (!response.error) {
		yield put(AuthBearerActions.setView({ model: response.result?.model }))
	}
}

/**
 * View bearer model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, UsersBearerApi.view, null, ResponseHandler.addProjectOrigin)
}

/**
 * Create bearer model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, UsersBearerApi.create, null, ResponseHandler.addProjectOrigin, tokenResponseHandler)
}

/**
 * Remove bearer model.
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, UsersBearerApi.remove, null, ResponseHandler.addProjectOrigin, tokenResponseHandler)
}

export default [
	takeLatest(AUTH_BEARER_ACTIONS.VIEW, view),
	takeLatest(AUTH_BEARER_ACTIONS.CREATE, create),
	takeLatest(AUTH_BEARER_ACTIONS.REMOVE, remove),
]
