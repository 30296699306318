import avatar from 'assets/images/default_avatar.png'
import { ButtonsGroup } from 'components/global/AsidePanel/ButtonsGroup'
import { Title } from 'components/global/Title'
import { BirthdayInput } from 'components/user/BirthdayInput'
import { InputWrapper } from 'components/user/InputWrapper'
import { UserAvatar } from 'components/user/UserAvatar'
import styles from 'containers/User/styles.module.scss'
import { User } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'
import { userForm } from './form'

/**
 * Render User profile
 *
 * @param {Object} userState
 * @param {Object} history
 * @param {Function} updateUser
 *
 * @return {*}
 * @constructor
 */
export const UserData = ({ userState, history, updateUser }) => {
	const user = User.create(userState)

	const [userData, setUserData] = useState({
		...user.getAttributes(),
		email:  user.getSettings().getEmail(),
		status: user.getStatus(true),
		gender: user.getGender(true),
	})

	/**
	 * Set userData to state
	 *
	 * @param {Object} event
	 *
	 * @return {void}
	 */
	const dataToggle = event => setUserData({
		...userData,
		[event.target.name]: event.target.value,
	})

	const onUpdate = useCallback(event => {
		event.preventDefault()

		updateUser(userData)
		history.push('/')
	}, [updateUser, userData, history])

	return (
		<>
			<Title breadcrumbs name="Персональные данные" />
			<section className={styles.wrapper}>
				<UserAvatar image={avatar} />
				<form onSubmit={onUpdate} className={styles.inputsWrapper}>
					{userForm.map(input => (
						<InputWrapper
							key={input.name}
							name={input.name}
							title={input.title}
							disabled={input.disabled}
							onChange={dataToggle}
							value={userData[input.name] || ''}
							placeholder="Нет данных..."
						/>
					))}
					<BirthdayInput groupText />
					<ButtonsGroup cancelAction={() => history.push('/')} cancelTitle="На главную" />
				</form>
			</section>
		</>
	)
}

UserData.propTypes = {
	history:    PropTypes.object,
	updateUser: PropTypes.func,
	userState:  PropTypes.object,
}

UserData.defaultProps = {
	history:    {},
	updateUser: () => null,
	userState:  {},
}
