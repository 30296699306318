import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { USERS_MANAGE_ACTIONS } from 'reduxm/redux-saga/modules/users/manage/actionTypes'

export const UsersManageActions = {
	list:    RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_ACTIONS.LIST),
	create:  RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_ACTIONS.CREATE),
	update:  RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_ACTIONS.UPDATE),
	view:    RequestActionHelper.getActionCreatorFetch(USERS_MANAGE_ACTIONS.VIEW),
	setList: RequestActionHelper.getActionCreatorSuccess(USERS_MANAGE_ACTIONS.LIST),
	setView: RequestActionHelper.getActionCreatorSuccess(USERS_MANAGE_ACTIONS.VIEW),
}
