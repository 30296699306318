import { callApi } from 'reduxm/redux-saga/api'

export const methods = {
	list:   'users.manage.list',
	create: 'users.manage.create',
	update: 'users.manage.update',
	view:   'users.manage.view',
}

/**
 * Call api to get list of user models.
 *
 * @param {Object} searchQuery
 *
 * @return {function}
 */
const list = searchQuery => callApi(methods.list, searchQuery)

/**
 * Call api to update user model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {function}
 */
const update = searchQuery => callApi(methods.update, searchQuery)

/**
 * Call api to create user model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const create = searchQuery => callApi(methods.create, searchQuery)

/**
 * Call api to view user model.
 *
 * @param {SearchQuery} searchQuery
 *
 * @return {IterableIterator<Promise<{response: {response: Response, json: any}}|{error: (*|string)}>|*>}
 */
const view = searchQuery => callApi(methods.view, searchQuery)

export const UsersManageApi = {
	list,
	update,
	create,
	view,
}
