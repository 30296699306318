import { AUTHORIZATION_PERMISSIONS_ACTIONS } from 'reduxm/redux-saga/modules/authorization/permissions/actionTypes'

export const AuthorizationPermissionsSelectors = {
	list:        state => state.authorization.permissions[AUTHORIZATION_PERMISSIONS_ACTIONS.LIST],
	create:      state => state.authorization.permissions[AUTHORIZATION_PERMISSIONS_ACTIONS.CREATE],
	update:      state => state.authorization.permissions[AUTHORIZATION_PERMISSIONS_ACTIONS.UPDATE],
	remove:      state => state.authorization.permissions[AUTHORIZATION_PERMISSIONS_ACTIONS.REMOVE],
	view:        state => state.authorization.permissions[AUTHORIZATION_PERMISSIONS_ACTIONS.VIEW],
	batchCreate: state => state.authorization.permissions[AUTHORIZATION_PERMISSIONS_ACTIONS.BATCH_CREATE],
}
