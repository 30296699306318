import Switch from '@material-ui/core/Switch'
import { Button } from 'components/global/Button'
import PropTypes from 'prop-types'
import React from 'react'
import './styles.scss'

/**
 * Render custom switch input
 *
 * @param {boolean} checked
 * @param {string} label
 * @param {Function} action
 * @param {Object} style
 *
 * @return {*}
 * @constructor
 */
export const IosSwitch = ({ checked, label, action, style }) => (
	<div className="ios-btn" style={style}>
		{label}
		<Button onClick={action}>
			<Switch checked={checked} />
		</Button>
	</div>
)

IosSwitch.propTypes = {
	action:  PropTypes.func,
	checked: PropTypes.bool,
	label:   PropTypes.string,
	style:   PropTypes.object,
}

IosSwitch.defaultProps = {
	action:  () => null,
	checked: false,
	label:   '',
	style:   {},
}
