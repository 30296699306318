import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * RedisCredentials model class
 */
class RedisCredentials extends BaseModel {
	/**
	 * Create RedisCredentials instance
	 *
	 * @param {Object} object
	 *
	 * @returns {RedisCredentials}
	 */
	static create(object) {
		return super.create(object?.result?.model || object.result || object)
	}

	/**
	 * Create RedisCredentials list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<RedisCredentials>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map RedisCredentials
	 *
	 * @param {Array.<object>} objects
	 * @param {function(RedisCredentials, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get project id
	 *
	 * @returns {number}
	 */
	getProjectId = () => this.model.projectId

	/**
	 * Get service alias
	 *
	 * @returns {string}
	 */
	getServiceAlias = (asLabel = false) => (asLabel &&
		this.model.service === '*' && 'Все') || this.model.service

	/**
	 * Get host
	 *
	 * @returns {string}
	 */
	getHost = () => this.model.host

	/**
	 * Get port
	 *
	 * @returns {number}
	 */
	getPort = () => this.model.port

	/**
	 * Get password
	 *
	 * @returns {string}
	 */
	getPassword = () => this.model.password

	/**
	 * Get database
	 *
	 * @returns {string}
	 */
	getDatabase = () => this.model.database

	/**
	 * Get srv
	 *
	 * @returns {string}
	 */
	getSrv = () => this.model.srv || ''

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default RedisCredentials
