import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTHORIZATION_ROLES_ACTIONS } from 'reduxm/redux-saga/modules/authorization/roles/actionTypes'

const ACTIONS = {
	...AUTHORIZATION_ROLES_ACTIONS,
	[AUTHORIZATION_ROLES_ACTIONS.LIST]:
		[AUTHORIZATION_ROLES_ACTIONS.LIST, DataProvider.getDefaultState()],
	[AUTHORIZATION_ROLES_ACTIONS.SEARCH_LIST]:
		[AUTHORIZATION_ROLES_ACTIONS.SEARCH_LIST, DataProvider.getDefaultState()],
}
export default RequestActionHelper.createReducerActions(Object.values(ACTIONS))
