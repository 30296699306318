import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import Drawer from '@material-ui/core/Drawer'
import { Edit } from '@material-ui/icons'
import { AsidePanel } from 'components/global/AsidePanel'
import { ModelForm } from 'components/global/AsidePanel/ModelForm'
import { Button } from 'components/global/Button'
import { Tip } from 'components/global/Tip'
import styles from 'components/global/UpdateModel/styles.module.scss'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useState,
} from 'react'

/**
 * Render toggle right menu
 *
 * @param {string} modelType
 * @param {Service|Project} model
 * @param {Function} action
 * @param {boolean} tip
 * @param {Object} children
 * @param {Function} callBack
 *
 * @return {*}
 * @constructor
 */
export const UpdateModel = ({
	formFields,
	title,
	model,
	action,
	tip,
	children,
	callBack,
}) => {
	const [isActive, setIsActive] = useState(false)

	const setActive = () => setIsActive(true)

	const close = useCallback(() => {
		setIsActive(false)

		if (callBack) {
			callBack()
		}
	}, [callBack])

	return (
		<Tip title={tip ? 'Редактировать' : ''}>
			<div className={styles.menuWrapper}>
				{children ?
					React.cloneElement(children, { onClick: setActive }) :
					<Button className={styles.btn} onClick={setActive}>
						<Edit className={styles.editIcon} />
					</Button>}
				<Drawer
					anchor="right"
					open={isActive}
					onClose={close}
				>
					<AsidePanel title={title}>
						<ModelForm
							model={model}
							fields={formFields}
							action={action}
							close={close}
						/>
					</AsidePanel>
				</Drawer>
			</div>
		</Tip>
	)
}

UpdateModel.propTypes = {
	model:      PropTypes.instanceOf(BaseModel),
	action:     PropTypes.func,
	callBack:   PropTypes.func,
	children:   PropTypes.object,
	formFields: PropTypes.array.isRequired,
	tip:        PropTypes.bool,
	title:      PropTypes.string,
}

UpdateModel.defaultProps = {
	model:    BaseModel.create({}),
	action:   () => null,
	callBack: () => null,
	children: null,
	tip:      false,
	title:    '',
}
