import { CloseRounded as Cancel } from '@material-ui/icons'
import { Button as CustomButton } from 'components/global/Button'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import PropTypes from 'prop-types'
import React, { useRef } from 'react'
import { Button } from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render remove file warning
 *
 * @param {Function} action
 * @param {Function} setActive
 * @param {boolean} isActive
 *
 * @return {*}
 * @constructor
 */
export const RemoveFileWarning = ({ isActive, action, setActive }) => {
	const containerRef = useRef()

	useCheckOutsideClick(containerRef, isActive, setActive, isActive)

	return (
		isActive &&
		<div className={styles.alert} ref={containerRef}>
			<div>Удалить безвозвратно?</div>
			<div className={styles.buttons}>
				<Button onClick={action} color="danger">Да</Button>
				<Button onClick={() => setActive(false)}>Нет</Button>
			</div>
			<CustomButton className={styles.close}>
				<Cancel onClick={() => setActive(false)} />
			</CustomButton>
		</div>
	)
}

RemoveFileWarning.propTypes = {
	action:    PropTypes.func,
	setActive: PropTypes.func,
	isActive:  PropTypes.bool,
}

RemoveFileWarning.defaultProps = {
	action:    () => null,
	setActive: () => null,
	isActive:  false,
}
