import { isFetching as _ } from 'helpers/methods'
import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { RedisCredentialsTable } from './index'

const { controlPanel: { settings: { redis: { list, create, update, remove, view } } } } = StateSelector

const mapStateToProps = state => ({
	redisCredentialsState: list(state),
	isFetching:            _(list(state), create(state), update(state), remove(state), view(state)),
})

const mapDispatchToProps = {
	getRedisCredentials:    DispatchSelector.controlPanel.settings.redis.list,
	createRedisCredentials: DispatchSelector.controlPanel.settings.redis.create,
	updateRedisCredentials: DispatchSelector.controlPanel.settings.redis.update,
	removeRedisCredentials: DispatchSelector.controlPanel.settings.redis.remove,
	viewRedisCredentials:   DispatchSelector.controlPanel.settings.redis.view,
}

export default connect(mapStateToProps, mapDispatchToProps)(RedisCredentialsTable)
