import Radio from '@material-ui/core/Radio'
import { withStyles } from '@material-ui/styles'
import React from 'react'

const color = '#009b00'

/**
 * Render custom colored radio button
 *
 * @type {React.ComponentType}
 */
export const RadioButton = withStyles({
	root:    {
		'&$checked': { color },
	},
	checked: {},
})(props => <Radio color="default" {...props} />)
