import { EditRounded as Edit } from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { TableSearch } from 'components/global/Table/TableSearch'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/headerTitles'
import styles from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/MethodsTable/styles.module.scss'
import { attributesFields } from 'forms/authorization/attributes'
import { methodsFields } from 'forms/authorization/methods'
import { Service } from 'models'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useRef,
} from 'react'
import { EmptyResultMessage } from '../EmptyList/EmptyResultMessage'

const tableName = 'methods'

/**
 * Render methods table
 *
 * @param {boolean} isActive
 * @param {Array.<AuthorizationMethods>} methods
 * @param {Service} currentService
 * @param {Function} modifySearchQuery
 * @param {Function} setSearchString
 * @param {Function} createMethods
 * @param {Function} updateMethods
 * @param {Function} removeMethods
 * @param {Function} updateAttributes
 * @param {Element} pagination
 * @param {number} totalItems
 *
 * @return {*}
 * @constructor
 */
export const MethodsTable = ({
	isActive,
	methods,
	currentService,
	modifySearchQuery,
	setSearchString,
	createMethods,
	updateMethods,
	removeMethods,
	updateAttributes,
	pagination,
	totalItems,
}) => {
	const searchRef = useRef()

	useEffect(() => {
		if (isActive) {
			searchRef.current.value = ''
		}
	}, [currentService, isActive])

	return (isActive &&
		<Table>
			<TableInfoWrapper>
				<ModelsCounter count={totalItems} />
				<TableSearch onChange={setSearchString} innerRef={searchRef} />
				<CreateModelButton
					action={searchQuery => modifySearchQuery(searchQuery, createMethods)}
					initialData={{ serviceAlias: currentService.getAlias() }}
					formFields={methodsFields}
					title="Создать метод"
				/>
			</TableInfoWrapper>
			{methods.length ?
				<>
					<TableHeader titles={headerTitles} tableName={tableName} />
					{methods.map(method => (
						<TableWrapper key={`${method.getAlias()}`}>
							<TableRow tableName={tableName}>
								<TableCol title={method.getId()} />
								<TableCol>
									<TableNameCol>{method.getName(true)}</TableNameCol>
								</TableCol>
								<TableCol title={method.getAlias()} />
								<TableCol>
									<UpdateModel
										model={method.getAttributeIn()}
										action={searchQuery => modifySearchQuery(searchQuery, updateAttributes)}
										formFields={attributesFields}
										title="Редактировать входящие атрибуты"
									>
										<Button className={styles.editAttribute}>
											<Edit />
											{method.getAttributeIn().getName(true)}
										</Button>
									</UpdateModel>
								</TableCol>
								<TableCol>
									<UpdateModel
										model={method.getAttributeOut()}
										action={searchQuery => modifySearchQuery(searchQuery, updateAttributes)}
										formFields={attributesFields}
										title="Редактировать исходящие атрибуты"
									>
										<Button className={styles.editAttribute}>
											<Edit />
											{method.getAttributeOut().getName(true)}
										</Button>
									</UpdateModel>
								</TableCol>
							</TableRow>
							<TableActions>
								<UpdateModel
									model={method}
									action={searchQuery => modifySearchQuery(searchQuery, updateMethods)}
									formFields={methodsFields}
									title="Редактировать метод"
									tip
								/>
								<RemoveModel
									id={{ id: method.getId() }}
									modelName={method.getName()}
									action={removeMethods}
									actionName="удалить"
									tip
								/>
							</TableActions>
						</TableWrapper>))}
				</> :
				<EmptyResultMessage message="Ничего не найдено..." />}
			{pagination}
		</Table>
	)
}

MethodsTable.propTypes = {
	createMethods:     PropTypes.func,
	methods:           PropTypes.array,
	currentService:    PropTypes.instanceOf(Service),
	isActive:          PropTypes.bool,
	modifySearchQuery: PropTypes.func,
	pagination:        PropTypes.element,
	removeMethods:     PropTypes.func,
	setSearchString:   PropTypes.func,
	totalItems:        PropTypes.number,
	updateAttributes:  PropTypes.func,
	updateMethods:     PropTypes.func,
}

MethodsTable.defaultProps = {
	createMethods:     () => null,
	methods:           [],
	currentService:    Service.create({}),
	isActive:          false,
	modifySearchQuery: () => null,
	pagination:        null,
	removeMethods:     () => null,
	setSearchString:   () => null,
	totalItems:        0,
	updateAttributes:  () => null,
	updateMethods:     () => null,
}
