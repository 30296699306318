import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { PROJECT_REDIS_CREDENTIALS_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/settings/redis/actionTypes'

export const RedisCredentialsActions = {
	list:    RequestActionHelper.getActionCreatorFetch(PROJECT_REDIS_CREDENTIALS_ACTIONS.LIST),
	setList: RequestActionHelper.getActionCreatorSuccess(PROJECT_REDIS_CREDENTIALS_ACTIONS.LIST),
	create:  RequestActionHelper.getActionCreatorFetch(PROJECT_REDIS_CREDENTIALS_ACTIONS.CREATE),
	remove:  RequestActionHelper.getActionCreatorFetch(PROJECT_REDIS_CREDENTIALS_ACTIONS.REMOVE),
	update:  RequestActionHelper.getActionCreatorFetch(PROJECT_REDIS_CREDENTIALS_ACTIONS.UPDATE),
	view:    RequestActionHelper.getActionCreatorFetch(PROJECT_REDIS_CREDENTIALS_ACTIONS.VIEW),
}
