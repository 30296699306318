import { Spinner } from 'components/global/Spinner'
import { Title } from 'components/global/Title'
import { ServiceFullInfoCard } from 'components/project/Home/Cards/ServiceFullInfoCard'
import { ConnectedProjects } from 'components/service/ConnectedProjects'
import {
	Project,
	Service,
} from 'models'
import PropTypes from 'prop-types'
import React, {
	useEffect,
	useMemo,
} from 'react'
import {
	Button,
	Col,
	Container,
	Row,
} from 'reactstrap'
import styles from './styles.module.scss'

/**
 *  * Render service page
 *
 * @param {Object} match
 * @param {Function} getService
 * @param {Object} serviceState
 * @param {Function} getProjects
 * @param {Object} projectsState
 * @param {boolean} isFetching
 * @param {Object} history
 *
 * @return {*}
 * @constructor
 */
export const ServicePage = ({
	history,
	match,
	getService,
	serviceState,
	projectsState,
	getProjects,
	isFetching,
}) => {
	const { params: { alias } } = match

	useEffect(() => {
		getService({ alias })
		getProjects({
			query: { expands: ['Services'] },
		})
	}, [alias, getService, getProjects])

	const projects = Project.createList(projectsState)
	const service  = Service.create(serviceState)

	const connectedProjects = useMemo(() => projects.filter(project => project.getServices()
		.filter(microservice => microservice.getAlias() === service.getAlias()).length), [projects, service])

	return (
		<section className={styles.section}>
			<Spinner block isFetching={isFetching} />
			<Title breadcrumbs name={`Сервис ${service.getTitle()}`} />
			<Container className="mt-5 position-relative">
				<Row className="justify-content-md-center justify-content-lg-start">
					<Col xs={12} md={6} className="mb-5">
						<ServiceFullInfoCard service={service} connectedProjects={connectedProjects} />
					</Col>
					<Col xs={12} md={6}>
						<ConnectedProjects projects={connectedProjects} />
						<div className={styles.btnWrapper}>
							<Button
								className={styles.btn}
								color="primary"
								onClick={() => history.push('/services')}
							>
								Назад
							</Button>
						</div>
					</Col>
				</Row>
			</Container>
		</section>
	)
}

ServicePage.propTypes = {
	getProjects:   PropTypes.func,
	getService:    PropTypes.any,
	history:       PropTypes.object,
	isFetching:    PropTypes.bool,
	match:         PropTypes.object,
	projectsState: PropTypes.object,
	serviceState:  PropTypes.object,
}

ServicePage.defaultProps = {
	getProjects:   () => null,
	getService:    () => null,
	history:       {},
	isFetching:    false,
	match:         {},
	projectsState: {},
	serviceState:  {},
}
