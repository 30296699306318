import 'forms/fields/JsonInput/styles.css'
import JSONEditor from 'jsoneditor'
import 'jsoneditor/dist/jsoneditor.css'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

/**
 * Render JSON editor
 *
 * @return {*}
 * @constructor
 */
export class JsonEditor extends Component {
	componentDidMount() {
		const { json, text, ...options } = this.props

		this.jsoneditor = new JSONEditor(this.container, options)

		if (text) {
			this.jsoneditor.setText(text)
		}
	}

	componentDidUpdate() {
		const { text, mode } = this.props

		if (text) {
			this.jsoneditor.updateText(text)
		}
		if (mode) {
			this.jsoneditor.setMode(mode)
		}
	}

	componentWillUnmount() {
		if (this.jsoneditor) {
			this.jsoneditor.destroy()
		}
	}

	render() {
		return (
			<div
				className="jsoneditor-react-container"
				ref={elem => this.container = elem}
			/>
		)
	}
}

JsonEditor.propTypes = {
	json: PropTypes.string,
	text: PropTypes.string,
	mode: PropTypes.string,
}

JsonEditor.defaultProps = {
	json: '',
	text: '',
	mode: '',
}
