import MicroserviceConfig from 'components/project/ServiceConfig/MicroserviceConfig/index.store'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render authorization config tab
 *
 * @param {number} projectId
 * @param {Function} updateServiceConfig
 *
 * @return {*}
 * @constructor
 */
export const ConfigTab = ({ projectId, updateServiceConfig }) => (
	<MicroserviceConfig
		service="authorization"
		type="microservice"
		projectId={projectId}
		className={styles.container}
		updateService={updateServiceConfig}
	/>
)

ConfigTab.propTypes = {
	projectId:           PropTypes.number,
	updateServiceConfig: PropTypes.func.isRequired,
}

ConfigTab.defaultProps = {
	projectId: null,
}
