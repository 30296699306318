import { CheckBox } from 'components/global/Inputs/Checkbox'
import { InputLabel } from 'components/global/Inputs/InputLabel'
import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

const settings = [
	{ label: 'AWS', value: 'AwsCredentials' },
	{ label: 'MYSQL', value: 'MysqlCredentials' },
	{ label: 'REDIS', value: 'RedisCredentials' },
	{ label: 'MAIL', value: 'MailCredentials' },
]

/**
 * Render settings checkboxes
 *
 * @param {Array} currentValues
 * @param {Function} handler
 *
 * @return {*[]}
 * @constructor
 */
export const SettingCheckboxes = ({ currentValues, handler }) => (
	<div className={styles.checkboxes}>
		{settings.map(setting => {
			const isChecked = currentValues.includes(setting.value)

			return (
				<InputLabel
					key={setting.label}
					label={setting.label}
					checked={isChecked}
				>
					<CheckBox
						value={setting.value}
						onChange={event => handler(event.target.value, isChecked)}
						checked={isChecked}
					/>
				</InputLabel>
			)
		})}
	</div>
)

SettingCheckboxes.propTypes = {
	handler:       PropTypes.func,
	currentValues: PropTypes.array,
}

SettingCheckboxes.defaultProps = {
	handler:       () => null,
	currentValues: [],
}
