import RequestActionHelper from '@kakadu-dev/base-frontend-helpers/helpers/Redux/RequestActionHelper'
import { AUTHORIZATION_FILTERS_ACTIONS } from 'reduxm/redux-saga/modules/authorization/filters/actionTypes'

export const AuthorizationFiltersActions = {
	list:    RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_FILTERS_ACTIONS.LIST),
	create:  RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_FILTERS_ACTIONS.CREATE),
	update:  RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_FILTERS_ACTIONS.UPDATE),
	view:    RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_FILTERS_ACTIONS.VIEW),
	remove:  RequestActionHelper.getActionCreatorFetch(AUTHORIZATION_FILTERS_ACTIONS.REMOVE),
	setList: RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_FILTERS_ACTIONS.LIST),
	setView: RequestActionHelper.getActionCreatorSuccess(AUTHORIZATION_FILTERS_ACTIONS.VIEW),
}
