import { takeLatest } from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import ResponseHandler from 'reduxm/redux-saga/helpers/ResponseHandler'
import {
	MySqlCredentialsActions,
	MySqlCredentialsSelectors,
	PROJECT_MYSQL_CREDENTIALS_ACTIONS,
} from 'reduxm/redux-saga/modules/control-panel/settings/mysql'
import { MySqlCredentialsApi } from 'reduxm/redux-saga/modules/control-panel/settings/mysql/api'

/**
 *  Get services list
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* list(action) {
	yield defaultAction(action, MySqlCredentialsApi.list)
}

/**
 * View service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, MySqlCredentialsApi.view)
}

/**
 * Remove service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, MySqlCredentialsApi.remove)
}

/**
 * Updated service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, MySqlCredentialsApi.update, null, null,
		function* (...params) {
			yield ResponseHandler.afterUpdateModel(...params, MySqlCredentialsSelectors, MySqlCredentialsActions)
		})
}

/**
 * Create service model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, MySqlCredentialsApi.create, null, null,
		function* (...params) {
			yield ResponseHandler.afterCreateModel(...params, MySqlCredentialsSelectors.list,
				MySqlCredentialsActions.setList)
		})
}

export default [
	takeLatest(PROJECT_MYSQL_CREDENTIALS_ACTIONS.LIST, list),
	takeLatest(PROJECT_MYSQL_CREDENTIALS_ACTIONS.CREATE, create),
	takeLatest(PROJECT_MYSQL_CREDENTIALS_ACTIONS.REMOVE, remove),
	takeLatest(PROJECT_MYSQL_CREDENTIALS_ACTIONS.UPDATE, update),
	takeLatest(PROJECT_MYSQL_CREDENTIALS_ACTIONS.VIEW, view),
]
