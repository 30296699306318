import DateFnsUtils from '@date-io/date-fns'
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { ru } from 'date-fns/locale'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { InputGroupText } from 'reactstrap'
import './styles.css'

/**
 * Render birthday input
 *
 * @param {string} id
 * @param {string} value
 * @param {Function} onChange
 * @param {boolean} groupText
 * @param {boolean} disabled
 *
 * @return {*}
 * @constructor
 */
export const BirthdayInput = ({ id, value, onChange, groupText, disabled }) => {
	const [date, setDate] = useState('1990-01-01')

	const toggle = nextDate => setDate(nextDate)
	return (
		<>
			<div className="birthday-input-container">
				{groupText &&
				<InputGroupText>
					День рождения
				</InputGroupText>}
				<MuiPickersUtilsProvider locale={ru} utils={DateFnsUtils}>
					<KeyboardDatePicker
						id={id}
						value={value || date}
						onChange={onChange || toggle}
						className="date-picker"
						format="dd/MM/yyyy"
						minDate="1940-01-01"
						invalidDateMessage="Введите правильную дату в формате дд/мм/гггг"
						cancelLabel="Отмена"
						disabled={disabled}
					/>
				</MuiPickersUtilsProvider>
			</div>
			{groupText && <br />}
		</>
	)
}

BirthdayInput.propTypes = {
	disabled:  PropTypes.bool,
	groupText: PropTypes.bool,
	id:        PropTypes.string,
	onChange:  PropTypes.func,
	value:     PropTypes.any,
}

BirthdayInput.defaultProps = {
	disabled:  false,
	groupText: false,
	id:        '',
	onChange:  null,
	value:     null,
}
