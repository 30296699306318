import {
	WallpaperRounded as Image,
	CloudUploadRounded as Upload,
} from '@material-ui/icons'
import { getFile } from 'components/global/Dropzone/utils'
import PropTypes from 'prop-types'
import React from 'react'
import { useDropzone } from 'react-dropzone'
import { Button } from 'reactstrap'
import './dropzone.scss'
import { ImagesPreview } from './ImagesPreview'

/**
 * Render dropzone
 *
 * @param {Object} files
 * @param {Function} setFiles
 * @param {Function} onDelete
 * @param {string} maxSize
 * @param {number} maxFiles
 * @param {string} accept
 * @param {string} title
 * @param {boolean} disabled
 *
 * @return {*}
 * @constructor
 */
export const Dropzone = ({
	files,
	setFiles,
	maxSize,
	maxFiles,
	accept,
	title,
	onDelete,
	disabled,
}) => {
	const { getRootProps, getInputProps, open } = useDropzone({
		accept,
		maxSize,
		onDrop:     acceptedFiles => acceptedFiles
			.slice(0, maxFiles - files.length)
			.forEach(data => getFile(data).then(base64Obj => setFiles(prev => [...prev, base64Obj]))),
		multiple:   maxFiles > 1,
		noClick:    true,
		noKeyboard: true,
		disabled,
	})

	return (
		<div className="dropzone-container">
			<div id="dropzone" {...getRootProps({ className: 'dropzone' })}>
				<input {...getInputProps()} />
				<div className="dropzone__title">{title}</div>
				<div className="dropzone__btn-wrapper">
					<span>или</span>
					<Button
						className="dropzone__add-file-btn"
						color="success"
						onClick={open}
						disabled={maxFiles === files.length || disabled}
					>
						<Upload />
						<span>Выберите</span>
					</Button>
				</div>
				<div className="dropzone__file-info">
					<span>Разрешенные форматы:</span>
					<span>{accept}</span>
				</div>
				<div className="dropzone__file-info">
					<span>Максимальный размер файла:</span>
					<span>{`${maxSize / 1024}kb`}</span>
				</div>
				<div className="dropzone__files-counter">
					<Image />
					{`Изображений ${files.length} из ${maxFiles}`}
				</div>
			</div>
			<ImagesPreview
				title="Загруженные изображения"
				files={files.filter(file => file.isLoaded)}
				onDelete={onDelete}
			/>
			<ImagesPreview
				title="Ожидают загрузки"
				files={files.filter(file => !file.isLoaded)}
				onDelete={onDelete}
			/>
		</div>
	)
}

Dropzone.propTypes = {
	accept:   PropTypes.string,
	disabled: PropTypes.bool,
	files:    PropTypes.array,
	maxFiles: PropTypes.number,
	maxSize:  PropTypes.number,
	onDelete: PropTypes.func,
	setFiles: PropTypes.func,
	title:    PropTypes.string,
}

Dropzone.defaultProps = {
	accept:   'image/*',
	disabled: false,
	files:    [],
	maxFiles: 1,
	maxSize:  null,
	onDelete: () => null,
	setFiles: () => null,
	title:    'Перетащите файлы сюда',
}
