import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render aside panel
 *
 * @param {string} title
 * @param {Node} children
 *
 * @return {*}
 * @constructor
 */
export const AsidePanel = ({ title, children }) => (
	<div className={styles.list} role="presentation">
		<div className={styles.title}>
			{title}
		</div>
		{children}
	</div>
)

AsidePanel.propTypes = {
	children: PropTypes.node,
	title:    PropTypes.string,
}

AsidePanel.defaultProps = {
	children: null,
	title:    '',
}
