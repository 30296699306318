import { TimezoneOptions } from 'components/global/AsidePanel/ModelForm/TimezoneOptions'
import CopyProjectSettings from 'forms/control-panel/project/fields/CopyProjectSettings/index.store'
import { MultipleInput } from 'forms/fields'
import React from 'react'
import { ServiceOptions } from './fields/ServiceOptions'

export * from './fields/Service'

const copyProjectSettings = (isEditing = false) => ({
	id:        'copyProjectSettings',
	title:     'Копировать настройки проекта',
	component: <CopyProjectSettings isEditing={isEditing} />,
})

export const projectFormFields = (isModelExist = false) => {
	const fields = [
		{ id: 'title', title: 'Название' },
		{ id: 'alias', title: 'Алиас', ...(isModelExist && { disabled: true }) },
		{ id: 'domain', title: 'Домен' },
		{ id: 'otherDomains', title: 'Другие домены', component: <MultipleInput /> },
		{ id: 'timezone', title: 'Временная зона', children: <TimezoneOptions />, type: 'select' },
		{ id: 'description', title: 'Описание' },
	]

	return isModelExist ? fields : [...fields, copyProjectSettings()]
}

export const editProjectFields = [copyProjectSettings(true)]

export const servicesField = (type, services) => ({
	id:       type.value,
	type:     'select',
	title:    type.label,
	disabled: !services.length,
	children: <ServiceOptions services={services} />,
})
