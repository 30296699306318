import { connect } from 'react-redux'
import {
	DispatchSelector,
	StateSelector,
} from 'reduxm/modules/selectors'
import { SignInPage } from './index'

const mapStateToProps = state => ({
	userState:   StateSelector.users.actions.current(state),
	authState:   StateSelector.auth.actions.signIn(state),
	signInState: StateSelector.auth.actions.signIn(state),
})

const mapDispatchToProps = {
	signIn: DispatchSelector.auth.actions.signIn,
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInPage)
