import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { Pagination } from 'components/global/Pagination'
import { Spinner } from 'components/global/Spinner'
import { EmptyMethodsList } from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/EmptyList'
import MethodsTable from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/MethodsTable/index.store'
import { ServicesList } from 'components/project/ServiceConfig/Authorization/SettingTabs/Methods/ServicesList'
import _ from 'lodash'
import { AuthorizationMethods } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
	useState,
} from 'react'
import { useParams } from 'react-router-dom'

/**
 * Render methods tab
 *
 * @param {boolean} isFetching
 * @param {Function} getMethodsList
 * @param {Object} methodsListState
 * @param {Object} attributesListState
 * @param {Function} createMethods
 * @param {Array.<Service>} services
 *
 * @return {*}
 * @constructor
 */
export const MethodsTab = ({
	getMethodsList,
	methodsListState,
	createMethods,
	services,
	isFetching,
}) => {
	const [serviceAlias, setServiceAlias] = useState(null)
	const [searchString, setSearchString] = useState('')
	const [page, setPage]                 = useState(1)

	const { settingTab } = useParams()

	const methods        = AuthorizationMethods.createList(methodsListState)
	const methodsService = StateService.create(methodsListState)

	const searchQuery = useMemo(() => DataProvider
		.buildQuery()
		.addBody({
			query: {
				expands: ['AttributeIn', 'AttributeOut'],
				filter:  {
					...(serviceAlias && { serviceAlias }),
					...(searchString && {
						or: [
							{ alias: { like: `%${searchString}%` } },
							{ name: { like: `%${searchString}%` } },
						],
					}),
				},
				page,
				perPage: 20,
			},
		}), [serviceAlias, page, searchString])

	useEffect(() => {
		if (serviceAlias && settingTab !== 'methods') {
			setServiceAlias(null)
		}
	}, [settingTab, serviceAlias])

	useEffect(() => {
		getMethodsList(searchQuery)
	}, [getMethodsList, searchQuery])

	useEffect(() => {
		setPage(1)
	}, [serviceAlias, searchString])

	const search = useCallback(_.debounce(name => {
		setSearchString(name)
	}, 400), [])

	const pagination = useMemo(() => <Pagination
		data={methodsService.getPagination()}
		action={nextPage => setPage(nextPage)}
	/>, [methodsService])

	/**
	 * Modify search query
	 *
	 * @param {Object} nextSearchQuery
	 * @param {Function} action
	 *
	 * @return {undefined}
	 *
	 */
	const modifySearchQuery = useCallback((nextSearchQuery, action) => {
		nextSearchQuery.setCallback(() => getMethodsList(nextSearchQuery))

		action(nextSearchQuery)
	}, [getMethodsList])

	const switchService = nextAlias => {
		setSearchString('')
		setServiceAlias(nextAlias)
	}

	const currentService = useMemo(() => services.find(service => service.getAlias() === serviceAlias),
		[serviceAlias, services])

	const isMethodsTableActive = useMemo(() => Boolean(serviceAlias && methods.length) ||
		Boolean(serviceAlias && searchString), [serviceAlias, methods.length, searchString])

	const isEmptyMethodsListActive = useMemo(() => serviceAlias && !methods.length && !searchString &&
		!methodsService.isFetching(), [serviceAlias, methods.length, methodsService, searchString])

	return (
		<>
			<ServicesList
				services={services}
				activeService={serviceAlias}
				switchService={switchService}
			/>
			<Spinner block isFetching={isFetching} />
			<MethodsTable
				isActive={isMethodsTableActive}
				methods={methods}
				searchString={searchString}
				setSearchString={search}
				currentService={currentService}
				modifySearchQuery={modifySearchQuery}
				totalItems={methodsService.getPagination()?.getTotalItems?.() ?? 0}
				pagination={pagination}
			/>
			<EmptyMethodsList
				isActive={isEmptyMethodsListActive}
				currentService={currentService}
				createMethod={nextSearchQuery => modifySearchQuery(nextSearchQuery, createMethods)}
			/>
		</>
	)
}

MethodsTab.propTypes = {
	createMethods:    PropTypes.func,
	getMethodsList:   PropTypes.func,
	isFetching:       PropTypes.bool,
	methodsListState: PropTypes.object,
	services:         PropTypes.array,
}

MethodsTab.defaultProps = {
	createMethods:    () => null,
	getMethodsList:   () => null,
	isFetching:       false,
	methodsListState: {},
	services:         [],
}
