import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router'
import {
	Col,
	Row,
} from 'reactstrap'
import styles from './styles.module.scss'

/**
 * Render project page container
 *
 * @param {Node} left
 * @param {Node} right
 *
 * @return {*}
 * @constructor
 */
export const Container = ({ left, right }) => {
	const { serviceType } = useParams()

	return (
		<div className={styles.container}>
			<Row className="justify-content-between">
				<Col md={12} lg={!serviceType ? 6 : 12}>
					{left}
				</Col>
				{!serviceType &&
				<Col md={12} lg={6}>
					{right}
				</Col>}
			</Row>
		</div>
	)
}

Container.propTypes = {
	left:  PropTypes.node,
	right: PropTypes.node,
}

Container.defaultProps = {
	left:  null,
	right: null,
}
