import { CardContent } from 'components/project/Home/Cards/CardContent'
import { ProjectCardHeader } from 'components/project/Home/Cards/ProjectCard/ProjectCardHeader'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React from 'react'
import { useParams } from 'react-router'
import styles from './styles.module.scss'

/**
 * Render Project card
 *
 * @param {Project} project
 * @param {Function} projectAction
 *
 * @return {*}
 * @constructor
 */
export const ProjectCard = ({ project, projectAction }) => {
	const { serviceType } = useParams()

	return (
		!serviceType &&
		<div className={styles.wrapper}>
			<div className={styles.title}>
				Карточка проекта
			</div>
			<div className={styles.body}>
				<ProjectCardHeader
					project={project}
					projectAction={projectAction}
				/>
				<CardContent model={project} />
			</div>
		</div>
	)
}

ProjectCard.propTypes = {
	project:       PropTypes.instanceOf(Project),
	projectAction: PropTypes.func.isRequired,
}

ProjectCard.defaultProps = {
	project: Project.create({}),
}
