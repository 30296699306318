import PropTypes from 'prop-types'
import React from 'react'
import styles from './styles.module.scss'

/**
 * Render user avatar
 *
 * @param {string} image
 *
 * @return {*}
 * @constructor
 */
export const UserAvatar = ({ image }) => (
	<div className={styles.wrapper}>
		<img className={styles.image} src={image} alt="avatar" />
		<div className={styles.title}>Ваше фото</div>
	</div>
)

UserAvatar.propTypes = {
	image: PropTypes.string,
}

UserAvatar.defaultProps = {
	image: '',
}
