import {
	put,
	takeLatest,
} from 'redux-saga/effects'
import { defaultAction } from 'reduxm/redux-saga/helpers/defaultAction'
import { FirebaseConfigActions } from 'reduxm/redux-saga/modules/control-panel/settings/firebase/actionCreators'
import { PROJECT_FIREBASE_CONFIG_ACTIONS } from 'reduxm/redux-saga/modules/control-panel/settings/firebase/actionTypes'
import { FirebaseConfigApi } from 'reduxm/redux-saga/modules/control-panel/settings/firebase/api'

/**
 * Worker for create/update methods
 *
 * @param {Object} data
 * @param {Object} data.response Response data
 * @param {Object} data.result Firebase config model
 *
 * @return {Generator<*, void, ?>}
 * */
function* creationWorker(data) {
	const { response, result: { model } } = data

	yield put(FirebaseConfigActions.setView({ model, response }))
}

/**
 * View firebase config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* view(action) {
	yield defaultAction(action, FirebaseConfigApi.view)
}

/**
 * Remove firebase config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* remove(action) {
	yield defaultAction(action, FirebaseConfigApi.remove, null, null, function* (data) {
		if (data?.result?.result) {
			yield put(FirebaseConfigActions.setView(null))
		}
	})
}

/**
 * Updated firebase config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* update(action) {
	yield defaultAction(action, FirebaseConfigApi.update, null, null, function* (...params) {
		yield creationWorker(...params)
	})
}

/**
 * Create firebase config model
 *
 * @param {Object} action Action
 * @param {string} action.type Action name
 * @param {Object} action.payload Action payload
 *
 * @return {Generator<*, void, ?>}
 */
function* create(action) {
	yield defaultAction(action, FirebaseConfigApi.create, null, null, function* (...params) {
		yield creationWorker(...params)
	})
}

export default [
	takeLatest(PROJECT_FIREBASE_CONFIG_ACTIONS.CREATE, create),
	takeLatest(PROJECT_FIREBASE_CONFIG_ACTIONS.REMOVE, remove),
	takeLatest(PROJECT_FIREBASE_CONFIG_ACTIONS.UPDATE, update),
	takeLatest(PROJECT_FIREBASE_CONFIG_ACTIONS.VIEW, view),
]
