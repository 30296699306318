import DataProvider from '@kakadu-dev/base-frontend-helpers/helpers/DataProvider'
import styles from 'components/global/RemoveModel/RemoveModal/styles.module.scss'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useMemo,
	useRef,
	useState,
} from 'react'
import {
	Button,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
} from 'reactstrap'

/**
 * Render modal window
 *
 * @param {function} action
 * @param {string} actionName
 * @param {string} modelName
 * @param {Object} id
 * @param {Object} children
 *
 * @return {*}
 * @constructor
 */
export const RemoveModal = ({
	action,
	actionName,
	modelName,
	id,
	children,
}) => {
	const [isModalActive, setIsModalActive]             = useState(false)
	const [inputValue, setInputValue]                   = useState('')
	const [isValidationSuccess, setIsValidationSuccess] = useState(false)

	const inputRef = useRef()

	const modalToggle = useCallback(() => {
		setIsModalActive(!isModalActive)
		setInputValue('')
	}, [isModalActive])

	const showWrongName = useCallback(() => {
		setInputValue('')
		setIsValidationSuccess(true)
		inputRef.current.focus()
		setTimeout(() => setIsValidationSuccess(false), 2000)
	}, [])

	const searchQuery = useMemo(() => DataProvider
		.buildQuery()
		.addBody(id), [id])

	const remove = useCallback(() => {
		if (inputValue === modelName) {
			action(searchQuery)
		} else {
			showWrongName()
		}
	}, [inputValue, modelName, action, searchQuery, showWrongName])

	const modalText = useCallback(() => (
		<span>
			Чтобы подтвердить выбранное действие,
			<br />
			введите
			<b>
				{' '}
				{modelName}
				{' '}
			</b>
			в поле ниже:
		</span>
	), [modelName])

	const enterKeyListener = useCallback(event => {
		if (event.key === 'Enter' && inputValue.length >= modelName.length) {
			remove()
		}
	}, [inputValue.length, modelName.length, remove])

	return (
		<>
			{React.cloneElement(children,
				{
					className: [children.props.className, styles.customBtn].join(' '),
					onClick:   modalToggle,
				})}
			<Modal isOpen={isModalActive} toggle={modalToggle} className={styles.modalWrapper}>
				<ModalHeader toggle={modalToggle}>
					Подтвердите действие
				</ModalHeader>
				<ModalBody>
					<div className={styles.input}>
						<Label for="input">{modalText()}</Label>
						<div className={[styles.wrongName, isValidationSuccess && styles.showWrongName].join(' ')}>
							Названия не совпадают
						</div>
						<Input
							innerRef={inputRef}
							id="input"
							value={inputValue}
							onKeyDown={enterKeyListener}
							onChange={event => setInputValue(event.target.value)}
							placeholder="Введите здесь..."
						/>
					</div>
				</ModalBody>
				<ModalFooter>
					<Button
						color="danger"
						onClick={remove}
						disabled={inputValue.length < modelName.length}
					>
						{_.startCase(actionName)}
					</Button>
					<Button color="primary" onClick={modalToggle}>Отмена</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

RemoveModal.propTypes = {
	action:     PropTypes.func.isRequired,
	actionName: PropTypes.string.isRequired,
	id:         PropTypes.object.isRequired,
	modelName:  PropTypes.string.isRequired,
	children:   PropTypes.node,
}

RemoveModal.defaultProps = {
	children: null,
}
