import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { CreateModelButton } from 'components/global/CreateModelButton'
import { ModelsCounter } from 'components/global/ModelsCounter'
import { RemoveModel } from 'components/global/RemoveModel'
import { Spinner } from 'components/global/Spinner'
import {
	Table,
	TableActions,
	TableCol,
	TableHeader,
	TableInfoWrapper,
	TableNameCol,
	TableRow,
	TableWrapper,
} from 'components/global/Table'
import { UpdateModel } from 'components/global/UpdateModel'
import { headerTitles } from 'components/project/Setting/SettingTabs/MailCredentialsTable/headerTitles'
import { mailCredentialFields } from 'forms/control-panel/settings/mail'
import { getCredentialsForm } from 'forms/control-panel/settings/utils'
import { MailCredentials } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
} from 'react'

const tableName = 'mailCredentials'

const searchQuery = (projectId, page = 1) => ({ query: { filter: { projectId }, page } })

/**
 * Render mail credentials table
 *
 * @param {Object} mailCredentialsState
 * @param {Function} getMailCredentials
 * @param {Function} createMailCredentials
 * @param {Function} updateMailCredentials
 * @param {Function} removeMailCredentials
 * @param {Project} project
 * @param {boolean} isFetching
 *
 * @return {*}
 * @constructor
 */
export const MailCredentialsTable = ({
	mailCredentialsState,
	getMailCredentials,
	createMailCredentials,
	updateMailCredentials,
	removeMailCredentials,
	project,
	isFetching,
}) => {
	const projectId = project.getId()

	useEffect(() => {
		getMailCredentials(searchQuery(projectId))
	}, [getMailCredentials, projectId])

	const mailCredentials        = MailCredentials.createList(mailCredentialsState)
	const mailCredentialsService = StateService.create(mailCredentialsState)

	const removeModel = useCallback(nextSearchQuery => {
		nextSearchQuery.setCallback(() => getMailCredentials(searchQuery(projectId)))

		removeMailCredentials(nextSearchQuery)
	}, [getMailCredentials, projectId, removeMailCredentials])

	const getForm = getCredentialsForm(mailCredentialFields, mailCredentials)

	return (
		<Table
			paginationData={mailCredentialsService.getPagination()}
			paginationAction={page => getMailCredentials(searchQuery(projectId, page))}
		>
			<Spinner block isFetching={isFetching} />
			<TableInfoWrapper>
				<ModelsCounter count={mailCredentials.length} />
				<CreateModelButton
					initialData={{ projectId: project.getId() }}
					action={createMailCredentials}
					formFields={getForm()}
					title="Создать настройки Mail"
				/>
			</TableInfoWrapper>
			<TableHeader titles={headerTitles} tableName={tableName} />
			{(mailCredentials || []).map(credential => (
				<TableWrapper key={`${credential.getProjectId()}-${credential.getServiceAlias()}`}>
					<TableRow tableName={tableName}>
						<TableCol>
							<TableNameCol>
								{credential.getServiceAlias(true)}
							</TableNameCol>
						</TableCol>
						<TableCol title={credential.getHost()} />
						<TableCol title={credential.getPort()} />
						<TableCol title={credential.getUser()} />
						<TableCol title={credential.getEncryption()} />
					</TableRow>
					<TableActions>
						<UpdateModel
							model={credential}
							action={updateMailCredentials}
							formFields={getForm(credential.getServiceAlias())}
							title="Редактировать Mail настройки"
							tip
						/>
						<RemoveModel
							id={{
								projectId: credential.getProjectId(),
								service:   credential.getServiceAlias(),
							}}
							modelName={credential.getServiceAlias(credential)}
							action={removeModel}
							actionName="удалить"
							tip
						/>
					</TableActions>
				</TableWrapper>))}
		</Table>
	)
}

MailCredentialsTable.propTypes = {
	createMailCredentials: PropTypes.func,
	getMailCredentials:    PropTypes.func,
	isFetching:            PropTypes.bool,
	project:               PropTypes.object.isRequired,
	mailCredentialsState:  PropTypes.object,
	removeMailCredentials: PropTypes.func,
	updateMailCredentials: PropTypes.func,
}

MailCredentialsTable.defaultProps = {
	createMailCredentials: () => null,
	getMailCredentials:    () => null,
	isFetching:            false,
	mailCredentialsState:  {},
	removeMailCredentials: () => null,
	updateMailCredentials: () => null,
}
