import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'
import {
	MoreVertRounded as Actions,
	SettingsInputAntennaRounded as Workers,
} from '@material-ui/icons'
import { Button } from 'components/global/Button'
import { IosSwitch } from 'components/global/IosSwitch'
import { useCheckOutsideClick } from 'components/global/useCheckOutsideClick'
import ServiceCardActions from 'components/project/Home/Cards/ServiceCard/ServiceCardActions/index.store'
import { CardHeader } from 'components/project/Home/Cards/ServiceCard/ServiceCardHeader'
import styles from 'components/project/Home/Cards/ServiceCard/styles.module.scss'
import { Project } from 'models'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'

/**
 * Render service card
 *
 * @param {Service} service
 * @param {Project} project
 * @param {Function} toggle
 * @param {Object} serviceInfo
 * @param {string} type
 *
 * @return {*}
 * @constructor
 */
export const ServiceCard = ({
	service,
	project,
	serviceInfo,
	toggle,
	type,
}) => {
	const [isActionsOpen, setIsActionsOpen] = useState(false)
	const [isSwitchedOn, setIsSwitchedOn]   = useState(false)

	useEffect(() => {
		setIsSwitchedOn(serviceInfo.workers > 0)
	}, [serviceInfo])

	const toggleAction = useCallback(() => {
		toggle(isSwitchedOn ? 0 : 1)
		setIsSwitchedOn(!isSwitchedOn)
	}, [toggle, isSwitchedOn])

	const actionsRef = useRef()

	useCheckOutsideClick(actionsRef, isActionsOpen, setIsActionsOpen)

	return (
		<div className={[styles.wrapper, serviceInfo.workers < 1 && styles.switchedOff].join(' ')}>
			<Button
				ref={actionsRef}
				onClick={() => setIsActionsOpen(!isActionsOpen)}
				className={styles.actions}
			>
				<Actions />
			</Button>
			<ServiceCardActions
				project={project}
				service={service}
				type={type}
				taskCount={serviceInfo.workers || 0}
				isOpen={isActionsOpen}
				top={58}
				close={() => setIsActionsOpen(false)}
			/>
			<CardHeader
				model={service}
				type={type}
				{...(type === 'site' && { iconAlias: 'site' })}
			/>
			<div className={styles.switchWrapper}>
				<div className={styles.switch}>
					<IosSwitch
						label="Включен"
						action={toggleAction}
						checked={isSwitchedOn}
					/>
				</div>
			</div>
			<div className={styles.workersInfo}>
				<Workers />
				Workers:
				<div className={styles.workersCount}>
					{serviceInfo.workers || 0}
				</div>
			</div>
		</div>
	)
}

ServiceCard.propTypes = {
	project:     PropTypes.instanceOf(Project),
	service:     PropTypes.instanceOf(BaseModel),
	serviceInfo: PropTypes.object,
	toggle:      PropTypes.func,
	type:        PropTypes.string,
}

ServiceCard.defaultProps = {
	project:     Project.create({}),
	service:     BaseModel.create({}),
	serviceInfo: {},
	toggle:      () => null,
	type:        '',
}
