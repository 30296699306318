import BaseModel from '@kakadu-dev/base-frontend-helpers/models/BaseModel'

/**
 * MailCredentials model class
 */
class MailCredentials extends BaseModel {
	/**
	 * Create MailCredentials instance
	 *
	 * @param {Object} object
	 *
	 * @returns {MailCredentials}
	 */
	static create(object) {
		return super.create(object?.result?.model || object.result || object)
	}

	/**
	 * Create MailCredentials list
	 *
	 * @param {object} objects
	 *
	 * @return {Array.<MailCredentials>}
	 */
	static createList(objects) {
		return super.createList(objects)
	}

	/**
	 * Map MailCredentials
	 *
	 * @param {Array.<object>} objects
	 * @param {function(MailCredentials, number): undefined} callback
	 *
	 * @return {Array.<object>}
	 */
	static map(objects, callback) {
		return super.map(objects, callback)
	}

	/**
	 * Get project id
	 *
	 * @returns {number}
	 */
	getProjectId = () => this.model.projectId

	/**
	 * Get service alias
	 *
	 * @returns {string}
	 */
	getServiceAlias = (asLabel = false) => (asLabel &&
		this.model.service === '*' && 'Все') || this.model.service

	/**
	 * Get host
	 *
	 * @returns {string}
	 */
	getHost = () => this.model.host

	/**
	 * Get port
	 *
	 * @returns {number}
	 */
	getPort = () => this.model.port

	/**
	 * Get password
	 *
	 * @returns {string}
	 */
	getPassword = () => this.model.password

	/**
	 * Get user
	 *
	 * @returns {Array}
	 */
	getUser = () => this.model.user

	/**
	 * Get encryption
	 *
	 * @returns {string}
	 */
	getEncryption = () => this.model.encryption

	/**
	 * Get created at
	 *
	 * @returns {string}
	 */
	getCreatedAt = () => this.model.createdAt

	/**
	 * Get updated at
	 *
	 * @returns {string}
	 */
	getUpdatedAt = () => this.model.updatedAt

	/**
	 * Get attributes
	 *
	 *  @return {Object}
	 */
	getAttributes = () => {
		const { createdAt, updatedAt, ...attributes } = this.model

		return attributes
	}
}

export default MailCredentials
