import {
	MultipleInput,
	ServiceOptions,
} from 'forms/fields'
import React from 'react'

export const awsCredentialFields = (excluded, currentService) => [
	{ id:         'service',
		title:    'Сервис',
		type:     'select',
		children: <ServiceOptions excluded={excluded} currentService={currentService} /> },
	{ id: 'accessKeyId', title: 'ID ключа доступа' },
	{ id: 'accessSecretKey', title: 'ID секретного ключа' },
	{ id: 'region', title: 'Регион' },
	{ id: 'bucketName', title: 'Имя ведра' },
	{ id: 'bucketCdn', title: 'CDN ведра', keyName: '', component: <MultipleInput /> },
]
