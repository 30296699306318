import StateService from '@kakadu-dev/base-frontend-helpers/services/StateService'
import { Spinner } from 'components/global/Spinner'
import { Title } from 'components/global/Title'
import { ProjectCard } from 'components/project/Home/Cards/ProjectCard'
import { Container } from 'components/project/Home/Container'
import { Graphic } from 'components/project/Home/Graphic'
import { ServicesPanel } from 'components/project/Home/ServicesPanel'
import { SideBar } from 'components/project/Home/SideBar'
import { ServiceConfig } from 'components/project/ServiceConfig'
import { ProjectSettings } from 'components/project/Setting'
import styles from 'containers/Project/styles.module.scss'
import {
	Project,
	Service,
} from 'models'
import Site from 'models/control-panel/Site'
import PropTypes from 'prop-types'
import React, {
	useCallback,
	useEffect,
	useMemo,
} from 'react'

/**
 * Render Project page
 *
 * @param {Function} getProject
 * @param {Function} getServices
 * @param {Function} getServicesInfo
 * @param {Function} getSites
 * @param {Object} projectState
 * @param {Object} servicesState
 * @param {Object} servicesInfoState
 * @param {Object} connectServiceState
 * @param {Object} sitesState
 * @param {Function} updateProject
 * @param {Function} changeTaskCount
 * @param {boolean} isFetching
 * @param {Object} match
 *
 * @return {*}
 * @constructor
 */
export const ProjectPage = ({
	getProject,
	getServices,
	getServicesInfo,
	getSites,
	projectState,
	servicesState,
	servicesInfoState,
	connectServiceState,
	changeTaskCount,
	sitesState,
	updateProject,
	isFetching,
	match: { params: { alias } },
}) => {
	const project      = Project.create(projectState)
	const servicesInfo = StateService.create(servicesInfoState)
	const allServices  = Service.createList(servicesState).filter(service => service.getAlias() !== 'control-panel')
	const allSites     = Site.createList(sitesState)

	const connectedServices = project.getServices(true)

	const projectSearchQuery = useMemo(() => ({
		alias,
		query: { expands: ['Services', 'Sites'] },
	}), [alias])

	useEffect(() => {
		getServices()
		getSites()
	}, [getServices, getSites])

	useEffect(() => {
		getProject(projectSearchQuery)
	}, [connectServiceState.result, getProject, projectSearchQuery])

	useEffect(() => {
		if (project.isExist() && connectedServices.length &&
			!servicesInfo.isFetching() && servicesInfo.isEmptyResult()) {
			getServicesInfo({
				projectAlias:    project.getAlias(),
				servicesAliases: connectedServices.map(service => service.getAlias()),
			})
		}
	}, [getServicesInfo, project, connectedServices, servicesInfo])

	const notConnectedServices = useMemo(() => allServices
		.filter(service => !project.getServices()
			.map(connectedService => connectedService.getAlias())
			.includes(service.getAlias())), [allServices, project])

	const notConnectedSites = useMemo(() => allSites
		.filter(site => !project.getSites()
			.map(connectedSite => connectedSite.getAlias())
			.includes(site.getAlias())), [allSites, project])

	const projectAction = useCallback(data => {
		if (!data) {
			updateProject({
				attributes: {
					...project.getAttributes(),
					published: !project.isPublished(),
				},
			})
		} else {
			updateProject(data)
		}
	}, [project, updateProject])

	const rightContent = <Graphic title={project.getTitle()} />

	const leftContent = (project.isExist() &&
		<>
			<ProjectCard project={project} projectAction={projectAction} />
			<ProjectSettings project={project} />
			<ServiceConfig project={project} />
		</>
	)

	return (
		<section className={styles.section}>
			<Spinner isFetching={isFetching} block />
			<SideBar project={project} />
			<Title breadcrumbs name={`Проект ${project.getTitle()}`} />
			<Container left={leftContent} right={rightContent} />
			<ServicesPanel
				project={project}
				notConnectedSites={notConnectedSites}
				notConnectedServices={notConnectedServices}
				changeTaskCount={changeTaskCount}
				servicesInfo={servicesInfoState}
			/>
		</section>
	)
}

ProjectPage.propTypes = {
	changeTaskCount:     PropTypes.func,
	connectServiceState: PropTypes.object,
	getProject:          PropTypes.func,
	getServices:         PropTypes.func,
	getServicesInfo:     PropTypes.func,
	getSites:            PropTypes.func,
	isFetching:          PropTypes.bool,
	match:               PropTypes.object,
	projectState:        PropTypes.object,
	servicesInfoState:   PropTypes.object,
	servicesState:       PropTypes.object,
	sitesState:          PropTypes.object,
	updateProject:       PropTypes.func,
}

ProjectPage.defaultProps = {
	changeTaskCount:     () => null,
	connectServiceState: {},
	getProject:          () => null,
	getServices:         () => null,
	getServicesInfo:     () => null,
	getSites:            () => null,
	isFetching:          false,
	match:               {},
	projectState:        {},
	servicesInfoState:   {},
	servicesState:       {},
	sitesState:          {},
	updateProject:       () => null,
}
